import BaseMoviesContainer from '@components/BaseMoviesContainer/BaseMoviesContainer'
import MovieShelfItem from '@components/MovieShelfItem/MovieShelfItem';
import SearchMobile from '@components/SearchMobile/SearchMobile';
import { useWindowDimensions } from '@hooks/useWindowDimension';
import MovieSearchMobile from '@pages/MovieSearchMobile/MovieSearchMobile';
import { changeColorMode } from '@utils/colors';
import { runTimeTransform } from '@utils/runTimeTransform';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useSearchShowsQuery } from 'services/movie.services'
import { uiSelector } from 'store/selectors';

const SearchMovies = () => {

    const isDarkMode = useSelector(uiSelector).darkMode
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const searchName = searchParams.get('searchTerm');
    const { isMobile } = useWindowDimensions()

    const { data } = useSearchShowsQuery(
        searchName ? searchName : '',
    )

    const moviesData = !!searchName?.length && data?.map((item: any) => ({
        ...item,
        movieTitle: item.title,
        movieImgUrl: item.poster_url
    }))


    if (isMobile) {
        return <MovieSearchMobile />
    }

    return (
        <BaseMoviesContainer>
            <div className='base_movies_content'>
                <h1 style={{ margin: '12px 0px', color: changeColorMode(isDarkMode) }}>Search Results for "{searchName}"</h1>
                {!!moviesData?.length && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 12 }}>
                    {searchName?.length && moviesData?.map((movie: any) => {
                        const serviceItem = movie?.sources || []
                        const img = movie.img || `https://img.rgstatic.com/content/show/${movie.id}/backdrop-1280.webp`
                        
                        if (!movie.availability) return <></>

                        if ( (movie.availability && movie.availability.length === 0) ) return <></>
                        return (
                            <MovieShelfItem
                                movieClassification={movie?.classification}
                                tags={movie?.tags}
                                runTime={movie?.content_type === 'show' ? `${movie?.episodes_count} eps` : `${runTimeTransform(movie?.runtime)}`}
                                type={movie.content_type}
                                movieProvider={serviceItem}
                                linkTo={`${movie?.id}`}
                                key={movie?.id}
                                movieImgUrl={img}
                                movieTitle={movie.movieTitle}
                                onPressMovie={() => { }}
                            />
                        )
                    })}
                </div>}
            </div>
        </BaseMoviesContainer>
    )
}

export default SearchMovies