import { PhotoIcon } from '@components/IconComponents/IconComponents'
import { useWindowDimensions } from '@hooks/useWindowDimension'
import { useSelector } from 'react-redux'
import { uiSelector } from 'store/selectors'
import './TopSection.scss'
import ButtonSettings from '../ButtonsSettings/ButtonsSettings'
import Avatar from '@components/Avatar/Avatar'
import { changeColorMode } from '@utils/colors'
import { useLocation } from 'react-router-dom'
import ArtistImage from 'assets/images/artistimage.png'

interface TopSectionProps {
    Title: string
    Length: number
    TotalTime: string
    Photo: string
    ArtistName?: string
    clickHandler?: Function

}
const TopSection = ({ Length, Title, TotalTime, Photo, ArtistName, clickHandler}: TopSectionProps) => {

    const { pathname } = useLocation()
    const isAlbumPage = pathname.includes('album')
    const isDarkMode = useSelector(uiSelector).darkMode
    const { isMobile } = useWindowDimensions()

    return (
        <div className='top-section-container'>
            <div className='playlist-photo-container'
                style={{
                    backgroundImage: `url(${Photo})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    width: isMobile ? '133px' : '247px',
                    height: isMobile ? '133px' : '247px',
                }}
            >
                {!Photo && <>
                    <PhotoIcon className={"photoIcon"} />
                    <span style={{ color: changeColorMode(isDarkMode) }}>Add photo</span>
                </>}
            </div>
            <div className='top-right-section'>
                <h2 className='playlist-title' style={{ color: changeColorMode(isDarkMode) }}>{Title}</h2>
                <span className='playlist-length' style={{ color: changeColorMode(isDarkMode) }}>{Length} songs {TotalTime}</span>
                {(isAlbumPage && !isMobile) ? <div className='artist_image_name'>
                    {/* <img src={ArtistImage} alt='Artist' /> */}
                    <span style={{ color: changeColorMode(isDarkMode) }}>{ArtistName}</span>
                </div> : !isMobile && <Avatar showName />}
                <div className='buttons-container'>
                    <ButtonSettings clickHandler={clickHandler} />
                </div>
            </div>
        </div>
    )
}

export default TopSection