import { useState } from 'react'
import MusicStreamingSection from '@components/MusicStreamingSection/MusicStreamingSection'
import { musicLinksList } from '@utils/navigationsLinks/profileDropdownList'
import { radioMockedData } from 'mockdatas/radioMockedData'
import Base from 'template/Base'
import createRadioImg from 'assets/images/radio/create_radio_img.png'
import CreateRadioModal from './components/CreateRadioModal/CreateRadioModal'
import { useWindowDimensions } from '@hooks/useWindowDimension'

const RadioPage = () => {
    const [isLoading, setLoader] = useState<boolean>(true)

    const { isMobile } = useWindowDimensions()
    const [openModalCreateRadio, setOpenModalCreateRadio] = useState(false)
    const handleOpenModalCreateRadio = () => {
        setOpenModalCreateRadio(true)
    }
    const handleCloseModalCreateRadio = () => {
        setOpenModalCreateRadio(false)
    }

    const handleSaveRadio = () => {
        alert("Radio saved")
        handleCloseModalCreateRadio()
    }

    const sideBarList = isMobile && openModalCreateRadio ? [] : isMobile && !openModalCreateRadio ? musicLinksList : musicLinksList

    return (
        <>
            <Base sidebarList={sideBarList}>
                <article className='music__page__container'>
                    <MusicStreamingSection
                        isLoading={isLoading}
                        type={'radio'}
                        title="Radio For You"
                        additionalImage={createRadioImg}
                        additionItem='Create your radio'
                        onClickAdditional={handleOpenModalCreateRadio}
                        musicList={radioMockedData} />
                    <MusicStreamingSection type={'radio'} title="Top Broadcasters" musicList={radioMockedData}  isLoading={isLoading} />
                    <MusicStreamingSection type={'radio'} title="Top artists" musicList={radioMockedData}  isLoading={isLoading} />
                </article>
            </Base>
            <CreateRadioModal
                visible={openModalCreateRadio}
                handleSaveRadio={handleSaveRadio}
                handleCloseModal={handleCloseModalCreateRadio}
            />
        </>
    )
}

export default RadioPage