import React from 'react';
import { useAuth0 } from '@auth0/auth0-react'
import { Navigate, useLocation } from 'react-router-dom'
import Loading from '@components/Loading/Loading';

const ProtectedRoute: React.FC<{ children?: React.ReactElement }> = ({ children }) => {

    const location = useLocation()
    const { isAuthenticated, isLoading } = useAuth0();
    
    if(isLoading) return <Loading />
    if (isAuthenticated) {
        return <>{children}</>
    } else {
        return <Navigate to='/' />
    }
}

export default ProtectedRoute;