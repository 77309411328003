export const singers = [
    "Beyoncé",
    "Adele",
    "Taylor Swift",
    "Ed Sheeran",
    "ArianaGrande",
    "Bruno Mars",
    "Rihanna",
    "Justin Bieber",
    "Katy Perry",
    "Billie Eilish",
    "FreddieMercury",
    "Mick Jagger",
    "Kurt Cobain",
    "Chris Martin",
    "Amy Lee",
    "Dave Grohl",
    "Thom Yorke",
    "Eddie Vedder",
    "Robert Plant",
    "Brandon Flowers",
    "ArethaFranklin",
    "MarvinGaye",
    "WhitneyHouston",
    "StevieWonder",
    "Ray Charles",
    "AliciaKeys",
    "Sam Cooke",
    "EttaJames",
    "Usher",
    "Mary J. Blige",
    "TupacShakur",
    "TheNotoriousB.I.G.",
    "Jay-Z",
    "Eminem",
    "KanyeWest",
    "Nas",
    "KendrickLamar",
    "Drake",
    "Cardi B",
    "NickiMinaj",
    "JohnnyCash",
    "DollyParton",
    "WillieNelson",
    "ShaniaTwain",
    "GeorgeStrait",
    "CarrieUnderwood",
    "BlakeShelton",
    "MirandaLambert",
    "KennyChesney",
    "LukeBryan",
    "LouisArmstrong",
    "EllaFitzgerald",
    "MilesDavis",
    "BillieHoliday",
    "DukeEllington",
    "NatKingCole",
    "SarahVaughan",
    "FrankSinatra",
    "JohnColtrane",
    "NinaSimone",
    "WolfgangAmadeusMozart",
    "LudwigvanBeethoven",
    "JohannSebastianBach",
    "GiuseppeVerdi",
    "JohannStraussII",
    "MariaCallas",
    "LucianoPavarotti",
    "Yo-YoMa",
    "ItzhakPerlman",
    "LangLang",
    "DaftPunk",
    "CalvinHarris",
    "Skrillex",
    "DavidGuetta",
    "Avicii",
    "TheChemicalBrothers",
    "Zedd",
    "Marshmello",
    "Diplo",
    "Tiesto",
    "BobDylan",
    "JoniMitchell",
    "Simon&Garfunkel",
    "JamesTaylor",
    "JoanBaez",
    "CatStevens",
    "TracyChapman",
    "NeilYoung",
    "LeonardCohen",
    "Mumford&Sons",
    "BobMarley",
    "PeterTosh",
    "JimmyCliff",
    "DamianMarley",
    "TootsHibbert",
    "BujuBanton",
    "Shaggy",
    "ZiggyMarley",
    "SeanPaul",
    "Chronixx"
  ];
  
  