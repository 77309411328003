const { REACT_APP_BACKEND_URL } = process.env || 'http://localhost:8080'

class Api {
  static async post(data: any, route: string): Promise<any> {
    const url = `${REACT_APP_BACKEND_URL}/api${route}`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status}`);
      }

      const result = await response.json();
      return result
    } catch (error) {
      throw error;
    }
  }


  static async get(data: any, route: string): Promise<any> {
    return new Promise((resolve, reject) => {

      const queryParams = new URLSearchParams(data);
      const url = `${REACT_APP_BACKEND_URL}/api${route}?${queryParams}`      

      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json', // Set the appropriate content type
          // You can add other headers if needed
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.status}`);
          }
          return response.json(); // Parse the response as JSON
        })
        .then((responseData) => {
          // Handle the responseData here
          resolve(responseData);
        })
        .catch((error) => {
          reject(error);
        });
    })
  }

  static async delete(route: string, data?: any): Promise<any> {
    const url = `${REACT_APP_BACKEND_URL}/api${route}`;

    try {
      const options: RequestInit = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          // Add other headers if needed
        },
      };

      // Include body if data is provided
      if (data) {
        options.body = JSON.stringify(data);
      }

      const response = await fetch(url, options);

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status}`);
      }

      // Some DELETE requests might not return a JSON response.
      // Adjust the following line based on your API's behavior.
      return await response.json();
    } catch (error) {
      throw error;
    }
  }
}

export default Api