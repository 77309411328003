import { SpotifyApi, AccessToken, UserProfile } from '@spotify/web-api-ts-sdk'
import { IMergeMusicProviders } from '../../interfaces/providers/provider-interface'
import { ISong, MediaType, MusicPlatform } from 'interfaces/models/model-interfaces'

import store from '../../store'
import { sendStreamAction } from 'actions/stream/stream-actions'
import { BAD_TOKEN_ERROR_REFRESHTOKEN, SpotifyHasLoggedIn, SpotifyRetryLogIn, SpotifyTokenKey, USerProfileKey } from '../../constants'
import Api from '../../services/api'
import SpotifyClientApi from './SpotifyApi'
import { User } from '@auth0/auth0-spa-js'
import SpotifyParser from './spotify-parser'
import { isTokenExpired } from './spotify-utils'

const clientId = "55e7ffa1c8c542daa9dbfb8e4116fb93"
let toparttistCount = 0

let sdk: any
let spotifyJS: any

interface SpotifyModel {
    token: string
}

class SpotifyMusicProviders implements IMergeMusicProviders {

    private hasUserProfile: any

    constructor() {
        SpotifyMusicProviders.initialize()
    }

    public static async initialize() {
        const isLoggenIn = localStorage.getItem(SpotifyHasLoggedIn)

        if (isLoggenIn === "true") {
            const userProfile: User = JSON.parse(localStorage.getItem(USerProfileKey) as string)
            const response: any = await Api.get(`email=${userProfile.email}`, '/spotify/token')
            const { tokens } = response


            if (tokens && Object.entries(tokens).length > 0) {

                SpotifyMusicProviders.saveAccessTokenToLocalStorage(tokens)

                if (await isTokenExpired()) {
                    await SpotifyMusicProviders.renewToken()
                }

                store.dispatch(sendStreamAction(true))
            }
        }
    }

    public async isSubscribed(): Promise<boolean> {
        try {
            const isLoggenIn = localStorage.getItem(SpotifyHasLoggedIn)

            if (!sdk) {
                if (isLoggenIn === "true") {
                    await SpotifyMusicProviders.initialize()
                } else {
                    return false
                }
            }
            let token = await sdk.getAccessToken()
            if (token) {
                let { access_token, expires } = token
                if (access_token === undefined || (expires && expires < Date.now())) {
                    if (isLoggenIn === "true") {
                        await SpotifyMusicProviders.initialize()
                    } else {
                        return false
                    }
                    token = await sdk.getAccessToken()
                    if (token) {
                        return true
                    } else {
                        return false
                    }
                }

                return (access_token !== '')
            }
            return (token !== null)

        } catch (error) {
            return false
        }
    }

    public async searchUserPlaylists(): Promise<any[]> {
        if (await isTokenExpired()) {
            await SpotifyMusicProviders.renewToken()
        }

        try {
            console.log(`topArtistCount: ${toparttistCount++}`)

            const user: User = await SpotifyClientApi.getProfile()
            const playlistsInfo = await SpotifyClientApi.getPlaylists(user.id)
            const playlistsCalls = playlistsInfo.items?.map((playlist: any) => SpotifyClientApi.getPlaylist(playlist.id))
            const playListResults = await Promise.all(playlistsCalls)
            const result = SpotifyParser.parsePlaylist(playListResults)
            return result

        } catch (error) {
            return []
        }

    }

    public async searchArtists(artistsArray: string[], type: string): Promise<any> {
        if (await isTokenExpired()) {
            await SpotifyMusicProviders.renewToken()
        }
        try {
            const typeName = type.slice(0, -1)
            const apiCall = artistsArray.map((artist) => sdk.search(artist, [typeName]))
            const results: any[] = await Promise.all(apiCall)
            const artists: any[] = results.map((result) => result[type]?.items[0])
            const resultFlat = artists.flat().filter((item: any) => item !== undefined)
            const parsedResult = type === 'albums' ? SpotifyParser.parseAlbums(resultFlat) : SpotifyParser.parseArtists(resultFlat)
            return parsedResult
        } catch (error) {
            console.error(error)
            this.checkError(error)
            return []
        }
    }

    public async searchUserFavorites(type: string = 'tracks'): Promise<any> { // Search Artist
        if (await isTokenExpired()) {
            await SpotifyMusicProviders.renewToken()
        }
        try {
            const result = await SpotifyClientApi.getTopArtists(type)

            if (result.error) {
                await SpotifyMusicProviders.renewToken()
                return []
            }
            const artists = SpotifyParser.parseResponse(result)

            return artists
        } catch (error) {
            this.checkError(error)
        }
    }

    public static getApi(): SpotifyApi {
        return sdk
    }

    public async login() {
        // await sdk.currentUser.profile()
        const result = await Api.get({}, '/spotify/login')
        const { url }: any = result
        localStorage.setItem(SpotifyHasLoggedIn, "true")
        window.location.href = url
    }

    public async logout() {
        try {
            await sdk.logOut()
            const { email }: User = JSON.parse(localStorage.getItem(USerProfileKey) as string)
            Api.post({ email }, '/spotify/removetokens')
            localStorage.setItem(SpotifyTokenKey, '{}')
            localStorage.setItem(SpotifyHasLoggedIn, "false")
            store.dispatch(sendStreamAction(false))
        } catch (error) {
            store.dispatch(sendStreamAction(false))
        }

    }

    public async getEpisode(id: string): Promise<any> {
        const episode = await SpotifyClientApi.getEpisode(id)
        return episode
    }

    public async searchAlbum(album: string): Promise<any> {

    }

    public async searchArtist(artist: string): Promise<any> {

    }

    public async searchUserPodcasts(): Promise<ISong[]> {
        try {
            if (await isTokenExpired()) {
                await SpotifyMusicProviders.renewToken()
            }
            const userPodcasts = await SpotifyClientApi.getUserPodcasts()
            const podcastNames = userPodcasts.items?.map((podcast: any) => podcast.show.name)
            const parsedResponse = await this.searchPodcasts(podcastNames)
            return parsedResponse
        } catch (error) {
            return []
        }
    }

    public async searchPodcasts(podcastNames: string[]): Promise<ISong[]> {
        if (await isTokenExpired()) {
            await SpotifyMusicProviders.renewToken()
        }
        try {
            // const podcastsCalls = podcastNames.map((name) => sdk.search(name, ['show']))
            const podcastsCalls = podcastNames.map((name) => SpotifyClientApi.searchPodcast(name))
            const podcastsResult = await Promise.all(podcastsCalls)
            const resultFlattened = podcastsResult.flat().filter((item: any) => item !== undefined)
            const parsedResult = SpotifyParser.parsePodcasts(resultFlattened)
            return parsedResult
        } catch (error) {
            console.error(error)
            return []
        }

    }

    public async searchSong(song: string): Promise<any> {
        if (await isTokenExpired()) {
            await SpotifyMusicProviders.renewToken()
        }
        return new Promise(async (resolve, reject) => {
            // await this.renewToken()
            const result = await sdk.Tracks.get(song).catch((error: any) => {
                reject(error)
            })

            resolve(result)
        })
    }

    public async search(song: string): Promise<any> {
        if (await isTokenExpired()) {
            await SpotifyMusicProviders.renewToken()
        }
        return new Promise(async (resolve, reject) => {
            // await this.renewToken()
            try {
                const { tracks } = await sdk.search(song, ['track'])
                    .catch((error: unknown) => {
                        return reject(error)
                    })
                const parsedResult = SpotifyParser.parseResponse(tracks)

                resolve(parsedResult)
            } catch (error) {
                this.checkError(error)
            }
        })
    }

    public async getPodcastEpisodes(id: string): Promise<any> {
        try {
            const result = await SpotifyClientApi.getPodcastWithId(id)
            const parseResponse = SpotifyParser.parseEpisodes(result)
            return parseResponse
        } catch (error) {
            console.error(error)
            return []
        }
    }

    public async getTrack(trackId: string, mediaType?: string): Promise<ISong> {
        if (await isTokenExpired()) {
            await SpotifyMusicProviders.renewToken()
        }
        return new Promise(async (resolve, reject) => {
            const api = mediaType === MediaType.Tracks ? sdk.tracks : sdk.episodes
            const song = await api.get(trackId).catch((error: any) => {
                return reject(error)
            })
            
            song.img = mediaType === MediaType.Tracks ? song?.album.images[0].url : song?.images[0].url
            resolve(song)
        })
    }

    public static async getToken(): Promise<string> {

        if (sdk) {
            const { access_token } = await sdk.getAccessToken()

            if (access_token) {
                return access_token
            }
        }

        const response: string = localStorage.getItem(SpotifyTokenKey) as string

        if (response === "undefined") return 'NOTOKEN'

        const accessTokenObject: any = JSON.parse(response)
        const { accessToken } = accessTokenObject
        return accessToken
    }

    public static async sendAccessTokenToServer(accessToken: AccessToken) {
        try {
            const user: User = JSON.parse(localStorage.getItem(USerProfileKey) as string)
            const { email } = user
            const data = {
                ...accessToken,
                email
            }

            await Api.post(data, '/spotify/token')

            SpotifyMusicProviders.saveAccessTokenToLocalStorage(accessToken)

            localStorage.setItem(SpotifyHasLoggedIn, "true")

            store.dispatch(sendStreamAction(true))
        } catch (error) {
            console.error(`Error sending accesstoken to server: ${error}`)
        }
    }

    public static async getAccessTokenFromCode(code: string) {
        try {
            const accessToken: any = await Api.get({ code }, '/spotify/authenticatecode') as AccessToken
            if (accessToken.error) {

            } else {
                sdk = await SpotifyApi.withAccessToken(clientId, accessToken)
                await SpotifyMusicProviders.sendAccessTokenToServer(accessToken)
            }


        } catch (error) {
            console.error(error)
        }
        store.dispatch(sendStreamAction(true))
    }

    public static async renewToken() {
        const accessTokenString = localStorage.getItem(SpotifyTokenKey)
        if (accessTokenString) {
            const tokens = await SpotifyClientApi.refreshToken()
            if (tokens && tokens.error === undefined) {
                await SpotifyMusicProviders.sendAccessTokenToServer(tokens)
                SpotifyMusicProviders.saveAccessTokenToLocalStorage(tokens)
            }
        }
    }

    public async searchUserFavoriteAlbums(): Promise<any[]> {
        try {
            const response = await SpotifyClientApi.getAlbums()
            const parsedResponse = SpotifyParser.parseAlbums(response.items)
            return parsedResponse
        } catch(error) {
            console.error(error)
            return []
        }      
    }

    public async searchUserFavoriteArtists(): Promise<any[]> {
        try {
            const response = await SpotifyClientApi.getTopArtists('artists')
            const parsedResponse = SpotifyParser.parseArtists(response.items)
            return parsedResponse
        } catch(error) {
            console.error(error)
            return []
        }      
    }

    private async checkError(error: any) {
        if (typeof error === "object" && error.message.includes(BAD_TOKEN_ERROR_REFRESHTOKEN)) {
            await this.logout()
            SpotifyMusicProviders.renewToken()
            // window.location.href = window.location.origin;
        }
    }



    private static saveAccessTokenToLocalStorage(tokens: any) {
        const { accessToken, refreshToken } = tokens

        const accessTokenData: any = {
            access_token: accessToken,
            refresh_token: refreshToken,
            accessToken,
            refreshToken,
            token_type: 'Bearer',
            expires_in: 3600
        }

        sdk = SpotifyApi.withAccessToken(clientId, accessTokenData)
        localStorage.setItem(SpotifyTokenKey, JSON.stringify(tokens))
    }
}

export default SpotifyMusicProviders