import { useState, useRef, useEffect } from 'react';
import './Table.scss';
import { musicsMockedData } from 'mockdatas/musics_mocked_data';
import HearIcon from 'assets/icon/heart.png'
import HeartIconFill from 'assets/icon/heart_icon_fill.png'
import { BsThreeDots } from 'react-icons/bs'
import { changeColorMode } from '@utils/colors';
import { useSelector, useDispatch } from 'react-redux';
import { uiSelector } from 'store/selectors';
import MenuOptions from '@components/MenuOptions/MenuOptions';
import useClickOutside from '@hooks/useClickOutside';
import { selectMusic } from 'store/musicSlice';
import { ISong, MediaType } from 'interfaces/models/model-interfaces';
import MergeMusicProviders from 'providers/MergeMusicProviders';
import { ThreeDotsIcon } from '@components/IconComponents/IconComponents';
import { convertMillisecondsToDigitalClock, getUserEmail } from '@utils/helpers';
import { User, useAuth0 } from '@auth0/auth0-react';
import { USerProfileKey } from '../../constants';
import Api from 'services/api';

interface TableComponentProps {
    tableHeaderItems?: string[]
    tableItems: any[]
    showHeaders?: boolean
    mediaType?: string
}

const tableHeaderItemsDefault = ['Song', 'Album', 'Length', 'Platform', ' ']
const TableComponent = ({ tableHeaderItems = tableHeaderItemsDefault, tableItems, showHeaders = true, mediaType }: TableComponentProps) => {

    const auth0 = useAuth0()
    const dispatch = useDispatch()
    const isDarkMode = useSelector(uiSelector).darkMode
    const [activeMenuIndex, setActiveMenuIndex] = useState<number | null>(null);
    const menuRef = useRef<HTMLDivElement>(null)
    const [likes, setLikes] = useState({});

    useClickOutside(menuRef, () => {
        setActiveMenuIndex(null);
    })

    const toggleMenuOptions = (index: number) => {
        if (activeMenuIndex === index) {
            setActiveMenuIndex(null);
        } else {
            setActiveMenuIndex(index);
        }
    };

    const closeMenuOptions = () => {
        setActiveMenuIndex(null);
    }

    const likesHandler = (song: ISong, idx: number) => {
        const { id, platform } = song
        const email = getUserEmail()
        if (song.liked) { // toggle remove
            tableItems[idx].liked = false
            Api.delete('/user/likes', { email, id, platform })
        } else {
            tableItems[idx].liked = true
            Api.post({ email, platform, song }, '/user/likes')
        }

        setLikes([false])
    }

    const handlePlayMusic = async (music: any) => {
        const { platform, id } = music
        const song = await MergeMusicProviders.getContent(id, platform, mediaType || MediaType.Tracks)

        if (song) {
            const updatedMusic = {
                ...music,
                songInfo: song,
                type: mediaType
            };

            dispatch(selectMusic(updatedMusic))
        }
    }

    useEffect(() => {
        const getLikes = async () => {
            const email = getUserEmail()
            let likes = {}           

            tableItems.forEach(async (song, idx) => {
                const { id, platform } = song
                const response = await Api.get({ id, email, platform }, '/user/likes')
                const { like } = response
                tableItems[idx].liked = like
                idx++
                setLikes(likes)            
            })
        }

        getLikes()

    }, [tableItems])

    return (
        <table className="table">
            {showHeaders && <thead>
                <tr className='header_titles'>
                    <th className="episodeColumn" style={{ color: changeColorMode(isDarkMode), background: 'transparent' }}>{tableHeaderItems[0]}</th>
                    <th style={{ display: 'flex', widows: '10%', marginRight: '15%', color: changeColorMode(isDarkMode), background: 'transparent' }}>{tableHeaderItems[1]}</th>
                    <th style={{ display: 'flex', marginRight: '5%', color: changeColorMode(isDarkMode), background: 'transparent' }}>{tableHeaderItems[2]}</th>
                    <th style={{ display: 'flex', marginRight: '5%', color: changeColorMode(isDarkMode), background: 'transparent' }}>{tableHeaderItems[3]}</th>
                    <th>{' '}</th>
                </tr>
            </thead>}
            <tbody>
                {tableItems.map((item, idx) => (
                    <tr key={idx} style={{ marginTop: 20, display: 'flex', padding: '5px 0px', alignItems: 'center', border: '0px' }}>
                        <td style={{ display: 'flex', alignItems: 'center', gap: 8, background: 'transparent' }} className='episodeColumnBody'>
                            <img src={item.img} alt='icon-play' style={{ width: "55px" }} onClick={() => handlePlayMusic(item)} />
                            <div>
                                <p className='song_name' style={{ background: 'transparent', color: changeColorMode(isDarkMode) }}>{item.songName}</p>
                                <p className='song_artist' style={{ background: 'transparent', color: changeColorMode(isDarkMode) }}>{item.artistName}</p>
                            </div>
                        </td>
                        <td style={{ background: 'transparent', marginLeft: 8, display: 'flex', width: '15%', marginRight: '10%', color: changeColorMode(isDarkMode), border: "0px" }}>{item.albumName}</td>
                        <td style={{ background: 'transparent', marginLeft: 8, display: 'flex', marginRight: '10%', color: changeColorMode(isDarkMode), border: "0px" }}>{convertMillisecondsToDigitalClock(item.durationInMillis)}</td>
                        <td style={{ background: 'transparent', marginLeft: 8, display: 'flex', marginRight: '10%', color: changeColorMode(isDarkMode), border: "0px" }}>
                            <img src={item.icon} alt='icon-play' />
                        </td>
                        <td style={{ border: "0px", background: 'transparent' }}>
                            <img src={tableItems[idx].liked ? HeartIconFill : HearIcon}
                                alt="heart-favorite-icon"
                                style={{ cursor: 'pointer', width: "25px" }}
                                onClick={() => likesHandler(item, idx)}
                            />
                        </td>
                        {/* <td style={{ marginLeft: 'auto', marginRight: '20px', position: 'relative' }}>
                            <ThreeDotsIcon strokeColor={changeColorMode(isDarkMode)} onClick={() => toggleMenuOptions(index)} style={{ cursor: 'pointer' }} />
                            {activeMenuIndex === index && (
                                <div className="menu-container" ref={menuRef} >                                   
                                    <MenuOptions closeMenu={closeMenuOptions} />
                                </div>
                            )}

                        </td> */}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default TableComponent;

