import './MoviesHeaderRight.scss'
import ProfileComponent from '@components/ProfileComponent/ProfileComponent'
import { NotificationIcon, SearchIcon } from '@components/IconComponents/IconComponents'
import { useSelector } from 'react-redux'
import { uiSelector } from 'store/selectors'
import { changeColorMode } from '@utils/colors'
import { useNavigate } from 'react-router-dom'
import { useWindowDimensions } from '@hooks/useWindowDimension'

interface MoviesHeaderRightProps {
    handleOpenSearchInput: () => void
    hasBackgroundImageUrl?: boolean
}
const MoviesHeaderRight = ({ handleOpenSearchInput, hasBackgroundImageUrl }: MoviesHeaderRightProps) => {

    const isDarkMode = useSelector(uiSelector).darkMode
    const { isMobile } = useWindowDimensions()
    const navigation = useNavigate()

    return (
        <div className='movies_header_user_container'>
            <SearchIcon strokeColor={changeColorMode(!hasBackgroundImageUrl ? isDarkMode : !isDarkMode)} className='search__icon' onClick={isMobile ? () => navigation('/merge-movies/search') : handleOpenSearchInput} />
            <NotificationIcon strokeColor={changeColorMode(!hasBackgroundImageUrl ? isDarkMode : !isDarkMode)} />
            <ProfileComponent />
        </div>
    )
}

export default MoviesHeaderRight