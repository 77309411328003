import TableComponent from '@components/Table/Table';
import React, { useEffect, useState } from 'react';
import ArrowDown from 'assets/icon/arrow.svg'
import MusicStreamingSection from '@components/MusicStreamingSection/MusicStreamingSection';
import ContentProvider from '@components/ContentProvider/ContentProvider';
import MyFavoritesSection from '@components/MyFavoritesSection/MyFavoritesSection';
import { ISong } from 'interfaces/models/model-interfaces';
import Loader from '@components/Loader/loader';
interface SearchResultsProps {
    selectedFilter: string;
    handleClickButtonFilter: (filter: any) => void;
    tableItems: ISong[];
    artistItems: any[];
    albumItems: any[];
    additionalClassName?: string;
    isLoading: boolean
}

const filterState = new Set<string>()

const SearchResults: React.FC<SearchResultsProps> = ({ selectedFilter, handleClickButtonFilter, tableItems, artistItems, albumItems, additionalClassName, isLoading }) => {

    const [openContentProvider, setOpenContentProvider] = useState(false)
    const [searchResult, setSearchResults] = useState(tableItems)

    const handleCloseContentProvider = () => setOpenContentProvider(false)
    const handleOpenContentProvider = () => setOpenContentProvider(true)
    const filterHandler = (event: any, platform: string) => {
        const isChecked = event.target.checked

        if (isChecked) {
            filterState.add(platform)
        } else {
            filterState.delete(platform)
        }
        // @ts-ignore
        const filterArray = [...filterState]
        let tempArray: any = []

        filterArray.forEach(element => {
            const temp = tableItems.filter((item) => {
                return item.platform === element
            })
            tempArray = [...tempArray, ...temp]
        });

        tempArray = tempArray.length === 0 ? tableItems : tempArray

        setSearchResults(tempArray)
    }

    useEffect(() => {
        setSearchResults(tableItems)

    }, [tableItems])

    return (
        <>
            <div className='filter_container' style={{ marginLeft: '62px', display: 'flex', alignItems: 'center', gap: 16 }}>
                <div className="button-wrapper" style={{ position: 'relative' }}>
                    <button className="filter_container_button" onClick={!openContentProvider ? handleOpenContentProvider : () => { }}>
                        Content provider
                        <img src={ArrowDown} alt='arrow-down' />
                    </button>
                    <ContentProvider handleCloseContentProvider={handleCloseContentProvider} openContentProvider={openContentProvider} filterHandler={filterHandler} />
                </div>

                {/* <button className={`filter_container_button ${selectedFilter === 'all' ? 'active' : ''}`} onClick={() => handleClickButtonFilter('all')}>All</button>
                <button className={`filter_container_button ${selectedFilter === 'songs' ? 'active' : ''}`} onClick={() => handleClickButtonFilter('songs')}>Songs</button>
                <button className={`filter_container_button ${selectedFilter === 'artists' ? 'active' : ''}`} onClick={() => handleClickButtonFilter('artists')}>Artists</button>
                <button className={`filter_container_button ${selectedFilter === 'playlists' ? 'active' : ''}`} onClick={() => handleClickButtonFilter('playlists')}>Playlists</button>
                <button className={`filter_container_button ${selectedFilter === 'albums' ? 'active' : ''}`} onClick={() => handleClickButtonFilter('albums')}>Albums</button> */}
            </div>
            <>
                {isLoading ? <Loader /> :

                    <div style={{ marginLeft: '342px' }}>
                        {
                            selectedFilter === 'songs' &&
                            <div style={{ margin: 30 }}>

                                <TableComponent tableItems={searchResult} />
                            </div>
                        }
                        {
                            selectedFilter === 'artists' &&
                            <div style={{ margin: 30 }}>

                                <MyFavoritesSection showSectionHeader={false} title="Top artists" type='artists' items={artistItems} />
                            </div>
                        }
                        {
                            selectedFilter === 'all' && (
                                <>
                                    <div style={{ margin: 30 }}>
                                        <TableComponent tableItems={searchResult} />
                                        {/* <MusicStreamingSection title="Top artists" musicList={artistItems} isLoading={isLoading}/>
                                <MusicStreamingSection title="Albums" musicList={albumItems} isLoading={isLoading}/> */}
                                    </div>
                                </>
                            )
                        }
                        {
                            selectedFilter === 'albums' && (
                                <>
                                    <div style={{ margin: 30 }}>
                                        <MyFavoritesSection showSectionHeader={false} title="top albums" type='albums' items={albumItems} />
                                    </div>
                                </>
                            )
                        }
                        {
                            selectedFilter === 'playlists' && (
                                <>
                                    <div style={{ margin: 30 }}>
                                        <MusicStreamingSection type='playlists' title="Playlists" musicList={albumItems} isLoading={isLoading} />
                                    </div>
                                </>
                            )
                        }

                    </div>
                }
            </>
        </>
    );
}

export default SearchResults;
