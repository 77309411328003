import { count } from "console"
import { SEND_STREAM_ACTION } from "../../actions/stream/stream-actions"

const initialState = { 
    message: 'none',
    streamState: 0
}

const streamActionReducer = (state = initialState, action: any ) => {
    switch ( action.type ) {
        case SEND_STREAM_ACTION:
            return {
                ...state,
                message: action.payload,
                streamState: state.streamState + 1
            }            
        default:
            return state
    }
}

export default streamActionReducer