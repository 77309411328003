import { CloseIcon, SearchIcon } from '@components/IconComponents/IconComponents'
import './SearchInputContainer.scss'
import { useNavigate } from 'react-router-dom'

interface SearchInputContainerProps {
    searchResult?: any
    handleChangeInput?: (e: any) => void
    searchInputValue?: string
    handleInputFocus?: () => void
    handleInputBlur?: () => void
    handleClickClearInput?: () => void
    handleKeyDown?: (e: any) => void

}
const SearchInputContainer = ({
    handleClickClearInput,
    handleInputFocus,
    handleInputBlur,
    searchResult,
    handleChangeInput,
    handleKeyDown,
    searchInputValue }: SearchInputContainerProps) => {
    const navigation = useNavigate()

    const handleNavigationMovie = (id: string) => {
        navigation(`/movie/${id}`)
    }

    return (
        <div style={{ position: 'relative' }} className='search_header_movie_container'>
            <SearchIcon />
            <input
                value={searchInputValue}
                name='movies'
                onChange={handleChangeInput}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                onKeyDown={handleKeyDown}
                type="text"
                className="search__input"
                placeholder="Search" />
            <CloseIcon className="search__close" onClick={handleClickClearInput} />
            {!!searchResult?.length && <div style={{
                padding: 12,
                position: 'absolute',
                top: 60,
                backgroundColor: "#FFF",
                borderRadius: '8px',
                width: '100%',
                left: '0px',
                gap: 12,
                display: 'flex',
                flexDirection: 'column',
                maxHeight: '300px',
                overflow: 'auto',
            }} className='search_result_container'>

                {searchResult.map((item: any) => {

                    const movieImgUrl = `https://img.rgstatic.com/content/${item.content_type?.toLowerCase()}/${item.id}/poster-780.jpg`

                    return (
                        <div className='search_result_item'
                            key={item.id}
                            onClick={() => handleNavigationMovie(item.id)}
                            style={{
                                display: 'flex',
                                gap: 12,
                                zIndex: 99999999999999999999999,
                                alignItems: 'center',
                                cursor: 'pointer',

                            }}
                        >
                            <img src={movieImgUrl} alt='movie' style={{ height: 40, width: 40 }} />
                            <span>{item.title}</span>
                        </div>
                    )

                })}
            </div>
            }
        </div>

    )
}

export default SearchInputContainer