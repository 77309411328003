import PlatformMerge from '@components/LandingPage/PlatformMergeItem/PlatformMergeItem'
import { changeColorMode } from '@utils/colors'
import React from 'react'
import { useSelector } from 'react-redux'
import { uiSelector } from 'store/selectors'

interface LandingPagePlatformsProps {
    ourPlatforms: any[]
}
const LandingPagePlatforms = ({ ourPlatforms }: LandingPagePlatformsProps) => {
    const isDarkMode = useSelector(uiSelector).darkMode
    return (
        <article className='landing__container__container'>
            <h2 className='landing__container__container__heading' style={{ color: changeColorMode(isDarkMode) }}>Our platforms</h2>
            <div className='landing__container__container__platforms'>
                {ourPlatforms.map((platform, index) => {
                    return <PlatformMerge 
                    key={index} 
                    title={platform.title} 
                    backgroundImageUrl={platform.backgroundImage} 
                    url={platform.url} 
                    available={platform.available}
                    />
                })}
            </div>
        </article>
    )
}

export default LandingPagePlatforms