import PlatformMerge from '@components/LandingPage/PlatformMergeItem/PlatformMergeItem';
import { ourPlatforms } from 'mockdatas/streamings';
import React from 'react';
import './OurPlatforms.scss'

const OurPlatformsList: React.FC = () => {
    return (
        <div className='__platforms'>
            {ourPlatforms.map((platform, index) => {
                return <PlatformMerge 
                key={index} 
                title={platform.title} 
                available={platform.available}
                backgroundImageUrl={platform.backgroundImage} 
                url={platform.url} />
            })}
        </div>
    )
}

export default OurPlatformsList;