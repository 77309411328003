import GenreImage1 from 'assets/images/genres/genre (1).png'
import GenreImage2 from 'assets/images/genres/genre (2).png'
import GenreImage3 from 'assets/images/genres/genre (3).png'
import GenreImage4 from 'assets/images/genres/genre (4).png'
import GenreImage5 from 'assets/images/genres/genre (5).png'
import GenreImage6 from 'assets/images/genres/genre (6).png'
import GenreImage7 from 'assets/images/genres/genre (7).png'
import GenreImage8 from 'assets/images/genres/genre (8).png'
import GenreImage9 from 'assets/images/genres/genre (9).png'
import GenreImage10 from 'assets/images/genres/genre (10).png'
import GenreImage11 from 'assets/images/genres/genre (11).png'
import GenreImage12 from 'assets/images/genres/genre (12).png'
import GenreImage13 from 'assets/images/genres/genre (13).png'
import GenreImage14 from 'assets/images/genres/genre (14).png'
import GenreImage15 from 'assets/images/genres/genre (15).png'
import GenreImage16 from 'assets/images/genres/genre (16).png'
import GenreImage17 from 'assets/images/genres/genre (17).png'
import GenreImage18 from 'assets/images/genres/genre (18).png'

export interface IGenreData {
    id: number
    genreName: string
    img: string
}
export const genresData: IGenreData[] = [
    {
        id: 1,
        genreName: 'Pop',
        img: GenreImage1,
    },
    {
        id: 2,
        genreName: 'Rock',
        img: GenreImage2,
    },
    {
        id: 3,
        genreName: 'Hip-Hop / Rap',
        img: GenreImage3,
    },
    {
        id: 4,
        genreName: 'Electronic / Dance',
        img: GenreImage4,
    },
    {
        id: 5,
        genreName: 'R&B / Soul',
        img: GenreImage5,
    },
    {
        id: 6,
        genreName: 'Country',
        img: GenreImage6,
    },
    {
        id: 7,
        genreName: 'Jazz',
        img: GenreImage7,
    },
    {
        id: 8,
        genreName: 'Classical',
        img: GenreImage8,
    },
    {
        id: 9,
        genreName: 'Reggae',
        img: GenreImage9,
    },
    {
        id: 10,
        genreName: 'Latin',
        img: GenreImage10,
    },
    {
        id: 11,
        genreName: 'Blues',
        img: GenreImage11,
    },
    {
        id: 12,
        genreName: 'Folk',
        img: GenreImage12,
    },
    {
        id: 13,
        genreName: 'Metal',
        img: GenreImage13,
    },
    {
        id: 14,
        genreName: 'Indie / Alternative',
        img: GenreImage14,
    },
    {
        id: 15,
        genreName: 'Punk',
        img: GenreImage15,
    },
    {
        id: 16,
        genreName: 'Funk',
        img: GenreImage16,
    },
    {
        id: 17,
        genreName: 'New Age / Ambient',
        img: GenreImage17,
    },
    {
        id: 18,
        genreName: 'Experimental',
        img: GenreImage18,
    },
]
