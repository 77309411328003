import React from 'react'
import Modal from '@components/Modal/Modal'
import { MdClose } from 'react-icons/md'
import './AddPlaylistModal.scss'
import { PhotoIcon } from '@components/IconComponents/IconComponents'
import { useWindowDimensions } from '@hooks/useWindowDimension'
import { changeColorMode } from '@utils/colors'
import { useSelector } from 'react-redux'
import { uiSelector } from 'store/selectors'

interface AddPlaylistModalProps {
    handleCloseModal: () => void
    visible: boolean
    handleSaveRadio?: () => void
}

const AddPlaylistModal = ({ handleCloseModal, visible, handleSaveRadio }: AddPlaylistModalProps) => {
    const isDarkMode = useSelector(uiSelector).darkMode
    const { isMobile } = useWindowDimensions()
    return (
        <Modal handleCancel={handleCloseModal} modalVisible={visible} style={{ height: isMobile ? '100%' : 'auto', backgroundColor: changeColorMode(!isDarkMode) }}>
            <div className={"modalContent"}>
                <div className={"header"}>
                    <h3 style={{ color: changeColorMode(isDarkMode) }}>Create new playlist</h3>
                    <MdClose className={"closeIcon"} onClick={handleCloseModal} color={changeColorMode(isDarkMode)} size={20} />
                </div>
                <div className={"content"}>
                    <div className={"photoBox"}>
                        <PhotoIcon className={"photoIcon"} />
                        <span className={"addPhotoText"}>Add photo</span>
                    </div>
                    <div className={"details"}>
                        <input type="text" placeholder="Playlist name" className={"inputText"} style={{ color: changeColorMode(isDarkMode), background:changeColorMode(!isDarkMode) }} />
                        <textarea className={"textArea"} placeholder="Description (not obligatory)" style={{ color: changeColorMode(isDarkMode), background:changeColorMode(!isDarkMode) }} />
                    </div>
                </div>
                <div style={{ marginTop: isMobile ? 'auto' : 'unset' }}>
                    <button onClick={handleSaveRadio} className={"createButton"}>Create</button>
                </div>
            </div>
        </Modal>
    )
}

export default AddPlaylistModal
