import './SimilarMovies.scss'
import { contentProviderObject } from '@utils/contentProviderObject'
import { Link } from 'react-router-dom'

interface SimiliarMoviesProps {
    similarMovies?: any[]
}
const SimiliarMovies = ({ similarMovies }: SimiliarMoviesProps) => {
    return (
        <div className='similiar-movies-container'>
            {similarMovies?.map((movie, index) => (
                <Link to={`/movie/${movie.id}`} className='similar-movie-item'
                    style={{
                        backgroundImage: `url(${movie?.img})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}>
                    <img src={contentProviderObject[movie.contentProvider]} alt={movie.contentProvider} />
                </Link>
            ))}
        </div>
    )
}

export default SimiliarMovies