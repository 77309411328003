import LoaderGif from '../../assets/images/loader/loader.gif'

import styled from 'styled-components';

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center; /* Center vertically */
  width: 100%; /* Takes full width of the parent container */
  height: 100vh; /* Takes full height of the viewport */
  /* If you want to center within a specific parent element, you might use a different height, like 100% */
`;

const LoaderImage = styled.img`
  height: 100px; /* Set the height of the loader image */
  /* If you want to maintain the aspect ratio of the image, you might want to set 'width: auto;' */
`;


const Loader: React.FC = () => {
    return (
        <LoaderContainer className= "loader-container" >
            <LoaderImage src={LoaderGif} alt = "Loading..." />
        </LoaderContainer>
    )
}

export default Loader