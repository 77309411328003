import { ISong } from "../../interfaces/models/model-interfaces";

// Define action types
export const FETCH_USER_PROFILE_START = 'FETCH_USER_PROFILE_START';
export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS';
export const FETCH_USER_PROFILE_FAIL = 'FETCH_USER_PROFILE_FAIL';
export const SEnD_MUSIC_CATEOGORY = "SEnD_MUSIC_CATEOGORY"

// Define action creators
export const fetchUserProfileStart = () => ({
  type: FETCH_USER_PROFILE_START,
});

export const fetchUserProfileSuccess = (userInfo: any) => ({
  type: FETCH_USER_PROFILE_SUCCESS,
  payload: userInfo,
});

export const fetchUserProfileFail = (error: any) => ({
  type: FETCH_USER_PROFILE_FAIL,
  payload: error,
});

export const sendMusicCategory = (data: ISong[], musicType: string) => ({
  type: SEnD_MUSIC_CATEOGORY,
  payload: {
    data,
    musicType
  }
})
