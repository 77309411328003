import ReplayIcon from 'assets/icon/replay_icon.svg'
import PreviousIcon from 'assets/icon/previous_icon.svg'
import PlayMusicIcon from 'assets/icon/play_icon_player.svg'
import ShuffleActived from 'assets/icon/shuffle_icon_actived.svg'
import ReplayIconActived from 'assets/icon/replay_icon_actived.svg'
import ShuffleIcon from 'assets/icon/shuffle_icon.svg'
import { calculateTime } from '@utils/minutesToSeconds'
import { GiPauseButton } from 'react-icons/gi'
import { InputProgress } from './InputProgress'
import { uiSelector } from 'store/selectors'
import { useSelector } from 'react-redux'
import AppleMusicPlayer from '@components/MusicPlayer/apple/apple-music-player'
import { useState } from 'react'
import ApplePlaylistManager from 'providers/apple/apple-playlist-manager'

interface PlayerButtonsProps {
    isAppleSong: () => boolean;
    isPlaying: boolean;
    togglePlayPause: () => void;
    audioPlayerRef: React.RefObject<HTMLAudioElement>;
    progressBar: React.RefObject<HTMLInputElement>;
    currentTime: number;
    changeRange: () => void;
    duration: number
}
const PlayerButtons = ({ audioPlayerRef, isPlaying, togglePlayPause, currentTime, progressBar, changeRange, isAppleSong, duration }: PlayerButtonsProps) => {
    const isDarkMode = useSelector(uiSelector).darkMode
    const calculateProgressWidth = (progressBar: number) => {
        if (!audioPlayerRef.current) return 0;

        const progressWidth = ((progressBar / duration) * 100);
        return progressWidth;
    };

    const [repeatActived, setRepeatActived] = useState(false)

    const handleRepeat = () => {
        setRepeatActived(previousState => !previousState)
        if (isAppleSong()) {
        AppleMusicPlayer.toogleRepeat()
        return
        }
        if (!audioPlayerRef.current) return;
        audioPlayerRef.current.currentTime = 0;
    }

    const playNext = () => {
        ApplePlaylistManager.playNextSong()
    }

    const playPrevious = () => {
        ApplePlaylistManager.playPreviousSong()
    }   

    return (
        <div className='player_options_buttons_container'>
            <div className='player_options_buttons'>
                <img style={{ cursor: 'pointer' }} title="replay" src={repeatActived ? ReplayIconActived : ReplayIcon} alt="" onClick={handleRepeat} />
                <img style={{ cursor: 'pointer' }} title="previous" src={PreviousIcon} alt="" onClick={playPrevious}/>
                {isPlaying ? <GiPauseButton style={{ cursor: 'pointer' }} title="pause" size={20} onClick={togglePlayPause} /> : <img style={{ cursor: 'pointer' }} title="play" src={PlayMusicIcon} alt="" onClick={togglePlayPause} />}
                <img title='next' src={PreviousIcon} alt="" style={{ transform: 'rotate(180deg)', cursor: 'pointer' }} onClick={playNext}/>
                {/* <img style={{ cursor: 'pointer' }} src={shuffleActived ? ShuffleActived : ShuffleIcon} alt="shuffle-musics" onClick={handleClickShuffle} /> */}
            </div>
            {audioPlayerRef.current && <div className='player_time_container'>
                <small className='current-time'>{calculateTime(currentTime)}</small>
                <InputProgress currentProgress={calculateProgressWidth(currentTime)} type="range" defaultValue="0" ref={progressBar} onChange={changeRange} />
                <small className='total-time'>{calculateTime(duration) || '00:00'}</small>
            </div>}
        </div>
    )
}

export default PlayerButtons