import React, { useState } from 'react'
import './MyFavoritesMobile.scss'
import { RiSearchLine } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import FavoritesShelf from './FavoritesShelf'
import { artistsMockedData, musicsMockedData } from 'mockdatas/musics_mocked_data'
import MyFavoritesSection from '@components/MyFavoritesSection/MyFavoritesSection'
import MusicStreamingSection from '@components/MusicStreamingSection/MusicStreamingSection'
import { musicsMadeForYou } from 'mockdatas/musics_page_mocks'
import EpisodesCard from '@components/EpisodesCard/EpisodesCard'
import add_playlist_mobile from 'assets/images/add_playlist_mobile.png'
import PlayListSection from '@components/PlayListSection/PlayListSection'
import AddPlaylistModal from '@components/MyFavoritesSection/AddPlaylistModal'
import { ISong } from 'interfaces/models/model-interfaces'

interface MyFavoritesMobileProps {
    type?: string
    handleClickItemMenu: (link: string) => void
    artists: ISong[]
    userFavorites: ISong[]
    albums: ISong[]
    playlists: ISong[]
    loader: boolean
}
const MyFavoritesMobile = ({ type, handleClickItemMenu, albums, artists, userFavorites, playlists, loader }: MyFavoritesMobileProps) => {
    type = type || 'all'
    // const [isLoading, setLoader] = useState<boolean>(true)
    const [addnewPlaylistModal, setAddnewPlaylistModal] = React.useState(false)

    const toggleAddnewPlaylistModal = () => {
        setAddnewPlaylistModal(!addnewPlaylistModal)
    }

    const menuItems = [
        { label: 'All', link: '/my-favorites?type=all' },
        { label: 'Songs', link: '/my-favorites?type=songs' },
        { label: 'Playlists', link: '/my-favorites?type=playlists' },
        { label: 'Albums', link: '/my-favorites?type=albums' },
        { label: 'Artists', link: '/my-favorites?type=artists' },
    ]
    
    return (
        <div className='my-favorites-mobile-container'>
            <AddPlaylistModal visible={addnewPlaylistModal} handleCloseModal={toggleAddnewPlaylistModal} />
            <div className='top-container'>
                <h2 className='top-container-title'>Favorite {type === 'all' ? '' : type}</h2>
                {/* <RiSearchLine size={30} color="#333" onClick={searchHandler} /> */}
            </div>
            <div className='menu'>
                {menuItems.map((item, index) => {
                    const isSelected = item.label.toLowerCase() ===  type 
                    return (
                        <button onClick={() => handleClickItemMenu(item.link)} style={{ backgroundColor: isSelected ? '#6B37FE' : '#F3F4F6', color: isSelected ? 'white' : '#1F2937' }} className='menu-item'>{item.label}</button>
                    )
                })}
            </div>

            <>
                {( type === 'all' || type === undefined ) && <div style={{ paddingLeft: '20px' }}>
                    <MusicStreamingSection
                        isLoading={loader}
                        additionalImage={add_playlist_mobile}
                        additionItem='Add new playlist'
                        onClickAdditional={() => alert("add")}
                        type="music" title="" musicList={playlists} />
                    <h4 style={{ marginTop: 20 }}>Songs</h4>
                    <EpisodesCard tableItems={userFavorites} />
                    <MusicStreamingSection  clickRoute={"/artist/"} title="Artists" musicList={artists} isLoading={loader}/>
                </div>}

                {type === 'songs' && <div style={{ paddingLeft: '20px' }}>
                    <h4 style={{ marginTop: 20 }}>Songs</h4>
                    <EpisodesCard tableItems={userFavorites} />
                </div>}

                {type === "artists" && <MyFavoritesSection type='artists' title="Artists" items={artists} />}
                {type === "playlists" && <MyFavoritesSection toggleNewPlaylistModal={toggleAddnewPlaylistModal} type='playlists' title="Playlists" items={playlists} />}
                {type === "albums" && <MyFavoritesSection type='albums' title="Albums" items={albums} />}

            </>

        </div>
    )
}

export default MyFavoritesMobile