import React, { useRef } from 'react'
import './MoviesFilterItem.scss'
import { filter } from 'lodash';
import { useWindowDimensions } from '@hooks/useWindowDimension';
import { AmazonPrimeIcon, HBOIcon, HuluIcon, NetFlixIcon } from '@components/IconComponents/IconComponents';
import { changeColorMode } from '@utils/colors';
import { useSelector } from 'react-redux';
import { uiSelector } from 'store/selectors';
import AppleTvIcon from 'assets/icon/apple_tv_plus.png'
import DisneyPlusIcon from 'assets/icon/disney_plus.png'
import ParamountPlusIcon from 'assets/icon/paramount_plus.svg'
import CrunchrollIcon from 'assets/icon/crunchyroll.png'

interface MoviesFilterItemProps {
    isSelected?: boolean;
    handleClickFilterItem?: () => void;
    filterName: string;
    id?: string;
    handleSelectFilter: (filter: string) => void
    handleSelectProvider: (provider: string) => void
    selectedProvider: string[]
}

const MoviesFilterItem = ({ selectedProvider, handleSelectProvider, handleSelectFilter, id, filterName, handleClickFilterItem, isSelected }: MoviesFilterItemProps) => {
    const [contentProviderOpen, setContentProviderOpen] = React.useState(false)

    const { isMobile } = useWindowDimensions()
    const isDarkMode = useSelector(uiSelector).darkMode

    const providersRef = useRef<HTMLDivElement>(null)
    if (id === 'contentProviders') {
        return (
            <div style={{ position: 'relative' }}>
                <div
                    ref={providersRef}
                    className='content-provider-container' onClick={() => setContentProviderOpen(c => !c)}>
                    <span style={{ fontSize: isMobile ? 12 : 16 }}>{filterName}</span>

                </div >
                {contentProviderOpen &&


                    <div className='provider-content'
                        style={{
                            position:'fixed',
                            backgroundColor: changeColorMode(!isDarkMode),
                            top: providersRef.current?.getBoundingClientRect().bottom,
                            left: !!providersRef.current ? providersRef.current?.getBoundingClientRect().left - (isMobile ? 100 : 0) : 0,
                            width: !!providersRef.current && providersRef.current?.getBoundingClientRect()?.width * 1.5 || 0,
                        }}
                    >
                        <div className='provider-item'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <AmazonPrimeIcon />
                                <label htmlFor={id} style={{ color: changeColorMode(isDarkMode) }}>Amazon Prime</label>
                            </div>

                            <div style={{
                                border: '1px solid black',
                                height: '20px',
                                width: '20px',
                                marginRight: '10px',
                                backgroundColor: selectedProvider.includes('amazon_prime') ? '#6B37FE' : '#fff'

                            }} onClick={() => handleSelectProvider('amazon_prime')} />

                        </div>
                        <div className='provider-item'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <NetFlixIcon />
                                <label htmlFor={id} style={{ color: changeColorMode(isDarkMode) }}>Netflix</label>
                            </div>
                            <div style={{
                                border: '1px solid black',

                                height: '20px',
                                width: '20px',
                                marginRight: '10px',
                                backgroundColor: selectedProvider.includes('netflix') ? '#6B37FE' : '#fff'

                            }} onClick={() => handleSelectProvider('netflix')} />

                        </div>
                        <div className='provider-item'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <HuluIcon />
                                <label htmlFor={id} style={{ color: changeColorMode(isDarkMode) }}>Hulu</label>
                            </div>
                            <div style={{
                                border: '1px solid black',

                                height: '20px',
                                width: '20px',
                                marginRight: '10px',
                                backgroundColor: selectedProvider.includes('hulu_plus') ? '#6B37FE' : '#fff'
                            }} onClick={() => handleSelectProvider('hulu_plus')} />

                        </div>
                        <div className='provider-item'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <HBOIcon />
                                <label htmlFor={id} style={{
                                    color: changeColorMode(isDarkMode)
                                }}>HBO</label>
                            </div>
                            <div style={{
                                border: '1px solid black',

                                height: '20px',
                                width: '20px',
                                marginRight: '10px',
                                backgroundColor: selectedProvider.includes('hbo_max') ? '#6B37FE' : '#fff'
                            }} onClick={() => handleSelectProvider('hbo_max')} />

                        </div>
                        <div className='provider-item'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <img src={AppleTvIcon} alt="apple-tv-provider" style={{ height: 32, width: 32, objectFit: 'contain' }} />
                                <label htmlFor={id} style={{
                                    color: changeColorMode(isDarkMode)
                                }}>Apple Tv</label>
                            </div>
                            <div style={{
                                border: '1px solid black',

                                height: '20px',
                                width: '20px',
                                marginRight: '10px',
                                backgroundColor: selectedProvider.includes('apple_tv_plus') ? '#6B37FE' : '#fff'
                            }} onClick={() => handleSelectProvider('apple_tv_plus')} />

                        </div>
                        <div className='provider-item'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <img src={DisneyPlusIcon} alt="apple-tv-provider" style={{ height: 40, width: 32, objectFit: 'contain' }} />
                                <label htmlFor={id} style={{
                                    color: changeColorMode(isDarkMode)
                                }}>Disney+</label>
                            </div>
                            <div style={{
                                border: '1px solid black',

                                height: '20px',
                                width: '20px',
                                marginRight: '10px',
                                backgroundColor: selectedProvider.includes('disney_plus') ? '#6B37FE' : '#fff'
                            }} onClick={() => handleSelectProvider('disney_plus')} />

                        </div>
                        <div className='provider-item'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <img src={ParamountPlusIcon} alt="apple-tv-provider" style={{ height: 40, width: 32, objectFit: 'contain' }} />
                                <label htmlFor={id} style={{
                                    color: changeColorMode(isDarkMode)
                                }}>Paramount</label>
                            </div>
                            <div style={{
                                border: '1px solid black',

                                height: '20px',
                                width: '20px',
                                marginRight: '10px',
                                backgroundColor: selectedProvider.includes('paramount_plus') ? '#6B37FE' : '#fff'
                            }} onClick={() => handleSelectProvider('paramount_plus')} />

                        </div>
                        <div className='provider-item'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <img src={CrunchrollIcon} alt="apple-tv-provider" style={{ height: 40, width: 32, objectFit: 'contain' }} />
                                <label htmlFor={id} style={{
                                    color: changeColorMode(isDarkMode)
                                }}>Crunchyroll</label>
                            </div>
                            <div style={{
                                border: '1px solid black',

                                height: '20px',
                                width: '20px',
                                marginRight: '10px',
                                backgroundColor: selectedProvider.includes('crunchyroll_premium') ? '#6B37FE' : '#fff'
                            }} onClick={() => handleSelectProvider('crunchyroll_premium')} />

                        </div>
                    </div>

                }
            </div>
        )
    }

    return (
        <button style={{
            backgroundColor: isSelected ? '#6B37FE' : '#fff',

        }}
            className='movieFilterItemButton'
            onClick={e => handleSelectFilter(filterName)}>
            <span style={{ color: isSelected ? '#fff' : '#000' }}>{filterName}</span>
        </button>
    )
}

export default MoviesFilterItem