import { platform } from "os";
import { PlatformModel } from "../../interfaces/models/model-interfaces";
import MergeMusicProviders from "../../providers/MergeMusicProviders";
import { IMergeMusicProviders } from "interfaces/providers/provider-interface";

class AuthenticationBridge {

    public static async login(authData: PlatformModel) {
        const platform: IMergeMusicProviders = MergeMusicProviders.getProvider(authData.platform)
        platform.login()
    }

    public static async logout(authData: PlatformModel) {
        const platform: IMergeMusicProviders = MergeMusicProviders.getProvider(authData.platform)
        platform.logout()
    }

    public static async logoutAll() {
        const subscribers: IMergeMusicProviders[] = await MergeMusicProviders.getSubsctibedPlatforms()
        for (const platform of subscribers) {
            platform.logout()
        }
    }
}

export default AuthenticationBridge