import styled from "styled-components";

export const InputProgress = styled.input<{ currentProgress?: number }>`
                appearance: none;
                background: lightgray;
                border-radius: 10px;
                position: relative;
                width: 400px;
                height: 4px;
                outline: none;
                cursor: pointer;
                -webkit-appearance: none;
                appearance: none;
                background: #ccc; 

                    &::-webkit-slider-thumb {
                      -webkit-appearance: none;
                      appearance: none;
                      width: 20px; 
                      height: 20px; 
                      background: transparent; 
                      border-radius: 50%; 
                      cursor: pointer;
                    }

                &:active::-webkit-slider-thumb {
                  background-color: #6B37FE;
                  transform: scale(0.6);
                }

                &::before {
                  content: '';
                    height: 4px;
                    width: ${props => Math.floor(props.currentProgress || 0).toFixed(0)}%;
                    background-color: #6B37FE;
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 2;
                    cursor: pointer;
                }
`

export const InputVolume = styled.input<{ currentProgress?: number }>`
                appearance: none;
                background: lightgray;
                border-radius: 10px;
                position: relative;
                width: 100px;
                height: 4px;
                outline: none;
                cursor: pointer;
                -webkit-appearance: none;
                appearance: none;
                background: #ccc; 

                    &::-webkit-slider-thumb {
                      -webkit-appearance: none;
                      appearance: none;
                      width: 20px; 
                      height: 20px; 
                      background: transparent; 
                      border-radius: 50%; 
                      cursor: pointer;
                    }

                &:active::-webkit-slider-thumb {
                  background-color: #6B37FE;
                  transform: scale(0.6);
                }

                &::before {
                  content: '';
                    height: 4px;
                    width: ${props => Math.floor(props.currentProgress || 0).toFixed(0)}px;
                    background-color: #6B37FE;
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 2;
                    cursor: pointer;
                }
`