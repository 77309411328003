import ChooseExperienceDropdown from '@components/ChooseExperienceDropdown/ChooseExperienceDropdown'
import MoviesHeaderRight from './components/MoviesHeaderRight/MoviesHeaderRight'
import MoviesHeaderLeft from './components/MoviesHeaderLeft/MoviesHeaderLeft'
import { useMovieHeaderBar } from './useMovieHeaderbar'
import { useSelector } from 'react-redux'
import { uiSelector } from 'store/selectors'
import { changeColorMode } from '@utils/colors'
import './MoviesHeaderBar.scss'

interface MoviesHeaderBarProps {
    backgroundImageUrl?: string;
}
const MoviesHeaderBar = ({ backgroundImageUrl }: MoviesHeaderBarProps) => {

    const isDarkMode = useSelector(uiSelector).darkMode

    const { userDropdownActived,
        handleOpenDropdown,
        handleCloseDropdown,
        searchInputActived,
        searchResult,
        handleChangeInput,
        searchInputValue,
        handleOpenSearchInput,
        handleClickClearInput,
        handleInputBlur,
        handleKeyDown,
        handleInputFocus
    } = useMovieHeaderBar()

    const backgroundStyle = {
        background: 'rgba(250, 250, 250, 0.24)',
        backdropFilter: 'blur(8px)',
        color: changeColorMode(isDarkMode),

    }

    return (
        <div className='movies_headerbar_container' style={backgroundImageUrl ? backgroundStyle : { backgroundColor: changeColorMode(!isDarkMode, '#F4F4F5', 'rgba(55, 65, 81, 0.64)') }}>
            <ChooseExperienceDropdown contentVisible={userDropdownActived} handleCloseDropdown={handleCloseDropdown} />
            <MoviesHeaderLeft
                hasBackgroundImageUrl={!!backgroundImageUrl}
                handleKeyDown={handleKeyDown}
                handleClickClearInput={handleClickClearInput}
                handleInputFocus={handleInputFocus}
                handleInputBlur={handleInputBlur}
                searchInputValue={searchInputValue}
                handleChangeInput={handleChangeInput}
                searchResult={searchResult}
                searchInputActived={searchInputActived}
                handleOpenDropdown={handleOpenDropdown} />
            <MoviesHeaderRight
                hasBackgroundImageUrl={!!backgroundImageUrl}
                handleOpenSearchInput={handleOpenSearchInput}
            />
        </div>
    )
}

export default MoviesHeaderBar