import BaseMoviesContainer from '@components/BaseMoviesContainer/BaseMoviesContainer'
import MoviesListShelf from '@components/MoviesListShelf/MoviesListShelf'
import { mockedMoviesData } from 'mockdatas/moviesMockedData'
import { transformedData } from '@pages/AllGenresMovies/allgenresmoviesdata'
import { useGetMoviesQuery } from 'services/movie.services'

const Movies = () => {

  const { data: movies, error, isLoading } = useGetMoviesQuery({});

  const moviesData = movies?.map((item: any) => ({
    ...item,
    movieTitle: item.title,
    movieImgUrl: item.poster_url
  }))

  const randomMovies = moviesData?.length && [...moviesData].sort(() => Math.random() - 0.5).slice(0, 12) || []

  return (
    <BaseMoviesContainer>
      <div className='base_movies_content'>
        <MoviesListShelf
          firstElement
          title='Movies For You'
          redirectTitle='View All'
          moviesList={moviesData || []} />
        <MoviesListShelf
          title='Keep Watching'
          redirectTitle='View All'
          moviesList={randomMovies || []} />
        <MoviesListShelf
          category
          title='Genres'
          redirectTitle='/movies/genres'
          moviesList={transformedData || []}
        />
      
      </div>
    </BaseMoviesContainer>
  )
}

export default Movies