import { useEffect, useRef, useState } from 'react';
import { AiOutlineHeart, AiOutlinePlus } from 'react-icons/ai'
import { GiPauseButton } from 'react-icons/gi'
import PlayMusicIcon from 'assets/icon/play_icon_player_white.svg'
import { useSelector } from 'react-redux';
import { musicSelector, uiSelector } from 'store/selectors';
import { ISong, MusicPlatform } from 'interfaces/models/model-interfaces';
import AppleMusicPlayer from '@components/MusicPlayer/apple/apple-music-player';
import ApplePlaylistManager from 'providers/apple/apple-playlist-manager';
import { UPDATE_APPLE_MUSIC, lockFunction } from '../../constants'
import SpotifyWebPlayer from 'react-spotify-web-playback';
import { changeColorMode } from '@utils/colors';
import SpotifyMusicProviders from 'providers/spofity/SpotifyMusicProvider';
import './player-mobile.css'


let currentSong: any
let currentSpotifyToken = 'none'

const PlayerMobile = () => {
    const isDarkMode = useSelector(uiSelector).darkMode
    const [isPlayerOpen, setIsPlayerOpen] = useState(true);

    // Function to toggle modal
    const toggleModal = () => {
        const newState = !isPlayerOpen
        setIsPlayerOpen(newState)
        newState ? setPlayerOpenState('Hide Player') : setPlayerOpenState('Open Player')
    }

    const { selectedMusic } = useSelector(musicSelector)
    const [spotifyToken, setSpotifyToken] = useState<string | undefined>('')
    const [spotifyTrack, setSpotifyTrack] = useState<string[] | undefined>([])
    const audioPlayerRef = useRef<HTMLInputElement | any>(null);
    const isAppleSong = () => selectedMusic?.platform === MusicPlatform.AppleMusic
    const isSpotify = () => selectedMusic?.platform === MusicPlatform.SpotifyMusic
    const [image, setImage] = useState('')
    const [songName, setSongName] = useState('')
    const [artistName, setArtist] = useState('')
    const [playerOpenState, setPlayerOpenState] = useState<string>('Hide Player')


    useEffect(() => { // Play Song(s) ****************

        const upadte = async () => {
            AppleMusicPlayer.pause()

            if (!isAppleSong() && !isSpotify()) return

            const { songInfo, type, appleSongs, spotifySongs }: any = selectedMusic

            if (isAppleSong()) {
                if (type === 'playlist') {
                    ApplePlaylistManager.startPlaylist(appleSongs)
                    const thisSong: ISong = ApplePlaylistManager.getCurrentSong() as ISong
                    configureTrack(thisSong)
                } else if (type === UPDATE_APPLE_MUSIC) {
                    const newSong = ApplePlaylistManager.getCurrentSong()
                    // configureTrack(song)
                } else {
                    AppleMusicPlayer.setQueueItems([selectedMusic])
                    // configureTrack(selectedMusic)
                }
                setIsPlaying(true)
                AppleMusicPlayer.play()
                currentSong = selectedMusic
            }

            if (isSpotify()) {

                if (type === 'playlist') {
                    setSpotifyTrack(spotifySongs)
                } else {
                    const playback: string[] = [songInfo.uri]
                    setSpotifyTrack(playback)
                }
                await checkSpotifyToken()
                setIsPlaying(false)
            }
           
        }

        upadte()

        // togglePlayPause()
    }, [selectedMusic])

    const togglePlayPause = () => {
        const prevValue = isPlaying;

        setIsPlaying(!prevValue);

        if (!prevValue) {

            if (isAppleSong()) {

                if (currentSong || currentSong.id !== selectedMusic?.id) {
                    AppleMusicPlayer.setQueueItems([selectedMusic])
                    currentSong = selectedMusic?.songInfo as ISong
                }
                AppleMusicPlayer.play()

                return
            }
            audioPlayerRef.current.play();

        } else {

            if (isAppleSong()) {
                AppleMusicPlayer.pause()
                return
            }
            audioPlayerRef.current.pause();

        }
    };

    const checkSpotifyToken = async () => {
        const spotifySdk = SpotifyMusicProviders.getApi()
        const { access_token }: any = await spotifySdk.getAccessToken()
        if (access_token && access_token !== currentSpotifyToken) {
            setSpotifyToken(access_token)
        }
    }

    useEffect(() => {
        const initializePlatforms = async () => {
            if (isSpotify()) {
                // @ts-ignore
                const spotifySdk = SpotifyMusicProviders.getApi()
                // @ts-ignore
                const token: AccessToken = await spotifySdk.getAccessToken()
                setSpotifyToken(token.access_token)
            }
        }
        initializePlatforms()

    }, [spotifyToken])

    const [isPlaying, setIsPlaying] = useState(false);

    const configureTrack = (currentSong: ISong) => {
        if (currentSong) {
            const { img, artistName, songName } = currentSong
            setImage(img)
            setSongName(songName as string)
            setArtist(artistName)
        }
    }



    /// if (!selectedMusic?.son) return (<></>)   

    return (
        <>
            {isSpotify() ? (
                <>
                    <div className={`player-container-mobile-spotify`} style={{ bottom: `${isPlayerOpen ? '' : '-148px'}`}}>
                        <button onClick={toggleModal} style={{
                            color: "white",
                            width: "100%",
                            backgroundColor: 'black' //"#1DB954"
                        }}
                        >{playerOpenState}</button>

                        <SpotifyWebPlayer
                            // @ts-ignore
                            token={spotifyToken}
                            // @ts-ignore
                            uris={spotifyTrack}
                            // @ts-ignore
                            // autoPlay={true}

                        />
                    </div>

                </>
            ) : (
                <div className={`player-container-mobile`}>
                    <img src={selectedMusic?.img || image} className="player-img" />
                    <div className="player-song-artist-name">
                        <span className='player-music-name'>{selectedMusic?.songName || songName}</span>
                        <span className='player-music-artist'>{selectedMusic?.artistName || artistName}</span>
                    </div>
                    <div className="player-favorite-play">
                        <AiOutlineHeart size={24} color='white' className='favorite-button' />
                        {isPlaying ? (
                            <GiPauseButton color="white" title="pause" size={20} onClick={togglePlayPause} />
                        ) : (
                            <img title="play" src={PlayMusicIcon} alt="Play" onClick={togglePlayPause} />
                        )}
                    </div>
                    {selectedMusic?.songInfo && <audio ref={audioPlayerRef} src={selectedMusic?.songInfo?.url} />}
                </div>
            )}
        </>
    )
}

export default PlayerMobile