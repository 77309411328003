import { ArrowDownIcon } from "@components/IconComponents/IconComponents";
import { changeColorMode } from "@utils/colors";
import { useState } from "react";
import Switch from "react-switch";
import './SettingsPage.scss'
interface SettingsItemProps {
    title: string;
    isDarkMode: boolean;
    text?: string;
}

const SettingsItem: React.FC<SettingsItemProps> = ({ title, isDarkMode, text }) => {

    const [isExpanded, setIsExpanded] = useState(false)

    const toggleExpand = () => {
        setIsExpanded(!isExpanded)
    }

    return (
        <div className='settings-item-container' onClick={toggleExpand} >
            <div className='settings-item-container-top' style={{ backgroundColor: changeColorMode(!isDarkMode), borderBottom: isDarkMode ? 'none' : '1px solid #D1D5DB' }}>
                <h3 style={{ color: changeColorMode(isDarkMode, '#F9FAFB') }} className="settings-item-title">{title}</h3>
                <ArrowDownIcon strokeColor={changeColorMode(isDarkMode)} />
            </div>
            {isExpanded && !text?.length && <div>
                <div className='settings-item-option'>
                    <span style={{ color: changeColorMode(isDarkMode) }}>Push</span>
                    <Switch checked={true} onChange={() => { }} />
                </div>
                <div className='settings-item-option'>
                    <span style={{ color: changeColorMode(isDarkMode) }}>Email</span>
                    <Switch className='switch-svg' checked={true} onChange={() => { }} />
                </div>
            </div>}
            {isExpanded && text?.length && <p style={{color:changeColorMode(isDarkMode)}} className='settings-item-text'>
                {text}
            </p>}
        </div>
    );
};

export default SettingsItem;
