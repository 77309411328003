import { useWindowDimensions } from '@hooks/useWindowDimension';
import React from 'react'

interface MovieGenreCategoryProps {
    onClickCategory?: () => void;
    categoryImg?: string;
}

const MovieGenreCategory = ({ onClickCategory, categoryImg }: MovieGenreCategoryProps) => {
    const { isMobile } = useWindowDimensions()
    return (
        <div onClick={onClickCategory} style={{ cursor: 'pointer' }}>
            <img src={categoryImg} alt="categoryImg" style={{ maxWidth: isMobile ? '120px' : "248px" }} />
        </div>
    )
}

export default MovieGenreCategory