import ApiBridge from '../../api/api-bridge'
import SearchMobile from '@components/SearchMobile/SearchMobile'
import SearchResults from '@components/UserHeaderBar/SearchResults'
import { useWindowDimensions } from '@hooks/useWindowDimension'
import { changeColorMode } from '@utils/colors'
import { musicLinksList, sideBarLinksListMobile } from '@utils/navigationsLinks/profileDropdownList'
import { artistsMockedData } from 'mockdatas/musics_mocked_data'
import { musicsMadeForYou, } from 'mockdatas/musics_page_mocks'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { searchSelector, uiSelector } from 'store/selectors'
import Base from 'template/Base'

const SearchPage = () => {

    const isDarkMode = useSelector(uiSelector).darkMode
    const searchedInput = useSelector(searchSelector).currentSearchInput
    const location = useLocation()

    const { isMobile } = useWindowDimensions()
    const responsiveSideBarList = isMobile ? sideBarLinksListMobile : musicLinksList
    const searchResults = useSelector(searchSelector).results
    const [displaySearch, setSearchResult] = useState(searchResults)
    const [selectedFilter, setSelectedFilter] = useState('all');
    const [ isLoading, setLoader ] = useState<boolean>(true)
    const handleClickButtonFilter = (filterItem: string) => {
        setSelectedFilter(filterItem);
    };

    useEffect(() => {
        const search = async () => {
            setLoader(true)
            if (searchedInput && searchedInput?.length > 0) {
                const response = await ApiBridge.search(searchedInput)
                setSearchResult(response)
            } else {
                const queryParams = new URLSearchParams(location.search);
                const searchTerm = queryParams.get('term');
                if (searchTerm) {
                    const response = await ApiBridge.search(searchTerm)
                    setSearchResult(response)
                }
            }
            setLoader(false)
        }   

        search()

    }, [searchResults, searchedInput])


    return (
        <>
            {isMobile ?
                <>
                    <SearchMobile tableItems={displaySearch} />
                </>
                :
                <Base sidebarList={responsiveSideBarList}>
                    <h1 style={{ marginLeft: '340px', marginTop: 20, color: changeColorMode(isDarkMode) }}>Search Results: {searchedInput}</h1>
                    <SearchResults
                        additionalClassName={'active'}
                        selectedFilter={selectedFilter}
                        handleClickButtonFilter={handleClickButtonFilter}
                        tableItems={displaySearch}
                        artistItems={artistsMockedData}
                        albumItems={musicsMadeForYou}
                        isLoading={isLoading}
                    />
                </Base>}
        </>
    )
}

export default SearchPage