import { useSelector } from 'react-redux';
import { uiSelector } from 'store/selectors';
import { changeColorMode } from '@utils/colors';
import { IMovie } from 'interfaces/models/model-interfaces';
import './MovieDetails.scss'
import { CloseIcon } from '@components/IconComponents/IconComponents';

interface MovieDetailsProps extends IMovie {
    movieTrailerStarted?: boolean;
    movieTrailer?: string;
    handleCloseTrailer?: () => void;
}

const MovieDetails = ({
    movieDescription,
    movieDirector,
    movieLength,
    movieReleaseDate,
    movieTitle,
    movieMinAge,
    contentProvider,
    movieTrailerStarted,
    movieTrailer,
    handleCloseTrailer
}: MovieDetailsProps) => {

    const isDarkMode = useSelector(uiSelector).darkMode

    return (
        <div className='movie-details-container'>
            {movieTrailerStarted &&
                <div className='movie-trailer-container'>
                    <CloseIcon strokeColor='#FFF' style={{
                        position: 'absolute',
                        right: 20,
                        top: 20,
                        cursor: 'pointer',
                        height: 30,
                    }}
                        onClick={handleCloseTrailer}
                    />
                    <iframe
                        className='movie-trailer'
                        src={movieTrailer}
                        title="YouTube video player"
                        frameBorder="0"
                        allowFullScreen
                    ></iframe>
                </div>
            }
            <h1 className='movie-title' style={{ color: changeColorMode(!isDarkMode) }}>{movieTitle}</h1>
            <div className='movie-info-container'>
                <span style={{
                    color: changeColorMode(!isDarkMode),
                }}>{movieLength}  •</span>
                <span style={{
                    color: changeColorMode(!isDarkMode),
                    
                }}>{movieReleaseDate}  •</span>
                <span style={{
                    color: changeColorMode(!isDarkMode), borderRadius: 6,
                   
                    padding: 6,
                    border: `1px solid ${changeColorMode(!isDarkMode)}`,
                }}>{movieMinAge}</span>
                <span style={{ color: changeColorMode(!isDarkMode) }}>  •</span>
                {!!contentProvider && <img src={contentProvider} alt={contentProvider} style={{ height: '60px', width: '60px', objectFit: 'contain' }} />}
            </div>
            <p className='movie-description' style={{
                color: changeColorMode(!isDarkMode),
                fontWeight: 'bolder',
                textShadow: !isDarkMode ? '0px 0px 10px #000' : '0px 0px 10px #FFF'
            }}>{movieDescription}</p>
            {/* <p className='movie-director' style={{
                color: changeColorModeisDarkMode),
            }}>Director: {movieDirector}</p> */}
        </div>
    )
}

export default MovieDetails