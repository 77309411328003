import BaseMoviesContainer from '@components/BaseMoviesContainer/BaseMoviesContainer'
import MoviesListShelf from '@components/MoviesListShelf/MoviesListShelf'
import { mockedMoviesData } from 'mockdatas/moviesMockedData'
import { transformedData } from '@pages/AllGenresMovies/allgenresmoviesdata'
import { useGetTVShowsQuery } from 'services/movie.services'
// import './MoviesPage.scss'

const TvShowsPage = () => {


  const { data: tvShows, } = useGetTVShowsQuery({});

  const showData = tvShows?.map((item: any) => ({
    ...item,
    movieTitle: item.title,
    movieImgUrl: item.poster_url
  }))

  const sortResponseRandom = () => showData?.length && [...showData].sort(() => Math.random() - 0.5).slice(0, 12) || []

  return (
    <BaseMoviesContainer>
      <div className='base_movies_content'>
        <MoviesListShelf
          firstElement
          title='Tv shows For You'
          redirectTitle='View All'
          type="show"
          moviesList={showData || []} />
        <MoviesListShelf
          type="show"
          title='Keep Watching'
          redirectTitle='View All'
          moviesList={sortResponseRandom() || []} />

      </div>
    </BaseMoviesContainer>
  )
}

export default TvShowsPage