import Heading from '@components/Heading/Heading'
import { useWindowDimensions } from '@hooks/useWindowDimension'
import { musicLinksList, sideBarLinksListMobile } from '@utils/navigationsLinks/profileDropdownList'
import Base from 'template/Base'
import './Categories.scss'
import Arrow from 'assets/icon/arrow.svg'
import { useNavigate } from 'react-router-dom'
import { ICategory, categoriesMockedData } from 'mockdatas/categoriesMockedData'

const Categories = () => {
    const { isMobile } = useWindowDimensions()
    const responsiveSideBarList = isMobile ? sideBarLinksListMobile : musicLinksList
    const navigate = useNavigate()
    const handleGoBack = () => {
        navigate(-1)
    }


    return (
        <Base sidebarList={responsiveSideBarList}>
            <div className='all__genres__container'>
                <div className='all__genres__container__heading' onClick={handleGoBack}>
                    <img src={Arrow} style={{ transform: 'rotate(90deg)' }} alt='arrow' />
                    <Heading>Podcasts Categories</Heading>
                </div>
                <div className='all__genres__container__content'>
                    {categoriesMockedData.map((category: ICategory) => {
                        return (
                            <div className='category_container'>
                                <h4>{category.category}</h4>
                                <div className='category_container__items' style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                    {category.items.map((item) => (
                                        <img src={item.img} alt={item.name} className='category_container__items__img' />
                                    ))}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </Base >
    )
}

export default Categories