import PlayListImg from 'assets/images/playlistImg.png'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { uiSelector } from 'store/selectors';
import './PlayListSection.scss'
import SpotifyImageIcon from 'assets/icon/spotify_green.svg'
import { ISong } from 'interfaces/models/model-interfaces';
import { useWindowDimensions } from '@hooks/useWindowDimension';


interface PlayListSectionProps {
    playlistTitle?: string;
    playlistLink?: string;
    playlistImage?: string;
    playlistLength?: number | string;
    providerImg?: string;
    tracks?: ISong[]
}
const PlayListSection = ({ playlistTitle, playlistImage, playlistLength, playlistLink, providerImg, tracks }: PlayListSectionProps) => {
    const isDarkMode = useSelector(uiSelector).darkMode
    const { isMobile } = useWindowDimensions()

    const imageStyle = {
        width: isMobile ? "100px" : "250px", // Change width based on isMobile
        // Add any other styles you need here
    };

    return (
        <Link to={ `/playlist/${playlistLink}`}
            state={{
                playlistTitle,
                playlistImage,
                playlistLength, 
                playlistLink, 
                providerImg,
                tracks
            }}
            
            className='playlist_container'>
            <div style={{ position: 'relative' }}>
                <img src={playlistImage || PlayListImg} alt="music" className="playlist__img" style={imageStyle} />
                {playlistTitle !== 'Create playlist' && <img src={providerImg} className='provider-img' alt="music" />}
            </div>
            <span className="playlist__title" style={{ color: isDarkMode ? '#FFF' : '#293342' }}>{playlistTitle}</span>
            <span className="playlist__subtitle" style={{ color: isDarkMode ? '#FFF' : '#293342' }}>{playlistLength}</span>
        </Link>
    )
}

export default PlayListSection