import logo from '../../assets/icon/apple.svg'
import { IMergeMusicProviders } from '../../interfaces/providers/provider-interface'
import store from '../../store'
import { sendAuthenticationComplete } from 'actions/authentication/authentication-actions'
import { ISong } from 'interfaces/models/model-interfaces'
import AppleMusicPlayer from '@components/MusicPlayer/apple/apple-music-player'
import AppleParser from './apple-parsers'
import { TrackLimit } from '../../constants'
import { sendStreamAction } from 'actions/stream/stream-actions'

const { REACT_APP_BACKEND_URL } = process.env

interface MergeAppleApi extends ReadableStream<Uint8Array> {
    token: string
}

let musickit: any = null
let musicPlayer: AppleMusicPlayer

class AppleMusicProvider implements IMergeMusicProviders {

    constructor() {
        this.initialize()
    }

    public async initialize() {
        const repsonse = await fetch(`${REACT_APP_BACKEND_URL}/api/token`)
        const body: MergeAppleApi = await repsonse.json()
        const { token } = body

        MusicKit.configure({
            developerToken: token,
            app: {
                name: 'Merge',
                icon: logo,
                build: '1.0.0',
                version: '1.0.0',
            },
            bitrate: MusicKit.PlaybackBitrate.HIGH,
        });

        musickit = MusicKit.getInstance()

        this.addListeners()
    }

    public async login() {
        if (!musickit) {
            await this.initialize()
            musickit = MusicKit.getInstance()
        }
        await musickit.authorize()
    }

    public async logout() {
        if (!musickit) {
            await this.initialize()
            musickit = MusicKit.getInstance()
        }
        await musickit.unauthorize()
        store.dispatch(sendStreamAction(false))
    }

    public async isSubscribed(): Promise<boolean> {
        if (!musickit) {
            await this.initialize()
        }
        const isSubscribed = MusicKit.getInstance() ? MusicKit.getInstance().isAuthorized : false
        return isSubscribed
    }

    public async searchArtists(artistsArray: string[], type: string): Promise<any> {

        const apiCall = artistsArray.map((artist) => musickit.api.search(artist, { limit: 1, type }))
        const results: any[] = await Promise.all(apiCall)
        const artists: any[] = results.map((result) => result[type].data)
        const resultFlat = artists.flat().filter((item: any) => item !== undefined)
        const parsedResult = AppleParser.parseAblums(resultFlat)
        return parsedResult
    }

    public async searchUserFavorites(): Promise<ISong[]> {
        const result = await musickit.api.library.songs()
        const normalizedResult = AppleParser.parsePlaylistTracks(result)
        const filteredFavorites = normalizedResult.filter((song) => song.id !== undefined)
        return filteredFavorites
    }

    private addListeners() {
        musickit.addEventListener(
            MusicKit.Events.authorizationStatusDidChange,
            (event: MusicKit.Events) => {
                if (event.authorizationStatus) {
                    console.log('User is authorized');
                    store.dispatch(sendAuthenticationComplete(true))

                } else {
                    console.log('User is unauthorized');
                    store.dispatch(sendAuthenticationComplete(false))
                }
            }
        );       
    }

    public async searchUserPlaylists(): Promise<any[]> {
        try {
            const playlistsArray = await musickit.api.library.playlists()
            const apiCalls = playlistsArray.map(({ id }: any) => musickit.api.library.playlist(id))
            const playlists = await Promise.all(apiCalls)

            const parsedPlaylists = AppleParser.parsePlaylists(playlists)
            return parsedPlaylists
        } catch (error) {
            return []
        }
    }

    public async searchPodcasts(searchTerm: string[]): Promise<ISong[]> {
        return []
        return new Promise(async (resolve, _reject) => {
            const PodcastCalls = searchTerm.map((name) => musickit.api.search(name, { limit: 10, types: ['podcasts'] }))
            const podcatsResults = await Promise.all(PodcastCalls)
            const podcats: any[] = podcatsResults.map((result) => result.podcasts.data)
            const resultFlat: any = podcats.flat().filter((item: any) => item !== undefined)
            const parsedResult = AppleParser.parseResponse(resultFlat.data)
            resolve(parsedResult)
        })
    }

    public async searchUserPodcasts(): Promise<ISong[]> {
        return []
        return new Promise(async (resolve, _reject) => {
            const userPodcasts = 'Manny'
            const result = await musickit.api.search(userPodcasts, { limit: 10, types: ['podcasts'] })
            const { podcasts } = result
            return []
            const parsedResult = AppleParser.parseResponse(podcasts.data)
            resolve(parsedResult)
        })
    }

    public async searchAlbum(album: string): Promise<any> {

    }

    public async searchArtist(artist: string): Promise<any> {

    }

    public async searchSong(song: string) {

    }

    public async search(searchTerm: string, types: string[] = ['songs']): Promise<MusicKit.Resource> {
        return new Promise(async (resolve, _reject) => {
            try {
                const result = await musickit.api.search(searchTerm, { limit: TrackLimit, types })
                const { songs } = result
                const parsedResult = AppleParser.parseResponse(songs.data)
                resolve(parsedResult)

            } catch (error) {
                console.error(error)
                resolve ([])
            }
        })
    }

    public async searchUserFavoriteAlbums(): Promise<any[]> {
        const favorites = await musickit.api.library.albums()
        const parsedResponse = AppleParser.parseResponse(favorites)
        return parsedResponse
    }

    public async searchUserFavoriteArtists(): Promise<any[]> {
        try {
            const favorites = await musickit.api.library.artists()
            const favoriteCalls = favorites.map(({ attributes }: any) => musickit.api.search(attributes.name, { limit: TrackLimit, types: ['songs'] } ))
            const favoriteResults: any = await Promise.all(favoriteCalls)
            const parsedResponse = AppleParser.parseArtists(favoriteResults)
            return parsedResponse
        } catch(error) {
            console.error(error)
            return []
        }
       
    }

    public async getTrack(trackId: string): Promise<any> {
        try {
            const song = await musickit.api.song(trackId)
            const { artwork } = song.attributes
            const response = {
                ...song,
                artistName: song.attributes.artistName,
                songName: song.attributes.name,
                img: AppleParser.getArtwork(artwork),
                durationInMillis: song.attributes.durationInMillis
            }
            return response
        } catch(error) {
            console.error(error)
            return ''
        }
       
    }

    public player(): AppleMusicPlayer {
        return musicPlayer
    }

    public async getPodcastEpisodes(id: string): Promise<any> {
        return []
    }

    public async getEpisode(show: string): Promise<any> {
        return []
    }
}

export default AppleMusicProvider
