import Modal from '@components/Modal/Modal'
import { SearchIcon } from '@components/IconComponents/IconComponents'
import './CreateRadioModal.scss'
import TableComponent from '@components/Table/Table'
import { musicsMockedData } from 'mockdatas/musics_mocked_data'
import { useWindowDimensions } from '@hooks/useWindowDimension'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import EpisodesCard from '@components/EpisodesCard/EpisodesCard'
import { useSelector } from 'react-redux'
import { uiSelector } from 'store/selectors'
import { changeColorMode } from '@utils/colors'

interface CreateRadioModalProps {
    handleCloseModal: () => void
    visible: boolean
    handleSaveRadio?: () => void
}
const CreateRadioModal = ({ handleCloseModal, visible, handleSaveRadio }: CreateRadioModalProps) => {
    const { isMobile } = useWindowDimensions()
    const isDarkMode = useSelector(uiSelector).darkMode
    return (
        <Modal style={{ backgroundColor: changeColorMode(!isDarkMode) }} handleCancel={handleCloseModal} modalVisible={visible} >
            <div className='modal-container'>
                {isMobile ? <>
                    <div className='mobile-create-radio'>
                        <AiOutlineArrowLeft onClick={handleCloseModal} />
                        <span className='text-create-radio' style={{ color: changeColorMode(isDarkMode) }}>Create radio</span>
                        {isMobile && <hr />}
                        <span className='text-cancel' onClick={handleCloseModal}>Cancel</span>
                    </div>
                </> : <>
                    <h2 className='modal-title' style={{
                        color: changeColorMode(isDarkMode)

                    }}>Create new radio</h2>
                    <p className='modal-info' style={{
                        color: changeColorMode(isDarkMode)

                    }}>Choose one song and we will create a radio based on its vibe</p>
                </>}
                <div className='modal-search'>
                    <SearchIcon />
                    <input type='text' placeholder='Search for a song' className='modal-search-input' />
                </div>
                {isMobile ? <p className='modal-info'>Choose one song and we will create a radio based on its vibe</p> : <h3 style={{
                    color: changeColorMode(isDarkMode)

                }} className='modal-recommends-title'>Recommended</h3>}
                <div className='modal-recommended-songs'>
                    {!isMobile ? <TableComponent showHeaders={false} tableItems={musicsMockedData?.slice(0, 5)} /> : <EpisodesCard
                        showDescription={false}
                        tableItems={musicsMockedData} />}
                </div>
                <button className='modal-create-radio-button' onClick={handleSaveRadio} >
                    Create
                </button>

            </div>
        </Modal>
    )
}

export default CreateRadioModal