import AddStreamingList from '@components/AddStreamingList/AddStreamingList'
import Modal from '@components/Modal/Modal'
import { changeColorMode } from '@utils/colors'
import { musicStreamings, videoStreamings } from 'mockdatas/streamings'
import { useSelector } from 'react-redux'
import { uiSelector } from 'store/selectors'

interface MergedAccountsModalProps {
    modalVisible: boolean
    handleToggleModal: () => void
    unsubscribedMusicStreams: any[]
}

const MergedAccountsModal = ({ modalVisible, handleToggleModal, unsubscribedMusicStreams }: MergedAccountsModalProps) => {
    const isDarkMode = useSelector(uiSelector).darkMode
    return (
        <Modal style={{
            backgroundColor: changeColorMode(!isDarkMode),
        }} modalVisible={modalVisible} handleCancel={handleToggleModal}>
           <AddStreamingList streamingList={unsubscribedMusicStreams} listTitle="Music" /> 
        </Modal>
    )
}

export default MergedAccountsModal