
import { RadioSelected, RadioUnselected } from '@components/IconComponents/IconComponents';
import { useWindowDimensions } from '@hooks/useWindowDimension';
import { changeColorMode } from '@utils/colors';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { uiSelector } from 'store/selectors';


const SettingsQuality = () => {
    const isDarkMode = useSelector(uiSelector).darkMode
    const { isMobile } = useWindowDimensions()
    const [quality, setQuality] = useState('standard');
    const [isOptionsOpen, setIsOptionsOpen] = useState(false);

    const handleQualityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuality(event.target.value);
    };

    const handleToggleOptions = () => setIsOptionsOpen(!isOptionsOpen);


    return (
        <div>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 8,
                flexDirection: isMobile ? 'column' : 'row',
            }}>
                <div onClick={handleToggleOptions} style={{ display: 'flex', cursor: 'pointer', borderRadius: 8, padding: 16, width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', backgroundColor: changeColorMode(!isDarkMode) }}>
                    <span style={{ color: changeColorMode(isDarkMode) }}>Mobile Data</span>
                    <span style={{ color: changeColorMode(isDarkMode) }}>Standard</span>
                </div>
                <div onClick={handleToggleOptions} style={{ display: 'flex', cursor: 'pointer', borderRadius: 8, padding: 16, width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', backgroundColor: changeColorMode(!isDarkMode) }}>
                    <span style={{ color: changeColorMode(isDarkMode) }}>Mobile Data</span>
                    <span style={{ color: changeColorMode(isDarkMode) }}>Standard</span>
                </div>
            </div>
            {isOptionsOpen && (
                <div style={{ flexDirection: 'column', display: 'flex', gap: 12, width: '100%' }}>
                    <label onClick={() => setQuality('Basic')} style={{ padding: 16, cursor: 'pointer', marginTop: 7, display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <span style={{ color: changeColorMode(isDarkMode) }}>Basic</span>
                        {quality === 'Basic' ? <RadioSelected /> : <RadioUnselected />}
                    </label>

                    <label onClick={() => setQuality('Standard')} style={{ padding: 16, cursor: 'pointer', marginTop: 7, display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <span style={{ color: changeColorMode(isDarkMode) }}>Standard</span>
                        {quality === 'Standard' ? <RadioSelected /> : <RadioUnselected />}
                    </label>


                    <label onClick={() => setQuality('High Quality (HQ)')} style={{ padding: 16, cursor: 'pointer', marginTop: 7, display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <span style={{ color: changeColorMode(isDarkMode) }}>HighQuality (HQ)</span>
                        {quality === 'High Quality (HQ)' ? <RadioSelected /> : <RadioUnselected />}
                    </label>

                </div>
            )}
        </div>
    );
};


export default SettingsQuality