import imgAction from 'assets/images/movies_genres/action.png';
import imgAdventure from 'assets/images/movies_genres/adventure.png';
import imgComedy from 'assets/images/movies_genres/comedy.png';
import imgDrama from 'assets/images/movies_genres/drama.png';
import imgScienceFiction from 'assets/images/movies_genres/sciencefiction.png';
import imgFantasy from 'assets/images/movies_genres/fantasy.png';
import imgHorror from 'assets/images/movies_genres/horror.png';
import imgThriller from 'assets/images/movies_genres/thriller.png';
import imgRomance from 'assets/images/movies_genres/romance.png';
import imgCrime from 'assets/images/movies_genres/historical.png';
import imgAnimation from 'assets/images/movies_genres/animation.png';
import imgFamily from 'assets/images/movies_genres/family.png';
import imgMusical from 'assets/images/movies_genres/musical.png';
import imgWar from 'assets/images/movies_genres/war.png';
import imgDocumentary from 'assets/images/movies_genres/documentary.png';
import imgBiography from 'assets/images/movies_genres/biography.png';
import imgSports from 'assets/images/movies_genres/sports.png';
import imgWestern from 'assets/images/movies_genres/western.png'
import imgSuperhero from 'assets/images/movies_genres/superhero.png';

export const allGenresMoviesData = [
    {
        id: 'action',
        title: 'Action',
        to: '/search/action',
        img: imgAction,
        genre: 5
    },
    {
        id: 'adventure',
        title: 'Adventure',
        to: '/search/adventure',
        img: imgAdventure,
        genre: 5
    },
    {
        id: 'comedy',
        title: 'Comedy',
        to: '/search/comedy',
        img: imgComedy,
        genre: 9
    },
    {
        id: 'drama',
        title: 'Drama',
        to: '/search/drama',
        img: imgDrama,
        genre: 3,
    },
    {
        id: 'science-fiction',
        title: 'Science Fiction',
        to: '/search/science-fiction',
        img: imgScienceFiction,
        genre: 26
    },
    {
        id: 'fantasy',
        title: 'Fantasy',
        to: '/search/fantasy',
        img: imgFantasy,
        genre: 13
    },
    {
        id: 'horror',
        title: 'Horror',
        to: '/search/horror',
        img: imgHorror,
        genre: 19
    },
    {
        id: 'thriller',
        title: 'Thriller',
        to: '/search/thriller',
        img: imgThriller,
        genre: 23
    },
    {
        id: 'romance',
        title: 'Romance',
        to: '/search/romance',
        img: imgRomance,
        genre: 4
    },
    {
        id: 'crime',
        title: 'Crime',
        to: '/search/crime',
        img: imgCrime,
        genre: 10,
    },
    {
        id: 'animation',
        title: 'Animation',
        to: '/search/animation',
        img: imgAnimation,
        genre: 6
    },
    {
        id: 'family',
        title: 'Family',
        to: '/search/family',
        img: imgFamily,
        genre: 12
    },
    {
        id: 'musical',
        title: 'Musical',
        to: '/search/musical',
        img: imgMusical,
        genre: 22,
    },
    {
        id: 'war',
        title: 'War',
        to: '/search/war',
        img: imgWar,
        genre: 19
    },
    {
        id: 'documentary',
        title: 'Documentary',
        to: '/search/documentary',
        img: imgDocumentary,
        genre: 11
    },
    {
        id: 'biography',
        title: 'Biography',
        to: '/search/biography',
        img: imgBiography,
        genre: 7
    },
    {
        id: 'sports',
        title: 'Sports',
        to: '/search/sports',
        img: imgSports,
        genre: 16
    },
    {
        id: 'western',
        title: 'Western',
        to: '/search/western',
        img: imgWestern,
        genre: 18
    },
    {
        id: 'superhero',
        title: 'Superhero',
        to: '/search/superhero',
        img: imgSuperhero,
        genre: 14
    }
];


// biograpg = 7

// romance = 4

// animation = 6
// children = 8

// crime = 10
// documentary = 11
// family  - 12
// fantasy = 13
// film-noir = 14
// food = 15
// game show =16
// history = 17
// home & garen = 18
// horror = 19
// mini-series = 20
// music = 21
// musical = 22
// mystery = 23
// news = 24
// reality = 25
// sciencete ficiton = 26


export const transformedData = allGenresMoviesData.map(genre => ({
    movieImgUrl: genre.img,
    movieTitle: genre.title,
    duration: 'Unknown', // Replace with actual data if available
    contentProvider: 'Unknown', // Replace with actual data if available
    category: genre.title,
    type: 'Genre', // or replace with actual type if available
    minimumAge: 'Unknown' // Replace with actual data if available
}));