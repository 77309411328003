import React from 'react';
import StreamActionCard from '@components/StreamActionCard/StreamActionCard';
import './AddStreamingList.scss';
import { useSelector } from 'react-redux';
import { uiSelector } from 'store/selectors';
import { changeColorMode } from '@utils/colors';

interface AddStreamingListProps {
    streamingList: any[];

    listTitle: string
    action?: 'add' | 'remove'
}

const AddStreamingList: React.FC<AddStreamingListProps> = ({ streamingList, listTitle, action = 'add' }) => {
    const isDarkMode = useSelector(uiSelector).darkMode

    return (
        <div className="__list">
            <h2 style={{ color: changeColorMode(isDarkMode) }}>{listTitle}</h2>
            <div className="__list__items">
                {streamingList.map((streaming, idx) => {
                    return (<StreamActionCard
                        streamInfo={streaming}
                        action={action}
                        key={idx}
                        SvgIcon={streaming.SvgIcon}
                    />)
                })}
            </div>
        </div>
    )
}

export default AddStreamingList;