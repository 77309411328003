import { musicLinksList } from '@utils/navigationsLinks/profileDropdownList'
import React, { useState } from 'react'
import Base from 'template/Base'
import './PlaylistPage.scss'
import TopSection from '../../components/TopSection/TopSection'
import Search from '@pages/MoviePage/components/Search/Search'
import { useWindowDimensions } from '@hooks/useWindowDimension'
import EpisodesCard from '@components/EpisodesCard/EpisodesCard'
import TableComponent from '@components/Table/Table'
import BackAndOptions from '../../components/BackAndOptions/BackAndOptions'
import { useLocation } from 'react-router-dom'
import { ISong, MediaType, MusicPlatform } from 'interfaces/models/model-interfaces'
import { getTotalTime } from '@utils/helpers'
import { selectMusic } from '../../store/musicSlice'
import { useDispatch } from 'react-redux'
import PlayerManager from 'managers/player-manager'
import PlaylistPlatformModal from '@components/Modal/playlist-platform-modal'


interface PlaylistInfo {
    playlistTitle: string
    playlistLength: number
    playlistTotalTime: string
    playlistPhoto: string
    tracks?: ISong[]
    providerImg?: string
    playlistImage?: string
}


const PlaylistPage = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const { isMobile } = useWindowDimensions()
    const [inputSearch, setInputSearch] = useState('')
    const location = useLocation()
    const playlistInfo = location.state || {}
    const playlistTitle = playlistInfo?.playlistTitle
    const playlistLength = playlistInfo.playlistLength
    const playlistPhoto = playlistInfo.playlistImage
    const tracks = playlistInfo.tracks
    const playlistTotalTime = getTotalTime(tracks)

    const searchTracks = (tracks: ISong[], searchText: string) => {
        if (!searchText.trim()) {
            return tracks
        }

        const lowerCaseSearchText = searchText.toLowerCase();

        return tracks.filter((track: ISong) => {

            const trackNameMatch = track.songName?.toLowerCase().includes(lowerCaseSearchText);
            const albumNameMatch = track.albumName.toLowerCase().includes(lowerCaseSearchText);
            const artistNameMatch = track.artistName.toLowerCase().includes(lowerCaseSearchText);

            return trackNameMatch || albumNameMatch || artistNameMatch;
        });
    };

    const handlePlatformSelection = (platform: string) => {       
        PlayerManager.PlayAllSong(tracks, platform)
        setIsModalVisible(false); // Hide modal after selection
    };

    const openModal = () => setIsModalVisible(true)    

    return (

        <Base sidebarList={musicLinksList}>

            <div className='playlistpage_container'>
                {isModalVisible && (
                    <PlaylistPlatformModal show={isModalVisible} onPlatformSelect={handlePlatformSelection} />
                )}

                {isMobile && <BackAndOptions />}
                <TopSection
                    Title={playlistTitle}
                    Length={playlistLength}
                    TotalTime={playlistTotalTime}
                    Photo={playlistPhoto}
                    clickHandler={openModal}
                />
                {!isMobile && <Search
                    contentLength={tracks.length}
                    inputSearch={inputSearch}
                    setInputSearch={(setInputSearch)}
                />}
                {isMobile ?
                    <EpisodesCard tableItems={tracks} /> :
                    <TableComponent
                        tableItems={searchTracks(tracks, inputSearch)}
                    />
                }


            </div>
        </Base>

    )
}

export default PlaylistPage