import { useEffect, useState } from 'react'
import { moviesFilterItemsList } from '@utils/moviesFilterList'
import { useGetMoviesQuery, useGetTVShowsQuery } from 'services/movie.services'
import { transformedData } from '@pages/AllGenresMovies/allgenresmoviesdata'
import { Movie, mockedMoviesData } from 'mockdatas/moviesMockedData'
import { contentProviders } from '@utils/contentProviderObject'
import BaseMoviesContainer from '@components/BaseMoviesContainer/BaseMoviesContainer'
import MoviesFilterList from '@components/MoviesFilterItem/MoviesFilterList'
import MoviesListShelf from '@components/MoviesListShelf/MoviesListShelf'
import './MoviesPage.scss'

const MoviesPage = () => {

  const { data: movies, isLoading } = useGetMoviesQuery({});
  const { data: tvShows, } = useGetTVShowsQuery({});
  const [selectedFilter, setSelectedFilter] = useState('')
  const [selectedProviders, setSelectedProviders] = useState<string[]>([])

  const handleSelectFilter = (filter: string) => {
    if (filter === selectedFilter) {
      setSelectedFilter('')
      return

    }
    setSelectedFilter(filter)
  }



  const handleSelectProvider = (provider: string) => {
    if (selectedProviders.includes(provider)) {
      setSelectedProviders((prev) => prev.filter((item) => item !== provider))
      return
    }
    setSelectedProviders((prev) => [...prev, provider])
  }



  const moviesData = movies?.map((item: any) => ({
    ...item,
    movieTitle: item.title,
    movieImgUrl: item.poster_url
  }))

  // const filterTvShows = tvShows?.filter((movie: Movie) =>
  //   movie.service_groups!.some(serviceGroup =>
  //     contentProvidersIds.includes(serviceGroup.toString())
  //   )
  // );

  const showData = tvShows?.map((item: any) => ({
    ...item,
    movieTitle: item.title,
    movieImgUrl: item.poster_url
  }))

  const filterMoviesByProviders = (list: any) => {
    if (selectedProviders.length === 0) {
      return list;
    }


    const movieDataFiltered = list?.filter((movie: Movie) =>
      movie.sources?.some(serviceGroup =>
        selectedProviders?.includes(serviceGroup?.toString())
      )
    );
    return movieDataFiltered
  };


  const sortResponseRandom = () => moviesData?.length && [...moviesData].sort(() => Math.random() - 0.5).slice(0, 12)


  return (
    <BaseMoviesContainer backgroundImageUrl={""}>
      <div className='base_movies_content'>
        <MoviesFilterList
          selectedProviders={selectedProviders}
          handleSelectProvider={handleSelectProvider}
          selectedFilter={selectedFilter}
          list={moviesFilterItemsList}
          handleSelectFilter={handleSelectFilter}
        />
        {selectedFilter !== "TV Shows" && <MoviesListShelf
          isLoading={true}
          firstElement
          title='Movies For You'
          redirectTitle='movies-for-you'
          type='movie'
          moviesList={isLoading ? mockedMoviesData : filterMoviesByProviders(moviesData) || []} />}
        <MoviesListShelf
          isLoading={true}
          title='Keep Watching'
          redirectTitle='Keep Watching'
          moviesList={sortResponseRandom() || []} />
        <MoviesListShelf
          isLoading={true}
          category
          title='Genres'
          redirectTitle='movies/genres'
          moviesList={transformedData || []}
        />
        {selectedFilter !== "Movies" && <MoviesListShelf
          title='Best TV Shows'
          type='show'
          redirectTitle='Best TV Shows'
          moviesList={filterMoviesByProviders(showData) || []} />}
        {selectedFilter !== "TV Shows" && <MoviesListShelf
          title='Trending now'
          redirectTitle='merge-movies/trending-now'
          moviesList={filterMoviesByProviders(sortResponseRandom()) || []} />}
        {selectedFilter !== "TV Shows" && <MoviesListShelf
          title='Hollywood Movies'
          redirectTitle='merge-movies/holly-wood-movies'
          moviesList={filterMoviesByProviders(sortResponseRandom()) || []} />}
      </div>
    </BaseMoviesContainer>
  )
}

export default MoviesPage