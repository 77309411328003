import React from 'react';
import './MusicStreaming.scss'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'
import { useWindowDimensions } from '@hooks/useWindowDimension';
import AdditionalItemComponent from './AdditionalItem';
import { useDispatch, useSelector } from 'react-redux';
import { selectMusic } from 'store/musicSlice';
import { RootState } from 'store';
import { ArrowDownIcon } from '@components/IconComponents/IconComponents';
import { changeColorMode } from '@utils/colors';
import MergeMusicProviders from 'providers/MergeMusicProviders';
import { Swiper, SwiperSlide, SwiperProps } from 'swiper/react';
import PlayListSection from '@components/PlayListSection/PlayListSection';
import { Pagination } from 'swiper/modules'

import 'swiper/css';
import 'swiper/css/pagination';
import Loader from '../../components/Loader/loader'

interface MusicStreamingSectionProps {
  title: string;
  musicList: any[];
  seeAll?: null | string;
  type?: string;
  additionalImage?: string;
  additionItem?: string;
  onClickAdditional?: () => void;
  isLoading?: boolean
  clickRoute?: string
}

interface SwiperRef extends SwiperProps {

  swiper: {
    slideNext(): void;
    slidePrev(): void;
  }
}

const MusicStreamingSection: React.FC<MusicStreamingSectionProps> = ({ title, musicList, seeAll, type = "music", additionItem, additionalImage, onClickAdditional, isLoading, clickRoute }) => {
  const isDarkMode = useSelector((state: RootState) => state.ui.darkMode)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handlePlayMusic = async (music: any) => {
    const { platform, id, genreName } = music
    if (genreName) {
      navigate(`/search?term=${genreName}`)
    } else if (clickRoute) {
      const { songName, artistName } = music
      navigate(`${clickRoute}${songName}/${artistName || songName}`)
    } else {
      const song = await MergeMusicProviders.getContent(id, platform)
      if (false && song) {
        let response = {
          ...music,
          song: song,
          type: 'track'
        }
        dispatch(selectMusic(response))
      }

      if (song) {
        // let updatedMusic = {
        //     ...music,
        //     song: {
        //       ...song
        //     },
        //     type: 'track'
        // };

        let updatedMusic: any = { songInfo: {} }

        for (let key in music) {
          if (music.hasOwnProperty(key)) {
            updatedMusic[key] = music[key];
          }
        }


        for (let key in song) {
          if (song.hasOwnProperty(key)) {
            updatedMusic.songInfo[key] = song[key];
          }
        }

        dispatch(selectMusic(updatedMusic))
      }
    }
  }

  const slideRef = React.useRef<SwiperRef>(null);


  const handleNavigation = () => {
    seeAll && navigate(seeAll)
  }
  const { isMobile } = useWindowDimensions()

  const handleNextSlide = () => {
    slideRef.current && slideRef.current?.swiper.slideNext()
  }

  const handlePreviousSlide = () => {
    slideRef.current && slideRef.current?.swiper.slidePrev()
  }

  return (<>
    {isLoading ? (
      <Loader />
    ) : (
      <>
        <div className='music__page__container__top'>
          <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }} onClick={handleNavigation}>
            <h2 className='music__page__container__top__title' style={{ color: isDarkMode ? '#FFF' : 'unset' }}>{title} </h2>
            {seeAll && <ArrowDownIcon strokeColor={changeColorMode(isDarkMode)} style={{ transform: 'rotate(270deg)' }} />}
          </div>
          {!isMobile && <div className='music__page__container__top__previousNextContainer'>
            <ArrowDownIcon onClick={handlePreviousSlide} strokeColor={changeColorMode(isDarkMode)} height={20} width={20} style={{
              transform: 'rotate(90deg)',
              cursor: 'pointer'
            }} />
            <ArrowDownIcon onClick={handleNextSlide} strokeColor={changeColorMode(isDarkMode)} height={20} width={20} style={{
              transform: 'rotate(270deg)',
              cursor: 'pointer'
            }} />
          </div>}
        </div>
        <div className='music__page__container__madeforyou'>
          {/* {false && additionalImage && <AdditionalItemComponent additionalImage={additionalImage} additionItem={additionItem} onClickAdditional={onClickAdditional} />} */}
          <Swiper ref={slideRef} className='music__page__container__madeforyou' spaceBetween={50}
            slidesPerView={3}

            breakpoints={{
              '640': {
                slidesPerView: 3,
                spaceBetween: 50,

              }
            }}
            onSlideChange={() => { }}
            onSwiper={(swiper: any) => { }} >
            {musicList && musicList.map((music: any, index) => {
              let { id, songName, artistName, albumName, platform, url, img, icon, playlistName, playlistArtworkUrl, tracks, providerImg, genreName } = music
              if (playlistArtworkUrl) {
                songName = playlistName
                music.img = playlistArtworkUrl
                artistName = ''
              }
              return (
                <SwiperSlide key={index} className="music__item__container">
                  {
                    type === 'playlists' ?
                      <PlayListSection
                        playlistImage={playlistArtworkUrl}
                        playlistLength={`${tracks.length} tracks`}
                        playlistLink={'test'}
                        playlistTitle={playlistName}
                        providerImg={providerImg}
                        tracks={tracks}
                      /> :
                      type === 'music' ?
                        <div onClick={() => handlePlayMusic({
                          id,
                          song: url,
                          songName,
                          artistName,
                          albumName,
                          platform,
                          genreName
                        })} className='music__item__container__link' style={{
                          alignItems: songName ? 'flex-start' : 'center',
                          color: isDarkMode ? '#FFF' : '#293342'
                        }}>
                          <img src={music.img} alt="music" className="music__item__container__img" />
                          {songName && <span className="music__item__container__title" style={{ color: isDarkMode ? '#FFF' : '#293342' }}>{songName}</span>}
                          {artistName && <span className="music__item__container__subtitle" style={{ color: isDarkMode ? '#FFF' : '#293342' }}>{artistName}</span>}
                          {icon && <img src={icon} style={{ float: "right", width: "21px" }} alt='icon' />}
                        </div>
                        :
                        <Link to={`/${type}/${songName}/${artistName || songName}`}
                          state={music}
                          className='music__item__container__link'
                          style={{
                            alignItems: songName ? 'flex-start' : 'center',
                          }}>
                          <img src={music.img} alt="music" className="music__item__container__img" />
                          {(songName) && <span className="music__item__container__title" style={{ color: isDarkMode ? '#FFF' : '#293342' }}>{songName}</span>}
                          {artistName && <span className="music__item__container__subtitle" style={{ color: isDarkMode ? '#FFF' : '#293342' }}>{artistName}</span>}
                          {icon && <img src={icon} style={{ float: "right", width: "21px" }} alt='icon' />}
                        </Link>
                  }
                </SwiperSlide>
              )
            }
            )}
          </Swiper>
        </div>
      </>)
    }
  </>)
}

export default MusicStreamingSection;