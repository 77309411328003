import { profileDropdownList } from '@utils/navigationsLinks/profileDropdownList'
import Base from 'template/Base'
import './SettingsPage.scss'
import { EyeIcon, MusicIcon, NotificationIcon, VideoIcon } from '@components/IconComponents/IconComponents'
import { useSelector } from 'react-redux'
import { uiSelector } from 'store/selectors'
import { changeColorMode } from '@utils/colors'
import SettingsItem from './SettingsItem'
import SettingsQuality from './SettingsQuality'
import SettingsApperance from './SettingsApperance'
import { useWindowDimensions } from '@hooks/useWindowDimension'

const SettingsPage = () => {
    const isDarkMode = useSelector(uiSelector).darkMode
    const { isMobile } = useWindowDimensions()

    return (
        <Base sidebarList={isMobile ? [] : profileDropdownList}>
            <main className='settings-page-container'>
                <h2 className='settings-page-title' style={{ color: changeColorMode(isDarkMode) }}>Settings</h2>
                <article className='settings-page-content-container' style={{ border: isDarkMode ? 'none' : isMobile ? 'none' : '1px solid #E5E7EB' }}>
                    <div className='settings-page-content-title'>
                        <NotificationIcon strokeColor='#6B37FE' />
                        <h3 style={{ color: changeColorMode(isDarkMode) }}>Notifications</h3>
                    </div>
                    <SettingsItem title="Music" isDarkMode={isDarkMode} />
                    <SettingsItem title="Video" isDarkMode={isDarkMode} />
                    <SettingsItem title="Subscriptions" isDarkMode={isDarkMode} />
                    <SettingsItem title="General updates" isDarkMode={isDarkMode} />
                </article>

                <article className='settings-page-content-container' style={{ border: isDarkMode ? 'none' : isMobile ? 'none' : '1px solid #E5E7EB' }}>
                    <div className='settings-page-content-title'>
                        <MusicIcon strokeColor='#6B37FE' />
                        <h3 style={{ color: changeColorMode(isDarkMode) }}>Music quality</h3>
                    </div>
                    <SettingsQuality />
                </article>

                <article className='settings-page-content-container' style={{ border: isDarkMode ? 'none' : isMobile ? 'none' : '1px solid #E5E7EB' }}>
                    <div className='settings-page-content-title'>
                        <VideoIcon strokeColor='#6B37FE' />
                        <h3 style={{ color: changeColorMode(isDarkMode) }}>Video quality</h3>
                    </div>
                    <SettingsQuality />
                </article>

                <article className='settings-page-content-container' style={{ border: isDarkMode ? 'none' : isMobile ? 'none' : '1px solid #E5E7EB' }}>
                    <div className='settings-page-content-title'>
                        <EyeIcon strokeColor='#6B37FE' />
                        <h3 style={{ color: changeColorMode(isDarkMode) }}>Appearence</h3>
                    </div>
                    <SettingsApperance />
                </article>

                <article className='settings-page-content-container'>
                    <div className='settings-page-content-title'>
                        <h3 style={{ color: changeColorMode(isDarkMode) }}>About</h3>
                    </div>
                    <SettingsItem title="Terms of service" text={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam tristique nisl in ante tincidunt, a faucibus dui laoreet. Fusce et ipsum in arcu fringilla iaculis. Vestibulum id justo vel odio iaculis pellentesque ac nec nunc. Donec auctor id dolor a fringilla. Sed id libero vitae eros lobortis lacinia a eu quam. Vestibulum eget vehicula quam. Nullam bibendum, velit vel consequat vehicula, ligula quam vehicula purus, eu ultricies justo sapien nec dolor. Suspendisse potenti. In id arcu non nisl accumsan iaculis in a elit. Suspendisse potenti. Integer eu urna vel arcu imperdiet lacinia. Integer eget lectus eget ex sodales hendrerit ac vel felis. Sed sit amet sapien tortor. Sed eu eleifend justo, non auctor libero. Curabitur in erat a urna facilisis fringilla ut vel erat. Nullam sit amet facilisis urna. Vivamus convallis, dolor vel malesuada tincidunt, velit elit laoreet libero, non lacinia libero dui vel arcu."} isDarkMode={isDarkMode} />
                    <SettingsItem title="Privacy Policy" text={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam tristique nisl in ante tincidunt, a faucibus dui laoreet. Fusce et ipsum in arcu fringilla iaculis. Vestibulum id justo vel odio iaculis pellentesque ac nec nunc. Donec auctor id dolor a fringilla. Sed id libero vitae eros lobortis lacinia a eu quam. Vestibulum eget vehicula quam. Nullam bibendum, velit vel consequat vehicula, ligula quam vehicula purus, eu ultricies justo sapien nec dolor. Suspendisse potenti. In id arcu non nisl accumsan iaculis in a elit. Suspendisse potenti. Integer eu urna vel arcu imperdiet lacinia. Integer eget lectus eget ex sodales hendrerit ac vel felis. Sed sit amet sapien tortor. Sed eu eleifend justo, non auctor libero. Curabitur in erat a urna facilisis fringilla ut vel erat. Nullam sit amet facilisis urna. Vivamus convallis, dolor vel malesuada tincidunt, velit elit laoreet libero, non lacinia libero dui vel arcu."} isDarkMode={isDarkMode} />
                </article>

            </main>
        </Base>
    )
}

export default SettingsPage