import React, { useEffect, useState } from 'react';
import { FastAverageColor } from 'fast-average-color';
import MoviesHeaderBar from '@components/MoviesHeaderbar/MoviesHeaderBar';
import { useSelector } from 'react-redux';
import { uiSelector } from 'store/selectors';
import { changeColorMode } from '@utils/colors';
import { useWindowDimensions } from '@hooks/useWindowDimension';
import MoviesHeaderBarMobile from '@components/MoviesHeaderBarMobile/MoviesHeaderBarMobile';
import './BaseMoviesContainer.scss';

interface BaseMoviesContainerProps {
    children?: React.ReactNode;
    backgroundImageUrl?: string;
    showHeaderBar?: boolean;
    isTvShow?: boolean;
}

const BaseMoviesContainer = ({ children, backgroundImageUrl = '', isTvShow }: BaseMoviesContainerProps) => {

    const [dominantColor, setDominantColor] = useState<any | null>(null);
    const isDarkMode = useSelector(uiSelector).darkMode
    const { height, isMobile } = useWindowDimensions()

    useEffect(() => {
        if (backgroundImageUrl) {
            const fac = new FastAverageColor();
            const img = new Image();
            img.src = backgroundImageUrl;
            img.crossOrigin = 'Anonymous';
            img.onload = () => {
                const color = fac.getColor(img);
                setDominantColor(color);
                fac.destroy();
            };

        }
    }, [backgroundImageUrl]);

    const backgroundStyle = {
        backgroundImage: dominantColor ? `linear-gradient(${dominantColor?.rgb}, ${dominantColor?.rgba}, url(${backgroundImageUrl}))` : `url(${backgroundImageUrl})`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: height + (isTvShow ? 450 : 150),
        color: changeColorMode(isDarkMode),
    };



    return (
        <>
            <section className='base_movies_container' style={backgroundImageUrl ? backgroundStyle : {
                backgroundColor: changeColorMode(!isDarkMode, '#F4F4F5', 'rgba(55, 65, 81, 0.64)'),
            }}>
                <MoviesHeaderBar backgroundImageUrl={backgroundImageUrl} />
                {children}
            </section>
            {isMobile && <MoviesHeaderBarMobile />}
        </>
    );
};

export default BaseMoviesContainer;
