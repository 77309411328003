export const colors = {
    darkMode: {
        bgColor: "rgb(31, 41, 55)",
        text: "#FFF"
    }
}

export const changeColorMode = (isDarkModeActived: boolean, toColor: string = colors.darkMode.text, previousColor: string = colors.darkMode.bgColor) => {

    return isDarkModeActived ? toColor : previousColor
}