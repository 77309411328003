import { AddIcon, WatchIcon } from '@components/IconComponents/IconComponents'
import './MovieButtons.scss'
import { useSelector } from 'react-redux'
import { uiSelector } from 'store/selectors'
import { changeColorMode } from '@utils/colors'


const MovieButtons: React.FC<any> = ({ watchMovieLink, handlePressTrailer }) => {

    const isDarkMode = useSelector(uiSelector).darkMode


    return (
        <div className='movie-buttons-container'>
            <button className='button-watch' onClick={watchMovieLink}>
                <span style={{ color: "#FFF" }}>Watch</span>
                <WatchIcon strokeColor={"#FFF"} />
            </button>
            <button className='button-watch-trailer' onClick={handlePressTrailer}>
                Watch Trailer
            </button>
            <button className='button-add-playlist'>
                <AddIcon />
            </button>
        </div>
    )
}

export default MovieButtons