import React from 'react'
import './Heading.scss'
import { RootState } from 'store'
import { useSelector } from 'react-redux'
import { colors } from '@utils/colors'

interface HeadingProps {
    children: React.ReactNode
}
const Heading = ({ children }: HeadingProps) => {
    const isDarkMode = useSelector((state: RootState) => state.ui.darkMode)

    return (
        <h1 className='heading' style={{ color: isDarkMode ? colors.darkMode.text : colors.darkMode.bgColor }}>{children}</h1>
    )
}

export default Heading