import React from 'react';
import Input from '@components/Input/Input';
import Button from '@components/Button/Button';
import { Link } from 'react-router-dom';
import CloseIcon from '../../../assets/icon/close_icon.svg'
import LeftArrowIcon from '../../../assets/icon/left__arrow.svg'
import './ProfilePageChangePassword.scss'
import { useWindowDimensions } from '@hooks/useWindowDimension';


interface ProfilePageChangePasswordProps {
    modalVisible: boolean;
    setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProfilePageChangePassword: React.FC<ProfilePageChangePasswordProps> = ({
    modalVisible,
    setModalVisible
}) => {
    const { isMobile } = useWindowDimensions();
    const [oldPassword, setOldPassword] = React.useState<string>('');
    // const [newPassword, setNewPassword] = React.useState<string>('');

    const handleCancel = () => {
        setModalVisible(false);
    }

    return (
        <>
            <div className={`change__password__overlay ${modalVisible ? 'visible' : ''}`} onClick={handleCancel} />
            <div className={`change__password__modal ${modalVisible ? 'visible' : ''}`}>
                <div className="top_container">
                    <h2>Change Password</h2>
                    <img src={isMobile ? LeftArrowIcon : CloseIcon} alt="Close icon" onClick={handleCancel} />
                </div>
                <hr style={{ margin: '40px 0px' }} />
                <div className="form__content">
                    <Input
                        labelVisible
                        value={oldPassword}
                        onChange={event => setOldPassword(event.target.value)}
                        inputTitle="Old Password"
                        type="password"
                        placeholder=""
                    />
                    <Link to="/forgot-password" className="forgot__password">Forgot Password?</Link>
                    <hr />
                    <Input
                        labelVisible
                        value={oldPassword}
                        onChange={event => setOldPassword(event.target.value)}
                        inputTitle="New Password"
                        type="password"
                        placeholder=""
                    />
                    <Input
                        labelVisible
                        value={oldPassword}
                        onChange={event => setOldPassword(event.target.value)}
                        inputTitle="Confirm password"
                        type="password"
                        placeholder=""
                    />
                    <div className='buttons__container'>
                        <Button
                            buttonText="Cancel"
                            onClick={handleCancel}
                            style={{ width: '100%', borderRadius: 24, color: "#6B37FE", backgroundColor: "#FFF", border: "1px solid #6B37FE" }}
                        />
                        <Button
                            buttonText="Save"
                            onClick={() => { }}
                            style={{ width: '100%', borderRadius: 24, }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfilePageChangePassword;