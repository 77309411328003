import { BackLongArrow, ThreeDotsIcon } from '@components/IconComponents/IconComponents'
import { changeColorMode } from '@utils/colors'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { uiSelector } from 'store/selectors'

const BackAndOptions = () => {

    const isDarkMode = useSelector(uiSelector).darkMode
    const navigation = useNavigate()
    const handleBackButton = () => {
        navigation(-1)
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
            <BackLongArrow onClick={handleBackButton} strokeColor={changeColorMode(isDarkMode)} />
            {/* <ThreeDotsIcon strokeColor={changeColorMode(isDarkMode)} /> */}
        </div>
    )
}

export default BackAndOptions