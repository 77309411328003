import React from 'react'
import { SearchIcon, CloseIcon } from 'components/IconComponents/IconComponents';
import './UserHeaderBar.scss'
interface UserHeaderBarSearchProps {
  searchActived: boolean;
  fullSize?: boolean;
  searchInput?: string
  handleChangeSearchInputType?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  toggleSearch?: () => void;
  closeHeaderSearch?: () => void;

}
const UserHeaderBarSearch = ({
  searchActived,
  fullSize,
  searchInput,
  handleChangeSearchInputType,
  closeHeaderSearch,
  toggleSearch }: UserHeaderBarSearchProps) => {
  const inputRef = React.useRef<HTMLInputElement>(null)

  const handleClickSearch = () => {
    inputRef.current?.focus()
  }

  return (
    <div className={`search_side_bar ${searchActived ? 'actived' : ''}`} onClick={fullSize ? handleClickSearch : toggleSearch} >
      <SearchIcon style={{ cursor: 'pointer' }} strokeColor={searchActived ? "#B79FFF" : "#000"} />
      {searchActived &&
        <>
          <input style={{ width: '100%' }} ref={inputRef} type="text" placeholder="Search" value={searchInput} onChange={handleChangeSearchInputType} />
          <CloseIcon strokeColor='#B79FFF' className='search__closeicon' onClick={fullSize ? toggleSearch : () => { }} />
        </>
      }
    </div>
  )
}

export default UserHeaderBarSearch