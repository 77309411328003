
export type MoviesFilterItemType = typeof moviesFilterItemsList[0];

export const moviesFilterItemsList = [
    {
        id: "tvshows",
        label: "TV Shows",
        link: "/movies/tvshows"
    },
    {
        id: "movies",
        label: "Movies",
        link: "/movies/movies"
    },
    {
        id: "free",
        label: "Free",
        link: "/movies/free"
    },
    {
        id: "genres",
        label: "Genres",
        link: "/movies/genres"
    },
    {
        id: "contentProviders",
        label: "Content Providers",
        link: "/movies/content-providers"
    }
]