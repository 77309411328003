import TitleInfoComponent from '@components/TitleInfoComponent/TitleInfoComponent';
import React from 'react';
import './ProfilePageUserInfo.scss'
import { useAuth0 } from '@auth0/auth0-react';

const ProfilePageUserInfo: React.FC = () => {
    const { user } = useAuth0();

    return (
        <div className='profile__page__userinfo'>
            <TitleInfoComponent title="Name" info={user?.name} />
            <TitleInfoComponent title="Email" info={user?.email} />
            <TitleInfoComponent title="Country or region" info={user?.locale} />
        </div>
    )
}

export default ProfilePageUserInfo;