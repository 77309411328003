import ApiBridge from '../../api/api-bridge'
import MusicStreamingSection from '@components/MusicStreamingSection/MusicStreamingSection'
import { useWindowDimensions } from '@hooks/useWindowDimension'
import { musicLinksList } from '@utils/navigationsLinks/profileDropdownList'
import { ArtistsRankings, ISong } from 'interfaces/models/model-interfaces'
import { categoriesMockedData } from 'mockdatas/categoriesMockedData'
import { musicsMadeForYou } from 'mockdatas/musics_page_mocks'
import { useEffect, useState } from 'react'
import Api from 'services/api'
import Base from 'template/Base'
import Loader from '@components/Loader/loader'

const PodcastsPage = () => {
  const [isLoading, setLoader] = useState<boolean>(true)
  const { isMobile } = useWindowDimensions()
  const [topPodcastsData, setPodcasts] = useState<ISong[]>([])
  const [usePodcastData, setUserPodcasts] = useState<ISong[]>([])
  const musicLinksListMobile = [...musicLinksList].splice(0, 4)
  const removeMyFavoritesIcon = isMobile ? musicLinksListMobile : musicLinksList

  useEffect(() => {
    const fetchData = async () => {
      const rankingsTerm: ArtistsRankings = await Api.get({}, '/rankings');
      const { topPodcasts } = rankingsTerm
      const topPodcastsCall = ApiBridge.searchPodcasts(topPodcasts)
      const userPodcastCall = ApiBridge.searchUserPodcasts()
      const [topPodcastsResult, userPodcastResult]: any = (await Promise.all([topPodcastsCall, userPodcastCall]))
      setPodcasts(topPodcastsResult)
      setUserPodcasts(userPodcastResult)
      setLoader(false)
    }

    fetchData()
  }, [])

  return (<>
    {isLoading ?
      <Loader />
      :
      <Base sidebarList={removeMyFavoritesIcon}>
        <article className='music__page__container'>
          <MusicStreamingSection type="podcast" title="Best Podcasts" musicList={topPodcastsData} isLoading={isLoading} />
          <MusicStreamingSection type="podcast" title="Specially For You" musicList={usePodcastData} isLoading={isLoading} />
          {/* <MusicStreamingSection type="podcast" title="Categories" seeAll={"/podcasts/categories"} musicList={categoriesMockedData[0].items} isLoading={false} /> */}
          {/* <MusicStreamingSection type="podcast" title="Our Choice" musicList={musicsMadeForYou} isLoading={isLoading} /> */}
        </article>
      </Base>
    }
  </>)
}

export default PodcastsPage