import EpisodesCard from '@components/EpisodesCard/EpisodesCard'
import FilterButtons from '@components/FilterButtons/FilterButtons'
import { BackLongArrow, CloseIcon } from '@components/IconComponents/IconComponents'
import AddPlaylistModal from '@components/MyFavoritesSection/AddPlaylistModal'
import MyFavoritesSection from '@components/MyFavoritesSection/MyFavoritesSection'
import { changeColorMode } from '@utils/colors'
import AlbumCard from '@components/AlbumCard/AlbumCard'
import ArtistsCard from '@components/ArtistsCard/ArtistsCard'
import { artistsMockedData, musicsMockedData, recentSearchesMockedData } from 'mockdatas/musics_mocked_data'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { uiSelector } from 'store/selectors'
import Loader from '@components/Loader/loader'
import ApiBridge from 'api/api-bridge'
import { ISong } from 'interfaces/models/model-interfaces'
import Base from 'template/Base'

interface SeacrhMobileProps {
    tableItems: any[]
}

const SearchMobile: React.FC<SeacrhMobileProps> = ({ tableItems }) => {
    const navigation = useNavigate()
    const [searchInput, setSearchInput] = React.useState('')
    const [openPlaylistModal, setOpenPlaylistModal] = React.useState(false)
    const [isLoading, setLoader] = useState<boolean>(false)
    const [searchRestult, setSearchResult] = useState([])


    const isDarkMode = useSelector(uiSelector).darkMode
    const [inputSelected, setInputSelected] = useState(true)
    const [selectedFilter, setSelectedFilter] = useState('Songs')
    const inputRef = React.useRef<HTMLInputElement>(null)

    const handleCleanInput = () => {
        setSearchInput('')
    }

    const handleFocusInput = () => {
        inputRef.current?.focus()
        setInputSelected(true)
    }

    // const searchedMusics = tableItems.filter(item => item.songName.toLowerCase().includes(searchInput.toLowerCase()))
    // const searchedArtists = tableItems.filter(item => item.songArtist.toLowerCase().includes(searchInput.toLowerCase()))
    // const transformSearchArtistToSong = tableItems.map(item => ({ songName: item.songArtist }))
    // const mergedSearch = [...searchedMusics, ...transformSearchArtistToSong]

    const handlePressItem = (item: string) => {
        setInputSelected(false)
        setSearchInput(item)
    }

    const togglePlaylistModal = () => {
        setOpenPlaylistModal(!openPlaylistModal)
    }

    const handleSavePlaylist = () => {
        alert("Playlist saved")
        togglePlaylistModal()
    }

    const handleEnterPress = async (inputValue: string) => {
        setLoader(true)
        if (inputValue && inputValue?.length > 0) {
            const response = await ApiBridge.search(inputValue)
            setSearchResult(response)
        } else {
            // @ts-ignore
            const queryParams = new URLSearchParams(window.location.search);
            const searchTerm = queryParams.get('term');
            if (searchTerm) {
                const response = await ApiBridge.search(searchTerm)
                setSearchResult(response)
            }
        }
        setLoader(false)
    }

    useEffect(() => {
        const searchTerm = async () => {
            setLoader(true)
            const queryParams = new URLSearchParams(window.location.search);
            const searchTerm = queryParams.get('term');
            if (searchTerm) {
                const response = await ApiBridge.search(searchTerm)
                setSearchResult(response)
            }
            setLoader(false)
        }

        searchTerm()
    }, [window.location.search])



    return (
        <>
            {isLoading ? <Loader /> :
                <Base>
                    <div style={{ width: '100%' }}>
                        <AddPlaylistModal handleCloseModal={togglePlaylistModal} visible={openPlaylistModal} handleSaveRadio={handleSavePlaylist} />
                        <div style={{ display: 'flex', alignItems: 'center', width: '100%', gap: 8, marginBottom: 12, padding: '0px 10px', marginTop: 10 }}>
                            <BackLongArrow onClick={() => navigation(-1)} strokeColor={changeColorMode(isDarkMode)} />
                            <div onClick={handleFocusInput} style={{ width: '100%', display: 'flex', alignItems: 'center', height: '48px', padding: '0px 12px', borderRadius: '12px', border: '1px solid black', backgroundColor: changeColorMode(!isDarkMode) }}>
                                <input autoFocus
                                    ref={inputRef}
                                    value={searchInput}
                                    onKeyUp={e => {
                                        if (e.key === 'Enter') {
                                            // Call the function you want to execute when Enter is pressed
                                            handleEnterPress(searchInput);
                                        }
                                    }}

                                    onChange={e => setSearchInput(e.target.value)} type="text" placeholder="Search"
                                    style={{ width: '100%', height: '100%', border: 'none', background: 'transparent', color: changeColorMode(isDarkMode) }} />
                                {!!searchInput.length && <CloseIcon strokeColor={changeColorMode(isDarkMode)} style={{ marginRight: 20, height: 30, width: 15 }} onClick={handleCleanInput} />}
                            </div>
                        </div>

                        <hr style={{ marginBottom: '20px' }} />
                        {/* {inputSelected && !searchInput.length && <>
                <div>
                    <h3 style={{ color: changeColorMode(isDarkMode), padding: '0px 10px' }}>Recent searches</h3>
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '10px', gap: '6px' }}>
                        {recentSearchesMockedData.map((item) => <div onClick={() => handlePressItem(item)}
                            style={{
                                width: '100%',
                                paddingBottom: 8,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                borderBottom: '1px solid #FAFAFA',
                            }}>
                            <span style={{ color: changeColorMode(isDarkMode) }}>{item}</span>
                            <CloseIcon style={{ marginRight: 20 }} />
                        </div>)}
                    </div>
                </div>
            </>} */}

                        {/* {inputSelected && !!searchInput.length && <>
                        <h3 style={{ padding: '0px 10px', color: changeColorMode(isDarkMode) }}>Recommended</h3>
                        <div className='' style={{ display: 'flex', flexDirection: 'column', padding: '10px', gap: 12 }}>
                            {searchRestult.map((item: any, index) => (
                                <span style={{
                                    width: '100%',
                                    paddingBottom: 8,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    borderBottom: '1px solid #FAFAFA',
                                    color: changeColorMode(isDarkMode)
                                }} onClick={() => handlePressItem(item?.songName)}>{item?.songName}</span>
                            ))}
                        </div>
                    </>} */}

                        {/* {!inputSelected && !!searchInput.length && (
                        <FilterButtons
                            selectedFilter={selectedFilter}
                            setSelectedFilter={setSelectedFilter}
                        />
                    )} */}
                        <div style={{ margin: "25px" }}>
                            <EpisodesCard tableItems={searchRestult} />
                        </div>

                        {/* {!inputSelected && !!searchInput.length && selectedFilter === 'Artists' && <ArtistsCard listItems={searchRestult} />}
                    {!inputSelected && !!searchInput.length && selectedFilter === 'Playlists' && <MyFavoritesSection toggleNewPlaylistModal={togglePlaylistModal} title='' type='playlists' items={searchRestult} />}
                    {!inputSelected && !!searchInput.length && selectedFilter === 'Albums' && <AlbumCard listItems={searchRestult} />}
                    {!inputSelected && !!searchInput.length && selectedFilter === 'Episodes' && <AlbumCard listItems={searchRestult} />} */}

                    </div>
                </Base>
            }
        </>)
}

export default SearchMobile