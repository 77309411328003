import React from 'react';
import './PlatformMergeItem.scss'
import { Link } from 'react-router-dom'

interface Props {
    title: string;
    backgroundImageUrl: string;
    url?: string;
    available?: boolean;
}

const PlatformMerge: React.FC<Props> = ({ title, backgroundImageUrl, url, available = true }) => {
    return (
        <Link to={url || '#'}>
            <div className="bg-image-component" style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
                <h2 >{title}</h2>
                {!available && <p className='comming-soon-text' >Coming soon</p>}
            </div>
        </Link>
    );
}

export default PlatformMerge;
