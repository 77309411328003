import { musicLinksList } from '@utils/navigationsLinks/profileDropdownList'
import { useEffect, useState } from 'react'
import Base from 'template/Base'
import TopSection from '../../components/TopSection/TopSection'
import { useWindowDimensions } from '@hooks/useWindowDimension'
import EpisodesCard from '@components/EpisodesCard/EpisodesCard'
import TableComponent from '@components/Table/Table'
import { musicsMockedData } from 'mockdatas/musics_mocked_data'
import BackAndOptions from '../../components/BackAndOptions/BackAndOptions'
import ApiBridge from 'api/api-bridge'
import { useParams } from 'react-router-dom'
import { getTotalTime } from '@utils/helpers'
import Loader from '@components/Loader/loader'
import { useDispatch } from 'react-redux'
import PlayerManager from 'managers/player-manager'
import { MusicPlatform } from 'interfaces/models/model-interfaces'
import PlaylistPlatformModal from '@components/Modal/playlist-platform-modal'


const ArtistPage = () => {

    const { isMobile } = useWindowDimensions()
    const [isLoading, setLoading] = useState<boolean>(true)
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
    const [artistImage, setArtistImage] = useState<string>()
    const [inputSearch, setInputSearch] = useState('')
    const [artistSongs, setArtistSongs] = useState<any[]>([])
    const artistPhoto = 'https://s3-alpha-sig.figma.com/img/eae3/13a4/8883a46e7a2a60ee806e73a8052191be?Expires=1700438400&Signature=k5SLkOCaubCI-bJ8O7THHCt3p9eU125oczxWd8fQwi1f92gJlBMzNtZPwa6ZGgAxoC2AO5yBA5AvpUjxGR3SJUCvvzVS9Fz3UQwz2I8PV1iwn84FBFGrOt3gIyLNKLklyxUYPlWsqI9S4VCj9UboyVzTDOtLOWjl5IsljRqlwxgbTUXtNxdx48t9PxzCTylT7X9l7~R1KEX7TEsDF07e5kH9eQQWjNmbu0ndL1FCc7ceHQs94Y21uvFv4TfdtpTBQIY5P~wybbdNSqnq-UhNyOHRoUG6aoo3sahY7Wp8LqgggJ7bLklovOapDYtSX7zQnXZJEO3DFny~tuxS0RE3mQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4'

    
    const { artistName } = useParams<string>()


    useEffect(() => {
        const fetchArtistData = async () => {
            const response = await ApiBridge.search(artistName as string)
            setArtistSongs(response)
            setArtistImage(response[0]?.img || artistPhoto)
            setLoading(false)
        }

        fetchArtistData()
    }, [])

    const handlePlatformSelection = (platform: string) => {
        // Do something with the selected platform
        PlayerManager.PlayAllSong(artistSongs, platform)
        setIsModalVisible(false); // Hide modal after selection
    };

    const openModal = () => setIsModalVisible(true)   

    return (<>

        {isLoading ? <Loader /> :
            <Base sidebarList={musicLinksList}>
                <div className='playlistpage_container'>
                    {isModalVisible && (
                        <PlaylistPlatformModal show={isModalVisible} onPlatformSelect={handlePlatformSelection} />
                    )}
                    {isMobile && <BackAndOptions />}
                    <TopSection
                        Title={artistName as string}
                        Length={artistSongs.length}
                        TotalTime={getTotalTime(artistSongs)}
                        Photo={artistImage as string}
                        clickHandler={openModal}
                    />

                    {isMobile ?
                        <EpisodesCard tableItems={artistSongs} /> :
                        <TableComponent
                            tableItems={artistSongs}
                        />
                    }
                </div>
            </Base>
        }
    </>)
}

export default ArtistPage