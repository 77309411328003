import NetflixLogo from "assets/icon/netflix_icon.svg";
import PrimeLogo from "assets/icon/prime_logo_icon.png";
import HuluLogo from "assets/icon/hulu.png";
import HBOLogo from "assets/icon/hbo.png";
import DisneyPlusLogo from "assets/icon/disney_plus.png";
import AppleTVLogo from "assets/icon/apple_tv_plus.png";
import CrunchRollLogo from "assets/icon/crunchyroll.png";
import Paramount from "assets/icon/paramount_plus.svg";

export const contentProviderObject: any = {
    spotify: NetflixLogo,
    Netflix: NetflixLogo,
}

export const contentProviders = [
    { name: "netflix", logo: NetflixLogo },
    { name: "amazon_prime", logo: PrimeLogo },
    { name: "hulu_plus", logo: HuluLogo },
    { name: "hbo_max", logo: HBOLogo },
    { name: "disney_plus", logo: DisneyPlusLogo },
    { name: "paramount_plus", logo: Paramount },
    { name: "apple_tv_plus", logo: AppleTVLogo },
    { name: "crunchyroll_premium", logo: CrunchRollLogo },
    { name: "amazon_buy", logo: PrimeLogo }

]