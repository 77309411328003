// useUserHeaderBar.ts
import { useState, useRef } from 'react';
import useClickOutside from '@hooks/useClickOutside';
import { appleMusicService } from 'services/applemusics';
import { Song, SongsResponse } from 'dtos/search';
import { useWindowDimensions } from '@hooks/useWindowDimension';
import { useNavigate, useLocation } from 'react-router-dom';
import ApiBridge from '../../api/api-bridge';
import { ISong } from 'interfaces/models/model-interfaces';
import { useDispatch } from 'react-redux';

import { saveSearchResult, updateSearchInput } from 'actions/search/search-actions';
import { useSelector } from 'react-redux';
import { searchSelector } from 'store/selectors';

export const useUserHeaderBar = () => {
    const searchInputSelector = useSelector(searchSelector).currentSearchInput
    const dispatch = useDispatch()
    const navigation = useNavigate();
    const location = useLocation();
    const isSearchPage = location.pathname === '/search';
    const recomendationsItensRef = useRef<HTMLDivElement>(null);
    const { isMobile } = useWindowDimensions()

    const [searchActived, setSearchActived] = useState(false);
    const [choosedOption, setChoosedOption] = useState(false);
    const [searchResults, setSearchResults] = useState<ISong[]>([]);
    const [showSearchResults, setShowSearchResults] = useState(false);

    const toggleSearch = () => {
        recomendationsItensRef.current && recomendationsItensRef.current.focus();
        setSearchActived(!searchActived);
        dispatch(updateSearchInput(''))
    };

    const handleChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target
        setShowSearchResults(true)
        dispatch(updateSearchInput(value))
        setChoosedOption(false);
    };

    const closeHeaderSearch = () => {
        dispatch(updateSearchInput(''))
        setSearchActived(currentState => !currentState)
    }

    const handleClickSearchItem = async (textSearch: string) => {
        setChoosedOption(true);
        await handleSearchResult();
        dispatch(updateSearchInput(textSearch))
        setShowSearchResults(false)
        if (!isSearchPage) navigation(`/search?term=${textSearch}`);
        // await ApiBridge.search(textSearch)
    };

    useClickOutside(recomendationsItensRef, () => {
        if (isMobile) return;
        else setChoosedOption(true);
        setShowSearchResults(false)
    });



    const handleSearchResult = async () => {
        try {
            const response: ISong[] = await ApiBridge.search(searchInputSelector)
            dispatch(saveSearchResult(response))
            setSearchResults(response);
        } catch (error) {
            console.error('Error fetching from API:', error);
        }
    }

    return {
        recomendationsItensRef,
        searchActived,
        toggleSearch,
        searchInput: searchInputSelector,
        handleChangeSearchInput,
        handleClickSearchItem,
        choosedOption,
        setSearchActived,
        searchResults,
        closeHeaderSearch,
        showSearchResults
    };
};
