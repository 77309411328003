import SpotifyClientApi from "./SpotifyApi"

export const isTokenExpired = async () => {
   try {
        const result: any = await SpotifyClientApi.fetchWebApi(`/me`)
        const { error } = result
        if (error === undefined) {
            return false
        } else {
            if (error) {
                return error.message.includes("401")
            }
        }
   } catch(error) {
        return true
   }
}