import Button from '@components/Button/Button';
import Input from '@components/Input/Input';
import React, { useState } from 'react';
import './ProfilePageForm.scss'
import { useAuth0 } from '@auth0/auth0-react';
import { uiSelector } from 'store/selectors';
import { useSelector } from 'react-redux';

interface ProfilePageFormProps {
    setEditProfile: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProfilePageForm: React.FC<ProfilePageFormProps> = ({ setEditProfile }) => {

    const { user } = useAuth0();
    const [username, setUsername] = useState(user?.name || '');
    const [email, setEmail] = useState(user?.email || '');
    const [country, setCountry] = useState(user?.locale || '');

    const cancelEdit = () => {
        setEditProfile(false)
    }

    return (
        <div className='profile__content__form'>
            <Input inputTitle='Name' placeholder='Name' value={username} onChange={(event) => setUsername(event.target.value)} defaultValue={user?.name} />
            <Input inputTitle='Email' placeholder='myemail@gmail.com' value={email} onChange={(event) => setEmail(event.target.value)} defaultValue={user?.email} />
            <Input inputTitle='Country or region' placeholder='USA' value={country} onChange={(event) => setCountry(event.target.value)} defaultValue={user?.locale} />
            <div className='buttons__container'>
                <Button buttonText='Save' additionalClassName='save__button' />
                <Button buttonText='Cancel' additionalClassName='save__button' onClick={cancelEdit} />
            </div>
        </div>
    )
}

export default ProfilePageForm;