import React, { useState, useEffect } from 'react'
import PlayIcon from 'assets/icon/play_icon.svg'
import LandingPageBanner from 'assets/images/landingPageMainBanner.png'
import LandingPageBannerMobile from 'assets/images/landing_page_banner_mobile.webp'
import { useWindowDimensions } from '@hooks/useWindowDimension'
import Button from '@components/Button/Button'
import { useSelector } from 'react-redux'
import { uiSelector } from 'store/selectors'
import { changeColorMode } from '@utils/colors'

interface LandingPageHeaderInformationProps {
    subTitleText: string
    getStartedButton: () => void
}
const LandingPageHeaderInformation = ({ getStartedButton, subTitleText }: LandingPageHeaderInformationProps) => {
    const isDarkMode = useSelector(uiSelector).darkMode
    const { isMobile, width } = useWindowDimensions()
    const landingPageImage = isMobile ? LandingPageBannerMobile : LandingPageBanner;
    const [fontSize, setFontSize] = useState(calculateFontSize());

    useEffect(() => {
        const handleResize = () => {
            setFontSize(calculateFontSize());
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function calculateFontSize() {
        const width = window.innerWidth;
        // Modify the formula according to your requirements
        let calculatedFontSize = width * 0.05; // Example formula
        return Math.max(32, Math.min(calculatedFontSize, 59)) + "px";
    }


    return (
        <div className='landing__container__top' >
            <div className='landing__container__top_info'>
                <div>
                    <h1 className='landing__container__top_info__header' style={{ fontSize, color: changeColorMode(isDarkMode) }}>
                        Use your subscriptions
                    </h1>
                    <h1 className='landing__container__top_info__header' style={{ fontSize, color: changeColorMode(isDarkMode) }}>
                        <img src={PlayIcon} alt='Play Icon' className='landing__container__top_info__header__icon' />
                        on one platform
                    </h1>
                </div>
                <h3 style={{color: changeColorMode(isDarkMode, '#E5E7EB')}}>{subTitleText}</h3>
                <Button buttonText='Get started' additionalClassName='landing__container__top_info__button' onClick={getStartedButton} />
            </div>
            <img src={landingPageImage} alt='Landing Page Banner' className='landing__banner' />
            <Button buttonText='Get started' additionalClassName='landing__container__top__mobile__button'  onClick={getStartedButton} />
        </div>
    )
}

export default LandingPageHeaderInformation