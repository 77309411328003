import './App.scss';
import Routes from './routes';
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useLocation } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

function App() {

  const isDarkMode = useSelector((state: RootState) => state.ui.darkMode)


  useEffect(() => {
    document.body.className = isDarkMode ? 'dark-mode' : ''
  }, [isDarkMode])

  const location = useLocation();
  return (
    <TransitionGroup>
      <CSSTransition key={location.key} classNames="fade" timeout={300}>
        <Routes />
      </CSSTransition>
    </TransitionGroup>
  );
}

export default App;
