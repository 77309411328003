import { ISong, MusicPlatform } from "interfaces/models/model-interfaces"
import store from "../store"
import { selectMusic } from "store/musicSlice"
import AppleMusicPlayer from "@components/MusicPlayer/apple/apple-music-player"

class PlayerManager {
    static PlayAllSong(tracks: ISong[], platform: string) {
        AppleMusicPlayer.pause()
        
        if (tracks.length) {
            
            const appleSongs = tracks.filter(({ id, platform }) => platform === MusicPlatform.AppleMusic)
                .filter(track => track !== undefined)

            const spotifySongs = tracks.map(({ id, platform }) => {
                if (platform === MusicPlatform.SpotifyMusic) {
                    return `spotify:track:${id}`                    
                }               
            }).filter(track => track !== undefined)

            const playlist: any = {
                appleSongs,
                spotifySongs,
                platform,
                type: 'playlist',
                tracks
            }
            store.dispatch(selectMusic(playlist))
        }
    }
}

export default PlayerManager