import { changeColorMode } from '@utils/colors'
import { supportServices } from 'mockdatas/streamings'
import { useSelector } from 'react-redux'
import { uiSelector } from 'store/selectors'

const LandingPageFooter = () => {
    const isDarkMode = useSelector(uiSelector).darkMode

    return (
        <footer className='landing__container__footer'>
            <h1 className='landing__container__footer__title' style={{color: changeColorMode(isDarkMode)}}>What services we support?</h1>
            <div className='landing__container__footer__services'>
                {supportServices.map((service, index) => {
                    return <img key={index} src={service.img} alt={service.name} className='landing__container__footer__services__img' />
                })}
            </div>
        </footer>
    )
}

export default LandingPageFooter