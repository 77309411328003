import { set } from "lodash"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useLazySearchShowsQuery } from "services/movie.services"

export const useMovieHeaderBar = () => {
    const navigation = useNavigate()
    const [searchMovie, { isLoading }] = useLazySearchShowsQuery()
    const [searchInputActived, setSearchInputActived] = useState(false)
    const [userDropdownActived, setUserDropdownActived] = useState(false)
    const [isInputFocused, setIsInputFocused] = useState(false)
    const [searchInputValue, setSearchInputValue] = useState('')
    const [searchResult, setSearchResult] = useState([]) as any[]

    const handleInputFocus = () => setIsInputFocused(true)
    const handleInputBlur = () => {
        setIsInputFocused(false)
        setSearchInputActived(false)
    }

    const handleOpenSearchInput = () => {
        setSearchInputActived(true)
        setIsInputFocused(true)
    }
    const handleCloseSearchInput = () => setSearchInputActived(false)
    const handleToggleSearchInput = () => {
        setSearchInputActived(currentStante => !currentStante)
    }

    const handleOpenDropdown = () => {
        setUserDropdownActived(true)
    }
    const handleCloseDropdown = () => setUserDropdownActived(false)

    const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            const { data } = await searchMovie(searchInputValue)
            setSearchResult(data)
            navigation(`/merge-movies/search?searchTerm=${searchInputValue}`)
        }
    };

    const handleChangeInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInputValue(e.target.value)
        const { data } = await searchMovie(e.target.value)



        if (e.target.value === '') {
            setSearchResult([])
            setSearchInputValue('')
            return
        }


        setSearchResult(data)
    }

    const handleClickClearInput = async () => {
        setSearchResult([])
        setSearchInputValue('')
    }

    return {
        searchInputActived,
        setSearchInputActived,
        handleOpenSearchInput,
        handleCloseSearchInput,
        userDropdownActived,
        setUserDropdownActived,
        handleOpenDropdown,
        handleCloseDropdown,
        isInputFocused,
        handleInputFocus,
        handleInputBlur,
        searchInputValue,
        setSearchInputValue,
        handleChangeInput,
        handleClickClearInput,
        handleToggleSearchInput,
        searchResult,
        handleKeyDown

    }
}
