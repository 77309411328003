// ProfileComponent.tsx
import { useState } from 'react';
import './ProfileComponent.scss';
import ProfileDropdown from '@components/ProfileDropdown/ProfileDropdown';
import Avatar from '@components/Avatar/Avatar';
import { ArrowDownIcon } from '@components/IconComponents/IconComponents';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
interface ProfileComponentProps {
    showName?: boolean;
}

const ProfileComponent = ({ showName }: ProfileComponentProps) => {
    const isDarkMode = useSelector((state: RootState) => state.ui.darkMode)
    const [profileDropdownVisible, setProfileDropdownVisible] = useState(false)
    const toggleProfileDropdown = () => setProfileDropdownVisible(previousState => !previousState);

    return (
        <div id="profile-container" className="profile-container" onClick={profileDropdownVisible ? () => { } : toggleProfileDropdown} >
            <Avatar showName={showName} />
            <ArrowDownIcon style={{ cursor: 'pointer' }} className='arrow-icon' strokeColor={isDarkMode ? "#FFF" : "black"} />
            <ProfileDropdown onClick={toggleProfileDropdown} profileDropdownVisible={profileDropdownVisible} />
        </div>
    );
}

export default ProfileComponent;
