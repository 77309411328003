import AppleMusicPlayer from "@components/MusicPlayer/apple/apple-music-player";
import store from "../../store";
import { updateMusic  } from '../../store/musicSlice'
class ApplePlaylistManager {
    static songs = [];
    static currentIndex = 0;
    
    static setupEventListeners() {
        window.MusicKit.getInstance().addEventListener(MusicKit.Events.playbackStateDidChange, (event: any) => {
           
            console.log(`playbackState: ${window.MusicKit.getInstance().player.playbackState}`)
            if ( window.MusicKit.getInstance().player.playbackState ===  MusicKit.PlaybackStates.ended ) {
                console.log('Song has ended');
                ApplePlaylistManager.playNextSong()                             
            }
        });
    }

    static playSong(index: number, update: boolean = true) {
        const song: any = ApplePlaylistManager.songs[index];
        if (song) {
            // window.MusicKit.getInstance().setQueue({ songs: [song.id] }).then(() => {
            //     window.MusicKit.getInstance().play();
            // });
            AppleMusicPlayer.setQueueItems([song])
            window.MusicKit.getInstance().play();
            if (update) {
                store.dispatch(updateMusic(song))
            }           
        }
    }

    static playNextSong() {
        ApplePlaylistManager.currentIndex++;
        if (ApplePlaylistManager.currentIndex >= ApplePlaylistManager.songs.length) {
            ApplePlaylistManager.currentIndex = 0; // Restart the playlist or handle the end of the playlist
        }
        ApplePlaylistManager.playSong(ApplePlaylistManager.currentIndex);
    }

    static playPreviousSong() {
        ApplePlaylistManager.currentIndex--;

        if (ApplePlaylistManager.currentIndex < 0) {
            ApplePlaylistManager.currentIndex = 0
        }        
       
        ApplePlaylistManager.playSong(ApplePlaylistManager.currentIndex);
    }

    static startPlaylist(songs: any) {
        ApplePlaylistManager.currentIndex = 0
        ApplePlaylistManager.songs = songs;
        ApplePlaylistManager.setupEventListeners();
        ApplePlaylistManager.playSong(ApplePlaylistManager.currentIndex, false);
    }

    static getCurrentSong() {
        return ApplePlaylistManager.songs[ApplePlaylistManager.currentIndex]
    }
}

export default ApplePlaylistManager
