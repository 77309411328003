import TableComponent from '@components/Table/Table'
import { musicsMockedData } from 'mockdatas/musics_mocked_data'
import Arrow from 'assets/icon/arrow.svg'
import { useSelector } from 'react-redux'
import { uiSelector } from 'store/selectors'
import { changeColorMode } from '@utils/colors'
import { useNavigate } from 'react-router-dom'
import MyFavoritesSectionHeader from '@components/MyFavoritesSection/MyFavoritesSectionHeader'
import { ISong } from 'interfaces/models/model-interfaces'


const FavoriteSongs = ({ favoriteMusic, itemsLength = 5, seeAll = '' }: { favoriteMusic: ISong[], itemsLength?: number, seeAll?: string }) => {
    const isDarkMode = useSelector(uiSelector).darkMode
    const navigate = useNavigate()
    const handleNavigation = () => {
        seeAll && navigate(seeAll)
    }   

    return (
        <div>
            {seeAll ? <div onClick={handleNavigation} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: '0px 40px' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                    <h2 className='music__page__container__top__title' style={{ color: changeColorMode(isDarkMode) }}>Favorite Songs </h2>
                    {seeAll && <img src={Arrow} style={{ transform: 'rotate(270deg)' }} alt='arrow-right' />}
                </div>
            </div> :
                <div className='section__container'>
                    <MyFavoritesSectionHeader
                        searchInput='' onChangeInput={() => { }} handleClearInput={() => { }}>
                        <div style={{display:'flex',alignItems:'flex-end',gap:12}}>
                            <h2>Songs</h2>
                            <small>({itemsLength})</small>
                        </div>
                    </MyFavoritesSectionHeader>
                </div>
            }


            <div style={{ paddingLeft: '40px', paddingTop: '30px' }}>

                <TableComponent
                    tableHeaderItems={['Episode', 'Date', 'Length', 'Platform', '']}
                    tableItems={favoriteMusic}
                />
            </div>

        </div>
    )
}

export default FavoriteSongs