import NoImageFound from '../assets/images/merge_background_image_music.png'



export const lockFunction = (func: any) =>{
    let isLocked = false;

    return function(...args: any) {
        if (isLocked) {
            console.log("Function call is locked.");
            return;
        }

        // @ts-ignore
        func.apply(this, args);
        isLocked = true;

        setTimeout(() => {
            isLocked = false;
        }, 500);
    };
}

export const TidalTokenKey = "tidalTokenKey"
export const DefaultImage = NoImageFound

export const SpotifyHasLoggedIn = "false"
export const SpotifyTokenKey = "spotifyTokenKey"
export const SpotifyRetryLogIn = 'sporifyretrylogin'

export const USerProfileKey = "userprofilekey"
export const TrackLimit = 50
export const BAD_TOKEN_ERROR_REFRESHTOKEN = 'Bad or expired token.'
export const UPDATE_APPLE_MUSIC = 'updateAppleMusic'
export const delay = ( milliseconds: number ) => new Promise(resolve =>  setTimeout(() => resolve, milliseconds))
