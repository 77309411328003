
import { tvshowMockedPage } from './tvshow_page_mocked'
import { useWindowDimensions } from '@hooks/useWindowDimension'

import './MoviePage.scss'
import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import moment from 'moment'
import axios from 'axios'
import { useGetTvShowQuery } from 'services/movie.services'
import Error from '@pages/Error/Error'
import MoviePageMobile from '@pages/MoviePageMobile/MoviePageMobile'
import BaseMoviesContainer from '@components/BaseMoviesContainer/BaseMoviesContainer'
import BackButton from '@components/BackButton/BackButton'
import MovieDetails from './components/MovieDetails/MovieDetails'
import MovieButtons from './components/MovieButtons/MovieButtons'
import { changeColorMode } from '@utils/colors'
import { useSelector } from 'react-redux'
import { uiSelector } from 'store/selectors'
import { contentProviders } from '@utils/contentProviderObject'
import Loading from '@components/Loading/Loading'


const TvShowPage = () => {
    const { isMobile } = useWindowDimensions()
    const searchParams = useLocation()
    const showId = searchParams.pathname.split('/')[3]
    const isTvShow = searchParams.pathname.split('/')[2] === 'tv-show'
    const { data: tvShow } = useGetTvShowQuery(showId)
    const [tvShowInfo, setTvShowInfo] = useState<any>([])
    const [hoverEpisode, setHoverEpisode] = useState<number | null>(null)

    const [tvShowTrailerStart, setTvShowTrailerStart] = useState<boolean>(false)
    const [selectedSeason, setSelectedSeason] = useState<number | null>(1);

    const isDarkMode = useSelector(uiSelector).darkMode
    const IMG_POSTER = `https://img.rgstatic.com/content/show/${showId}/poster-780.jpg`
    const IMG_BACKDROP = `https://img.rgstatic.com/content/show/${showId}/backdrop-1280.webp`
    const youtube_trailer = `https://www.youtube.com/embed/${tvShow?.trailer?.key}`
    const movieProvider = tvShow?.sources?.find((source: any) => contentProviders.some(item => item.name === source))
    const providerLogo = contentProviders.find((item) => item.name === movieProvider)?.logo

    const episodes: any = Object.entries(tvShow?.episodes || {})?.[0]?.[1]
    const firstEpisode = episodes?.availability
    const firstEpisodeLink = firstEpisode?.find((item: any) => contentProviders?.some((provider: any) => provider?.name === item.source_name))

    const reverseSeasons = tvShow?.seasons
    const sortSeasons: any = !!reverseSeasons?.length && [...reverseSeasons].sort((a: any, b: any) => a.number - b.number)

    const getTvShowInfo = async () => {
    }

    useEffect(() => {
        getTvShowInfo()
    }, [])

    const handlePressTrailer = async () => {
        setTvShowTrailerStart(true)
    }

    const handleCloseTrailer = () => {
        setTvShowTrailerStart(false)
    }


    const openIframe = () => {
        window.open(firstEpisodeLink?.source_data?.web_link, '_blank')

    };

    const setHoverEpisodeTrue = (episodeIndex: number) => {
        setHoverEpisode(episodeIndex)
    }


    const seasonNumbers = tvShow?.seasons?.length
    const releaseDate = moment(tvShow?.released_on).format("YYYY");
    const director = tvShow?.talent?.crew?.find((person: any) => person.role_type === 'Director');

    const userAgent = navigator.userAgent

    if (!tvShow) return <div>
        <Loading />

    </div>
    if (isMobile) return (
        <>
            <MoviePageMobile
                backgroundImg={IMG_POSTER}
                description={tvShow?.overview}
                director={director?.name || ''}
                title={tvShow?.title}
                contentProvider={providerLogo}
                length={`${seasonNumbers} Seasons`}
                minAge={tvShow.classification}
                releaseDate={releaseDate}
                movieTrailer={youtube_trailer}
                movieTrailerStarted={tvShowTrailerStart}
                handleCloseTrailer={handleCloseTrailer}
                watchMovieLink={openIframe}
                handlePressTrailer={handlePressTrailer}

            />
            <div style={{ paddingBottom: '20px', padding: '20px', backgroundColor: "rgba(0,0,0,0.15)", borderRadius: 12 }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '18px', margin: "20px 0px", justifyContent: 'space-between' }}>
                    <h3 style={{ color: changeColorMode(isDarkMode) }}>Episodes</h3>
                    <select

                        style={{
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            padding: '8px',
                            borderRadius: '12px',
                            border: '1px solid #ccc',
                            backgroundColor: '#F3F4F6)',
                        }} onChange={(e) => setSelectedSeason(parseInt(e.target.value))}>
                        <option value={''}>Select Season</option>
                        {sortSeasons?.map((season: any) => (
                            <option key={season.id} value={season.number}>
                                Season {season.number}
                            </option>
                        ))}
                    </select>
                </div>

                {selectedSeason !== null && (
                    <div className='season-container' style={{
                        overflowX: 'scroll',
                    }}>
                        {tvShow?.seasons?.map((season: any, index: number) => {
                            return (
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    gap: 28,

                                }}>
                                    {season?.episodes.map((episode: any, episodeIndex: number) => {
                                        if (index + 1 !== selectedSeason) return null
                                        const episodeImg = `https://img.rgstatic.com/content/show/${showId}/${season.id}/${episode}/screenshot-400.webp`
                                        const episodeInfo: any = Object.entries(tvShow?.episodes || {}).find((key: any) => key[0] === episode)?.[1]
                                        const titleToFirstLetterCapitalized = (title: string) => {
                                            return title.charAt(0).toUpperCase() + title.slice(1).toLocaleLowerCase() || ''
                                        }
                                        const availabilityStream = episodeInfo?.availability?.find((item: any) => contentProviders.some((
                                            provider: any
                                        ) => provider.name === item.source_name))
                                        let episodeLink = availabilityStream?.source_data?.links?.web
                                        const handleClickEpisode = () => {
                                            window.open(episodeLink, '_blank')
                                        }

                                        return (
                                            <div
                                                onMouseOver={() => setHoverEpisodeTrue(episodeIndex)}
                                                onMouseLeave={() => setHoverEpisode(null)}
                                                onClick={handleClickEpisode}
                                                key={episode?.id}
                                                style={{
                                                    cursor: "pointer",
                                                    height: "272px",
                                                    width: "109px",
                                                    overflow: 'hidden'
                                                }}>
                                                <img style={{
                                                    height: '147px',
                                                    minHeight: 109,
                                                    width: '100%',
                                                    imageResolution: "from-image",
                                                    objectFit: "cover",
                                                    borderRadius: 12,
                                                    transition: 'all 0.3s ease-in-out',
                                                }} src={episodeImg} alt={episodeImg} />
                                                <h3 className='episode-title' style={{
                                                    color: changeColorMode(isDarkMode),
                                                    fontSize: 16,

                                                }}>{titleToFirstLetterCapitalized(episodeInfo?.title)}</h3>
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                )}

            </div>
        </>
    )

    return (
        <BaseMoviesContainer isTvShow={isTvShow} backgroundImageUrl={IMG_BACKDROP}>
            <div className='movie-page-container'>
                <BackButton />
                <div className='tvshow-details-container'>
                    <div className='tvshow-details-info'>
                        <MovieDetails
                            backdropImage={IMG_POSTER}
                            seasonNumber={tvShowInfo?.seasons?.length}
                            tvShowStarted={tvShowTrailerStart}
                            movieDescription={tvShow?.overview}
                            movieDirector={director?.name || ''}
                            movieLength={`${seasonNumbers} Seasons`}
                            movieReleaseDate={releaseDate}
                            movieTitle={tvShow.title}
                            movieMinAge={tvShow.classification}
                            tvShowTrailerUrl={youtube_trailer}
                            handleCloseTrailer={handleCloseTrailer}
                            contentProvider={providerLogo}
                        />
                        <MovieButtons
                            handlePressTrailer={handlePressTrailer}
                            watchMovieLink={openIframe}
                        />
                    </div>
                </div>
                <div style={{ paddingBottom: '100px', padding: '20px', backgroundColor: "rgba(0,0,0,0.15)", marginTop: 30, borderRadius: 12 }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '18px', margin: "20px 0px" }}>
                        <h1 style={{ color: changeColorMode(!isDarkMode) }}>Episodes</h1>
                        <select style={{
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            padding: '8px',
                            borderRadius: '12px',
                            border: '1px solid #ccc',
                            backgroundColor: '#F3F4F6)',
                        }} onChange={(e) => setSelectedSeason(parseInt(e.target.value))}>
                            <option value={''}>Select Season</option>
                            {tvShow?.seasons?.map((season: any) => (
                                <option key={season.id} value={season.number}>
                                    Season {season.number}
                                </option>
                            ))}
                        </select>
                    </div>

                    {selectedSeason !== null && (
                        <div className='season-container'>
                            {tvShow?.seasons?.map((season: any, index: number) => {
                                return (
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        gap: 28,

                                    }}>
                                        {season?.episodes.map((episode: any, episodeIndex: number) => {
                                            if (index + 1 !== selectedSeason) return null
                                            const episodeImg = `https://img.rgstatic.com/content/show/${showId}/${season.id}/${episode}/screenshot-400.webp`
                                            const episodeInfo: any = Object.entries(tvShow?.episodes || {}).find((key: any) => key[0] === episode)?.[1]
                                            const titleToFirstLetterCapitalized = (title: string) => {
                                                return title.charAt(0).toUpperCase() + title.slice(1).toLocaleLowerCase() || ''
                                            }
                                            const availabilityStream = episodeInfo?.availability?.find((item: any) => contentProviders.some((
                                                provider: any
                                            ) => provider.name === item.source_name))
                                            let episodeLink = availabilityStream?.source_data?.links?.web
                                            if (userAgent.match(/Android/i)) {
                                                episodeLink = availabilityStream?.source_data?.links?.android
                                            } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
                                                episodeLink = availabilityStream?.source_data?.links?.ios
                                            } else {
                                                episodeLink = availabilityStream?.source_data?.links?.web
                                            }

                                            const handleClickEpisode = () => {
                                                window.open(episodeLink, '_blank')
                                            }

                                            return (
                                                <div
                                                    onMouseOver={() => setHoverEpisodeTrue(episodeIndex)}
                                                    onMouseLeave={() => setHoverEpisode(null)}
                                                    onClick={handleClickEpisode}
                                                    key={episode?.id}
                                                    style={{
                                                        cursor: "pointer",
                                                        height: "272px",
                                                        width: "248px",
                                                        overflow: 'hidden'
                                                    }}>
                                                    <img style={{
                                                        height: '100%',
                                                        minHeight: 182,
                                                        maxHeight: hoverEpisode === episodeIndex ? 222 : 182,
                                                        width: '100%',
                                                        imageResolution: "from-image",
                                                        objectFit: "cover",
                                                        borderRadius: 12,
                                                        transition: 'all 0.3s ease-in-out',
                                                    }} src={episodeImg} alt={episodeImg} />
                                                    <h3 className='episode-title' style={{
                                                        color: changeColorMode(!isDarkMode),
                                                        fontSize: 16,

                                                    }}>{titleToFirstLetterCapitalized(episodeInfo?.title)}</h3>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                    )}

                </div>

            </div>

        </BaseMoviesContainer >
    )
}

export default TvShowPage