import { ArrowDownIcon } from '@components/IconComponents/IconComponents'
import { changeColorMode } from '@utils/colors'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { uiSelector } from 'store/selectors'

interface MoviesListShelfTopContainerProps {
    title: string
    handleNextSlide?: () => void
    handlePreviousSlide?: () => void
    redirectTitle?: string

}
const MoviesListShelfTopContainer = ({ title, handleNextSlide, handlePreviousSlide, redirectTitle }: MoviesListShelfTopContainerProps) => {

    const isDarkMode = useSelector(uiSelector).darkMode

    const navigation = useNavigate()

    const handleNavigation = () => {
        navigation(`/merge-movies/list/${redirectTitle}` || '/')
    }

    return (
        <div className='movie-section-title'>
            <h2 style={{ color: changeColorMode(isDarkMode), cursor:'pointer' }} onClick={handleNavigation}>{title}</h2>
            <ArrowDownIcon strokeColor={changeColorMode(isDarkMode)} style={{ rotate: '270deg', cursor:'pointer' }} onClick={handleNavigation} />
            <div className='arrows-container'>
                <button className='button-arrow' onClick={handlePreviousSlide}>
                    <ArrowDownIcon className='arrow-previous' />
                </button>
                <button className='button-arrow' onClick={handleNextSlide}>
                    <ArrowDownIcon className='arrow-next' />
                </button>
            </div>
        </div>
    )
}

export default MoviesListShelfTopContainer