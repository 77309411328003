import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MdOutlineKeyboardArrowRight } from "react-icons/md"
import { useWindowDimensions } from '@hooks/useWindowDimension';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { changeColorMode } from '@utils/colors';
interface SideBarItemProps {
    link: string;
    icon: any;
    label: string;
    isRouteSelected?: boolean;
    items?: string[]
    isSidebar?: boolean;
}

const SideBarItem: React.FC<SideBarItemProps> = ({ link, icon, label, isRouteSelected, items, isSidebar }) => {
    const isDarkMode = useSelector((state: RootState) => state.ui.darkMode)
    const { pathname, search } = useLocation()
    const selectedPathName = pathname.split('/')[1]
    const { isMobile } = useWindowDimensions()
    const type = search?.split("=")[1]

    return (
        <>
            <Link to={link}
                className={`sidebar__item ${isRouteSelected ? 'selected' : ''}`}
                style={{
                    color: changeColorMode(isDarkMode),
                    backgroundColor: isRouteSelected && isDarkMode && !isMobile ?
                        '#6B37FE' : isRouteSelected && !isDarkMode ?
                            '#F9FAFB' : '',
                    borderRadius: '16px',
                    padding: (isSidebar && isMobile) ? '0px' : !isMobile && isSidebar ? '12px 16px  ' : '12px',
                }}

            >
                {icon}
                <span style={{ color: isRouteSelected && isDarkMode ? '#FFF' : isRouteSelected && !isDarkMode ? '#8d66fe' : '' }}> {label}</span>
                {!!items?.length && !isMobile && <MdOutlineKeyboardArrowRight />}
            </Link>
            {!!items?.length && selectedPathName === 'my-favorites' && <div className='sidebar__item__subitems'>
                {items.map((item, index) => {
                    const isSubItemSelected = type === item?.toLocaleLowerCase()
                    return (
                        <Link
                            key={index}
                            style={{
                                marginLeft: '30px',
                                color: isSubItemSelected ? '#8d66fe' : changeColorMode(isDarkMode)

                            }}
                            to={`/my-favorites?type=${item?.toLocaleLowerCase() || 'all'}`}>{item}</Link>
                    )
                })}
            </div>
            }
        </>
    )
}

export default SideBarItem;