import RadioImage from 'assets/images/radio_image.png'

export const radioMockedData = [
    {
        id: 0,
        title: "Radio 1",
        radioDescription: "Radio 1 description",
        img: RadioImage,
        subTitle: "Author 1"
    },
    {
        id: 1,
        title: "Radio 2",
        radioDescription: "Radio 2 description",
        img: RadioImage,
        subTitle: "Author 2"
    },
    {
        id: 2,
        title: "Radio 3",
        radioDescription: "Radio 3 description",
        img: RadioImage,
        subTitle: "Author 3"
    },
    {
        id: 3,
        title: "Radio 4",
        radioDescription: "Radio 4 description",
        img: RadioImage,
        subTitle: "Author 4"
    },
    {
        id: 5,
        title: "Radio 5",
        radioDescription: "Radio 5 description",
        img: RadioImage,
        subTitle: "Author 5"
    },
    {
        id: 6,
        title: "Radio 6",
        radioDescription: "Radio 6 description",
        img: RadioImage,
        subTitle: "Author 6"
    },
]