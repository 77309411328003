import Heading from '@components/Heading/Heading'
import { useWindowDimensions } from '@hooks/useWindowDimension'
import { musicLinksList, sideBarLinksListMobile } from '@utils/navigationsLinks/profileDropdownList'
import { IGenreData, genresData } from 'mockdatas/genresMockedData'
import Base from 'template/Base'
import './Genres.scss'
import { useNavigate } from 'react-router-dom'
import { ArrowDownIcon } from '@components/IconComponents/IconComponents'
import { colors } from '@utils/colors'
import { RootState } from 'store'
import { useSelector } from 'react-redux'

const Genres = () => {
    const isDarkMode = useSelector((state: RootState) => state.ui.darkMode)
    const { isMobile } = useWindowDimensions()
    const responsiveSideBarList = isMobile ? sideBarLinksListMobile : musicLinksList
    const navigate = useNavigate()
    const handleGoBack = () => {
        navigate(-1)
    }


    return (
        <Base sidebarList={responsiveSideBarList}>
            <div className='all__genres__container'>
                <div className='all__genres__container__heading' onClick={handleGoBack}>
                    <ArrowDownIcon strokeColor={isDarkMode ? colors.darkMode.text : colors.darkMode.bgColor} style={{ transform: 'rotate(90deg)' }}  />
                    <Heading>All Genres</Heading>
                </div>
                <div className='all__genres__container__content'>
                    {genresData.map((genre: IGenreData, index) => {
                        return (
                            <div className='all__genres__container__content__item'>
                                <img src={genre.img} alt={genre.genreName} className='all__genres__container__content__item' />
                            </div>
                        )
                    })}
                </div>
            </div>
        </Base >
    )
}

export default Genres