import { AddIcon, ArrowDownIcon, CloseIcon, WatchIcon } from '@components/IconComponents/IconComponents'
import { useSelector } from 'react-redux'
import { uiSelector } from 'store/selectors'
import { changeColorMode } from '@utils/colors'
import { useNavigate } from 'react-router-dom'
import MoviesHeaderBarMobile from '@components/MoviesHeaderBarMobile/MoviesHeaderBarMobile'
import SimiliarMovies from '@components/SimiliarMovies/SimiliarMovies'
import './MovieMobilePage.scss'
import { useGetMoviesQuery } from 'services/movie.services'

interface MoviePageMobileProps {
    backgroundImg: string;
    title: string;
    description: string;
    director: string;
    length?: string; // Optional props can be marked with '?'
    releaseDate?: string;
    minAge?: string;
    contentProvider?: string;
    watchMovieLink?: () => void;
    movieTrailer: string;
    movieTrailerStarted?: boolean;
    handleCloseTrailer: () => void;
    handlePressTrailer: () => void;
    genres?: number[];
}

const MoviePageMobile = ({
    backgroundImg,
    description,
    director,
    title,
    contentProvider,
    length,
    minAge,
    releaseDate,
    watchMovieLink,
    movieTrailer,
    movieTrailerStarted,
    genres,
    handleCloseTrailer,
    handlePressTrailer
}: MoviePageMobileProps) => {
    const { data } = useGetMoviesQuery({})

    const relatedMovies = () => {
        if (!data) return []
        const relatedMovies = data?.filter((movie: any) => movie?.genres && movie?.genres?.some((genre: any) => genres?.includes(genre))) || []
        return relatedMovies && relatedMovies.length > 0 ? relatedMovies.slice(0, 10) : data.slice(0, 5)
    }
    const navigation = useNavigate()
    const isDarkMode = useSelector(uiSelector).darkMode


    const handleGoBack = () => {
        navigation(-1)
    }

    return (
        <section style={{ height: '100%', paddingBottom: '50px' }}>
            <div className='img-background' style={{
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                background: `url(${backgroundImg})`,
                height: '335px',
                width: '100%',

            }}>
                {movieTrailerStarted &&
                    <div className='movie-trailer-container'>
                        <CloseIcon strokeColor={changeColorMode(!isDarkMode)} style={{
                            position: 'absolute',
                            right: 20,
                            top: 20,
                            cursor: 'pointer',
                            height: 30,
                        }}
                            onClick={handleCloseTrailer}
                        />
                        <iframe
                            className='movie-trailer'
                            src={movieTrailer}
                            title="YouTube video player"
                            frameBorder="0"
                            allowFullScreen

                        ></iframe>
                    </div>
                }
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: '20px' }}>
                    <ArrowDownIcon onClick={handleGoBack} strokeColor={changeColorMode(!isDarkMode)} style={{ transform: 'rotate(90deg)' }} />
                    <button className='button-add-playlist'>
                        <AddIcon />
                    </button>
                </div>
            </div>
            <div className='mobile-movie-description'>
                <h2 className='mobile-movie-title'>{title}</h2>
                <div className='movie-info-container'>
                    <span style={{ color: changeColorMode(!isDarkMode) }}>{length}  •</span>
                    <span style={{ color: changeColorMode(!isDarkMode) }}>{releaseDate}  •</span>
                    <span style={{
                        color: changeColorMode(!isDarkMode), borderRadius: 6,
                        padding: 6,
                        border: `1px solid ${changeColorMode(isDarkMode)}`
                    }}>{minAge}</span>
                    <span style={{ color: changeColorMode(isDarkMode) }}>  •</span>
                    {!!contentProvider && <img src={contentProvider} style={{ height: 60, width: 60 }} alt={contentProvider} />}
                </div>
                <div className='mobile-movie-buttons-container'>
                    <button className='mobile-button-watch' onClick={watchMovieLink}>
                        <span style={{ color: changeColorMode(!isDarkMode) }}>Watch</span>
                        <WatchIcon strokeColor={changeColorMode(!isDarkMode)} />
                    </button>
                    <button className='mobile-button-watch-trailer' onClick={handlePressTrailer}>
                        Watch Trailer
                    </button>
                </div>
                <p style={{
                    color: changeColorMode(isDarkMode, '#F3F4F6'),
                    margin: '20px 0px'
                }} className=''>
                    {description}
                </p>

                <hr color={changeColorMode(isDarkMode)} style={{ margin: '20px 0px', width: '100%' }} />
            </div>


            <div className='mobile-similar-movies'>

            </div>
            <SimiliarMovies similarMovies={relatedMovies() || []} />
            <MoviesHeaderBarMobile />
        </section>
    )
}

export default MoviePageMobile