import Sidebar from '@components/Sidebar/Sidebar';
import UserHeaderBar from '@components/UserHeaderBar/UserHeaderBar';
import React from 'react';
import './Base.scss'
import ChooseExperienceDropdown from '@components/ChooseExperienceDropdown/ChooseExperienceDropdown';
import Footer from '@components/Footer/Footer';
import Player from '@components/Player/Player';
import { useWindowDimensions } from '@hooks/useWindowDimension';
import PlayerMobile from '@components/Player/PlayerMobile';
import { useSelector } from 'react-redux';
import { musicSelector, uiSelector } from 'store/selectors';

interface BaseProps {
    sidebarList?: Array<any>
    children?: React.ReactNode
}
const Base: React.FC<BaseProps> = ({ children, sidebarList }) => {

    const [contentVisible, setContentVisible] = React.useState(false);
    const { isMobile, height } = useWindowDimensions();
    const { isPlaying, selectedMusic, queue } = useSelector(musicSelector)
    const { darkMode: isDarkMode } = useSelector(uiSelector)
    const handleOpenDropdown = () => setContentVisible(true)
    const closeDropdown = () => setContentVisible(false)

    return (
        <>
            <main className='base' style={{
                background: isDarkMode ? '#111827' : '#F9FAFB',
                minHeight: height - 10
            }}>
                <div className='base_sidebar'>
                    <Sidebar sidebarList={sidebarList} />
                </div>
                <div className='base__content' style={{
                    background: isDarkMode ? '#111827' : '#F9FAFB',
                }}>
                    <ChooseExperienceDropdown contentVisible={contentVisible} handleCloseDropdown={closeDropdown} />
                    <UserHeaderBar handleOpenDropdown={handleOpenDropdown} />
                    {children}
                </div>
            </main>
            {selectedMusic && (isMobile ? <PlayerMobile /> : <Player  />)}
            {/* <Footer /> */}
        </>
    )
}

export default Base;