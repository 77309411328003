import { useAuth0 } from '@auth0/auth0-react';
import HeaderNav from '@components/HeaderNav/HeaderNav';
import OurPlatformsList from '@components/OurPlatforms/OurPlatforms';
import React from 'react';
import './LoggedPage.scss'
import { uiSelector } from 'store/selectors';
import { useSelector } from 'react-redux';
import { changeColorMode } from '@utils/colors';

const LoggedPage: React.FC = () => {
    const isDarkMode = useSelector(uiSelector).darkMode
    const { user } = useAuth0();
    const userName = user?.given_name || 'User';
    return (
        <>
            <HeaderNav />
            <div className='startPage_container' >
                <h1 style={{ color: changeColorMode(isDarkMode) }}>Hello {userName}</h1>
                <h1 style={{ color: changeColorMode(isDarkMode) }}>Choose your experience</h1>
                <OurPlatformsList />
            </div>
        </>
    )
}

export default LoggedPage;