export interface PlatformModel {
    name: string
    logo: string
    id: string
    url: string
    platform: string,
    durationInMillis: number
}

export interface IPodcast extends ISong {
    description: string
    items: ISong[]
    providerImg: string
}

export interface IPlaylist {
    providerImg: string
    playlistName?: string
    playlistId?: string
    playlistArtworkUrl?: string
    tracks?: ISong[],
    icon?: string    
}

export enum MediaType {
    Tracks = 'track',
    Shows = 'episode'
}

export enum MusicPlatform {
    AppleMusic = "Apple",
    TidalMusic = "Tidal",
    SoundCloud = "SoundCloud",
    SpotifyMusic = "Spotify",
    YoutubeMusic = "YoutubeMusic"
}

export interface ISong {
    albumId?: string,
    platform: string
    id: string
    albumName: string,
    artistName: string,
    img: string,
    songName?: string,
    url: string,
    icon: string
    attributes?: {
        durationInMillis: number
    },
    durationInMillis: number
    appleSongs?: string[]
    spotifySongs?: string[],
    type?: string
    song?: any
    tracks?: any
    trackInfo?: any
    liked?: boolean
}

export interface IMovie {
    movieTitle?: string;
    movieLength?: string;
    movieDescription?: string;
    movieReleaseDate?: string;
    movieDirector?: string;
    movieMinAge?: string;
    contentProvider?: string;
}

export interface ISpotifyAuthParams {
    code: string
}

interface ModelLocator {
    [key: string]: any
}

export interface ArtistsRankings {
    topArtists: string[]
    topPodcasts: string[]
    topAlbums: string[]
    topRadio: string[]    
}