import React, { useState, useEffect } from 'react';
import './MergedAccountsPage.scss'
import Base from 'template/Base';
import Button from '@components/Button/Button';
import LeftIcon from 'assets/icon/left__arrow.svg'
import SubscriptionItem from '@components/SubscriptionItem/SubscriptionItem';
import MergedAccountsModal from './MergedAccountsModal';
import { profileDropdownList } from '@utils/navigationsLinks/profileDropdownList';
import { useWindowDimensions } from '@hooks/useWindowDimension';
import { uiSelector } from 'store/selectors';
import { useSelector } from 'react-redux';
import { changeColorMode } from '@utils/colors';
import { useAuth0 } from '@auth0/auth0-react';
import { PlatformModel } from 'interfaces/models/model-interfaces';
import MergeMusicProviders from 'providers/MergeMusicProviders';
import { musicStreamings } from 'mockdatas/streamings';
import AuthenticationBridge from 'authentication/music/authentication-bridge';

const MergedAccountsPage: React.FC = () => {

  const { user } = useAuth0()
  const isDarkMode = useSelector(uiSelector).darkMode;
  const { isMobile } = useWindowDimensions()
  const [modalVisible, setModalVisible] = useState<boolean>(false)

  const { streamActionReducer, authenticationReducer } = useSelector((state: any) => state)
  const { streamState, message } = streamActionReducer
  const { authState } = authenticationReducer
  const { streamInfo } = message
  const [subscribedMusicStreams, setSubscribedMusicStreams] = useState<any[]>([])
  const [unsubscribedMusicStreams, setUnsubscibedMusicStreams] = useState<any[]>([])

  const handleToggleModal = (): void => {
    setModalVisible(previousState => !previousState)
  }

  const handleStreamingAction = (platformTypes: PlatformModel, action: string = 'add') => {
    if (action === "add" && !subscribedMusicStreams.some(stream => stream.id === platformTypes.id)) {
      AuthenticationBridge.login(platformTypes)
    } else if (action === "remove" && (subscribedMusicStreams.length > 0)) {
      AuthenticationBridge.logout(platformTypes)
    }
  };

  useEffect(() => {

    const getSubs = async () => {
      const { action, streamInfo } = message

      if (action === 'add' || action === 'remove') {
        handleStreamingAction(streamInfo, action)
      }

      const subs = await Promise.all(musicStreamings.map((stream: PlatformModel) => {
        return MergeMusicProviders.isSubscribed(stream)
      }))


      setSubscribedMusicStreams(subs.map((platform, idx) => {
        if (platform === true) {
          return musicStreamings[idx]
        }

      }).filter((platform) => platform !== undefined));

      setUnsubscibedMusicStreams(subs.map((platform, idx) => {
        if (platform === false) {
          return musicStreamings[idx]
        }
      }).filter((platform) => platform !== undefined));
    }

    getSubs()


  }, [streamState, streamInfo, authState]);



  return (
    <Base sidebarList={profileDropdownList}>
      <MergedAccountsModal modalVisible={modalVisible} handleToggleModal={handleToggleModal} unsubscribedMusicStreams={unsubscribedMusicStreams} />
      <section className='merged_accounts__page__container'>

        <div className='merged_accounts__page__container__top'>
          <div className='merged_accounts__page__container__top__column__left'>
            <img src={LeftIcon} alt='left icon' className='hideDesktop' />
            <h1 className='hideDesktop' style={{ color: changeColorMode(isDarkMode) }}>Subscriptions</h1>
            <h1 className='hideMobile' style={{ color: changeColorMode(isDarkMode) }}>Merged Accounts</h1>
            <p className='hideMobile' style={{ color: changeColorMode(isDarkMode, '#D1D5DB') }}>These accounts are already connected to Merge</p>
          </div>
          {isMobile && <hr style={{ width: '100%', margin: '21px 0px' }} />}
          <div className='merged_accounts__page__container__top__column__right'>
            { unsubscribedMusicStreams.length > 0 ? <Button additionalClassName='add__button' onClick={handleToggleModal}>Add new</Button> : <div></div> }
            {/* <Button additionalClassName='choose_preferred__button'>Choose preferred content provider </Button> */}
          </div>
        </div>

        <div className='merged_accounts__page__container__content'>
          {subscribedMusicStreams.map((subscription: any, index: number) => {
            return (
              <SubscriptionItem
                StreamLogo={subscription?.SvgIcon || ''}
                streamlogo={subscription?.logo || ''}
                userAvatar={user?.picture || ''}
                userName={user?.name || ''}
                userEmail={user?.email || ''}
                streamName={subscription.name}
                key={index}
                subscriptionPrice={+subscription.subscriptionPrice || 0}
                onEditSubscription={() => { }}
                onRemoveSubscription={handleStreamingAction}
                platform={subscription}
              />
            )
          })}
        </div>

        <div style={{ height: 100 }} />
      </section>
    </Base>
  )
}

export default MergedAccountsPage;