import { BackLongArrow, CloseIcon, SearchIcon } from '@components/IconComponents/IconComponents'
import MovieShelfItem from '@components/MovieShelfItem/MovieShelfItem'
import MoviesHeaderBarMobile from '@components/MoviesHeaderBarMobile/MoviesHeaderBarMobile'
import { changeColorMode } from '@utils/colors'
import { contentProviderObject, contentProviders } from '@utils/contentProviderObject'
import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useLazySearchShowsQuery, useSearchShowsQuery } from 'services/movie.services'
import { uiSelector } from 'store/selectors'

const MovieSearchMobile = () => {
    const inputRef = React.useRef<HTMLInputElement>(null)
    const navigation = useNavigate()
    const isDarkMode = useSelector(uiSelector).darkMode
    const [searchInput, setSearchInput] = React.useState('')
    const [inputSelected, setInputSelected] = React.useState(true)
    const [searchLazy] = useLazySearchShowsQuery()
    const [searchResults, setSearchResults] = React.useState<any[]>([])
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const searchName = searchParams.get('searchTerm');

    const { data } = useSearchShowsQuery(searchName)

    const handleMovieNavigation = (id: string, content_type: string) => {
        const pathName = content_type === 'movie' ? `/movie/${id}` : `/merge-movies/tv-show/${id}`
        navigation(pathName)
    }

    const handleChangeSearchInput = async (e: any) => {
        setSearchInput(e.target.value)
        if (e.target.value.length) {
            const result = await searchLazy(e.target.value)
            setSearchResults(result.data)
        }
    }

    const clearInput = () => {
        setSearchInput('')
        setInputSelected(false)
    }

    const handleFocusInput = () => {
        inputRef.current?.focus()
        setInputSelected(true)
    }

    const handleSearchIconMovies = () => {
        navigation('/merge-movies/search?searchTerm=' + searchInput)
    }

    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter' || e.keyCode === 13 || e.key === 'NumpadEnter') {
            handleSearchIconMovies()
        }
    }

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', width: '100%', gap: 8, marginBottom: 12, padding: '0px 10px', marginTop: 10 }}>
                <BackLongArrow onClick={() => navigation(-1)} strokeColor={changeColorMode(isDarkMode)} />
                <div onClick={handleFocusInput} style={{ width: '100%', display: 'flex', alignItems: 'center', height: '48px', padding: '0px 12px', borderRadius: '12px', border: '1px solid black', backgroundColor: changeColorMode(!isDarkMode) }}>
                    <SearchIcon onClick={handleSearchIconMovies} strokeColor={changeColorMode(isDarkMode)} style={{ marginRight: 20, height: 30, width: 15 }} />
                    <input autoFocus ref={inputRef} value={searchInput}
                        onKeyDown={handleKeyPress}
                        onChange={e => handleChangeSearchInput(e)} type="text" placeholder="Search"
                        style={{ width: '100%', height: '100%', border: 'none', background: 'transparent', color: changeColorMode(isDarkMode) }} />
                    {!!searchInput.length && <CloseIcon strokeColor={changeColorMode(isDarkMode)} style={{ marginRight: 20, height: 30, width: 15 }} onClick={clearInput} />}
                </div>
            </div>

            <hr style={{ marginBottom: '20px' }} />
            {/* {inputSelected && !searchName && !searchInput.length && <>
                <div>
                    <h3 style={{ color: changeColorMode(isDarkMode), padding: '0px 10px' }}>Recent searches</h3>
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '10px', gap: '6px' }}>
                        {['spider-man'].map((item) => <div onClick={() => { }}
                            style={{
                                width: '100%',
                                paddingBottom: 8,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                borderBottom: '1px solid #FAFAFA',
                            }}>
                            <span onClick={() => handleMovieNavigation(item, )} style={{ color: changeColorMode(isDarkMode) }}>{item}</span>
                            <CloseIcon style={{ marginRight: 20 }} />
                        </div>)}
                    </div>
                </div>
            </>} */}

            {inputSelected && !!searchInput.length && <>
                <h3 style={{ padding: '0px 10px', color: changeColorMode(isDarkMode) }}>Recommended</h3>
                <div className='' style={{ display: 'flex', flexDirection: 'column', padding: '10px', gap: 12 }}>
                    {searchResults.map((item, index) => (
                        <span style={{
                            width: '100%',
                            paddingBottom: 8,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            borderBottom: '1px solid #FAFAFA',
                            color: changeColorMode(isDarkMode)
                        }} onClick={() => handleMovieNavigation(item.id, item.content_type === "Movie" ? "movie" : 'tv-show')}>{item.title}</span>
                    ))}
                </div>
            </>}

            {inputSelected && !searchInput.length && !!searchName && !!data?.length && <>
                <div>
                    {!!data?.length && <div
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 12, padding: 18 }}>
                        {data.map((movie: any) => {
                            const movieImgUrl = `https://img.rgstatic.com/content/${movie.content_type?.toLowerCase()}/${movie.id}/poster-780.jpg`
                            if (!movie.availability) return <></>
                            if (movie.availability && movie.availability.length === 0 ) return <></>

                            return <MovieShelfItem
                                type={"movie"}
                                movieProvider={movie?.sources || []}
                                linkTo={`${movie?.id}`}
                                key={movie?.id}
                                movieImgUrl={movieImgUrl}
                                movieTitle={movie.title}
                                onPressMovie={() => { }}

                            />
                        })}
                    </div>}
                </div>
            </>


            }

            <MoviesHeaderBarMobile />
        </div>
    )
}

export default MovieSearchMobile