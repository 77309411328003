import { useRef, useState } from 'react';
import MovieShelfItem from '@components/MovieShelfItem/MovieShelfItem';
import { Movie } from 'mockdatas/moviesMockedData';
import { Swiper, SwiperSlide } from 'swiper/react';
import MoviesListShelfTopContainer from './MoviesListShelfTopContainer';
import { useWindowDimensions } from '@hooks/useWindowDimension';
import MovieGenreCategory from '@components/MovieGenreCategory/MovieGenreCategory';
import 'swiper/css';
import './MoviesListShelf.scss'
import { runTimeTransform } from '@utils/runTimeTransform';
import { useNavigate } from 'react-router-dom';

interface MoviesListShelfProps {
    title?: string;
    redirectTitle?: string;
    moviesList?: Movie[];
    category?: boolean;
    type?: string
    isLoading?: boolean
    firstElement?: boolean
}
const MoviesListShelf = ({
    isLoading = false,
    type = "movie",
    moviesList,
    redirectTitle,
    title,
    category,
    firstElement
}: MoviesListShelfProps) => {
    const navigation = useNavigate()
    const [onMouseHover, setOnMouseHover] = useState(false)


    const slideRef = useRef<any>(null);

    const handleNextSlide = () => {
        slideRef.current && slideRef.current?.swiper.slideNext()
    }

    const handlePreviousSlide = () => {
        slideRef.current && slideRef.current?.swiper.slidePrev()
    }

    const { width, isMobile } = useWindowDimensions()

    const slidersPerWidth = () => {
        if (width > 1300) {
            return 4.5
        } else if (width > 1000 && width < 1230) {
            return 4
        }
        else
            return 3
    }

    const handleClickCategory = (category:string) => {
        navigation(`/merge-movies/genre/${category}`)
    }

    return (
        <article
            style={{
                height: (category || isMobile) ? '250px' : '500px',
                zIndex: onMouseHover ? 1 : 0,
                marginTop: firstElement ? 0 : -50
            }}
        >
            <MoviesListShelfTopContainer
                redirectTitle={redirectTitle}
                title={title || ''}
                handleNextSlide={handleNextSlide}
                handlePreviousSlide={handlePreviousSlide}
            />
            {!isMobile && <Swiper
                ref={slideRef}
                className='movie-list-container'
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 8,
                }}

                spaceBetween={28}
                slidesPerView={slidersPerWidth()}

                onSlideChange={() => { }}
                onSwiper={() => { }}
            >
                {moviesList && moviesList.map((movie, index) => {
                    const serviceItem = movie?.sources || []
                    const movieImgUrl = `https://img.rgstatic.com/content/${type}/${movie.id}/poster-780.jpg`


                    return (
                        <SwiperSlide role='article' style={{
                            height: '500px'
                        }}>
                            {category ?
                                <MovieGenreCategory
                                    categoryImg={movie.movieImgUrl}
                                    onClickCategory={() => handleClickCategory(movie.category.toLocaleLowerCase())}
                                />
                                : <MovieShelfItem
                                    tags={movie?.tags}
                                    runTime={movie.content_type === 'show' ? `${movie.episodes_count} eps` : `${runTimeTransform(movie?.runtime)}`}
                                    type={type}
                                    movieProvider={serviceItem}
                                    linkTo={`${movie?.id}`}
                                    key={movie?.id}
                                    movieImgUrl={movieImgUrl}
                                    movieTitle={movie.movieTitle}
                                    onPressMovie={() => { }}
                                    movieClassification={movie?.classification}
                                    setHover={setOnMouseHover}
                                    onHover={onMouseHover}
                                />}
                        </SwiperSlide>
                    )
                })}

            </Swiper>}
            {isMobile && <div className='shelf-list-mobile'>
                {moviesList && moviesList.map((movie, index) => {
                    const serviceItem = movie?.sources || []
                    const movieImgUrl = `https://img.rgstatic.com/content/${type}/${movie.id}/poster-780.jpg`

                    return (
                        <div role='article'>
                            {category ?
                                <MovieGenreCategory
                                    categoryImg={movie.movieImgUrl}
                                    onClickCategory={() => { }}
                                />
                                : <MovieShelfItem
                                    type={type}
                                    movieProvider={serviceItem}
                                    linkTo={`${movie?.id}`}
                                    key={movie?.id}
                                    movieImgUrl={movieImgUrl}
                                    movieTitle={movie.movieTitle}
                                    onPressMovie={() => { }}
                                />}
                        </div>
                    )
                })}
            </div>}
        </article>
    )
}

export default MoviesListShelf