import { IMergeMusicProviders } from "../../interfaces/providers/provider-interface";
import jwtDecode from 'jwt-decode'
import { TidalTokenKey, delay } from "../../constants";
import TidalApi from "./tidal-api";
import { ISong, MusicPlatform } from "interfaces/models/model-interfaces";
import TidalIcon from '../../assets/icon/tidal_icon.svg'
import { TrackLimit } from "../../constants";
import store from '../../store'
import { sendAuthenticationComplete } from "actions/authentication/authentication-actions";

const ERROR_451 = 451
const TidalApiUrl = process.env.REACT_APP_BACKEND_URL || `http://localhost:8080`
let token: any

export default class TidalMusicProvider implements IMergeMusicProviders {
    constructor() {
        this.initialize()
    }

    public async initialize(): Promise<void> {
        if (!this.isTokenExpired()) {
            token = await this.getToken()
        }
    }

    public async isSubscribed(): Promise<boolean> {
        return this.isTokenExpired()
    }
    public async searchPodcasts(podcasts: string[]): Promise<any> {
        return []
    }

    public async getEpisode(show: string): Promise<any> {
        return []
    }

    public async searchUserFavoriteArtists(): Promise<any> {
        return []
    }

    public async searchUserPodcasts(): Promise<ISong[]> {
        return []
     }

    public async searchAlbum(album: string): Promise<any> {

    }

    public async searchArtist(artist: string): Promise<any> {

    }

    public async getPodcastEpisodes(id: string): Promise<any> {
        return []
    }

    public async searchSong(song: string): Promise<any> {

    }

    public async searchUserFavoriteAlbums(): Promise<any[]> {
        return []        
    }

    public async searchArtists(artistsArray: string[], type: string): Promise<any> {
        try {
            const apiCall = artistsArray.map((artist) =>  TidalApi.get(`/search?query=${artist}&countryCode=US&type=${type.toUpperCase()}`))       
            const results: any[] = await Promise.all(apiCall)
            const artistsFiltered: any[] = results.map((result, idx) => {
                if (result[type][idx]) {
                    return result[type][idx]
                }               
            })
            const resultFlat = artistsFiltered.flat().filter((item: any) => item !== undefined)           
            const parsedResult = this.parseResponse(resultFlat)
            return parsedResult
        } catch (error) {
            return []
        }       
    }

    public async searchUserPlaylists(): Promise<any[]> {
        return []
    }

    public async searchUserFavorites(): Promise<any[]> {
        return []
    }

    public async search(searchTerm: string): Promise<any> {
        const response: any = await TidalApi.get(`/search?query=${searchTerm}&countryCode=US`)
        if (response.errors) {
            await this.getToken()
            await delay(1000)
            await this.search(searchTerm)
            return []
        } else {
            const { tracks } = response            
            const normalizedResults = this.parseResponse(tracks)
            return normalizedResults
        }
    }

    public async getTrack(track: string): Promise<any> {
        const trackInfo: any = await TidalApi.get(`/tracks/${track}?countryCode=US`)

        if (trackInfo.errors) {
            await this.getToken()
            await delay(1000)
            await this.getTrack(track)
            return []
        } else {
            const { album } = trackInfo.resource
            const url = `https://listen.tidal.com/album/${album.id}/track/${track}`
            window.open(url, '_blank')
        }

        return []
    }

    public async login(): Promise<void> {
        token = await this.getToken()
        store.dispatch(sendAuthenticationComplete(true))
    }

    public async logout(): Promise<void> {
        localStorage.removeItem(TidalTokenKey)
        Promise.resolve()
    }

    private isTokenExpired(): boolean {
        token = localStorage.getItem(TidalTokenKey)

        if (!token) return false

        const decode: any = jwtDecode(token)

        return decode.exp < Date.now()
    }

    private async getToken(): Promise<string | null> {
        try {
            const response: any = await fetch(`${TidalApiUrl}/api/tidal/token`)
            const jsonResponse: any = await response.json()
            const { access_token } = jsonResponse
            localStorage.setItem(TidalTokenKey, access_token)
            return access_token
        } catch (error) {
            return null
        }
    }

    private parseResponse(tracks: any): any {
      
        const result: ISong[] = tracks.map((track: any) => {
            if (track.status === ERROR_451) {
                return undefined
            }

            const { id, resource, imageCover } = track
            const { album, title, duration, artists } = resource

            const songData: ISong = {
                id,
                albumId: album.id,
                songName: title,
                img: album ? album.imageCover[0].url : imageCover[0].url,
                albumName: album.title,
                platform: MusicPlatform.TidalMusic,
                artistName: artists[0].name,
                url: 'none',
                icon: TidalIcon,
                durationInMillis: duration * 1000
            }

            return songData
        })

        return result.filter((track) => track !== undefined).slice(0, TrackLimit)
    }
    
}