import { MusicPlatform } from 'interfaces/models/model-interfaces';
import React from 'react';
import { Modal, Button } from 'react-bootstrap'; // Assuming you're using React Bootstrap
import './playlist-platform-modal'

interface PlaylistPlatformModalProps {
  show: boolean;
  onPlatformSelect: (platform: MusicPlatform) => void;
}

const PlaylistPlatformModal: React.FC<PlaylistPlatformModalProps> = ({ show, onPlatformSelect }) => {
  
  return (
    <Modal show={show} centered>
      <Modal.Header>
        <Modal.Title>Choose Platform to Stream Playlist</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Button
          variant="success"
          className="w-100 mb-2"
          onClick={() => onPlatformSelect(MusicPlatform.SpotifyMusic)}
        >
          <i className="fab fa-spotify"></i> Spotify
        </Button>
        <Button
          variant="dark"
          className="w-100"
          onClick={() => onPlatformSelect(MusicPlatform.AppleMusic)}
        >
          <i className="fab fa-apple"></i> Apple Music
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default PlaylistPlatformModal;
