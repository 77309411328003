import React from 'react'
import SettingsIcon from 'assets/icon/settings_icon.svg'
import VolumeIcon from 'assets/icon/volume_icon.svg'
import MenuIcon from 'assets/icon/menu_icon.svg'
import { InputVolume } from './InputProgress';
import { useSelector } from 'react-redux';
import { uiSelector } from 'store/selectors';
import { changeColorMode } from '@utils/colors';

interface PlayerConfigProps {
    handleVolume: (event: React.ChangeEvent<HTMLInputElement>) => void;
    audioPlayerRef: React.RefObject<HTMLAudioElement>;
}
const PlayerConfig = ({ handleVolume, audioPlayerRef }: PlayerConfigProps) => {
    const isDarkMode = useSelector(uiSelector).darkMode
    const calculateVolumeWidth = (progressBar: number) => {
        if (!audioPlayerRef.current) return 0;
        const progressWidth = progressBar * 100;
        return +progressWidth;
    }

    return (
        <div className='player_config_options'>
            {/* <img style={{ cursor: 'pointer' }} className='settings_icon' src={SettingsIcon} alt="" /> */}
            <img style={{ cursor: 'pointer' }} className="volume_icon" src={VolumeIcon} alt="" />
            <InputVolume
                currentProgress={audioPlayerRef.current?.volume && calculateVolumeWidth(audioPlayerRef.current?.volume)}
                min="0"
                max="100"
                type="range"
                onChange={handleVolume} />
            {/* <img style={{ cursor: 'pointer' }} className='menu_icon' src={MenuIcon} alt="" /> */}
            {/* <p style={{ color: changeColorMode(isDarkMode) }}>Queue</p> */}
        </div>
    )
}

export default PlayerConfig