import React from 'react'
import { Link } from 'react-router-dom';
import './MyFavoritesSection.scss'
import { useSelector } from 'react-redux';
import { uiSelector } from 'store/selectors';
import { changeColorMode } from '@utils/colors';
import createPlaylist from 'assets/images/add_playlist_mobile.png'
import AddPlaylistModal from './AddPlaylistModal';
import PlayListSection from '@components/PlayListSection/PlayListSection';
import { AddIcon } from '@components/IconComponents/IconComponents';
import MyFavoritesSectionHeader from './MyFavoritesSectionHeader';
import { useWindowDimensions } from '@hooks/useWindowDimension';

interface MyFavoritesSectionProps {
    title: string;
    items: any[];
    additionalClassName?: string;
    additionName?: string;
    type?: string;
    showSectionHeader?: boolean;
    toggleNewPlaylistModal?: () => void;
}
const MyFavoritesSection = ({ items, title, additionName, additionalClassName, type = '', showSectionHeader = true, toggleNewPlaylistModal }: MyFavoritesSectionProps) => {
    const [searchInput, setSearchInput] = React.useState('')
    const [openPlaylistModal, setOpenPlaylistModal] = React.useState(false)

    const { isMobile } = useWindowDimensions()
    const togglePlaylistModal = () => {
        setOpenPlaylistModal(!openPlaylistModal)
    }

    const isDarkMode = useSelector(uiSelector).darkMode
    const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(e.target.value);
    }

    const handleClearInput = () => {
        setSearchInput('')
    }

    const itemsFiltered = items?.filter(item => (item.songName || item.artistName || item.playlistName)?.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase()))
    const linkType = type === 'albums' ? '/albums/' : type === "playlists" ? '/music/playlist/' : '/artist/'

    const handleSavePlaylist = () => {
        alert("Playlist saved")
        togglePlaylistModal()
    }

    return (
        <section className='section__container'>
            <AddPlaylistModal handleCloseModal={togglePlaylistModal} visible={openPlaylistModal} handleSaveRadio={handleSavePlaylist} />
            {(!isMobile && showSectionHeader) && <MyFavoritesSectionHeader title={title} searchInput={searchInput} onChangeInput={onChangeInput} handleClearInput={handleClearInput} />}
            {type === 'playlists' && !isMobile && <div className='item' style={{ alignItems: 'center', display: 'flex', gap: 12, marginTop: '60px', }} onClick={togglePlaylistModal}>
                {/* <h2 className="item__item__container__title" style={{ color: changeColorMode(isDarkMode) }}>My Playlists</h2>
                <AddIcon strokeColor='#8D66FE' style={{ cursor: 'pointer', borderRadius: '50%', height: 40, width: 40, padding: 2, border: '1px solid #8D66FE' }} /> */}
            </div>}
            <div className='items__container'>
                {/* {type === 'playlists' && isMobile && <div onClick={toggleNewPlaylistModal} style={{ display: 'flex', flexDirection: 'column', width: '100px' }}>
                    <img src={createPlaylist} style={{ height: '100px', width: '100px' }} />
                    <small style={{ color: changeColorMode(isDarkMode), marginTop:15 }}>Create new playlist</small>
                </div>} */}
                {!!itemsFiltered?.length && itemsFiltered.map((item, index) => {
                    if (type === 'playlists') {
                        return (
                            <>
                                <PlayListSection
                                    playlistImage={item?.img || item?.playlistArtworkUrl}
                                    playlistLength={`${item?.tracks.length} tracks`}
                                    playlistLink={item?.playlistName}
                                    playlistTitle={item?.albumName || item?.playlistName}
                                    tracks={item?.tracks}
                                    providerImg={item?.providerImg}
                                />
                            </>
                        )
                    }
                    return (
                        <Link className='item' key={index} to={`${linkType}${item.songName}/${item.artistName || item.songName}`} style={{ maxWidth: !isMobile ? '270px' : '101px' }}>
                            <img src={item.img} alt="music" className="music__item__container__img" />
                            {(item.albumName || item.artistName) && <span className="item__item__container__title" style={{ color: changeColorMode(isDarkMode) }}>{item.albumName || item.artistName}</span>}
                            {item.songName && <span className="item__item__container__subtitle" style={{ color: changeColorMode(isDarkMode) }}>{item.songName}</span>}
                            {item.icon && <img src={item.icon} style={{ float: "right", width: "21px" }} alt='icon' />}

                        </Link>
                    )
                })}
            </div>
        </section>
    )
}

export default MyFavoritesSection