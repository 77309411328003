import _shuffle from 'lodash/shuffle';
/// import { createMediaItem } from '../utils/Utils';

let originalQueue: MusicKit.MediaItem[] | null = null;
let player

class AppleMusicPlayer {

    static currentTime = 0

    static async playTrack(tracks: MusicKit.MediaItem[], index: number) {
        const music = MusicKit.getInstance();
        await AppleMusicPlayer.setQueueItems(tracks, index);
        await music.player.play();
    }

    static async playAlbum(album: MusicKit.Resource, index: number) {
        const music = MusicKit.getInstance();

        let albumData = album;
        if (!albumData.relationships || !albumData.relationships.tracks) {
            albumData = isNaN(album.id)
                ? await music.api.library.album(album.id)
                : await music.api.album(album.id);
        }

        await AppleMusicPlayer.setQueueItems(albumData.relationships.tracks.data, index);

        await music.player.play();
    }

    static async shufflePlayAlbum(album: MusicKit.Resource) {
        const music = MusicKit.getInstance();
        const queue = _shuffle(album.relationships.tracks.data);
        await AppleMusicPlayer.setQueueItems(queue, 0, album);
        await music.player.play();
    }

    static async playPlaylist(playlist: MusicKit.Resource, index: number) {
        const music = MusicKit.getInstance();
        let playlistData = playlist;
        if (!playlistData.relationships || !playlistData.relationships.tracks) {
            playlistData = playlist.id.startsWith('p.')
                ? await music.api.library.playlist(playlist.id)
                : await music.api.playlist(playlist.id);
        }
        await AppleMusicPlayer.setQueueItems(playlistData.relationships.tracks.data, index, playlist);
        await music.player.play();
    }

    static async shufflePlayPlaylist(playlist: MusicKit.Resource) {
        const music = MusicKit.getInstance();
        const queue = _shuffle(playlist.relationships.tracks.data);
        await AppleMusicPlayer.setQueueItems(queue, 0, playlist);
        await music.player.play();
    }

    static async playNext(item: MusicKit.MediaItem) {
        let items;
        const music = MusicKit.getInstance();
        if (item.type === 'songs' || item.type === 'library-songs') {
            items = [item];
        } else if (item.type === 'albums' || item.type === 'library-albums') {
            let albumData = item as any;
            if (!albumData.relationships || !albumData.relationships.tracks) {
                albumData = isNaN((item.id as unknown) as number)
                    ? await music.api.library.album(item.id)
                    : await music.api.album(item.id);
            }
            items = albumData.relationships.tracks.data;
        } else if (item.type === 'playlists' || item.type === 'library-playlists') {
            let playlistData = item as any;
            if (!playlistData.relationships || !playlistData.relationships.tracks) {
                playlistData = item.id.startsWith('p.')
                    ? await music.api.library.playlist(item.id)
                    : await music.api.playlist(item.id);
            }
            items = playlistData.relationships.tracks.data;
        } else {
            return;
        }
        await AppleMusicPlayer.prependQueueItems(items);
    }

    static async playLater(item: MusicKit.MediaItem) {
        let items;
        const music = MusicKit.getInstance();
        if (item.type === 'songs' || item.type === 'library-songs') {
            items = [item];
        } else if (item.type === 'albums' || item.type === 'library-albums') {
            let albumData = item as any;
            if (!albumData.relationships || !albumData.relationships.tracks) {
                albumData = isNaN((item.id as unknown) as number)
                    ? await music.api.library.album(item.id)
                    : await music.api.album(item.id);
            }
            items = albumData.relationships.tracks.data;
        } else if (item.type === 'playlists' || item.type === 'library-playlists') {
            let playlistData = item as any;
            if (!playlistData.relationships || !playlistData.relationships.tracks) {
                playlistData = item.id.startsWith('p.')
                    ? await music.api.library.playlist(item.id)
                    : await music.api.playlist(item.id);
            }
            items = playlistData.relationships.tracks.data;
        } else {
            return;
        }
        await AppleMusicPlayer.appendQueueItems(items);
    }

    static async play() {
        await MusicKit.getInstance().player.play();
    }

    static async pause() {
        await MusicKit.getInstance().player.pause();
    }

    static async togglePlayback() {
        const { player } = MusicKit.getInstance();
        if (AppleMusicPlayer.isPlaying()) {
            await player.pause();
        } else {
            await player.play();
        }
    }

    static async seekToTime(time: number) {
        await MusicKit.getInstance().player.seekToTime(time);
    }

    static getPlayingItem() {
        return MusicKit.getInstance().player.nowPlayingItem;
    }

    static async shuffle() {
        const currentQueue = AppleMusicPlayer.getQueueItems();
        if (!AppleMusicPlayer.isShuffled()) {
            originalQueue = [...currentQueue];
        }

        const newQueue = _shuffle(currentQueue);
        newQueue[MusicKit.getInstance().player.queue.position] = AppleMusicPlayer.getPlayingItem();

        await AppleMusicPlayer.setQueueItems(newQueue);

        const wasPlaying = AppleMusicPlayer.isPlaying();
        setImmediate(() => {
            if (wasPlaying) {
                AppleMusicPlayer.play(); // TODO: Work out a better way to keep the track playing...
            }
        });
    }

    static async unShuffle() {
        if (AppleMusicPlayer.isShuffled()) {
            let newQueue = originalQueue;

            originalQueue = null;

            if (newQueue === null) {
                newQueue = [];
            }

            await AppleMusicPlayer.setQueueItems(newQueue);
        }

        const wasPlaying = AppleMusicPlayer.isPlaying();
        setImmediate(() => {
            if (wasPlaying) {
                AppleMusicPlayer.play(); // TODO: Work out a better way to keep the track playing...
            }
        });
    }

    static isShuffled() {
        return originalQueue !== null;
    }

    static getQueueItems() {
        return MusicKit.getInstance().player.queue.items;
    }

    static async setQueue(item: any) {
        await MusicKit.getInstance().setQueue({
            song: item
        })
    }

    static async setQueueItems(items: any[], index = 0, container: any = null) {
        const startPosition = !isNaN(index) ? index : MusicKit.getInstance().player.queue.position;

        await MusicKit.getInstance().setQueue({
            // @ts-ignore
            items: items.map(item => AppleMusicPlayer.createMediaItem(item, container)),
        });

        await MusicKit.getInstance().player.changeToMediaAtIndex(startPosition);
    }

    static async prependQueueItems(items: MusicKit.MediaItem[], container = null) {
        const s = { items: items.map(item => AppleMusicPlayer.createMediaItem(item, container)) };
        await MusicKit.getInstance().player.queue.prepend(s);
    }

    static async appendQueueItems(items: MusicKit.MediaItem[], container = null) {
        const s = { items: items.map(item => AppleMusicPlayer.createMediaItem(item, container)) };
        // @ts-ignore
        await MusicKit.getInstance().player.queue.append(s);
    }

    static isSame(a: any, b: any) {
        return (
            a &&
            b &&
            (a.id === b.id ||
                (b.container && a.id === b.container.id) ||
                (a.container && b.id === a.container.id))
        );
    }

    static isCurrentTrack(track: MusicKit.MediaItem) {
        const playing = AppleMusicPlayer.getPlayingItem();

        if (!playing) {
            return false;
        }

        return AppleMusicPlayer.isSame(track, playing);
    }

    static isPlaying() {
        return MusicKit.getInstance().player.isPlaying;
    }

    static isTrackPlaying(track: MusicKit.MediaItem) {
        return MusicKit.getInstance().player.isPlaying && AppleMusicPlayer.isCurrentTrack(track);
    }

    static createMediaItem(song: any, container: any = null) {
        if (container) {
            const containerName =
                container.type === 'albums' || container.type === 'library-albums' ? 'albums' : 'playlists';

            return {
                ...song,
                container: {
                    id: container.id,
                    type: container.type,
                    name: containerName,
                },
            };
        }

        return {
            ...song,
            container: {
                id: song.id,
            },
        };
    }

    static addListeners(setTimeUpdateState: any, progressbar: any) {
        MusicKit.getInstance().addEventListener("playbackTimeDidChange", (event: any) => {
            const { currentPlaybackTime } = event

            AppleMusicPlayer.currentTime = currentPlaybackTime

            if (progressbar && progressbar.current) {
                progressbar.current.value = currentPlaybackTime
            }

            setTimeUpdateState(currentPlaybackTime)
        })
    }

    static setVolume(value: number) {
        MusicKit.getInstance().player.volume = value
    }

    static toogleRepeat() {
        const { player } = MusicKit.getInstance()
        player.repeatMode = player.repeatMode === 1 ? 0 : 1
    }
}

export default AppleMusicPlayer