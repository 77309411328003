import React from 'react';
import { Link } from 'react-router-dom'
import MainMergeIcon from '../../assets/icon/merge_main_icon.svg'
import './Logo.scss'
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const Logo: React.FC<{ disableLink?: boolean, showName?: boolean }> = ({ disableLink = false, showName = true }) => {
    const { isAuthenticated, isLoading } = useAuth0();
    const isDarkMode = useSelector((state: RootState) => state.ui.darkMode)

    return (
        <Link to={isAuthenticated && !isLoading ? '/music' : '/'} className='logo' style={{
            pointerEvents: disableLink ? 'none' : 'auto',
            cursor: disableLink ? 'default' : 'pointer',
        }}>
            <img src={MainMergeIcon} alt='merge-logo' style={{ color: "red" }} />
            {showName && <span style={{ color: isDarkMode ? 'white' : 'unset', cursor:'pointer' }}>Merge</span>}
        </Link>
    )
}

export default Logo;