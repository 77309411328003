import { ISong, MediaType, MusicPlatform, PlatformModel } from "../interfaces/models/model-interfaces"
import { IMergeMusicProviders } from "../interfaces/providers/provider-interface"
import AppleMusicProvider from "./apple/AppleMusicProvider"
import SpotifyMusicProviders from "./spofity/SpotifyMusicProvider"
import TidalMusicProvider from "./tidal/TidalMusicProvider"
import YoutubeMusicProvider from "./youtube/youtube-music-provider"
import { musicStreamings } from "../mockdatas/streamings"

const {
    AppleMusic,
    SpotifyMusic,
    TidalMusic,
    YoutubeMusic
} = MusicPlatform

const Providers: Record<any, any> = {
    [AppleMusic]: new AppleMusicProvider(),
    [TidalMusic]: new TidalMusicProvider(),
    [SpotifyMusic]: new SpotifyMusicProviders(),
    [YoutubeMusic]: new YoutubeMusicProvider()
}

class MergeMusicProviders {
    public static async isSubscribed(platformData: PlatformModel): Promise<boolean> {
        const platform = Providers[platformData.id]
        if (platform) {
            const isPlatformSubscribed = await platform.isSubscribed()
            const isSubscribed = isPlatformSubscribed
            return isSubscribed
        }

        return false

    }

    public static getProvider(provider: string): IMergeMusicProviders {
        const platform = Providers[provider]
        return platform
    }

    public static async getSubsctibedPlatforms(): Promise<IMergeMusicProviders[]> {
        const subscribedArray = []

        for (const platform of musicStreamings) {
            const isSubscribed = await MergeMusicProviders.isSubscribed(platform);
            if (isSubscribed) {
                const provider = MergeMusicProviders.getProvider(platform.id);
                subscribedArray.push(provider);
            }
        };

        return subscribedArray;
    }

    public static async getContent(id: string, platform: string, mediaType: string = MediaType.Tracks): Promise<any> {
        const provider: IMergeMusicProviders = MergeMusicProviders.getProvider(platform)
        const track = await provider.getTrack(id, mediaType)
        return track  
        
        switch(mediaType) {
            case MediaType.Tracks:
                              
            case MediaType.Shows:
                const shows = await provider.getEpisode(id)
                return shows
            default:
                return []
                
                
        }       
    }
}

export default MergeMusicProviders