import { User } from "@auth0/auth0-spa-js";
import { AccessToken } from "@spotify/web-api-ts-sdk";
import { ISong } from "../interfaces/models/model-interfaces";



class ModelLocator {
    private static instance: ModelLocator;

    private constructor() {
        // Initialize your model here
    }

    public static getInstance(): ModelLocator {
        if (!ModelLocator.instance) {
            ModelLocator.instance = new ModelLocator();
        }

        return ModelLocator.instance;
    }

    // Add your model methods and properties here
    public accessToken?: AccessToken
    public userProfile?: User
    public topArtists?: ISong[]
    public topAlbums?: ISong[]
}

export default ModelLocator
