import { useNavigate } from 'react-router-dom';

import './Error.scss';
import Button from '@components/Button/Button';
import { useSelector } from 'react-redux';
import { uiSelector } from 'store/selectors';
import { changeColorMode } from '@utils/colors';
import HeaderNav from '@components/HeaderNav/HeaderNav';

const Error = () => {
    const navigate = useNavigate();
    const isDarkMode = useSelector(uiSelector).darkMode;

    return (
        <>
            <HeaderNav />
            <div className="error-container">
                <div className="oops" style={{ color: changeColorMode(isDarkMode) }}>Oops!</div>
                <p className="not-found" style={{ color: changeColorMode(isDarkMode) }}>Error 404: Page Not Found</p>
                <Button buttonText="Back Home" className="back-button button" onClick={() => navigate(-1)} />
            </div>
        </>
    );
};
export default Error;