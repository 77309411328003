import HeaderNav from '@components/HeaderNav/HeaderNav';
import React, { useState, useEffect } from 'react';
import { ourPlatforms } from 'mockdatas/streamings';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import LandingPageFooter from './components/LandingPageFooter/LandingPageFooter';
import LandingPageDetails from './components/LandingPageDetails/LandingPageDetails';
import LandingPagePlatforms from './components/LandingPagePlatforms/LandingPagePlatforms';
import LandingPageHeaderInformation from './components/LandingPageHeaderInformation/LandingPageHeaderInformation';
import './LandingPage.scss'
import { useWindowDimensions } from '@hooks/useWindowDimension';


const LandingPage: React.FC = () => {
   
    const navigate = useNavigate();
    const { loginWithRedirect, isAuthenticated } = useAuth0()
    const { isMobile } = useWindowDimensions()
    const subTitleText = isMobile ? 'Keep your accounts in one place' : 'Listen to music. Watch movies. And many more....'

    const [isAnimated, setIsAnimated] = useState(false);
    

    useEffect(() => {
       
        const handleScroll = () => {
            if (window.scrollY >= 500) {
                setIsAnimated(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {

            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const getStartedButton = () => {
        if (isAuthenticated) {
            navigate('/user')
        }
        loginWithRedirect({
            authorizationParams: {
                redirect_uri: `${window.location.origin}/addstreaming`,
            }
        })
    }

    return (
        <section className='landing__container'>
            <HeaderNav />
            <LandingPageHeaderInformation getStartedButton={getStartedButton} subTitleText={subTitleText} />
            <LandingPagePlatforms ourPlatforms={ourPlatforms} />
            <LandingPageDetails isAnimated={isAnimated} />
            <LandingPageFooter />
        </section>
    )
}

export default LandingPage;