import React from 'react'
import Button from '@components/Button/Button'
import LandingPageStreamings from 'assets/images/landing_page_streamings.png'
import LandingPageStreamsMobile from 'assets/images/landing_page_streamings.svg'
import { useWindowDimensions } from '@hooks/useWindowDimension'
import { uiSelector } from 'store/selectors'
import { useSelector } from 'react-redux'
import { changeColorMode } from '@utils/colors'

interface LandingPageDetailsProps {
    isAnimated: boolean
}
const LandingPageDetails = ({ isAnimated }: LandingPageDetailsProps) => {
    const isDarkMode = useSelector(uiSelector).darkMode
    const { isMobile } = useWindowDimensions()
    const landingPageStreamsImage =  LandingPageStreamings;
    return (
        <article className='landing__container__details__container'>
            <Button buttonText='Merge' additionalClassName='landing__container__top__mobile__button' />
            <img src={landingPageStreamsImage}
                className={`landing__container__details__container__image ${isAnimated ? 'animate-banner' : ''} `}
                alt="landing page streamings"
            />


            <div className='landing__container__details__container__info'>
                <h1 className='landing__container__details__container__info__title' style={{  color: changeColorMode(isDarkMode) }}>Merge all your subscriptions in one place</h1>
                <h3 className='landing__container__details__container__info__text' style={{  color: changeColorMode(isDarkMode) }}>Join Merge, add your accounts and enjoy full experience that  services offer without the need to move between platforms</h3>
                <Button buttonText='Merge' additionalClassName='landing__container__top_info__button' />
            </div>
        </article>
    )
}

export default LandingPageDetails