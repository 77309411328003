import { TrackLimit } from "../../constants"
import { IPlaylist, IPodcast, ISong, MusicPlatform } from "../../interfaces/models/model-interfaces"
import SpotifyIcon from '../../assets/icon/streaming_spotify.svg'
import { DefaultImage } from "../../constants"

class SpotifyParser {
    public static parseArtists(response: any) {
        const result: ISong[] = response.map((track: any) => {
            const { id, name, artists, duration_ms, images } = track

            if (!images.length) return

            const songData: ISong = {
                id,
                songName: name,
                img: images[0]?.url,
                albumName: artists,
                platform: MusicPlatform.SpotifyMusic,
                artistName: artists,
                url: 'none',
                icon: SpotifyIcon,
                durationInMillis: duration_ms
            }

            return songData
        })

        return result.filter((track) => track !== undefined).slice(0, TrackLimit)
    }

    public static parseAlbums(response: any): any {

        const result: ISong[] = response.map((track: any) => {
            const { id, name, artists, duration_ms, images } = track

            if (!images.length) return

            const songData: ISong = {
                id,
                songName: name,
                img: images[0]?.url,
                albumName: name,
                platform: MusicPlatform.SpotifyMusic,
                artistName: artists[0].name,
                url: 'none',
                icon: SpotifyIcon,
                durationInMillis: duration_ms
            }

            return songData
        })

        return result.filter((track) => track !== undefined).slice(0, TrackLimit)
    }

    public static parseResponse(response: any): any {
        const { items } = response
        const result: ISong[] = items.map((track: any) => {
            const { id, album, name, artist, duration_ms, artists } = track

            if (!album?.images.length) return

            const songData: ISong = {
                id,
                songName: name,
                img: album?.images[0]?.url,
                albumName: album ? album.name : '',
                platform: MusicPlatform.SpotifyMusic,
                artistName: artist || artists.map((artist: any) => `${artist.name} `).join(' '),
                url: 'none',
                icon: SpotifyIcon,
                durationInMillis: duration_ms
            }

            return songData
        })

        return result.filter((track) => track !== undefined).slice(0, TrackLimit)
    }

    public static parseResponseFromPlaylist(response: any): any {

        const { items } = response
        const result: ISong[] = items.map((track: any) => {
            const { id, album, name, artists, duration_ms } = track.track

            if (!album?.images.length) return

            const songData: ISong = {
                id,
                songName: name,
                img: album?.images[0]?.url,
                albumName: album ? album.name : '',
                platform: MusicPlatform.SpotifyMusic,
                artistName: artists[0]?.name || 'Unknown Artist',
                url: 'none',
                icon: SpotifyIcon,
                durationInMillis: duration_ms
            }

            return songData
        })

        return result.filter((track) => track !== undefined)
    }

    public static parsePlaylist(response: any): IPlaylist[] {
        const songsParsed: IPlaylist[] = response.map((playlist: any) => {
            const { id,  tracks, images, name } = playlist
                       
            const result: IPlaylist = {
                playlistId: id,
                playlistName: name,
                playlistArtworkUrl: images ? images[0]?.url : DefaultImage,
                tracks: SpotifyParser.parseResponseFromPlaylist(tracks),
                providerImg: SpotifyIcon
            }          

            return result
        })
        return songsParsed
    }

    public static parsePodcasts(response: any): any[] {
        const podcasts: IPlaylist[] = response.map((podcast: any) => {
            const { shows } = podcast
            const { items } = shows
            const mainPodcast  = items[0]
            const { images, id, name, publisher, description } = mainPodcast
            
            const result: IPodcast = {
                platform: MusicPlatform.SpotifyMusic,
                id,
                albumName: publisher,
                songName: name,
                img: images[0]?.url,
                icon: SpotifyIcon,
                artistName: name,
                url: 'none',
                durationInMillis: 0,
                providerImg: SpotifyIcon,
                items: items.map((podcast: any) => SpotifyParser.parsePodcast(podcast)),
                description
            }              

            return result
        })
        return podcasts
    }

    public static parseEpisodes(episodes: any) {
        const { items } = episodes
        const podcasts = items.map((podcast: any) => {
            const { duration_ms, id, images, description, name, release_date } = podcast
            const result: IPodcast = {
                platform: MusicPlatform.SpotifyMusic,
                id,
                albumName: release_date,
                songName: name,
                img: images[0]?.url,
                icon: SpotifyIcon,
                providerImg: SpotifyIcon,
                artistName: name,
                url: 'none',
                durationInMillis: duration_ms,
                items: items.map((podcast: any) => SpotifyParser.parsePodcast(podcast)),
                description
            }              

            return result
        })
        return podcasts
    }

    public static parsePodcast(podcast: any): ISong {
       
        const { images, id, name, publisher } = podcast

        return {
            platform: MusicPlatform.SpotifyMusic,
            id,
            albumName: publisher,
            songName: name,
            img: images[0]?.url,
            icon: SpotifyIcon,
            artistName: name,
            url: 'none',
            durationInMillis: 0
        }
    } 
}

export default SpotifyParser