import { SearchIcon } from '@components/IconComponents/IconComponents'
import './Search.scss'
import { useRef } from 'react'
import { uiSelector } from 'store/selectors'
import { useSelector } from 'react-redux'
import { changeColorMode } from '@utils/colors'

interface SearchProps {
    inputSearch: string
    setInputSearch: (value: string) => void
    contentLength?: number
}


const Search = ({ inputSearch, setInputSearch, contentLength }: SearchProps) => {
    const isDarkMode = useSelector(uiSelector).darkMode
    const inputSearchRef = useRef<HTMLInputElement>(null)

    const handleFocusInput = () => {
        inputSearchRef.current?.focus()
    }

    return (
        <div className='search-container'>
            {contentLength ? <> </> : <h2 className='search-title' style={{ color: changeColorMode(isDarkMode) }}>Let's find some songs for your playlist!</h2>}
            <div className='search-input-container' onClick={handleFocusInput}>
                <SearchIcon strokeColor='#8D66FE' />
                <input onChange={event => setInputSearch(event.target.value)} value={inputSearch} ref={inputSearchRef} type="text" placeholder="Search" />
            </div>
        </div>
    )
}

export default Search