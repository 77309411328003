import { changeColorMode } from '@utils/colors';
import React from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { CiSearch } from 'react-icons/ci'
import { useSelector } from 'react-redux';
import { uiSelector } from 'store/selectors';

interface MyFavoritesSectionHeaderProps {
    title?: string;
    searchInput: string;
    onChangeInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleClearInput: () => void;
    children?: React.ReactNode;

}
const MyFavoritesSectionHeader = ({ handleClearInput, onChangeInput, searchInput, title = '', children }: MyFavoritesSectionHeaderProps) => {

    const isDarkMode = useSelector(uiSelector).darkMode

    return (
        <div className='section__container__top'>
            {title ? <h1 className='section__container__top__title' style={{ color: changeColorMode(isDarkMode) }}>
                {title}
            </h1> : <>{children}</>}
            <div className='search__bar'>
                <CiSearch size={32} color={"#8D66FE"} />
                <input placeholder='Search' value={searchInput} style={{ outline: 'none', border: 'none' }} onChange={onChangeInput} />
                <AiOutlineClose size={24} color={'lightgray'} style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={handleClearInput} />
            </div>
        </div>
    )
}

export default MyFavoritesSectionHeader