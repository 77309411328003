import Base from 'template/Base'
import MusicStreamingSection from '@components/MusicStreamingSection/MusicStreamingSection'
import { musicLinksList } from '@utils/navigationsLinks/profileDropdownList'
import FavoriteSongs from './FavoriteSongs'
import { useLocation, useNavigate } from 'react-router-dom'
import { useWindowDimensions } from '@hooks/useWindowDimension'
import MyFavoritesMobile from './MyFavoritesMobile/MyFavoritesMobile'
import MyFavoritesSection from '@components/MyFavoritesSection/MyFavoritesSection'
import { useEffect, useState } from 'react'
import ModelLocator from '../../model/model-locator'
import { ISong } from '../../interfaces/models/model-interfaces'
import ApiBridge from '../../api/api-bridge'
import Loader from '@components/Loader/loader'
import { Song } from 'dtos/search'

const MyFavorites = () => {

    const [isLoading, setLoader] = useState<boolean>(true)
    const [playlists, setPlaylist] = useState<ISong[]>([])
    
    const [albumMusic, setAlbum] = useState<ISong[]>([])
    const [artistsMusic, setArtists] = useState<ISong[]>([])
    const [favoriteMucis, setFavorite] = useState<ISong[]>([])
    const routeNavigation = useNavigate()
    const { isMobile } = useWindowDimensions()
    const { search } = useLocation()
    const type = search?.split("=")[1]

    const handleClickItemMenu = (link: string) => {
        routeNavigation(link)
    }

    useEffect(() => {

        const fetchData = async () => {

            setLoader(true)

            try {
                const playlistsTerm: Promise<ISong[]> = ApiBridge.searchPlaylists()
                const userFavoritesTerm: Promise<ISong[]> = ApiBridge.searchUserFavorites(true)
                const userAlbumsTerm: Promise<ISong[]> = ApiBridge.searchUserFavoriteAlbums(true)
                const userArtistsTerm: Promise<ISong[]> = ApiBridge.searchUserFavoriteArtists(true)
                const [playlists, userFavorites, userAlbums, userArtists] = await Promise.all([playlistsTerm, userFavoritesTerm, userAlbumsTerm, userArtistsTerm])

                setArtists(userArtists)
                setAlbum(userAlbums)
                setPlaylist(playlists)
                setFavorite(userFavorites)

            } catch (error) {

            } finally {
                setLoader(false)
            }
        }

        fetchData()
    }, [])

   

    return (<>
        {isLoading ? (
            <Loader />
        ) : (

            <Base sidebarList={musicLinksList}>

                {isMobile ? <MyFavoritesMobile
                    artists={artistsMusic}
                    albums={albumMusic}
                    playlists={playlists}
                    userFavorites={favoriteMucis}
                    type={type} 
                    handleClickItemMenu={handleClickItemMenu}
                    loader={isLoading}
                    /> : <article className='music__page__container'>
                    {
                        type === "all" && 
                        <>
                            <MusicStreamingSection type="playlists" seeAll={"?type=playlists"} title="Favorite Playlists" musicList={playlists} isLoading={isLoading} />
                            <FavoriteSongs seeAll={"?type=songs"} favoriteMusic={favoriteMucis} />
                            <MusicStreamingSection clickRoute={"/albums/"} title="Favorite Albums" seeAll={"?type=albums"} musicList={albumMusic} isLoading={isLoading} />
                            <MusicStreamingSection clickRoute={"/artist/"} title="Favorite Artists" seeAll={"?type=artists"} musicList={artistsMusic} isLoading={isLoading} />
                        </>
                    }
                    {
                        type === "playlists" &&
                        <MyFavoritesSection
                            type={'playlists'}
                            title="Playlists"
                            items={playlists} />
                    }
                    {type === "artists" &&
                        <MyFavoritesSection title="Favorite Artists" items={artistsMusic} />
                    }
                    {type === "albums" &&
                        <MyFavoritesSection
                            type={type}
                            title="Favorite Albums"
                            items={albumMusic} />
                    }
                    {type === 'songs' && <FavoriteSongs favoriteMusic={favoriteMucis} itemsLength={albumMusic.length} />}
                </article>}

            </Base >)

        }
    </>
    )
}

export default MyFavorites