import BaseMoviesContainer from '@components/BaseMoviesContainer/BaseMoviesContainer'
import MovieShelfItem from '@components/MovieShelfItem/MovieShelfItem'
import { Movie, mockedMoviesData } from 'mockdatas/moviesMockedData'
import { useLocation, useNavigate } from 'react-router-dom'
import './MovieListPage.scss'
import { ArrowDownIcon } from '@components/IconComponents/IconComponents'
import { useSelector } from 'react-redux'
import { uiSelector } from 'store/selectors'
import { changeColorMode } from '@utils/colors'
import { useGetMoviesQuery } from 'services/movie.services'
import { runTimeTransform } from '@utils/runTimeTransform'

const MovieListPage = () => {


  const { data: movies } = useGetMoviesQuery({});


  const moviesData = movies?.map((item: any) => ({
    ...item,
    movieTitle: item.title,
    movieImgUrl: item.poster_url
  }))

  const navigation = useNavigate()
  const handleNavigation = () => {
    navigation(-1)
  }
  const isDarkMode = useSelector(uiSelector).darkMode
  const { pathname } = useLocation()

  const parts = pathname.split('/')
  const lastPart = parts[parts.length - 1]
  const removeDash = lastPart.replace(/-/g, ' ')
  const removeDashAndCapitalize = removeDash.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))

  return (
    <BaseMoviesContainer>
      <div className='mylist-moviepage-container'>
        <div className='top-title' onClick={handleNavigation}>
          <ArrowDownIcon strokeColor={changeColorMode(isDarkMode)} style={{ rotate: '90deg' }} />
          <h1 style={{ color: changeColorMode(isDarkMode) }}>{removeDashAndCapitalize}</h1>
        </div>
        <section className='movie-list-page-container'>
          {moviesData?.map((movie: Movie) => {
            const serviceItem = movie?.sources || []

            return (
              <MovieShelfItem
                tags={movie?.tags}
                runTime={movie.content_type === 'show' ? `${movie.episodes_count} eps` : `${runTimeTransform(movie?.runtime)}`}
                type={movie.content_type === 'show' ? 'show' : 'movie'}
                movieProvider={serviceItem}
                linkTo={`${movie?.id}`}
                key={movie?.id}
                movieImgUrl={movie.img}
                movieTitle={movie.movieTitle}
                onPressMovie={() => { }}
                movieClassification={movie?.classification}
                setHover={() => {}}
                onHover={false}
              />
            )
          })}
        </section>
      </div>
    </BaseMoviesContainer>
  )
}

export default MovieListPage