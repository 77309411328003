import ArtAndEnternainmentImg1 from 'assets/images/categories/artsandentertainment/image (1).png'
import ArtAndEnternainmentImg2 from 'assets/images/categories/artsandentertainment/image (2).png'
import ArtAndEnternainmentImg3 from 'assets/images/categories/artsandentertainment/image (3).png'
import ArtAndEnternainmentImg4 from 'assets/images/categories/artsandentertainment/image (4).png'
import ArtAndEnternainmentImg5 from 'assets/images/categories/artsandentertainment/image (5).png'
import ArtAndEnternainmentImg6 from 'assets/images/categories/artsandentertainment/image (6).png'
import ArtAndEnternainmentImg7 from 'assets/images/categories/artsandentertainment/image (7).png'

import EducationAndLearningImg1 from 'assets/images/categories/educationandlearning/education (1).png'
import EducationAndLearningImg2 from 'assets/images/categories/educationandlearning/education (2).png'
import EducationAndLearningImg3 from 'assets/images/categories/educationandlearning/education (3).png'
import EducationAndLearningImg4 from 'assets/images/categories/educationandlearning/education (4).png'
import EducationAndLearningImg5 from 'assets/images/categories/educationandlearning/education (5).png'

import NewsPoliticsAndSocietyImg1 from 'assets/images/categories/newspoliticssociety/news (1).png'
import NewsPoliticsAndSocietyImg2 from 'assets/images/categories/newspoliticssociety/news (2).png'
import NewsPoliticsAndSocietyImg3 from 'assets/images/categories/newspoliticssociety/news (3).png'
import NewsPoliticsAndSocietyImg4 from 'assets/images/categories/newspoliticssociety/news (4).png'

import HealthWellnessAndLifestyleImg1 from 'assets/images/categories/healthwellnesslifestyle/health (1).png'
import HealthWellnessAndLifestyleImg2 from 'assets/images/categories/healthwellnesslifestyle/health (2).png'
import HealthWellnessAndLifestyleImg3 from 'assets/images/categories/healthwellnesslifestyle/health (3).png'
import HealthWellnessAndLifestyleImg4 from 'assets/images/categories/healthwellnesslifestyle/health (4).png'
import HealthWellnessAndLifestyleImg5 from 'assets/images/categories/healthwellnesslifestyle/health (5).png'
import HealthWellnessAndLifestyleImg6 from 'assets/images/categories/healthwellnesslifestyle/health (6).png'
import HealthWellnessAndLifestyleImg7 from 'assets/images/categories/healthwellnesslifestyle/health (7).png'

import BusinessImg1 from 'assets/images/categories/businessfinancetechnology/business (1).png'
import BusinessImg2 from 'assets/images/categories/businessfinancetechnology/business (2).png'
import BusinessImg3 from 'assets/images/categories/businessfinancetechnology/business (3).png'
import BusinessImg4 from 'assets/images/categories/businessfinancetechnology/business (4).png'
import BusinessImg5 from 'assets/images/categories/businessfinancetechnology/business (5).png'

import selfimprovement1 from 'assets/images/categories/selfimprovement/self (1).png'
import selfimprovement2 from 'assets/images/categories/selfimprovement/self (2).png'
import selfimprovement3 from 'assets/images/categories/selfimprovement/self (3).png'
import selfimprovement4 from 'assets/images/categories/selfimprovement/self (4).png'
import selfimprovement5 from 'assets/images/categories/selfimprovement/self (5).png'
import selfimprovement6 from 'assets/images/categories/selfimprovement/self (6).png'
export interface ICategory {
    category: string
    items: {
        name: string
        img: string
    }[]
}
export const categoriesMockedData: ICategory[] = [
    {
        "category": "Arts and Entertainment",
        "items": [
            { "name": "Film and TV", "img": ArtAndEnternainmentImg1 },
            { "name": "Literature", "img": ArtAndEnternainmentImg2 },
            { "name": "Visual Arts", "img": ArtAndEnternainmentImg3 },
            { "name": "Performing Arts", "img": ArtAndEnternainmentImg4 },
            { "name": "Music", "img": ArtAndEnternainmentImg5 },
            { "name": "Pop Culture", "img": ArtAndEnternainmentImg6 },
            { "name": "Comedy", "img": ArtAndEnternainmentImg7 }
        ]
    },
    {
        "category": "Education and Learning",
        "items": [
            { "name": "Science", "img": EducationAndLearningImg1 },
            { "name": "History", "img": EducationAndLearningImg2 },
            { "name": "Language and Learning", "img": EducationAndLearningImg3 },
            { "name": "Educational Resources", "img": EducationAndLearningImg4 },
            { "name": "Technology", "img": EducationAndLearningImg5 }
        ]
    },
    {
        "category": "News, Politics, and Society",
        "items": [
            { "name": "Current Events", "img": NewsPoliticsAndSocietyImg1 },
            { "name": "Political Analysis", "img": NewsPoliticsAndSocietyImg2 },
            { "name": "Social Issues", "img": NewsPoliticsAndSocietyImg3 },
            { "name": "True Crime", "img": NewsPoliticsAndSocietyImg4 }
        ]
    },
    {
        "category": "Health, Wellness, and Lifestyle",
        "items": [
            { "name": "Fitness", "img": HealthWellnessAndLifestyleImg1 },
            { "name": "Mental Health", "img": HealthWellnessAndLifestyleImg2 },
            { "name": "Nutrition", "img": HealthWellnessAndLifestyleImg3 },
            { "name": "Medical Information", "img": HealthWellnessAndLifestyleImg4 },
            { "name": "Relationships", "img": HealthWellnessAndLifestyleImg5 },
            { "name": "Travel", "img": HealthWellnessAndLifestyleImg6 },
            { "name": "Cooking", "img": HealthWellnessAndLifestyleImg7 }
        ]
    },
    {
        "category": "Business, Finance, and Technology",
        "items": [
            { "name": "Entrepreneurship", "img": BusinessImg1 },
            { "name": "Business Strategy", "img": BusinessImg2 },
            { "name": "Financial Advice", "img": BusinessImg3 },
            { "name": "Investing", "img": BusinessImg4 },
            { "name": "Software Development", "img": BusinessImg5 }
        ]
    },
    {
        "category": "Self-Improvement",
        "items": [
            { "name": "Personal Development", "img": selfimprovement1 },
            { "name": "Motivation and Inspiration", "img": selfimprovement2 },
            { "name": "Mindfulness and Meditation", "img": selfimprovement3 },
            { "name": "Positive Psychology", "img": selfimprovement4 },
            { "name": "Career Growth", "img": selfimprovement5 },
            { "name": "Creativity and Innovation", "img": selfimprovement6 }
        ]
    }
]

