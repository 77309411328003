import { MoviesFilterItemType } from "@utils/moviesFilterList";
import MoviesFilterItem from "./MoviesFilterItem";

interface MoviesFilterListProps {
    list: MoviesFilterItemType[]
    handleSelectFilter: (filter: string) => void
    selectedFilter: string
    handleSelectProvider: (provider: string) => void
    selectedProviders: string[]
}
const MoviesFilterList = ({
    selectedProviders,
    handleSelectProvider,
    selectedFilter,
    list,
    handleSelectFilter
}: MoviesFilterListProps) => {
    return (
        <div className="movies_filter_container">
            {list.map((item) => {
                return (
                    <MoviesFilterItem
                        selectedProvider={selectedProviders}
                        handleSelectProvider={handleSelectProvider}
                        handleSelectFilter={handleSelectFilter}
                        id={item.id}
                        filterName={item.label}
                        isSelected={selectedFilter === item.label} />
                )
            })}
        </div>
    )
}

export default MoviesFilterList