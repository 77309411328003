import { useSelector } from 'react-redux'
import { uiSelector } from 'store/selectors'
import { changeColorMode } from '@utils/colors'
import { useLocation, useNavigate } from 'react-router-dom'
import './MoviesHeaderMenu.scss'

const MoviesHeaderMenu = ({ hasBackgroundImageUrl = false }: { hasBackgroundImageUrl?: boolean }) => {

    const isDarkMode = useSelector(uiSelector).darkMode
    const navigation = useNavigate()
    const { pathname } = useLocation()
    const handleNavigation = (path: string) => {
        navigation(path)
    }


    const headerMenuList = [
        {
            title: 'Home Page',
            path: '/merge-movies'
        },
        {
            title: 'My list',
            path: '/merge-movies/mylist'
        },
        {
            title: 'Movies',
            path: '/merge-movies/movies'
        },
        {
            title: 'TV Shows',
            path: '/merge-movies/tv-shows'
        }
    ]


    return (
        <div className='movies_header_menus'>
            {headerMenuList.map((item) =>
                <h3 onClick={() => handleNavigation(item.path)}
                    style={{
                        color: pathname === item.path ? "#6B37FE" : changeColorMode(!hasBackgroundImageUrl ? isDarkMode : !isDarkMode),
                        fontWeight: pathname === item.path ? 'bold' : 'normal'
                    }}>{item.title}</h3>
            )}
        </div>
    )
}

export default MoviesHeaderMenu