import { TidalTokenKey } from "../../constants";
import TidalResponse from "./tidal-response";


const TidalBase = `https://openapi.tidal.com`

class TidalApi {
    static async get(url: string) {
        return new Promise((resolve, reject) => {

            const BearerToken = localStorage.getItem(TidalTokenKey)
            
            try {
                fetch(`${TidalBase}${url}`, {                   
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/vnd.tidal.v1+json',
                        'Accept': 'application/vnd.tidal.v1+json',
                        'Authorization': `Bearer ${BearerToken}` 
                    }
                }).then((response) => {
                    return response.json()
                }).then((responose) => {
                    resolve(responose)
                }).catch((error) => {
                    resolve(TidalResponse)
                })
            } catch (error) {
                reject(error)
            }
        })
    }   
}

export default TidalApi