import { ArrowDownIcon } from '@components/IconComponents/IconComponents'
import { useSelector } from 'react-redux'
import { uiSelector } from 'store/selectors'
import { changeColorMode } from '@utils/colors'
import { useNavigate } from 'react-router'
import './BackButton.scss'

const BackButton = () => {
    const isDarkMode = useSelector(uiSelector).darkMode
    const navigation = useNavigate()

    const handleGoBack = () => {
        navigation(-1)
    }

    return (
        <button className='back-button' onClick={handleGoBack} style={{
            border: `1px solid ${changeColorMode(!isDarkMode)}`,
        }}>
            <ArrowDownIcon strokeColor={changeColorMode(!isDarkMode)} className='arrow-back-button' />
            <span style={{ color: changeColorMode(!isDarkMode) }}>Back</span>
        </button>
    )
}

export default BackButton