import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UPDATE_APPLE_MUSIC } from '../constants';
import { ISong } from 'interfaces/models/model-interfaces';

interface SelectedMusicProps {
    id: string;
    songName: string;
    albumName: string;
    // length: string;
    platform: string;
    song: ISong;
    img: string;
    artistName: string;
    playState: number;
    type: string
    songInfo?: any
}

interface MusicState {
    isPlaying: boolean;
    selectedMusic: SelectedMusicProps | null;
    queue: SelectedMusicProps[];
    currentIndex: number;
   
}

const initialState: MusicState = {
    isPlaying: false,
    selectedMusic: null,
    queue: [],
    currentIndex: -1
};

const musicSlice = createSlice({
    name: 'music',
    initialState,
    reducers: {
        playMusic(state) {
            state.isPlaying = true;
        },
        stopMusic(state) {
            state.isPlaying = false;
        },
        selectMusic(state, action: PayloadAction<SelectedMusicProps>) {
            const musicFromPayload = action.payload;
            // Check if the current selectedMusic is the same as the new selected music
            if (state.selectedMusic) {
                // Increment playState if it's the same song
                state.selectedMusic = {
                    ...musicFromPayload,
                    playState: state.selectedMusic.playState += 1       
                };                
            } else {
                // Set selectedMusic to the new music
                // Initialize playState to 1 since it's a new selection
                state.selectedMusic = {
                    ...musicFromPayload,
                    playState: 1
                };
            }
        },
        updateMusic(state, action: PayloadAction<SelectedMusicProps>) {
            state.selectedMusic = {
                ...action.payload,
                type: UPDATE_APPLE_MUSIC,
                //@ts-ignore
                playState: state.selectedMusic.playState += 1  
            }
        },
        queueMusic(state, action: PayloadAction<SelectedMusicProps[]>) {
            state.queue = action.payload;
            state.currentIndex = 0;
        },
        forwardMusic(state) {
            if (state.currentIndex < state.queue.length - 1) {
                state.currentIndex++;
                state.selectedMusic = state.queue[state.currentIndex];
            }
        },
        previousMusic(state) {
            if (state.currentIndex > 0) {
                state.currentIndex--;
                state.selectedMusic = state.queue[state.currentIndex];
            }
        },
        addToQueue(state, action: PayloadAction<SelectedMusicProps>) {
            state.queue = [...state.queue, action.payload];
        },
    },
});

export const {
    playMusic,
    stopMusic,
    selectMusic,
    updateMusic,
    queueMusic,
    forwardMusic,
    previousMusic,
    addToQueue,
} = musicSlice.actions;

export default musicSlice.reducer;
