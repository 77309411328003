import React from 'react';
import './SubscriptionItem.scss'
import RemoveIcon from '../../assets/icon/trash.svg'
import EditIcon from '../../assets/icon/pencil.svg'
import { useWindowDimensions } from '@hooks/useWindowDimension';
import { useSelector } from 'react-redux';
import { uiSelector } from 'store/selectors';
import { changeColorMode } from '@utils/colors';
import { DeleteIcon } from '@components/IconComponents/IconComponents';
import NetflixLogo from "assets/icon/netflix_icon.svg";
import { MusicPlatform, PlatformModel } from 'interfaces/models/model-interfaces';

interface SubscriptionItemProps {
    StreamLogo: string;
    streamName: string;
    userName: string;
    userEmail: string;
    subscriptionPrice: string | number;
    userAvatar: string;
    onRemoveSubscription: (p: PlatformModel, s: string) => void;
    onEditSubscription: () => void;
    streamlogo?: any
    platform: PlatformModel
}

const SubscriptionItem: React.FC<SubscriptionItemProps> = ({
    onEditSubscription,
    onRemoveSubscription,
    StreamLogo,
    streamName,
    subscriptionPrice,
    userAvatar,
    userEmail,
    userName,
    streamlogo,
    platform
}) => {
    const isDarkMode = useSelector(uiSelector).darkMode;
    const { isMobile } = useWindowDimensions()

    const isFreePlatform = () => platform.platform === MusicPlatform.TidalMusic || platform.platform === MusicPlatform.YoutubeMusic

    return (
        <div className='subscription__item__container' style={{ backgroundColor: isDarkMode ? '#1F2937' : '#FFF' }}>
            <div className='subscription__item__container__top' style={{ backgroundColor: isDarkMode ? '#1F2937' : '#FFF' }}>
                {<img src={streamlogo} alt="stream-logo" style={{
                    height: 50, width: 50
                }} />}
                <h1 style={{ color: changeColorMode(isDarkMode), backgroundColor: changeColorMode(!isDarkMode) }}>{streamName}</h1>
            </div>
            <hr color={"#D1D5DB"} />
            <div style={{ display: 'flex', flexDirection: 'column', gap: 32 }}>

                {!isFreePlatform() && <div className='subscription__item__container__content' style={{ color: changeColorMode(isDarkMode), backgroundColor: changeColorMode(!isDarkMode) }}>
                    <img src={userAvatar} alt="user-profile-img" className='user_info__avatar' />
                    {!isFreePlatform() && <div className='user_info'>
                        <span className='user_info__username' style={{ color: changeColorMode(isDarkMode) }}>{userName}</span>
                        <span className='user_info__useremail' style={{ color: changeColorMode(isDarkMode) }}>{userEmail}</span>
                        {/* {isMobile && <span className='user_info__user__subscriptionprice'>${subscriptionPrice}/month</span>} */}

                    </div>}
                    {/* {isMobile && <div style={{ position: 'absolute', right: 20, bottom: 30 }}>
                        <img src={EditIcon} alt="edit-subscription-icon" onClick={onEditSubscription} />
                    </div>} */}
                    {<div className='right__content'>
                        {/* <span>${subscriptionPrice}/month</span> */}
                        {!isFreePlatform() && <DeleteIcon style={{ cursor: 'pointer' }} strokeColor={changeColorMode(isDarkMode)} alt="remove-subscription-icon" onClick={() => onRemoveSubscription(platform, 'remove')} />}
                        {/* <img src={EditIcon} alt="edit-subscription-icon" onClick={onEditSubscription} /> */}
                    </div>}
                </div>
                }
            </div>
        </div>
    )
}

export default SubscriptionItem;