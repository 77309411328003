import { ISong, MusicPlatform } from "interfaces/models/model-interfaces"
import YoutubeIcon from "../../assets/icon/streaming_youtubemusic.svg";
import { IMergeMusicProviders } from "../../interfaces/providers/provider-interface";
import { TrackLimit } from "../../constants";
const YoutubeBackendURL = process.env.REACT_APP_BACKEND_URL || `http://localhost:8080`
const YoutubeMusicURL = `https://music.youtube.com/watch?v=`

class YoutubeMusicProvider implements IMergeMusicProviders{
    constructor() {
        this.initialize()
    }

    public initialize(): void {
        
    }

    public async isSubscribed(): Promise<boolean> {
        return true
    }

    public async searchAlbum(album: string): Promise<any> {
        
    }

    public async getEpisode(show: string): Promise<any> {
        return []
    }

    public async getPodcastEpisodes(id: string): Promise<any> {
        return []
    }

    public async searchUserFavoriteArtists(): Promise<any> {
        return []
    }

    public async searchArtist(artist: string): Promise<any> {
        
    }

    public async searchPodcasts(podcasts: string[]): Promise<any> {
        return []
    }


    public async searchUserPodcasts(): Promise<ISong[]> {
       return []
    }

    public async searchUserFavoriteAlbums(): Promise<any[]> {
        return []
    }

    public async searchArtists(artistsArray: string[], _type: string): Promise<any> {
        try {   
            const apiCall = artistsArray.map((artist) => {
                const request = encodeURI(`${YoutubeBackendURL}/api/youtube/search?searchTerm=${artist}`)
                return fetch(request)
            })        
            const responses: any[] = await Promise.all(apiCall)
            const results: any[] = await Promise.all(responses.map((response) => response.json()))
            const artists: any[] = results.map((result) => result.searchMusics)
            const resultFlat = artists.flat().filter((item: any) => item !== undefined)
            const parsedResult = this.parseResponse(resultFlat)
            return parsedResult

        } catch(error) {
            console.error(error)
            return []
        }
    }

    public async searchSong(song: string): Promise<any> {
        
    }

    public async searchUserFavorites(): Promise<any> {
        return []
    }

    public async search(searchTerm: string): Promise<any> {
        const request = encodeURI(`${YoutubeBackendURL}/api/youtube/search?searchTerm=${searchTerm}`)
        const response = await fetch(request)
        const { searchMusics } = await response.json()
        const normalizedResults = this.parseResponse(searchMusics)
        return normalizedResults
    }

    public async getTrack(track: string): Promise<any> {
        const url = `${YoutubeMusicURL}${track}`
        window.open(url, '_blank');        
    }

    public async login(): Promise<void> {
        Promise.resolve()
    }

    public async logout(): Promise<void> {
        Promise.resolve()
    }

    public async searchUserPlaylists(): Promise<any[]> {
        return []
    }

    private parseResponse(response: any): any {
       
        const result: ISong[] = response.map((track: any) => {
            const { youtubeId, album, title, artists, duration, thumbnailUrl } = track

            const songData: ISong = {
                id: youtubeId,
                songName: title,
                img: thumbnailUrl,
                albumName: album,
                platform: MusicPlatform.YoutubeMusic,
                artistName: artists[0].name,
                url: 'none',
                icon: YoutubeIcon,
                durationInMillis: duration.totalSeconds * 1000
            }

            return songData
        })

        return result.filter((track) => track !== undefined).slice(0, TrackLimit)
    }
}

export default YoutubeMusicProvider