import ImageSong from '../assets/images/song_image_example.png'
import SpotifyImageIcon from '../assets/icon/spotify_green.svg'
import Artist1 from 'assets/images/artist_1.svg'
import Artist2 from 'assets/images/artist_2.svg'
import Artist3 from 'assets/images/artist_3.svg'
import Artist4 from 'assets/images/artist_4.svg'

export interface IsongArtist {
    id: number;
    songArtist: string;
    img: string;
}

export type musicsMockedDataType = typeof musicsMockedData[0]
export const musicsMockedData = [
    {
        id: 1,
        songImage: ImageSong,
        songName: 'Melodic Winds',
        songArtist: 'The Harmony Group',
        albumName: "Nature's Echo",
        length: '03:48',
        platform: SpotifyImageIcon,
        song: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3',
    },
    {
        id: 2,
        songImage: ImageSong,
        songName: 'Rising Sun',
        songArtist: 'Dawn Chasers',
        albumName: 'Morning Glory',
        length: '04:12',
        platform: SpotifyImageIcon,
        song: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3',

    },
    {
        id: 3,
        songImage: ImageSong,
        songName: 'Lost Memories',
        songArtist: 'Twilight Serenade',
        albumName: 'Dusk Dreams',
        length: '05:03',
        platform: SpotifyImageIcon,
        song: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-3.mp3',
    },
    {
        id: 4,
        songImage: ImageSong,
        songName: 'Whispering Pines',
        songArtist: 'Forest Wanderers',
        albumName: 'Woodland Whispers',
        length: '03:29',
        platform: SpotifyImageIcon,
    },
    {
        id: 5,
        songImage: ImageSong,
        songName: 'Celestial Tides',
        songArtist: 'Lunar Lullaby',
        albumName: 'Starry Serenades',
        length: '04:56',
        platform: SpotifyImageIcon,
    },
    {
        id: 6,
        songImage: ImageSong,
        songName: "Ocean's Embrace",
        songArtist: 'Sirens of the Deep',
        albumName: 'Tidal Waves',
        length: '04:20',
        platform: SpotifyImageIcon,
    },
    {
        id: 7,
        songImage: ImageSong,
        songName: 'Desert Mirage',
        songArtist: 'Sands of Time',
        albumName: 'Golden Horizon',
        length: '03:50',
        platform: SpotifyImageIcon,
    },
    {
        id: 8,
        songImage: ImageSong,
        songName: "Mountain's Majesty",
        songArtist: 'Peak Performers',
        albumName: 'Highland Echoes',
        length: '05:10',
        platform: SpotifyImageIcon,
    },
    {
        id: 9,
        songImage: ImageSong,
        songName: 'Urban Pulse',
        songArtist: 'City Skylines',
        albumName: 'Neon Nights',
        length: '03:35',
        platform: SpotifyImageIcon,
    },
    {
        id: 10,
        songImage: ImageSong,
        songName: 'Frozen Echo',
        songArtist: 'Icy Intonations',
        albumName: 'Chilled Chords',
        length: '04:45',
        platform: SpotifyImageIcon,
    },
    {
        id: 11,
        songImage: ImageSong,
        songName: 'Eternal Harmony',
        songArtist: 'The Harmony Group',
        albumName: "Echoes of Eternity",
        length: '03:52',
        platform: SpotifyImageIcon,
    },
    {
        id: 12,
        songImage: ImageSong,
        songName: 'Skylight Dreams',
        songArtist: 'City Skylines',
        albumName: 'Dawn in the City',
        length: '04:15',
        platform: SpotifyImageIcon,
    },
    {
        id: 13,
        songImage: ImageSong,
        songName: 'Crystal Caves',
        songArtist: 'Icy Intonations',
        albumName: 'Frozen Fantasies',
        length: '04:01',
        platform: SpotifyImageIcon,
    },
    {
        id: 14,
        songImage: ImageSong,
        songName: 'Summit Serenade',
        songArtist: 'Peak Performers',
        albumName: 'Echoes of Altitude',
        length: '05:17',
        platform: SpotifyImageIcon,
    }
]

export const recentSearchesMockedData = [
    'AC/DC',
    'Metallica',
    'Queen',
    'Pink Floyd',
    'Led Zeppelin',
]

export const artistsMockedData: IsongArtist[] = [
    {
        id: 1,
        songArtist: 'The Harmony Group',
        img: Artist1,
    },
    {
        id: 2,
        songArtist: 'City Skylines',
        img: Artist2,
    },
    {
        id: 3,
        songArtist: 'Icy Intonations',
        img: Artist3,
    },
    {
        id: 4,
        songArtist: 'Peak Performers',
        img: Artist4,
    },
    {
        id: 5,
        songArtist: 'Dawn Chasers',
        img: Artist1,
    },
    {
        id: 6,
        songArtist: 'Forest Wanderers',
        img: Artist2,
    },
    {
        id: 7,
        songArtist: 'Lunar Lullaby',
        img: Artist3,
    },
    {
        id: 8,
        songArtist: 'Sands of Time',
        img: Artist4,
    }

]

