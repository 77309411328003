
import MergeMusicProviders from "../providers/MergeMusicProviders"
import { IMergeMusicProviders } from "../interfaces/providers/provider-interface"
import { IPodcast, ISong } from "../interfaces/models/model-interfaces"
import ModelLocator from "../model/model-locator"
import NotFoundImage from '../assets/images/404.png'
import NotFoundImageSmall from '../assets/images/404_small.png'
import Api from "services/api"
import { getUserEmail } from "@utils/helpers"

const NotFound: any[] = [{
    img: NotFoundImage,
    url: '',
    albumId: '',
    albumName: '',
    songName: 'Please Sign in to Apple or Spotify',
    platform: '',
    id: '',
    artistName: '',
    icon: NotFoundImageSmall,
    durationInMillis: 0,
    tracks: []
}]

class ApiBridge {

    public static async search(searchTerm: string, types?: string[]): Promise<any> {
        const subscribedPlatforms: IMergeMusicProviders[] = await MergeMusicProviders.getSubsctibedPlatforms()

        return new Promise(async (resolve, reject) => {

            const apiCalls = subscribedPlatforms.map((platform: IMergeMusicProviders) => platform.search(searchTerm, types))
            try {
                const result = await Promise.all(apiCalls)
                const resultFlat = result.flat().filter((item) => item !== undefined)
                resolve(CheckAndReturnArray(resultFlat))    
            } catch (error) {
                return reject(error);
            }
        })
    }

    public static async searchUserFavorites(returnTracks: boolean = false): Promise<any> {
        const subscribedPlatforms: IMergeMusicProviders[] = await MergeMusicProviders.getSubsctibedPlatforms()

        return new Promise(async (resolve, reject) => {
            const email = getUserEmail()
            const apiCalls = subscribedPlatforms.map((platform: IMergeMusicProviders) => platform.searchUserFavorites())
            const userLikesFromMerge = Api.get({ email }, '/user/alllikes')
            const mergedCalls = [ userLikesFromMerge, ...apiCalls ]

            try {
                const result = await Promise.all(mergedCalls);                 
                const resultFlat: ISong[] = result.flat().filter((item) => item !== undefined)
                const resultSet = removeDuplicateIds(resultFlat)

                if (returnTracks) return resolve(CheckAndReturnArray(resultSet))

                const artistNames = resultFlat.map((result) => result.artistName)
                const artistSet = new Set(artistNames)
                const shuffledArray = shuffleArray(Array.from(artistSet))
                
                resolve(shuffledArray[0])
            } catch (error) {
                console.error(error)
                return reject(error);
            }
        })
    }

    public static async searchUserFavoriteAlbums(returnTracks: boolean = false): Promise<any> {
        const subscribedPlatforms: IMergeMusicProviders[] = await MergeMusicProviders.getSubsctibedPlatforms()

        return new Promise(async (resolve, reject) => {
            const apiCalls = subscribedPlatforms.map((platform: IMergeMusicProviders) => platform.searchUserFavoriteAlbums())

            try {
                const result = await Promise.all(apiCalls);
                const resultFlat: ISong[] = result.flat().filter((item) => item !== undefined)

                if (returnTracks) return resolve(CheckAndReturnArray(resultFlat))

                const artistNames = resultFlat.map((result) => result.artistName)
                const artistSet = new Set(artistNames)
                const shuffledArray = shuffleArray(Array.from(artistSet))
                
                resolve(shuffledArray[0])
            } catch (error) {
                console.error(error)
                return reject(error);
            }
        })
    }

    public static async searchUserFavoriteArtists(returnTracks: boolean = false): Promise<any> {
        const subscribedPlatforms: IMergeMusicProviders[] = await MergeMusicProviders.getSubsctibedPlatforms()

        return new Promise(async (resolve, reject) => {
            const apiCalls = subscribedPlatforms.map((platform: IMergeMusicProviders) => platform.searchUserFavoriteArtists())

            try {
                const result = await Promise.all(apiCalls);
                const resultFlat: ISong[] = result.flat().filter((item) => item !== undefined)

                if (returnTracks) return resolve(CheckAndReturnArray(resultFlat))

                const artistNames = resultFlat.map((result) => result.artistName)
                const artistSet = new Set(artistNames)
                const shuffledArray = shuffleArray(Array.from(artistSet))

                resolve(shuffledArray[0])
            } catch (error) {
                console.error(error)
                reject(error) 
            }
        })
    }

    public static async searchTopArtists(artists: string[], type: string): Promise<ISong[]> {
        const subscribedPlatforms: IMergeMusicProviders[] = await MergeMusicProviders.getSubsctibedPlatforms()

        return new Promise(async (resolve, reject) => {

            const apiCalls = subscribedPlatforms.map((platform: IMergeMusicProviders) => platform.searchArtists(artists, type))

            try {
                const result = await Promise.all(apiCalls);
                const resultFlat: ISong[] = result.flat().filter((item) => item !== undefined)
                const saveArray: string = type === 'artists' ? 'topArtists' : "topAlbums"
                const model: ModelLocator = ModelLocator.getInstance()
                // @ts-ignore
                model[saveArray] = resultFlat
                resolve(CheckAndReturnArray(resultFlat))
            } catch (error) {
                console.error(error)
                return reject(error);
            }
        })
    }

    public static async searchPlaylists(): Promise<ISong[]> {
        const subscribedPlatforms: IMergeMusicProviders[] = await MergeMusicProviders.getSubsctibedPlatforms()

        return new Promise(async (resolve, reject) => {

            const apiCalls = subscribedPlatforms.map((platform: IMergeMusicProviders) => platform.searchUserPlaylists())
            try {
                const result = await Promise.all(apiCalls);
                const resultFlat: ISong[] = result.flat().filter((item) => item !== undefined)
                resolve(CheckAndReturnArray(resultFlat))
            } catch (error) {
                return reject(error);
            }
        })
    }

    public static async searchPodcasts(podcasts: string[] ): Promise<ISong[]> {
        const subscribedPlatforms: IMergeMusicProviders[] = await MergeMusicProviders.getSubsctibedPlatforms()

        return new Promise(async (resolve, reject) => {

            const apiCalls = subscribedPlatforms.map((platform: IMergeMusicProviders) => platform.searchPodcasts(podcasts))
            try {
                const result = await Promise.all(apiCalls);
                const resultFlat: ISong[] = result.flat().filter((item) => item !== undefined)
                resolve(CheckAndReturnArray(resultFlat))
            } catch (error) {
                return reject(error);
            }
        })
    }

    public static async searchUserPodcasts(): Promise<ISong[]> {
        const subscribedPlatforms: IMergeMusicProviders[] = await MergeMusicProviders.getSubsctibedPlatforms()

        return new Promise(async (resolve, reject) => {

            const apiCalls = subscribedPlatforms.map((platform: IMergeMusicProviders) => platform.searchUserPodcasts())
            try {
                const result = await Promise.all(apiCalls);
                const resultFlat: ISong[] = result.flat().filter((item) => item !== undefined)
                resolve(CheckAndReturnArray(resultFlat))
            } catch (error) {
                return reject(error);
            }
        })
    }

    public static async getPodcastById(podcast: IPodcast): Promise<any> {
        try {
            const platform = MergeMusicProviders.getProvider(podcast.platform)
            const result = await platform.getPodcastEpisodes(podcast.id)
            const flatResult = result.flat()
            return flatResult

        } catch (error) {
            return []
        }
    }
}

const CheckAndReturnArray = (arr: any[]): any[] =>  {
    return arr.length > 0 ? arr : NotFound
}


const shuffleArray = (array: string[]) => {
    for (let i = array.length - 1; i > 0; i--) {
        // Generate a random index lower than the current element
        const j = Math.floor(Math.random() * (i + 1));
        // Swap elements at indices i and j
        [array[i], array[j]] = [array[j], array[i]];
    }

    return array;
}

function removeDuplicateIds(songs: ISong[]) {
    const uniqueIds = new Set();
    const uniqueSongs = [];

    for (const song of songs) {
        if (!uniqueIds.has(song.id)) {
            uniqueIds.add(song.id);
            uniqueSongs.push(song);
        }
    }

    return uniqueSongs;
}

export default ApiBridge