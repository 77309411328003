import React from 'react';
import './Button.scss'

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    buttonText?: string;
    onClick?: () => void;
    additionalClassName?: string;
}

const Button: React.FC<ButtonProps> = ({ buttonText, children, onClick, additionalClassName, ...rest }) => {
    return (
        <>
            <button {...rest} className={`button ${additionalClassName}`} onClick={onClick}>
                {children || buttonText}
            </button>
        </>
    );
};

export default Button;