import { AiOutlineHeart, AiOutlinePlus } from 'react-icons/ai'
import ImageMockSong from 'assets/images/mock_img_song.svg'
import { musicSelector, uiSelector } from 'store/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { addToQueue } from 'store/musicSlice'
import { changeColorMode } from '@utils/colors'
import MergeMusicProviders from 'providers/MergeMusicProviders'

const PlayerDetails = ({ musicDetails }: any) => {
    const isDarkMode = useSelector(uiSelector).darkMode
    

    //@ts-ignore
    const { songName, artistName } = musicDetails

    return (
        <div className='player_info'>
            <img src={musicDetails.img || ImageMockSong } alt="" style={{ cursor: 'pointer', width: "50px" }} />
            <div className='player_info_name_artist'>
                <span className='player_info_name' style={{ color: changeColorMode(isDarkMode) }}>{songName}</span>
                <small className='player_info_artist' style={{ color: changeColorMode(isDarkMode) }}>{artistName}</small>
            </div>

            {/* <AiOutlineHeart size={24} color={changeColorMode(isDarkMode)} className='favorite-button' style={{ cursor: 'pointer' }} />
            <AiOutlinePlus size={24} color={changeColorMode(isDarkMode)} className='more-button' style={{ cursor: 'pointer' }} onClick={handleAddToPlayQueue} /> */}
        </div >
    )
}

export default PlayerDetails