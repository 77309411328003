import EpisodeCard from '@components/EpisodeCard/EpisodeCard'
import { changeColorMode } from '@utils/colors'
import { useSelector } from 'react-redux'
import { uiSelector } from 'store/selectors'

interface EpisodesCardProps {
    showDescription?: boolean
    tableItems: any[]
    mediaType?: any
}

const EpisodesCard = ({ tableItems, showDescription, mediaType }: EpisodesCardProps) => {
    const isDarkMode = useSelector(uiSelector).darkMode 

    return (
        <div style={{ backgroundColor: changeColorMode(!isDarkMode, '#f9fafb') }}>
            {tableItems?.map((item, index: number) => <EpisodeCard key={index}
                date={item.artistName}
                description='A three-part series from This American Life producer Nancy Updike. When Rachel McKibbens’s father and brother died suddenly last fall, two weeks apart, from Covid, she’d had no idea her father was sick, and no idea her brother was dying. They were unvaccinated, but the story of what happened started long before that. All three episodes of "We Were Three," a new show from Serial Productions and The New York Tim'
                episodeName={item.songName as string}
                imagePath={item.img}
                timeLength={item.durationInMillis}
                contentProvider={item.icon}
                showDescription={showDescription}
                index={index}
                metadata={item}
                mediaType={mediaType}
            />)}
        </div>
    )
}

export default EpisodesCard