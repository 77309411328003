import { profileDropdownList } from '@utils/navigationsLinks/profileDropdownList';
import React, { useEffect, useRef } from 'react';
import './ProfileDropdown.scss'
import ArrowRight from '../../assets/icon/arrow-right.svg'
import { useAuth0 } from '@auth0/auth0-react';
import Avatar from '@components/Avatar/Avatar';
import SideBarItem from '@components/Sidebar/SideBarItem';
import Switch from 'react-switch';
import { useDispatch, useSelector } from 'react-redux';
import { toggleDarkMode } from 'store/uiSlice';
import { uiSelector } from 'store/selectors';
import { changeColorMode } from '@utils/colors';
import ReactDOM from 'react-dom';
import AuthenticationBridge from 'authentication/music/authentication-bridge';

interface ProfileComponentProps {
    onClick?: () => void;
    profileDropdownVisible?: boolean;
}
const ProfileDropdown: React.FC<ProfileComponentProps> = ({ onClick, profileDropdownVisible }) => {
    const { darkMode: isDarkMode } = useSelector(uiSelector)
    const dispatch = useDispatch()
    const [switchDarkMode, setSwitchDarkMode] = React.useState(false);

    const handleToggleDarkMode = () => {
        setSwitchDarkMode(current => !current);
        dispatch(toggleDarkMode())
    }

    const { logout, user } = useAuth0()
    const dropdownRef = useRef<HTMLDivElement>(null);

    const logoutHandler = async () => {      
        await AuthenticationBridge.logoutAll()
        await logout({ logoutParams: { returnTo: window.location.origin } })
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if ((dropdownRef.current && !dropdownRef.current.contains(event.target as Node) && profileDropdownVisible)) {
                onClick && onClick();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef, profileDropdownVisible]);

    useEffect(() => {
        setSwitchDarkMode(isDarkMode)
    }, [])


    return ReactDOM.createPortal(
        <>
            <div className={`user__sidebar__outside ${profileDropdownVisible ? 'visible' : ''}`} onClick={onClick} />
            <div ref={dropdownRef}
                className={`profile-dropdown ${profileDropdownVisible ? 'profile-dropdown--visible' : ''}`}
                style={{ background: isDarkMode ? 'rgb(31, 41, 55)' : '#FFF' }}
            >
                <div className='profile__content'>
                    <Avatar showName={true} />
                </div>
                <div className='profile__dropdown__list'>
                    {profileDropdownList.map((item, index) => (
                        <SideBarItem
                            isRouteSelected={false}
                            key={index}
                            icon={<item.icon strokeColor={changeColorMode(isDarkMode)} />}
                            label={item.label}
                            link={item.link}
                        />
                    ))}
                </div>
                <label className='switch-dark-mode-container'>
                    <span style={{ color: !isDarkMode ? 'rgb(31, 41, 55)' : "#FFF" }}>Dark Mode</span>
                    <Switch className='switch-svg' onChange={handleToggleDarkMode} checked={switchDarkMode} />
                </label>
                <div className='profile-dropdown__bottom' style={{ cursor: "pointer" }} onClick={() => logoutHandler()}>
                    <span>Log out</span>
                    <img src={ArrowRight} alt="Icon for Log out" />
                </div>
            </div >
        </>
        , document.getElementById('portal-root') as Element)
}

export default ProfileDropdown;
