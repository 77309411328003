import SpotifyMusicProviders from "./SpotifyMusicProvider";
import { BAD_TOKEN_ERROR_REFRESHTOKEN, SpotifyTokenKey } from "../../constants";
import { delay } from "../../constants";
import { isTokenExpired } from "./spotify-utils";

const clientId = "ab321fcfb1d747aca65312ad1080a824"
const HTTP_ERROR_UNAUTHORIZIED = 401

class SpotifyClientApi {
    private static refreshTokenInProgress: Promise<any> | null = null;

    static async fetchWebApi(endpoint: string, retry: boolean = false): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {

                const BearerToken = await SpotifyMusicProviders.getToken()

                fetch(`https://api.spotify.com/v1${endpoint}`, {
                    headers: {
                        Authorization: `Bearer ${BearerToken}`,
                        "Content-Type": "application/x-www-form-urlencoded"
                    },
                    method: 'GET'
                }).then(((response) => {
                    response.json().then(async (result: any) => {
                        const { error } = result

                        if (error && (error.status === HTTP_ERROR_UNAUTHORIZIED || error.message.includes(HTTP_ERROR_UNAUTHORIZIED)) && !retry) {
                            handleRetryError(resolve, endpoint)
                            return
                        }

                        resolve(result)
                    }).catch(async (error: any) => {
                        if (error && error.status === HTTP_ERROR_UNAUTHORIZIED && !retry) {
                            handleRetryError(resolve, endpoint)
                            return
                        }
                    })

                })).catch(async (error) => {
                    console.error(error)

                    if (error && error.status === HTTP_ERROR_UNAUTHORIZIED && !retry) {
                        handleRetryError(resolve, endpoint)
                    }
                })

            } catch (error) {
                console.error(error)
                if (typeof error === "string" && error.includes(BAD_TOKEN_ERROR_REFRESHTOKEN)) {
                    // @ts-ignore
                    // window.location.reload(true)
                }
            }
        })
    }

    static async getTopArtists(type: string) {
        const result = await SpotifyClientApi.fetchWebApi(`/me/top/${type}?time_range=long_term`)
        return result
    }

    static async getPlaylists(user: string | undefined) {
        const result = await SpotifyClientApi.fetchWebApi(`/users/${user}/playlists`)
        return result

    }

    static async getPlaylist(playlistId: string) {
        const result = await SpotifyClientApi.fetchWebApi(`/playlists/${playlistId}`)
        return result
    }

    static async getProfile() {
        const result = await SpotifyClientApi.fetchWebApi('/me')
        return result
    }

    static async getAlbums() {
        const result = await SpotifyClientApi.fetchWebApi('/me/albums')
        return result
    }

    static async getArtists() {
        const result = await SpotifyClientApi.fetchWebApi('/me/artists')
        return result
    }

    static async searchPodcast(query: string): Promise<any> {
        const result = await SpotifyClientApi.fetchWebApi(`/search?=type=show&q=${query}`)
        return result
    }

    static async getPodcastWithId(id: string): Promise<any> {
        const result = await SpotifyClientApi.fetchWebApi(`/shows/${id}/episodes`)
        return result
    }

    static async getUserPodcasts(): Promise<any> {
        const result = await SpotifyClientApi.fetchWebApi('/me/shows')
        return result
    }

    static async getEpisode(id: string): Promise<any> {
        const result = await SpotifyClientApi.fetchWebApi(`/v1/episodes/${id}`)
        return result
    }

    static async refreshToken(): Promise<any> {
        // Check if a refresh token request is already in progress
        if (this.refreshTokenInProgress) {
            return this.refreshTokenInProgress;
        }

        this.refreshTokenInProgress = new Promise((resolve, reject) => {
            try {
                const tokens: any = JSON.parse(localStorage.getItem(SpotifyTokenKey) as string);
                const { refreshToken, refresh_token } = tokens;
                const tempRefreshToken = refresh_token || refreshToken;

                if (tempRefreshToken) {
                    const url = "https://accounts.spotify.com/api/token";

                    const payload = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        body: new URLSearchParams({
                            grant_type: 'refresh_token',
                            refresh_token: tempRefreshToken,
                            client_id: clientId
                        }),
                    };

                    fetch(url, payload).then((response: any) => {
                        response.json().then((jsonResponse: any) => {
                            const data = {
                                ...jsonResponse,
                                expires: Date.now() + jsonResponse?.expires_in * 1000 // Corrected `expire_in` to `expires_in`
                            };
                            // Clear the refresh token promise after success
                            this.refreshTokenInProgress = null;
                            resolve(data);
                        }).catch((error: any) => {
                            console.error(error);
                            // Clear the refresh token promise after failure
                            this.refreshTokenInProgress = null;
                            reject(error);
                        });
                    }).catch((error: any) => {
                        console.error(error);
                        // Clear the refresh token promise after failure
                        this.refreshTokenInProgress = null;
                        reject(error);
                    });
                }
            } catch (error) {
                console.error(error);
                // Clear the refresh token promise after failure
                this.refreshTokenInProgress = null;
                reject(error);
            }
        });

        return this.refreshTokenInProgress;
    }
}

const handleRetryError = async (resolve: any, endpoint: string) => {

    // @ts-ignore
    //window.location.reload(true)
    await SpotifyMusicProviders.renewToken()
    await delay(500)
    resolve(await SpotifyClientApi.fetchWebApi(endpoint, true))
}

export default SpotifyClientApi