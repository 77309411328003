import { HomeIcon, MyListIcon, SearchIcon } from '@components/IconComponents/IconComponents'
import './MoviesHeaderBarMobile.scss'
import { useSelector } from 'react-redux'
import { uiSelector } from 'store/selectors'
import { changeColorMode } from '@utils/colors'
import { useLocation, useNavigate } from 'react-router-dom'

const MoviesHeaderBarMobile = () => {

    const isDarkMode = useSelector(uiSelector).darkMode
    const navigation = useNavigate()

    const handleNavigation = (path: string) => {
        navigation(path)
    }

    const { pathname } = useLocation()
    const lastPathIndex = pathname.lastIndexOf('/')
    const lastPath = pathname.substring(lastPathIndex + 1)



    return (
        <div className='movies-headerbar-mobile-container'
            style={{ backgroundColor: changeColorMode(!isDarkMode) }}>
            <div className='movies-headerbar-mobile-item' onClick={() => handleNavigation('/merge-movies')}>
                <HomeIcon strokeColor={changeColorMode(isDarkMode, lastPath === 'merge-movies' ? '#6b37fe' : "#FFF")} />
                <span style={{ color: changeColorMode(isDarkMode, lastPath === 'merge-movies' ? '#6b37fe' : "#FFF") }}>Home Page</span>
            </div>
            <div className='movies-headerbar-mobile-item' onClick={() => handleNavigation('/merge-movies/mylist')}>
                <MyListIcon strokeColor={changeColorMode(isDarkMode, lastPath === 'mylist' ? '#6b37fe' : "#FFF")} />
                <span style={{ color: changeColorMode(isDarkMode, lastPath === 'mylist' ? '#6b37fe' : "#FFF") }}>My List</span>
            </div>
            <div className='movies-headerbar-mobile-item' onClick={() => handleNavigation('/merge-movies/search')}>
                <SearchIcon strokeColor={changeColorMode(isDarkMode, lastPath === 'search' ? '#6b37fe' : "#FFF")} />
                <span style={{ color: changeColorMode(isDarkMode, lastPath === 'search' ? '#6b37fe' : "#FFF") }}>Search</span>
            </div>
        </div>
    )
}

export default MoviesHeaderBarMobile