import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react'
import { Provider } from 'react-redux'
import store, { persistor } from './store'
import { BrowserRouter } from 'react-router-dom';
import MergeMusicProviders from './providers/MergeMusicProviders';
import { PersistGate } from 'redux-persist/integration/react';

import 'bootstrap/dist/css/bootstrap.min.css';



const _ = new MergeMusicProviders()

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

console.log(`process.env.REACT_APP_REDIRECT_URL: ${process.env.REACT_APP_REDIRECT_URL} process.env.REACT_APP_AUTH0_CLIENT_ID: ${process.env.REACT_APP_AUTH0_CLIENT_ID}`)

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={`${process.env.REACT_APP_AUTH0_DOMAIN}` as string}
      clientId={`${process.env.REACT_APP_AUTH0_CLIENT_ID}` as string}
      cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri: `${process.env.REACT_APP_REDIRECT_URL || `localhost:3000`}` as string
      }}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter basename='/'>
            <App />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
