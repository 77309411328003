import React, { MouseEventHandler, useEffect, useRef, useState } from 'react';
import './EpisodeCard.scss';
import { useSelector } from 'react-redux';
import { uiSelector } from 'store/selectors';
import { changeColorMode } from '@utils/colors';
import MenuOptions from '@components/MenuOptions/MenuOptions';
import useClickOutside from '@hooks/useClickOutside';
import { convertMillisecondsToDigitalClock, getUserEmail } from '@utils/helpers';
import MergeMusicProviders from 'providers/MergeMusicProviders';
import { ISong, MediaType } from 'interfaces/models/model-interfaces';
import { selectMusic } from 'store/musicSlice';
import store from '../../store'
import IconPlay from 'assets/icon/play.png'
import HearIcon from 'assets/icon/heart.png'
import HeartIconFill from 'assets/icon/heart_icon_fill.png'
import Api from 'services/api';

interface EpisodeCardProps {
    imagePath: string;
    episodeName: string;
    date: string;
    timeLength: any;
    description: string;
    showDescription?: boolean;
    menuOptionsVisible?: boolean;
    index?: number;
    contentProvider?: string;
    mediaType: string,
    metadata?: any
}

const EpisodeCard: React.FC<EpisodeCardProps> = ({
    imagePath,
    episodeName,
    date,
    timeLength,
    description,
    showDescription,
    contentProvider,
    menuOptionsVisible = false,
    index,
    mediaType,
    metadata
}) => {

    const [likes, setLikes] = useState({});
    const isDarkMode = useSelector(uiSelector).darkMode
    const [activeMenuIndex, setActiveMenuIndex] = useState<number | null>(null);
    const menuRef = useRef<HTMLDivElement>(null)

    useClickOutside(menuRef, () => {
        setActiveMenuIndex(null);
    })

    const toggleMenuOptions = (index: number) => {
        if (activeMenuIndex === index) {
            setActiveMenuIndex(null);
        } else {
            setActiveMenuIndex(index);
        }
    };

    const likesHandler = (event: any) => {
        // @ts-ignore
        event.stopPropagation()

        const { id, platform } = metadata
        const email = getUserEmail()
        if (metadata.liked) { // toggle remove
            metadata.liked = false
            Api.delete('/user/likes', { email, id, platform })
        } else {
            metadata.liked = true
            Api.post({ email, platform, song: metadata }, '/user/likes')
        }

        setLikes([false])
    }

    const closeMenuOptions = () => {
        setActiveMenuIndex(null);
    }

    const handlePlayMusic = async (music: any) => {
        const { platform, id } = music
        const song = await MergeMusicProviders.getContent(id, platform, mediaType || MediaType.Tracks)

        if (song) {
            const updatedMusic = {
                ...music,
                songInfo: song,
                type: mediaType
            };

            store.dispatch(selectMusic(updatedMusic))
        }
    }

    useEffect(() => {
        const getLikes = async () => {
            const email = getUserEmail()
            let likes = {}
            const { id, platform } = metadata
            
            Api.get({ id, email, platform }, '/user/likes')
                .then((response) => {
                    const { like } = response
                    metadata.liked = like        
                    setLikes(likes)
                })
          
        }

        getLikes()

    }, [metadata])

    return (
        <div className="episode-card" style={{
            backgroundColor: changeColorMode(!isDarkMode, 'transparent')

        }}
            onClick={() => handlePlayMusic(metadata)}
        >
            <div className='episode-card__info'>

                <div className='episode-card-container' style={{ position: 'relative' }}>
                    <img src={imagePath} alt='Episode' className='episode-card-img' />
                </div>
                <div className='episode-card__info__details' >
                    <h3 className='episode-name' style={{
                        color: changeColorMode(isDarkMode)

                    }}>{episodeName}</h3>
                    
                    <div className='episode-date-and-length'>
                        <span className='episode-date' style={{
                            color: changeColorMode(isDarkMode)

                        }}>{date} |</span>
                        <span className='episode-length' style={{
                            color: changeColorMode(isDarkMode)

                        }}> {convertMillisecondsToDigitalClock(timeLength)}</span> |
                        <img src={contentProvider} alt='Platform' className='episode-card-platform' style={{ marginLeft: "5px", width: "25px", marginTop: "-3px" }} />
                        {/* <div style={{ border: "0px", background: 'transparent', display: 'flex', justifyContent: 'flex-end', marginTop: '25px' }}> */}
                        <img src={metadata.liked ? HeartIconFill : HearIcon}
                            
                            alt="heart-favorite-icon"
                            style={{ cursor: 'pointer', width: "25px", marginLeft: '20px' }}
                            onClick={likesHandler}
                        />
                    {/* </div> */}

                    </div>
                   
                   
                </div>
                {/* <div className='episode-card-options'
                    onClick={() => toggleMenuOptions(index!)}
                    style={{
                        color: changeColorMode(isDarkMode),
                        position: 'relative'

                    }}>
                    ...
                    {activeMenuIndex === index && (
                        <div className="menu-container" ref={menuRef}>
                            <MenuOptions closeMenu={closeMenuOptions} />
                        </div>
                    )}
                </div> */}
                
                

            </div>

            {showDescription && <div className='episode-card__description'>
                {description}A three-part series from This American Life producer Nancy Updike. When Rachel McKibbens’s father and brother died suddenly last fall, two weeks apart, from Covid, she’d had no idea her father was sick, and no idea her brother was dying. They were unvaccinated, but the story of what happened started long before that. All three episodes of "We Were Three," a new show from Serial Productions and The New York Tim
            </div>}
        </div>
    );
};

export default EpisodeCard;
