import { createSlice } from "@reduxjs/toolkit";

export const cartSlice = createSlice({
  name: "ui",
  initialState: {
    menuUserVisible: false,
    darkMode: false,
  },
  reducers: {
    toggleMenuUser(state) {
      state.menuUserVisible = !state.menuUserVisible;
    },
    toggleDarkMode(state) {
      state.darkMode = !state.darkMode;
    }
  },
});

export const { toggleMenuUser, toggleDarkMode } = cartSlice.actions;
export default cartSlice.reducer;
