
interface SearchState {
    currentSearchInput: string;
    results: any[];
}



export enum SearchActionTypes {
    CLEAR_STATE = "CLEAR_STATE",
    UPDATE_CURRENT_SEARCH_INPUT = "UPDATE_CURRENT_SEARCH_INPUT",
    UPDATE_RESULTS = "UPDATE_RESULTS",
}

interface ClearStateAction {
    type: typeof SearchActionTypes.CLEAR_STATE;
}

interface UpdateCurrentSearchInputAction {
    type: typeof SearchActionTypes.UPDATE_CURRENT_SEARCH_INPUT;
    payload: string;
}

interface UpdateResultsAction {
    type: typeof SearchActionTypes.UPDATE_RESULTS;
    payload: any[];
}

type SearchAction =
    | ClearStateAction
    | UpdateCurrentSearchInputAction
    | UpdateResultsAction;

const initialState: SearchState = {
    currentSearchInput: "",
    results: [],
};

function searchReducer(state = initialState, action: SearchAction): SearchState {
    switch (action.type) {
        case SearchActionTypes.CLEAR_STATE:
            return initialState;
        case SearchActionTypes.UPDATE_CURRENT_SEARCH_INPUT:
            return {
                ...state,
                currentSearchInput: action.payload,
            };
        case SearchActionTypes.UPDATE_RESULTS:
            return {
                ...state,
                results: action.payload,
            };
        default:
            return state;
    }
}

export default searchReducer;
