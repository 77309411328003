import { FETCH_USER_PROFILE_START, FETCH_USER_PROFILE_SUCCESS, FETCH_USER_PROFILE_FAIL, SEnD_MUSIC_CATEOGORY } from '../../actions/music/update-userinfo';

const initialState = {
    loading: false,
    userInfo: null,
    error: null,
};

export const userReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case FETCH_USER_PROFILE_START:
            return {
                ...state,
                loading: true
            };
        case FETCH_USER_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                userInfo: action.payload
            };
        case FETCH_USER_PROFILE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case SEnD_MUSIC_CATEOGORY: 
            return {
                ...state,
                musicType: action.payload.musicsType,
                music: action.payload.data
            }
        default:
            return state;
    }
};

