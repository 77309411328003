
import { useAuth0 } from '@auth0/auth0-react'
import Button from '@components/Button/Button';
import './HeaderNav.scss'
import Logo from '@components/Logo/Logo';
import ProfileComponent from '@components/ProfileComponent/ProfileComponent';
import { useSelector } from 'react-redux';
import { uiSelector } from 'store/selectors';
import { changeColorMode } from '@utils/colors';



const HeaderNav = () => {
    const isDarkMode = useSelector(uiSelector).darkMode
    const { loginWithRedirect, isAuthenticated } = useAuth0()

    const handleGoToLogin = () => {
        loginWithRedirect({
            authorizationParams: {
                redirect_uri: `${window.location.origin}/addstreaming`,
            }
        })
    }

    return (
        <nav className="header-nav" style={{
            backgroundColor: changeColorMode(!isDarkMode),
        }}>
            <ul >
                <li className="header-nav-item--first">
                    <span>
                        <Logo />
                    </span>
                </li>
                <li>
                    <span style={{ color: changeColorMode(isDarkMode) }}>How it works?</span>
                </li>
                <li>
                    <span style={{ color: changeColorMode(isDarkMode) }}>What services was support?</span>
                </li>
                {isAuthenticated ?
                    <li style={{ marginLeft: 'auto', width: '160px', userSelect: 'none', outline: 'none' }}>
                        <ProfileComponent />
                    </li>
                    :
                    <li style={{
                        marginLeft: 'auto', width: '160px'
                    }}>
                        <Button onClick={handleGoToLogin} buttonText='Log in' additionalClassName='header__mobile__button' />
                    </li>
                }
            </ul>
        </nav>
    );
};

export default HeaderNav;