import React, { useState } from 'react';
import { CustomList } from '@utils/navigationsLinks/profileDropdownList';
import Logo from '@components/Logo/Logo';
import ChooseExperienceDropdown from '@components/ChooseExperienceDropdown/ChooseExperienceDropdown';
import SideBarItem from './SideBarItem';
import ArrowDown from 'assets/icon/arrow.svg'
import { useLocation } from 'react-router-dom'
import './Sidebar.scss'
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { ArrowDownIcon } from '@components/IconComponents/IconComponents';

interface SideBarProps {
  sidebarList?: CustomList[]
}

const Sidebar: React.FC<SideBarProps> = ({ sidebarList }) => {
  const isDarkMode = useSelector((state: RootState) => state.ui.darkMode)
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation();

  const toggleDropdown = () => {
    setShowDropdown(previous => !previous)
  }

  const handleCloseDropdown = () => {
    setShowDropdown(false);
  }


  return (
    <aside className={`sidebar__container ${isDarkMode ? 'dark-mode' : ''}`}
      style={{
        backgroundColor: isDarkMode ? '#1F2937' : '#F9FAFB',
        color: isDarkMode ? '#FFF' : '#374151'

      }}
    >
      <div className='sidebar__container__logoDropdown'>
        <Logo />
        <ArrowDownIcon strokeColor={isDarkMode ? "#FFF" : '#374151'} style={{ cursor: 'pointer' }} onClick={toggleDropdown} />
      </div>
      <ChooseExperienceDropdown contentVisible={showDropdown} handleCloseDropdown={handleCloseDropdown} />
      <div className='sidebar__container__list'>
        {sidebarList?.map((item, index) => {
          const itemLink = item.link?.split('?')[0];
          const isRouteSelected = location.pathname.includes(itemLink);
          const Icon = item.icon;
          return (
            <SideBarItem
              isSidebar
              isRouteSelected={isRouteSelected}
              key={index}
              icon={<Icon
                strokeColor={isRouteSelected && !isDarkMode ? '#8d66fe' : (!isRouteSelected && isDarkMode || isRouteSelected && isDarkMode) ? 'white' : '#374151'
                } />}
              label={item.label}
              link={item.link}
              items={item.items}
            />
          )
        })}
      </div>

    </aside>
  )
}

export default Sidebar;