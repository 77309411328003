import Logo from '@components/Logo/Logo'
import MoviesHeaderMenu from '../MoviesHeaderMenu/MoviesHeaderMenu'
import { ArrowDownIcon } from '@components/IconComponents/IconComponents'
import SearchInputContainer from '../SearchInputContainer/SearchInputContainer'
import { useSelector } from 'react-redux'
import { uiSelector } from 'store/selectors'
import { changeColorMode } from '@utils/colors'
import { useNavigate, useNavigation } from 'react-router-dom'

interface MoviesHeaderLeftProps {
    handleOpenDropdown: () => void
    searchInputActived?: boolean
    searchResult?: any
    handleChangeInput?: (e: any) => void
    searchInputValue?: string
    handleInputFocus?: () => void
    handleInputBlur?: () => void
    handleClickClearInput?: () => void
    handleKeyDown?: (e: any) => void
    hasBackgroundImageUrl?: boolean
}

const MoviesHeaderLeft = ({
    handleOpenDropdown,
    searchInputActived,
    searchResult,
    handleChangeInput,
    handleClickClearInput,
    handleInputFocus,
    handleInputBlur,
    handleKeyDown,
    hasBackgroundImageUrl,
    searchInputValue }: MoviesHeaderLeftProps) => {
    const navigation = useNavigate()
    const handleNavigation = () => {
        navigation('/merge-movies')
    }
    const isDarkMode = useSelector(uiSelector).darkMode
    return (
        <div className='movies_header_menu_container' >
            <div className='movies_header_menu'>
                <Logo showName={false} />
                <h2 onClick={handleNavigation} className='movies_header_title' style={{ cursor: 'pointer' }}>Movies</h2>
                <ArrowDownIcon strokeColor={changeColorMode(hasBackgroundImageUrl ? !isDarkMode : isDarkMode)} onClick={handleOpenDropdown} style={{ cursor: 'pointer' }} />
            </div>
            {searchInputActived ?
                <SearchInputContainer
                    handleKeyDown={handleKeyDown}
                    handleClickClearInput={handleClickClearInput}
                    handleInputFocus={handleInputFocus}
                    handleInputBlur={handleInputBlur}
                    searchInputValue={searchInputValue}
                    handleChangeInput={handleChangeInput}
                    searchResult={searchResult}
                /> :
                <MoviesHeaderMenu hasBackgroundImageUrl={hasBackgroundImageUrl} />}
        </div>
    )
}

export default MoviesHeaderLeft