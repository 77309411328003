import { AddIcon, WatchIcon } from '@components/IconComponents/IconComponents'
import './MovieButtons.scss'
import { useSelector } from 'react-redux'
import { uiSelector } from 'store/selectors'
import { changeColorMode } from '@utils/colors'

interface MovieButtonsProps {
    watchMovieLink?: () => void
    handlePressTrailer?: () => void
}
const MovieButtons = ({ watchMovieLink, handlePressTrailer }: MovieButtonsProps) => {

    const isDarkMode = useSelector(uiSelector).darkMode

    return (
        <div className='movie-buttons-container'>
            <button className='button-watch' onClick={watchMovieLink}>
                <span style={{ color: changeColorMode(!isDarkMode) }}>Watch</span>
                <WatchIcon strokeColor={changeColorMode(!isDarkMode)} />
            </button>
            <button className='button-watch-trailer' onClick={handlePressTrailer}>
                Watch Trailer
            </button>
            <button className='button-add-playlist'>
                <AddIcon />
            </button>
        </div>
    )
}

export default MovieButtons