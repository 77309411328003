import SpotifyProvider from 'assets/icon/spotify_provider.png'
import AppleProvider from 'assets/icon/apple_provider.png'
import TidalProvider from 'assets/icon/tidal_provider.png'
import YoutubeProvider from 'assets/icon/youtube_provider.png'

import { MusicPlatform } from 'interfaces/models/model-interfaces'

export const contentProvidersDropdown = [
    {
        id: MusicPlatform.SpotifyMusic,
        name: 'Spotify',
        img: SpotifyProvider
    },
    {
        id: MusicPlatform.AppleMusic,
        name: 'Apple Music',
        img: AppleProvider
    },

    {
        id: MusicPlatform.TidalMusic,
        name: 'Tidal Music',
        img: TidalProvider
    },
    {
        id: MusicPlatform.YoutubeMusic,
        name: 'YouTube Music',
        img: YoutubeProvider
    },

]