import Shuffle from "../assets/images/music_made_for_you (1).png";
import AllYours from "../assets/images/music_made_for_you (2).png";
import InRainbows from "../assets/images/music_made_for_you (3).png";
import BornToDie from "../assets/images/music_made_for_you (4).png";
import SoundTrackOne from "../assets/images/soundtracks_for_mood (1).png";
import SoundTrackTwo from "../assets/images/soundtracks_for_mood (2).png";
import SoundTrackThree from "../assets/images/soundtracks_for_mood (3).png";
import SoundTrackFour from "../assets/images/soundtracks_for_mood (4).png";
import TopArtistOne from "../assets/images/top_artists (1).png";
import TopArtistTwo from "../assets/images/top_artists (2).png";
import TopArtistThree from "../assets/images/top_artists (3).svg";
import TopArtistFour from "../assets/images/artist_4.svg";

export const musicsMadeForYou = [
  {
    id: 1,
    title: "Best tracks for your taste",
    subTitle: "",
    img: Shuffle,
    song: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3',
  },
  {
    id: 2,
    title: "This Is All Yours",
    subTitle: "Alt-J",
    img: AllYours,
    song: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3',
  },
  {
    id: 3,
    title: "In Rainbows",
    subTitle: "Radiohead",
    img: InRainbows,
    song: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-3.mp3',
  },
  {
    id: 4,
    title: "Born to Die",
    subTitle: "Lana Del Rey",
    img: BornToDie,
  },
  {
    id: 5,
    title: "The Dark Side of the Moon",
    subTitle: "Pink Floyd",
    img: "https://via.placeholder.com/150",
    song: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-4.mp3',
  },
  {
    id: 6,
    title: "Thriller",
    subTitle: "Michael Jackson",
    img: "https://via.placeholder.com/150",
    song: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-5.mp3',
  },
];

export const soundTracksForYourMood = [
  {
    id: 1,
    img: SoundTrackOne,
  },
  {
    id: 2,
    img: SoundTrackTwo,
  },
  {
    id: 3,
    img: SoundTrackThree,
  },
  {
    id: 4,
    img: SoundTrackFour,
  },
];

export const topArtists = [
  {
    id: 1,
    img: TopArtistOne,
  },
  {
    id: 2,
    img: TopArtistTwo,
  },
  {
    id: 3,
    img: TopArtistThree,
  },
  {
    id: 4,
    img: TopArtistFour,
  },
];
