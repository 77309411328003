import React from 'react';
import './Modal.scss'

interface ModalProps {
    modalVisible: boolean;
    handleCancel: () => void;
    children: React.ReactNode;
    [x: string]: any;
}

const Modal: React.FC<ModalProps> = ({ modalVisible, handleCancel, children, ...props }) => {
    return (
        <>
            <div className={`modal__overlay ${modalVisible ? 'visible' : ''}`} onClick={handleCancel} />
            <div {...props} className={`modal__container ${modalVisible ? 'visible' : ''}`}>
                {children}
            </div>
        </>
    )
}

export default Modal;