import { User } from "@auth0/auth0-spa-js";
import { USerProfileKey } from "../constants";
import { ISong } from "interfaces/models/model-interfaces";

export const getTotalTime = (tracks: ISong[]) => {
    const totalTime = tracks.reduce((total, song) => total + song.durationInMillis, 0);
    return formatDuration(totalTime)
}

export const formatDuration = (milliseconds: number): string => {

    let seconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(seconds / 3600);
    seconds %= 3600;
    const minutes = Math.floor(seconds / 60);

    seconds %= 60;

    return [hours, minutes, seconds]
        .map(value => value.toString().padStart(2, '0'))
        .join(':');
}

export const convertMillisecondsToDigitalClock = (milliseconds: number): string => {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;

    // Padding single digit minutes and seconds with zero
    const paddedMinutes = minutes.toString().padStart(2, '0');
    const paddedSeconds = seconds.toString().padStart(2, '0');

    return `${paddedMinutes}:${paddedSeconds}`;
}

export const getUserEmail = () => {
    const userProfile: User = JSON.parse(localStorage.getItem(USerProfileKey) as string)
    const { email } = userProfile
    return email
}