import BaseMoviesContainer from '@components/BaseMoviesContainer/BaseMoviesContainer'
import { ArrowDownIcon } from '@components/IconComponents/IconComponents'
import { allGenresMoviesData } from './allgenresmoviesdata'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { uiSelector } from 'store/selectors'
import { changeColorMode } from '@utils/colors'
import './AllGenresMovies.scss'


const AllGenresMovies = () => {

    const isDarkMode = useSelector(uiSelector).darkMode
    const navigation = useNavigate()
    const handleNavigation = () => {
        navigation(-1)
    }

    return (
        <BaseMoviesContainer>
            <section style={{
                padding: '44px'
            }}>
                <div onClick={handleNavigation} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center', marginBottom: "40px" }}>
                    <ArrowDownIcon strokeColor={changeColorMode(isDarkMode)} style={{ rotate: '90deg' }} />
                    <h1 style={{ color: changeColorMode(isDarkMode) }} className='all_genres_movies_title'>All Genres</h1>
                </div>
                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap',

                    gap: '10px'
                }}>

                    {allGenresMoviesData.map((genre) => {
                        return (
                            <Link to={`/merge-movies/genre/${genre.id}`} >
                                <img src={genre.img} alt={genre.title} style={{ width: '100%', height: 'auto', maxWidth: "248px", minWidth: "100px" }} />
                            </Link>
                        )
                    })}
                </div>
            </section>
        </BaseMoviesContainer>
    )
}

export default AllGenresMovies