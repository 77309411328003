import { musicLinksList } from '@utils/navigationsLinks/profileDropdownList'
import Base from 'template/Base'
import './RadioPage.scss'
import IconPlay from 'assets/icon/play.png'
import HeartIcon from 'assets/icon/heart.png'
import TableComponent from '@components/Table/Table'
import { musicsMockedData } from 'mockdatas/musics_mocked_data'
import { useWindowDimensions } from '@hooks/useWindowDimension'
import EpisodesCard from '@components/EpisodesCard/EpisodesCard'
import { BsArrowLeft } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import Avatar from '@components/Avatar/Avatar'
import { useSelector } from 'react-redux'
import { uiSelector } from 'store/selectors'
import { changeColorMode } from '@utils/colors'
import { DeleteIcon, EditIcon, ShareIcon } from '@components/IconComponents/IconComponents'


const RadioPage = () => {
    const isDarkMode = useSelector(uiSelector).darkMode
    const navigation = useNavigate()
    const { isMobile } = useWindowDimensions()
    const handleGoBack = () => {
        navigation(-1)
    }
    return (
        <Base sidebarList={musicLinksList}>
            <article className='radioPage__container'>
                {isMobile && <BsArrowLeft onClick={handleGoBack} size={24} color='black' style={{ marginLeft: '20px', marginTop: 20 }} />}
                <div className='radioPage__container__podcast__details'>
                    <div className='radioPage__container__podcast__details__image'>
                    </div>
                    <div className='radioPage__container__podcast__details__info'>
                        <h2 className='podcast_name' style={{ color: changeColorMode(isDarkMode) }}>Radio: Song Name</h2>
                        <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', gap: 12 }}>
                            <small className='podcast_quantities' style={{ color: changeColorMode(isDarkMode) }}>25 songs</small>
                            <small className='podcast_quantities' style={{ color: changeColorMode(isDarkMode) }}>2h 35min</small>
                        </div>
                        {!isMobile && <Avatar showName={true} />}
                        <div className='buttons__container'>
                            <hr color='#E5E7EB' style={{ position: 'absolute', background: "#E5E7EB", width: "100vw" }} />
                            {isMobile ? <>
                                <div className='buttons__container'>
                                    <hr color='#E5E7EB' style={{ position: 'absolute', background: "#E5E7EB", zIndex: -1 }} />

                                    <button className='circle__button'>
                                        <img src={IconPlay} alt='Icon Play' />
                                    </button>

                                    <button className='circle__button'>
                                        <img src={HeartIcon} alt='Icon Play' />
                                    </button>

                                    <button className='circle__button'>
                                        <ShareIcon strokeColor={changeColorMode(isDarkMode)} />
                                    </button>

                                </div>
                            </> :
                                <>
                                    <button className='circle__button'>
                                        <img src={IconPlay} alt='Icon Play' />
                                    </button>
                                    <button className='circle__button'>
                                        <EditIcon strokeColor={changeColorMode(isDarkMode)} />
                                    </button>
                                    <button className='circle__button'>
                                        <ShareIcon strokeColor={changeColorMode(isDarkMode)} />
                                    </button>
                                    <button className='circle__button'>
                                        <DeleteIcon strokeColor={changeColorMode(isDarkMode)} />
                                    </button>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </article>
            <article className='radioPage__soundorvideo'>
                {isMobile ?
                    <EpisodesCard tableItems={musicsMockedData} /> :
                    <TableComponent
                        tableItems={musicsMockedData}
                    />}
            </article>
        </Base>
    )
}

export default RadioPage