import BaseMoviesContainer from '@components/BaseMoviesContainer/BaseMoviesContainer'
import MoviesListShelf from '@components/MoviesListShelf/MoviesListShelf'
import { mockedMoviesData } from 'mockdatas/moviesMockedData'
import './MyListMovies.scss'
import { useGetMoviesQuery } from 'services/movie.services'


const NETFLIX_SERVICE_NUMBER = 1;
const HULU_SERVICE_NUMBER = 4;
const MyListMovies = () => {


    const { data: movies, error, isLoading } = useGetMoviesQuery({});

    const moviesData = movies?.map((item: any) => ({
        ...item,
        movieTitle: item.title,
        movieImgUrl: item.poster_url
    }))

    const filterMoviesByStreaming = (streaming: string) => {
        return moviesData?.filter((movie: any) => {
            return movie?.sources?.includes(streaming);
        });
    };

    return (
        <BaseMoviesContainer>
            <section className='my-list-section'>
                <MoviesListShelf
                    title='My Merge List'
                    redirectTitle='my-merge-list'
                    moviesList={moviesData || []} />
                <MoviesListShelf
                    title='My Netflix List'
                    redirectTitle='View All'
                    moviesList={filterMoviesByStreaming('netflix') || []} />
                <MoviesListShelf
                    title='My Hulu List'
                    redirectTitle='View All'
                    moviesList={filterMoviesByStreaming('hulu_plus') || []} />
            </section>
        </BaseMoviesContainer>
    )
}

export default MyListMovies