import React from 'react';
import './Input.scss'
import { useSelector } from 'react-redux';
import { uiSelector } from 'store/selectors';
import { changeColorMode } from '@utils/colors';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    placeholder: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    inputTitle?: string;
    labelVisible?: boolean;
}

const Input: React.FC<InputProps> = ({ placeholder, value, onChange, inputTitle, labelVisible }) => {
    const isDarkMode = useSelector(uiSelector).darkMode;
    return (
        <div className='input__container'>
            <label style={{ color: changeColorMode(isDarkMode) }} className={`input__label ${labelVisible ? 'label_visible' : ''}`} htmlFor={inputTitle}>{inputTitle}</label>
            <input style={{
                backgroundColor: changeColorMode(!isDarkMode, undefined, 'transparent'),
                color: changeColorMode(isDarkMode),
            }} placeholder={placeholder} id={inputTitle} value={value} onChange={onChange} className='input__container__input' />
        </div>
    )
}

export default Input;