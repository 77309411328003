import { useRoutes } from 'react-router-dom'
import { lazy, Suspense } from 'react'
import ProtectedRoute from './pages/ProtectedRoutes';
import LandingPage from './pages/LandingPage/LandingPage';
import LoggedPage from './pages/LoggedPage/LoggedPage';
import Error from './pages/Error/Error';
import ProfilePage from '@pages/ProfilePage/ProfilePage';
import MergedAccounts from '@pages/LoggedPage/MergedAccountsPage/MergedAccountsPage';
import Loading from '@components/Loading/Loading';
import PodcastsPage from '@pages/PodcastsPage/PodcastsPage';
import RadiosPage from '@pages/RadiosPage/RadiosPage';
import MyFavorites from '@pages/MyFavorites/MyFavorites';
import SearchPage from '@pages/SearchPage/SearchPage';
import Genres from '@pages/Genres';
import Categories from '@pages/Categories/Categories';
import RadioPage from '@pages/RadioPage/RadioPage';
import MoviesPage from '@pages/MoviesPage/MoviesPage';
import SettingsPage from '@pages/SettingsPage/SettingsPage';
import MoviePage from '@pages/MoviePage/MoviePage';
import PlaylistPage from '@pages/PlaylistPage/PlaylistPage';
import ArtistPage from '@pages/ArtistPage/ArtistPage';
import AllGenresMovies from '@pages/AllGenresMovies/AllGenresMovies';
import MyListMovies from '@pages/MyListMovies/MyListMovies';
import MovieListPage from '@pages/MovieListPage/MovieListPage';
import Movies from '@pages/Movies/Movies';
import TvShowsPage from '@pages/TvShowsPage/TvShowsPage';
import TvShowPage from '@pages/TvShowPage/TvShowPage';
import SearchMovies from '@pages/SearchMovies/SearchMovies';
import GenreMovies from '@pages/GenreMovies/GenreMovies';
import AddStreaming from '@pages/AddStreaming/AddStreaming';

const LazyAddStreaming = lazy(() => import('./pages/AddStreaming/AddStreaming'));
const LazyMusicStreaming = lazy(() => import('./pages/MusicStreaming/MusicStreaming'));
const LazyPodcastPage = lazy(() => import('./pages/PodcastPage/PodcastPage'));
const LazyAlbumPage = lazy(() => import('./pages/AlbumPage/AlbumPage'));
const LazyHelpPage = lazy(() => import('./pages/HelpPage/HelpPage'));
const path = ''

const AppRoutes = () => {

    const elements = useRoutes([
        {
            path: `/`,
            element: <LandingPage />,
        },
        {
            path: `${path}/user`,
            element:
                <ProtectedRoute>
                    <LoggedPage />
                </ProtectedRoute>,
            loader: () => <Loading />,

            children: [
                {
                    path: "musics",
                    element: <ProfilePage />,
                    loader: () => <Loading />,
                },
            ]
        },
        {
            path: "/my-",
            element: <ProtectedRoute>
                <Suspense fallback={<Loading />}>
                    <MyFavorites />
                </Suspense>
            </ProtectedRoute>,
        },
        {
            path: "/music/genres",
            element: <Genres />,
            loader: () => <Loading />,
        },
        {
            path: "/podcasts/categories",
            element: <Categories />,
            loader: () => <Loading />,
        },
        {
            path: "/music",
            element: <ProtectedRoute>
                <Suspense fallback={<Loading />}>
                    <LazyMusicStreaming />
                </Suspense>
            </ProtectedRoute>,
            loader: () => <Loading />,
            children: [
                {
                    path: "genres",
                    element: <Genres />,
                    loader: () => <Loading />,
                },

            ]
        },
        {
            path: "/podcasts",
            element: <ProtectedRoute>
                <PodcastsPage />
            </ProtectedRoute>,
            children: [
                {
                    path: "categories",
                    element: <Categories />,
                    loader: () => <Loading />,
                },
            ]
        },
        {
            path: `${path}/profile`,
            element: <ProtectedRoute>
                <ProfilePage />
            </ProtectedRoute>,
        },
        {
            path: `${path}/merged-accounts`,
            element: <ProtectedRoute>
                <MergedAccounts />
            </ProtectedRoute>,
        },

        {
            path: `${path}/addstreaming`,
            element:
                <ProtectedRoute>
                    <Suspense fallback={<Loading />}>
                        <LazyAddStreaming />
                    </Suspense>
                </ProtectedRoute>,
        },
        {
            path: "/podcast/:podcastName/:podcastName",
            element: <ProtectedRoute>
                <Suspense fallback={<Loading />}>
                    <LazyPodcastPage />
                </Suspense>
            </ProtectedRoute>,
            loader: () => <Loading />,
        },

        {
            path: "/albums/:albumName/:artistName",
            element: <ProtectedRoute>
                <Suspense fallback={<Loading />}>
                    <LazyAlbumPage />
                </Suspense>
            </ProtectedRoute>,
            loader: () => <Loading />,
        },

        {
            path: "/radio/:radioName",
            element: <ProtectedRoute>
                <Suspense fallback={<Loading />}>
                    <RadioPage />
                </Suspense>
            </ProtectedRoute>,
        },
        {
            path: "/playlist/:playlistName",
            element: <ProtectedRoute>
                <Suspense fallback={<Loading />}>
                    <PlaylistPage />
                </Suspense>
            </ProtectedRoute>,
        },
        {
            path: "/artist/:artistName/:artistName",
            element: <ProtectedRoute>
                <Suspense fallback={<Loading />}>
                    <ArtistPage />
                </Suspense>
            </ProtectedRoute>,
        },
        {
            path: "/radios",
            element: <ProtectedRoute>
                <Suspense fallback={<Loading />}>
                    <RadiosPage />
                </Suspense>
            </ProtectedRoute>,
        },
        {
            path: "/search",
            element: <ProtectedRoute>
                <Suspense fallback={<Loading />}>
                    <SearchPage />
                </Suspense>
            </ProtectedRoute>,
        },
        {
            path: "/my-favorites",
            element: <ProtectedRoute>
                <Suspense fallback={<Loading />}>
                    <MyFavorites />
                </Suspense>
            </ProtectedRoute>,
        },
        {
            path: "/merge-movies",
            element: <ProtectedRoute>
                <Suspense fallback={<Loading />}>
                    <MoviesPage />
                </Suspense>
            </ProtectedRoute>,
        },
        {
            path: "/help",
            element: <ProtectedRoute>
                <Suspense fallback={<Loading />}>
                    <LazyHelpPage />
                </Suspense>
            </ProtectedRoute>,
        },
        {
            path: "/settings",
            element: <ProtectedRoute>
                <Suspense fallback={<Loading />}>
                    <SettingsPage />
                </Suspense>
            </ProtectedRoute>,
        },
        {
            path: "/movie/:movieName",
            element: <ProtectedRoute>
                <Suspense fallback={<Loading />}>
                    <MoviePage />
                </Suspense>
            </ProtectedRoute>,
        },
        {
            path: "/merge-movies/list/movies/genres",
            element: <ProtectedRoute>
                <Suspense fallback={<Loading />}>
                    <AllGenresMovies />
                </Suspense>
            </ProtectedRoute>,
        },
        {
            path: "/merge-movies/mylist",
            element: <ProtectedRoute>
                <Suspense fallback={<Loading />}>
                    <MyListMovies />
                </Suspense>
            </ProtectedRoute>,
        },
        {
            path: "/merge-movies/mylist/:listName",
            element: <ProtectedRoute>
                <Suspense fallback={<Loading />}>
                    <MovieListPage />
                </Suspense>
            </ProtectedRoute>,
        },
        {
            path: "/merge-movies/list/:listName",
            element: <ProtectedRoute>
                <Suspense fallback={<Loading />}>
                    <MovieListPage />
                </Suspense>
            </ProtectedRoute>,
        },
        {
            path: "/merge-movies/movies",
            element: <ProtectedRoute>
                <Suspense fallback={<Loading />}>
                    <Movies />
                </Suspense>
            </ProtectedRoute>,
        },
        {
            path: "/merge-movies/tv-shows",
            element: <ProtectedRoute>
                <Suspense fallback={<Loading />}>
                    <TvShowsPage />
                </Suspense>
            </ProtectedRoute>,
        },
        {
            path: "/merge-movies/tv-show/:tvShowName",
            element: <ProtectedRoute>
                <Suspense fallback={<Loading />}>
                    <TvShowPage />
                </Suspense>
            </ProtectedRoute>,
        },
        {
            path: "/merge-movies/search",
            element: <ProtectedRoute>
                <Suspense fallback={<Loading />}>
                    <SearchMovies />
                </Suspense>
            </ProtectedRoute>,
        },
        {
            path: "/merge-movies/genre/:genreName",
            element: <ProtectedRoute>
                <Suspense fallback={<Loading />}>
                    <GenreMovies />
                </Suspense>
            </ProtectedRoute>,
        },

        {
            path: '*',
            element: <Error />
        }

    ]);

    return elements;
}

export default AppRoutes;