
export const IMG1 = 'https://s3-alpha-sig.figma.com/img/d489/8b9f/8402d3a818371c7121768a7d8950605c?Expires=1699228800&Signature=ad7XMElVPuvCyM5rXppOgU0s0oj7z0VNcLP8NRyP1p~h~yC3MHuBDPfv77XU82MmS2-qUkMBtDiYbjoBGnoZBjVNtffQi8i7M4F9PQs2lTq3yNDrS4ijLG4oRKEMfsZbQSJYl-Cgd6ZuP915qRs0r8NZrcl1~uTrc83sJ6bXJCY6ZInNGIHobQD2IuaxcNZWX9dYmHJ~Zk23QJajxale~yXQmVlrJwmaSBe~6t9eWNcG4NwmxyZ~ooTNCikq3UXcn-HRwUWlysdNgocDdqORsRXDc1MgWUT7bSPfv-WOy11pONAzv1JnsPuJE2Hre23uI5kNQcO0FZDQOI9WX6TzGQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4'
export const IMG2 = 'https://s3-alpha-sig.figma.com/img/7c68/2348/fd50c3567c68829b21e01142cf70670d?Expires=1699228800&Signature=Q1G1mugPplpidITFE8lUWwBOvQpMXm28EysdStor8UoiP7~RHmEDyBcm9yPXgNMXtNsIaOzm0CFcPvQ-56oYxrSa-rZZHrbv3vMehRkgS1Vxm0wrUKzUlO-ne-8~Aod2KSCTYiOXaKXGRCiptQ2IXFvnv1sVjiGq4ycF5kMtjCydg6bZikF7eYJxmy-eeUk3NvdhauF3wNgwNSxrBkAuA5tICbqODS-NHpLHdlsDYGQkEwLLs1~RyvWbeWBfasH0Xopqr964LbJa9MrxdvHwsWfbttA~16aOXz5FEVVIWsVvDK00bKudmjmOEQnlCDIe~Pw5NuDj~yytlWiZfMcT7w__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4'
export const IMG3 = 'https://s3-alpha-sig.figma.com/img/de1f/7a4a/8f6d0e1ffc3bf0907decb13172052c47?Expires=1699228800&Signature=DWJ8irEXVL8ltXyvWrVbMSD5Ex-SR~NxfaIUm9Fr1U4oBsRw7HNrYmgwd7HvmV-9VpFrBpt7Py1mthFkcQBGCEozu0HjUpaLJUyPErqrbgihNY1ANVw2Msevengc~YWhxjk5yQGwqbHCh5pcKnxGq6Kc~GXvHnFVbjanctn8KlBcCYWkIK2azD9CH7-gpE7P3sMTWCQEaITDpfb9QHupIF~mR~FMOwDIqgR8tUzWDrSBpiah4uxJ~i5ZbJKah58S6A-c4C4GGHqeTL9Jy-xc~EOp9LtKEbZ9VloO8NrSJziCjuvJ4ObiBSWoMBoSv-zkyl-WGyfABwDGF8353KJrug__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4'

export type Movie = {
    service_groups?: string[]
    id?: string;
    movieImgUrl: string;
    movieTitle: string;
    duration: string;
    contentProvider: string;
    category: string;
    type: string;
    minimumAge: string;
    content_type?: string;
    classification?: string;
    sources?: string[];
    [key: string]: any;
}
export const mockedMoviesData = [
    {
        movieImgUrl: IMG1,
        movieTitle: 'Movie 1',
        duration: '1h 30m',
        contentProvider: 'Spotify',
        category: 'Action',
        type: 'Movie',
        minimumAge: 'PG-13',
    },
    {
        movieImgUrl: IMG2,
        movieTitle: 'Movie 2',
        duration: '2h 10m',
        contentProvider: 'Spotify',
        category: 'Comedy',
        type: 'Movie',
        minimumAge: 'PG',
    },
    {
        movieImgUrl: IMG3,
        movieTitle: 'Movie 3',
        duration: '1h 45m',
        contentProvider: 'Spotify',
        category: 'Drama',
        type: 'Movie',
        minimumAge: 'R',
    },
    {
        movieImgUrl: 'img4.jpg',
        movieTitle: 'Movie 4',
        duration: '1h 55m',
        contentProvider: 'Spotify',
        category: 'Adventure',
        type: 'Movie',
        minimumAge: 'PG-13',
    },
    {
        movieImgUrl: 'img5.jpg',
        movieTitle: 'Movie 5',
        duration: '2h 20m',
        contentProvider: 'Spotify',
        category: 'Science Fiction',
        type: 'Movie',
        minimumAge: 'PG-13',
    },
    {
        movieImgUrl: 'img6.jpg',
        movieTitle: 'Movie 6',
        duration: '1h 40m',
        contentProvider: 'Spotify',
        category: 'Romance',
        type: 'Movie',
        minimumAge: 'PG',
    },
    {
        movieImgUrl: 'img7.jpg',
        movieTitle: 'Movie 7',
        duration: '2h 5m',
        contentProvider: 'Spotify',
        category: 'Comedy',
        type: 'Movie',
        minimumAge: 'PG-13',
    },
    {
        movieImgUrl: 'img8.jpg',
        movieTitle: 'Movie 8',
        duration: '1h 55m',
        contentProvider: 'Spotify',
        category: 'Action',
        type: 'Movie',
        minimumAge: 'PG-13',
    },
    {
        movieImgUrl: 'img9.jpg',
        movieTitle: 'Movie 9',
        duration: '2h 30m',
        contentProvider: 'Spotify',
        category: 'Drama',
        type: 'Movie',
        minimumAge: 'R',
    },
    {
        movieImgUrl: 'img10.jpg',
        movieTitle: 'Movie 10',
        duration: '1h 45m',
        contentProvider: 'Spotify',
        category: 'Comedy',
        type: 'Movie',
        minimumAge: 'PG',
    },
    // Continue adding more objects as needed...
];


