import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import './Avatar.scss'
import ArrowDown from 'assets/icon/arrow.svg'
import { colors } from '@utils/colors';
import { useSelector } from 'react-redux';
import { uiSelector } from 'store/selectors';
import ModelLocator from 'model/model-locator';
import { USerProfileKey } from '../../constants';
import Api from 'services/api';

interface AvatarProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    showName?: boolean;
    showArrowDown?: boolean;
    handleToggleMenu?: () => void;
}

const Avatar: React.FC<AvatarProps> = ({ showName = false, showArrowDown = false, handleToggleMenu = () => { }, ...props }) => {
    const { user } = useAuth0();
    const isDarkMode = useSelector(uiSelector).darkMode;
    const formatName = user?.name?.includes('@') ? user?.name?.split('@')[0] : user?.name;
    const model = ModelLocator.getInstance()

    model.userProfile = user
    localStorage.setItem(USerProfileKey, JSON.stringify(user))

    useEffect(() => {
        const fetchData = async () => {
            await Api.post({ email: user?.email }, '/users')
        }
        fetchData()
    }, [])  

    return (
        <div className="avatar" {...props}>
            <img src={user?.picture} alt="Avatar" className='avatar-img' />
            {showName && <div className="name" style={{ color: isDarkMode ? colors.darkMode.text : colors.darkMode.bgColor }}>{formatName}</div>}
            {showArrowDown && <img src={ArrowDown} onClick={handleToggleMenu} alt="arrow-down" />}
        </div>
    )
}

export default Avatar;