import { RadioSelected, RadioUnselected } from '@components/IconComponents/IconComponents'
import { changeColorMode } from '@utils/colors'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { uiSelector } from 'store/selectors'

export enum AppearanceType {
    Automatic = 'Automatic',
    DarkMode = 'Dark mode',
    LightMode = 'Light mode'
}
const SettingsApperance = () => {
    const isDarkMode = useSelector(uiSelector).darkMode
    const [appearceType, setAppearceType] = useState<AppearanceType>(AppearanceType.Automatic)


    return (
        <div style={{ flexDirection: 'column', display: 'flex', gap: 12, width:'100%' }}>

            <label onClick={() => setAppearceType(AppearanceType.Automatic)} style={{ padding: 16, cursor: 'pointer', marginTop: 7, display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <span style={{ color: changeColorMode(isDarkMode) }}>Automatic</span>
                {appearceType === AppearanceType.Automatic ? <RadioSelected /> : <RadioUnselected />}
            </label>


            <label onClick={() => setAppearceType(AppearanceType.DarkMode)} style={{ padding: 16, cursor: 'pointer', marginTop: 7, display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <span style={{ color: changeColorMode(isDarkMode) }}>Dark mode</span>
                {appearceType === AppearanceType.DarkMode ? <RadioSelected /> : <RadioUnselected />}
            </label>


            <label onClick={() => setAppearceType(AppearanceType.LightMode)} style={{ padding: 16, cursor: 'pointer', marginTop: 7, display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <span style={{ color: changeColorMode(isDarkMode) }}>Light mode</span>
                {appearceType === AppearanceType.LightMode ? <RadioSelected /> : <RadioUnselected />}
            </label>

        </div>
    )
}

export default SettingsApperance