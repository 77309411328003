import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
export const baseUrl = 'https://merge-backend.vercel.app/'

export const api = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl,
        headers: {
            'Content-Type': 'application/json',
        }
    }),

    endpoints: (builder) => ({
        getMovies: builder.query({
            query: () => `/movies`,
        }),
        getTVShows: builder.query({
            query: () => '/tvshows'
        }),
        searchShows: builder.query({
            query: (query) => `/movies/search/${query}`,
        }),
        getMovie: builder.query({
            query: (id) => `/movie/${id}`,
        }),
        getTvShow: builder.query({
            query: (id) => `/tvshow/${id}`,
        }),
        getUserPlatofrms: builder.query({
            query: (id) => `/get-platforms/${id}`,

        }),
        addUserPlatform: builder.mutation({
            query: ({ data, userId }) => ({
                url: `/get-platform/${userId}`,
                method: 'POST',
                body: data,
            }),
        })
    }),
});

export const {
    useGetMoviesQuery,
    useGetTVShowsQuery,
    useLazySearchShowsQuery,
    useSearchShowsQuery,
    useGetMovieQuery,
    useGetTvShowQuery,
    useGetUserPlatofrmsQuery,
    useAddUserPlatformMutation,
} = api;
