import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import thunk from "redux-thunk";
import ui from "./uiSlice";
import streamActionReducer from "reducers/stream/stream-reducer";
import { authenticationReducer } from "reducers/authentication/authentication-reducer";
import music from "./musicSlice";
import storage from "redux-persist/lib/storage";
import { PersistConfig, persistReducer, persistStore } from "redux-persist";
import searchReducer from "reducers/search/search-reducer";
import { api } from "services/movie.services";
import { userReducer } from "../reducers/music/userinfo-reducer";

const reducers = combineReducers({
  search: searchReducer,
  ui,
  streamActionReducer,
  authenticationReducer,
  userReducer,
  music,
  [api.reducerPath]: api.reducer
});

export type RootState = ReturnType<typeof reducers>;

const persistConfig: PersistConfig<RootState> = {
  key: 'root',
  storage,
  whitelist: ['ui'],
  blacklist: ['streamActionReducer', 'authenticationReducer', 'music']
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      // Specify the actions to ignore
      ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE', 'persist/REGISTER'],
    },
  }).concat(api.middleware),
});

export const persistor = persistStore(store)
export default store;
