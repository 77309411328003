import React from "react";
import GoogleIcon from "../assets/icon/streaming_google.svg";
import SpotifyIcon from "../assets/icon/streaming_spotify.svg";
import SoundCloundIcon from "../assets/icon/streaming_soundcloud.svg";
import AppleMusicIcon from "../assets/icon/streaming_applemusic.svg";
import YoutubeIcon from "../assets/icon/streaming_youtubemusic.svg";
import TidalIcon from '../assets/icon/tidal_icon.svg'

import MergeMusicBackground from "../assets/images/merge_background_image_music.png";
import MergeMovieBackground from "../assets/images/merge_background_image_movie.png";
import MergeFoodBackground from "../assets/images/merge_background_image_foods.png";
import MergeSocialsBackground from "../assets/images/merge_background_image_socials.png";
import MergeTransportBackground from "../assets/images/merge_background_image_transports.png";

import NetflixIconStreaming from "../assets/images/netflix_stream_icon.png";
import HuluIconStreaming from "../assets/images/hulu_streaming_icon.png";
import SoundCloudIconStreaming from "../assets/images/soundcloud_streaming_icon.png";
import YoutubeMusicIconStreaming from "../assets/images/youtube_music_streaming_icon.png";
import PrimeVideoIconStreaming from "../assets/images/primevideo_streaming_icon.png";
import SpotifyIconStreaming from "../assets/images/spotify_streaming_icon.png";

import { MusicPlatform, PlatformModel } from "../interfaces/models/model-interfaces";
import { AmazonPrimeIcon, HBOIcon, HuluIcon, NetFlixIcon } from "@components/IconComponents/IconComponents";

export const musicStreamings: PlatformModel[] = [

  {
    id: MusicPlatform.SpotifyMusic,
    name: "Spotify",
    url: "https://www.spotify.com/",
    logo: SpotifyIcon,
    platform: MusicPlatform.SpotifyMusic,
    durationInMillis: 234234
  },
  {
    id: MusicPlatform.AppleMusic,
    name: "Apple Music",
    url: "https://www.spotify.com/",
    logo: AppleMusicIcon,
    platform: MusicPlatform.AppleMusic,
    durationInMillis: 23422
  },
  {
    id: MusicPlatform.YoutubeMusic,
    name: "YouTube Music",
    url: "https://www.google.com/",
    logo: YoutubeIcon,
    platform: MusicPlatform.YoutubeMusic,
    durationInMillis: 2342
  },
  {
    id: MusicPlatform.TidalMusic,
    name: "Tidal Music",
    url: "https://tidal.com/",
    logo: TidalIcon,
    platform: MusicPlatform.TidalMusic,
    durationInMillis: 2342
  },

];

export const videoStreamings = [
  {
    id: 1,
    name: "Netflix",
    url: "https://www.google.com/",
    logo: NetFlixIcon,
    SvgIcon: NetFlixIcon
  },
  {
    id: 2,
    name: "Amazon Prime",
    url: "https://www.spotify.com/",
    logo: AmazonPrimeIcon,
    SvgIcon: AmazonPrimeIcon
  },
  {
    id: 3,
    name: "Hulu",
    url: "https://www.google.com/",
    logo: HuluIcon,
    SvgIcon: HuluIcon
  },
  {
    id: 4,
    name: "HBO Max",
    url: "https://www.spotify.com/",
    logo: HBOIcon,
    SvgIcon: HBOIcon
  },
];

export const ourPlatforms = [
  {
    title: "Merge Music",
    available: true,
    backgroundImage: MergeMusicBackground,
    url: "/music",
  },
  {
    title: "Merge Movie",
    available: true,
    backgroundImage: MergeMovieBackground,
    url: "/merge-movies"
  },
  {
    title: "Merge Food",
    available: false,
    backgroundImage: MergeFoodBackground,
  },
  {
    title: "Merge Socials",
    available: false,
    backgroundImage: MergeSocialsBackground,
  },
  {
    title: "Merge Transport",
    available: false,
    backgroundImage: MergeTransportBackground,
  },
];

export const supportServices = [
  {
    name: "Netflix",
    url: "https://www.netflix.com/",
    img: NetflixIconStreaming,
  },
  {
    name: "Hulu",
    url: "https://www.hulu.com/",
    img: HuluIconStreaming,
  },
  {
    name: "SoundCloud",
    url: "https://www.soundcloud.com/",

    img: SoundCloudIconStreaming,
  },
  {
    name: "YouTube Music",
    url: "https://music.youtube.com/",
    img: YoutubeMusicIconStreaming,
  },
  {
    name: "Prime Video",
    url: "https://www.amazon.com/primevideo",
    img: PrimeVideoIconStreaming,
  },
  {
    name: "Spotify",
    url: "https://www.spotify.com/",
    img: SpotifyIconStreaming,
  },
];
