import React, { useEffect, useState } from 'react';
import Logo from '@components/Logo/Logo';
import { ArrowDownIcon, NotificationIcon } from 'components/IconComponents/IconComponents'
import ProfileComponent from '@components/ProfileComponent/ProfileComponent';
import UserHeaderBarSearch from './UserHeaderBarSearch';
import { useWindowDimensions } from '@hooks/useWindowDimension';
import { useUserHeaderBar } from './useUserHeaderBar';
import { artistsMockedData, musicsMockedData } from 'mockdatas/musics_mocked_data';
import { singers } from 'mockdatas/mockedSingers';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useLocation, useNavigate } from 'react-router-dom';
import './UserHeaderBar.scss'

interface UserHeaderBarProps {
    handleOpenDropdown?: () => void;
}

const UserHeaderBar: React.FC<UserHeaderBarProps> = ({ handleOpenDropdown }) => {
    const navigation = useNavigate()
    const isDarkMode = useSelector((state: RootState) => state.ui.darkMode)
    const searchInputState = useSelector((state: RootState) => state.search.currentSearchInput)
    const { pathname } = useLocation()
    const searchPage = pathname.split('/')[1] === 'search'
    const { isMobile } = useWindowDimensions()
    const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
    const {
        recomendationsItensRef,
        searchActived,
        toggleSearch,
        searchInput,
        handleChangeSearchInput,
        handleClickSearchItem,
        choosedOption,
        closeHeaderSearch,
        showSearchResults
    } = useUserHeaderBar();

    const searchedInput = singers
        .filter(item => item.toLowerCase().includes(searchInput.toLowerCase()))
    // const mergedArtists = [...artistsMockedData, ...musicsMockedData]
    // const filteredArtists = mergedArtists.filter(item => item.songArtist?.toLowerCase().includes(searchInput.toLowerCase()))
    // const transformedArtists = filteredArtists.map(item => ({
    //     ...item,
    //     title: item.songArtist
    // }));



    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'ArrowDown') {
                setSelectedItemIndex(prev => Math.min(prev + 1, searchedInput.length - 1));
            } else if (e.key === 'ArrowUp') {
                setSelectedItemIndex(prev => Math.max(prev - 1, 0));
            } else if (e.key === 'Enter' && selectedItemIndex !== -1) {
                handleClickSearchItem(searchedInput[selectedItemIndex]);
                setSelectedItemIndex(-1);
            } else if (e.key === 'Enter' && (!!searchInputState.length)) {
                handleClickSearchItem(searchInputState);
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [searchedInput]);


    return (
        <div style={{ position: 'sticky', top: 0, zIndex: 99 }}>
            <div className={`user_header`} style={{ background: isDarkMode ? '#293342' : '' }}>
                <div className='left__side'>
                    <Logo disableLink />
                    <ArrowDownIcon strokeColor={isDarkMode ? "#FFF" : '#293342'} onClick={handleOpenDropdown} />
                </div>

                {((searchPage || searchActived) && !isMobile) && <UserHeaderBarSearch
                    fullSize
                    toggleSearch={toggleSearch}
                    closeHeaderSearch={closeHeaderSearch}
                    handleChangeSearchInputType={handleChangeSearchInput}
                    searchActived={searchActived || searchPage}
                    searchInput={searchInputState}
                />}

                <div className='right__side'>
                    {(!searchPage && !searchActived) && <UserHeaderBarSearch
                        searchActived={searchActived}
                        toggleSearch={isMobile ? () => navigation('/search') : toggleSearch}
                    />}
                    {/* <NotificationIcon
                        strokeColor={isDarkMode ? '#FFF' : 'rgb(31, 41, 55)'}
                        onClick={handleOpenDropdown} alt="arrow-down"
                        style={{ cursor: 'pointer' }}
                    /> */}
                    <ProfileComponent />
                </div>
            </div>

            {!isMobile &&
                showSearchResults && !!searchedInput.length &&
                <div className={isMobile ? 'hidden' : `search__recommendeds ${showSearchResults && !!searchedInput.length ? 'active' : ''}`} ref={recomendationsItensRef}>
                    {searchedInput
                        .map((item, index) => (
                            <span
                                key={item}
                                onClick={() => handleClickSearchItem(item)}
                                className={index === selectedItemIndex ? 'selected' : ''}
                            >{item}</span>
                        ))}
                </div>
            }
        </div>
    )
}

export default UserHeaderBar;