import BaseMoviesContainer from '@components/BaseMoviesContainer/BaseMoviesContainer'
import MovieDetails from './components/MovieDetails/MovieDetails'
import MovieButtons from './components/MovieButtons/MovieButtons'
import BackButton from '@components/BackButton/BackButton'
import MoviePageMobile from '@pages/MoviePageMobile/MoviePageMobile'
import moment from 'moment'
import { mockedMoviePage } from './movie_page_mocked'
import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useWindowDimensions } from '@hooks/useWindowDimension'
import { useGetMovieQuery, useGetMoviesQuery } from 'services/movie.services'
import './MoviePage.scss'
import { contentProviders } from '@utils/contentProviderObject'
import { runTimeTransform } from '@utils/runTimeTransform'
import SimiliarMovies from '@components/SimiliarMovies/SimiliarMovies'
import { changeColorMode } from '@utils/colors'
import { useSelector } from 'react-redux'
import { uiSelector } from 'store/selectors'

const MoviePage = () => {
    const searchParams = useLocation()
    const movieId = searchParams.pathname.split('/')[2]
    const { data: movieData, isLoading } = useGetMovieQuery(movieId)
    const { data } = useGetMoviesQuery({})
    const { isMobile, width } = useWindowDimensions()

    const isDarkMode = useSelector(uiSelector).darkMode
    const relatedMovies = () => {
        if (!data) return []
        return data?.filter((movie: any) => movie?.genres && movie?.genres?.some((genre: any) => movieData?.genres?.includes(genre))) || []
    }
    const [movieTrailerStart, setMovieTrailerStart] = useState<boolean>(false)
    const [movieTrailerUrl, setMovieTrailerUrl] = useState<string>('')

    const movieProvider = movieData?.sources.length > 0 ?  movieData?.sources?.find((source: any) => contentProviders.some(item => item.name === source)) :  
       movieData?.availability.find((availibilty: any) =>  contentProviders.some(item => item.name === availibilty.source_name))
           
    const providerLogo = contentProviders.find((item) => item.name === movieProvider)?.logo
    let movieLink = movieData?.availability.find((movie: any) => movie.source_name === movieProvider)?.source_data?.web_link
    if (!movieLink) {
        if (movieData?.availability.length > 0) {
            movieLink = movieData?.availability[0].source_data?.web_link
        }
    }
    const releaseDate = moment(movieData?.released_on).format("YYYY");
    const director = movieData?.talent?.crew?.find((person: any) => person.role_type === 'Director');

    const handlePressTrailer = async () => {
        setMovieTrailerStart(true)
        setMovieTrailerUrl(`https://www.youtube.com/embed/${movieData?.trailer?.key}`)
    }

    const handleCloseTrailer = () => {
        setMovieTrailerStart(false)
        setMovieTrailerUrl('')
    }


    const openIframe = () => {
        window.open(movieLink, 'NewWindow');
    };

    useEffect(() => {

        return () => {
            setMovieTrailerStart(false)
            setMovieTrailerUrl('')
        }
    }, [])

    const IMG_POSTER = `https://img.rgstatic.com/content/movie/${movieData?.id}/poster-780.jpg`


    if (isLoading) return <div>Loading...</div>
    if (!movieData) return <div>Movie not found</div>

    if (isMobile) return (
        <MoviePageMobile
            genres={movieData?.genres}
            watchMovieLink={openIframe}
            backgroundImg={IMG_POSTER}
            description={movieData.overview}
            director={director?.name || ''}
            title={movieData.title}
            contentProvider={providerLogo}
            length={runTimeTransform(movieData.runtime)}
            minAge={movieData.classification}
            releaseDate={releaseDate}
            movieTrailer={movieTrailerUrl}
            movieTrailerStarted={movieTrailerStart}
            handleCloseTrailer={handleCloseTrailer}
            handlePressTrailer={handlePressTrailer}
        />
    )

    if ( !movieLink ) return <></>

    return (
        <BaseMoviesContainer backgroundImageUrl={IMG_POSTER}>
            <div className='movie-page-container'>
                <BackButton />
                <MovieDetails
                    handleCloseTrailer={handleCloseTrailer}
                    movieTrailer={movieTrailerUrl}
                    movieTrailerStarted={movieTrailerStart}
                    movieDescription={movieData.overview}
                    movieDirector={director?.name || ''}
                    movieLength={runTimeTransform(movieData.runtime)}
                    movieReleaseDate={releaseDate}
                    movieTitle={movieData.title}
                    movieMinAge={movieData.classification}
                    contentProvider={providerLogo}

                />
                <MovieButtons handlePressTrailer={handlePressTrailer} watchMovieLink={openIframe} />
                <h3 style={{ margin: '20px 0px', color: changeColorMode(!isDarkMode) }}>Similar to: {movieData.title}</h3>
                <SimiliarMovies similarMovies={relatedMovies() || []} />
            </div>

        </BaseMoviesContainer>
    )
}

export default MoviePage