import {
  ProfileIconSvg,
  CardIconSvg,
  HelpIconsSvg,
  MergeIconSvg,
  SettingIconsSvg,
  MusicIcon,
  PodcastsIcon,
  RadioIcon,
  PlaylistIcon,
  SearchIcon,
  HeartIcon
} from "@components/IconComponents/IconComponents";

export interface CustomList {
  label: string;
  link: string;
  icon: any;
  items?: string[]
}

export const profileDropdownList: CustomList[] = [
  { label: "My Profile", link: "/profile", icon: ProfileIconSvg },
  {
    label: "Merged accounts",
    link: "/merged-accounts",
    icon: MergeIconSvg,
  },
  
  // { label: "Settings", link: "/settings", icon: SettingIconsSvg },
  // { label: "Help", link: "/help", icon: HelpIconsSvg },
];

export const musicLinksList: CustomList[] = [
  {
    icon: MusicIcon,
    label: "Music",
    link: "/music",
  },
  // {
  //   icon: PodcastsIcon,
  //   label: "Podcasts",
  //   link: "/podcasts",
  // },
  {
    icon: PlaylistIcon,
    label: "My Playlists",
    link: '/my-favorites?type=playlists'
  },
  // {
  //   icon: RadioIcon,
  //   label: "Radio",
  //   link: "/radios",
  // },
  {
    icon: HeartIcon,
    label: "My Favorites",
    link: "/my-favorites?type=all",
    items: [
      "All", "Songs", "Albums", "Artists"
    ]
  }, 

];

export const sideBarLinksListMobile: CustomList[] = [
  {
    icon: MusicIcon,
    label: "Music",
    link: "/music",
  },


  {
    icon: SearchIcon,
    label: "Search",
    link: "/search",
  },
  {
    icon: HeartIcon,
    label: "My Favorites",
    link: "/my-favorites",
  },
];


export const movies_menu_links = [
  {
    id: "homepage",
    label: "Home Page",
    link: "/movies"
  },
  {
    id: "mylist",
    label: "My List",
    link: "/movies/mylist"
  },
  {
    id: "movies",
    label: "Movies",
    link: "/movies/movies"
  },
  {
    id: "homepage",
    label: "Home Page",
    link: "/movies"
  },
]