import React from 'react'
import './ContentProvider.scss'
import { contentProvidersDropdown } from '@utils/contentsDropdown';
import useClickOutside from '@hooks/useClickOutside';

interface ContentProviderProps {
    openContentProvider: boolean;
    handleCloseContentProvider: () => void;
    filterHandler: (event: any, filter:any) => void
}
const ContentProvider = ({ openContentProvider, handleCloseContentProvider, filterHandler }: ContentProviderProps) => {

    const contentProviderRef = React.useRef<HTMLDivElement>(null);

    useClickOutside((contentProviderRef), () => { handleCloseContentProvider() });

    return (
        <div
            ref={contentProviderRef}
            className={`absolute-div ${openContentProvider ? 'open' : ''}`}
        >
            {contentProvidersDropdown.map((content, index) => (
                <div key={content.id} className='provider-item'>
                    <img src={content.img} alt="provider" width="20" height="20" />
                    <span>{content.name}</span>
                    <input type="checkbox" className='checkbox-provider' onChange={(event) => filterHandler(event, content.id)} />
                </div>
            ))}
        </div>
    )
}

export default ContentProvider