import { AUTHENTICATION_COMPLETE } from "../../actions/authentication/authentication-actions";

const initialState  = {
    complete: false,
    authState: 0
}

export const authenticationReducer = (state = initialState, action: any) => {
    switch(action.type) {
        case AUTHENTICATION_COMPLETE: 
            return {
                ...state,
                complete: action.payload,
                authState: action.payload ? state.authState + 1 : 0
            }
        default:
            return state
    }
}