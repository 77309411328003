
import { useState, useRef, useEffect } from 'react'
import './Player.scss'
import PlayerConfig from './components/PlayerConfig'
import PlayerDetails from './components/PlayerDetails'
import PlayerButtons from './components/PlayerButtons'
import { useSelector, useDispatch } from 'react-redux'
import { musicSelector, uiSelector } from 'store/selectors'
import { forwardMusic } from 'store/musicSlice'
import { changeColorMode } from '@utils/colors'
import AppleMusicPlayer from '@components/MusicPlayer/apple/apple-music-player'
import { ISong, MusicPlatform } from 'interfaces/models/model-interfaces'
import SpotifyPlayer from 'react-spotify-web-playback';
import SpotifyMusicProviders from 'providers/spofity/SpotifyMusicProvider'
import { AccessToken, SpotifyApi } from '@spotify/web-api-ts-sdk'
import ApplePlaylistManager from 'providers/apple/apple-playlist-manager'
import { UPDATE_APPLE_MUSIC, lockFunction } from '../../constants'

let currentSpotifyToken = 'none'

const Player = () => {
  const isDarkMode = useSelector(uiSelector).darkMode
  const { selectedMusic, queue } = useSelector(musicSelector)
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [spotifyToken, setSpotifyToken] = useState<string | undefined>('')
  const [spotifyTrack, setSpotifyTrack] = useState<string[] | undefined>([])
  const [musicDetails, setMusicDetails] = useState<any>()

  const progressBar = useRef<HTMLInputElement>(null);
  const audioPlayerRef = useRef<HTMLInputElement | any>(null);

  useEffect(() => { // Play Song(s) ****************

    const updateMusic = async () => {
      AppleMusicPlayer.pause()
      setIsPlaying(false)

      if (!isAppleSong() && !isSpotify()) return

      let { songInfo, type, appleSongs, spotifySongs }: any = selectedMusic

      if (isAppleSong()) {
        if (type === 'playlist') {
          ApplePlaylistManager.startPlaylist(appleSongs)
          const currentSong: ISong = ApplePlaylistManager.getCurrentSong() as ISong
          configureTrack(currentSong)
        } else if (type === UPDATE_APPLE_MUSIC) {
          const newSong = ApplePlaylistManager.getCurrentSong()
          configureTrack(newSong)
        } else {
          AppleMusicPlayer.setQueueItems([selectedMusic])
          configureTrack(selectedMusic?.songInfo)
        }
        setIsPlaying(true)
        AppleMusicPlayer.play()
      }

      if (isSpotify()) {

        if (type === 'playlist') {
          setSpotifyTrack(spotifySongs)
        } else {
          const playback: string[] = [songInfo.uri]
          setSpotifyTrack(playback)
        }
        await checkSpotifyToken()
        setIsPlaying(false)
      }

      if (!audioPlayerRef.current) return;
    }

    updateMusic()
      // togglePlayPause()
    }, [selectedMusic])

  const changeRange = () => {
    if (audioPlayerRef.current && progressBar.current) {
      audioPlayerRef.current.currentTime = Number(progressBar.current.value);
      changePlayerCurrentTime();
    }
  }

  const changePlayerCurrentTime = () => {
    if (!progressBar.current || duration === undefined) return;
    const value = Number(progressBar.current.value);

    if (isAppleSong()) {
      const { clientWidth } = progressBar.current
      const ratio = (value + 0) / 100
      //@ts-ignore
      const position: number = parseInt(duration * ratio)
      AppleMusicPlayer.seekToTime(position)
      // setCurrentTime(position)
      return
    }

    setCurrentTime(value);
  }

  const togglePlayPause = () => {
    const prevValue = isPlaying;

    setIsPlaying(!prevValue);

    if (!prevValue) {
      if (audioPlayerRef.current) {
        if (isAppleSong()) {
          AppleMusicPlayer.play()
        } else {
          audioPlayerRef.current.play();
        }

      }
    } else {
      if (audioPlayerRef.current) {
        if (isAppleSong()) {
          AppleMusicPlayer.pause()
        } else {
          audioPlayerRef.current.pause();
        }
      }
    }
  };

  const isAppleSong = () => selectedMusic?.platform === MusicPlatform.AppleMusic
  const isSpotify = () => {
    const isTrue = selectedMusic?.platform === MusicPlatform.SpotifyMusic
    return isTrue
  }

  const handleVolume = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    const amount = Number(value) / 100;

    if (isAppleSong()) {
      AppleMusicPlayer.setVolume(amount)
    }

    audioPlayerRef.current.volume = amount;
  }

  const configureTrack = (currentSong: any) => {
    if (currentSong) {
      const duration: number = currentSong.durationInMillis
      setDuration(duration / 1000)
      setMusicDetails(currentSong)
    }
  }

  useEffect(() => {
    const initializePlatforms = async () => {
      AppleMusicPlayer.addListeners(setCurrentTime, progressBar)

      if (isSpotify()) {
        const spotifySdk: SpotifyApi = SpotifyMusicProviders.getApi()
        // @ts-ignore
        const token: AccessToken = await spotifySdk.getAccessToken()
        setSpotifyToken(token.access_token)
      }

    }

    initializePlatforms()

  }, [spotifyToken])

  const checkSpotifyToken = async () => {
    const spotifySdk = SpotifyMusicProviders.getApi()
    const { access_token }: any = await spotifySdk.getAccessToken()
    if (access_token && access_token !== currentSpotifyToken) {
      setSpotifyToken(access_token)
    }
  }

  if (!isAppleSong() && !isSpotify()) return null

  if (isAppleSong() && !musicDetails) {
    return null;
  }
  return (
    <>
      {isSpotify() ?

        (<div className='player_container' style={{ background: changeColorMode(!isDarkMode), zIndex: 999999999 }}>

          <SpotifyPlayer
            // @ts-ignore
            token={spotifyToken}
            // @ts-ignore       
            uris={spotifyTrack}
            // autoPlay={true}

          />
        </div>) :

        (<div className='player_container' style={{ background: changeColorMode(!isDarkMode), zIndex: 999999999 }}>
          <PlayerDetails musicDetails={musicDetails} />
          <PlayerButtons
            isAppleSong={isAppleSong}
            isPlaying={isPlaying}
            togglePlayPause={togglePlayPause}
            audioPlayerRef={audioPlayerRef}
            progressBar={progressBar}
            currentTime={currentTime}
            duration={duration}
            changeRange={changeRange} />

          <PlayerConfig audioPlayerRef={audioPlayerRef} handleVolume={handleVolume} />
          <audio ref={audioPlayerRef} src={musicDetails?.url} /> </div>)
      }
    </>
  )
}

export default Player;
