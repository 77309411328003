import React, { useRef } from 'react';
import './ChooseExperienceDropdown.scss';
import MainMergeIcon from '../../assets/icon/merge_main_icon.svg'
import useClickOutside from '@hooks/useClickOutside';
import { useLocation, useNavigate } from 'react-router-dom'
import Arrow from '../../assets/icon/arrow.svg'
import { useSelector } from 'react-redux';
import { uiSelector } from 'store/selectors';
import { changeColorMode } from '@utils/colors';
import createDom from 'react-dom'

interface ChooseExperienceDropdownProps {
    contentVisible?: boolean;
    handleCloseDropdown: () => void;
}

const experienceList = [
    {
        id: 'music',
        title: 'Music',
    },
    {
        id: 'merge-movies',
        title: 'Movies',
    },
    {
        id: 'food',
        title: 'Food',
        commingSoon: true,
    },
    {
        id: 'socials',
        title: 'Socials',
        commingSoon: true,
    },
    {
        id: 'transport',
        title: 'Transport',
        commingSoon: true,
    },
]

const ChooseExperienceDropdown: React.FC<ChooseExperienceDropdownProps> = ({ contentVisible = true, handleCloseDropdown }) => {
    const navigation = useNavigate();
    const location = useLocation();
    const pathName = location.pathname.replace('/', '')
    const isDarkMode = useSelector(uiSelector).darkMode

    const filterByPathName = experienceList.filter((item) => item.id !== pathName)

    const dropdownRef = useRef<HTMLDivElement>(null);
    useClickOutside(dropdownRef, () => {
        handleCloseDropdown()
    });

    const handleClickNavigation = (path: string) => {
        handleCloseDropdown()
        navigation({
            pathname: `/${path}`,
        })
    }



    return createDom.createPortal(
        <>
            <div className={`choose_experience_dropdown__outside ${contentVisible ? 'visible' : ''}`} onClick={handleCloseDropdown} />
            <div style={{ backgroundColor: changeColorMode(!isDarkMode) }} ref={dropdownRef} className={`choose_experience_dropdown__container ${contentVisible ? 'visible' : ''} `}>
                <div className='choose_experience__top'>
                    <img alt='dropdown-menu' onClick={handleCloseDropdown} src={Arrow} style={{
                        transform: 'rotate(90deg)'
                    }} />
                    <h4 style={{ color: changeColorMode(isDarkMode) }}>Choose experience</h4>
                </div>
                <ul className='ul_dropdown'>
                    {filterByPathName.map((item) => {
                        return (
                            <li key={item.id} onClick={item.commingSoon ? () => { } : () => handleClickNavigation(item.id)} style={{
                                opacity: item.commingSoon ? 0.5 : 1,
                            }}>
                                <img src={MainMergeIcon} alt="" />
                                <h4 className={`title_stream_${item.id}`}>{item.title}</h4>
                                {item.commingSoon && <small
                                    style={{ color: changeColorMode(isDarkMode), }}
                                    className='comming-soon-text'>Comming soon</small>}
                            </li>
                        )
                    })}
                </ul>
            </div>
        </>
        , document.getElementById('choose-experience-root') as Element)
}

export default ChooseExperienceDropdown;