import Avatar from '@components/Avatar/Avatar';
import Base from 'template/Base';
import ProfilePageChangePassword from './ProfilePageChangePassword/ProfilePageChangePassword';
import React, { useState } from 'react';
import ProfilePageForm from './ProfilePageForm/ProfilePageForm';
import ProfilePageUserInfo from './ProfilePageUserInfo/ProfilePageUserInfo';
import { profileDropdownList } from '@utils/navigationsLinks/profileDropdownList';

import './ProfilePage.scss'
import { useSelector } from 'react-redux';
import { uiSelector } from 'store/selectors';
import { changeColorMode } from '@utils/colors';
import { ArrowDownIcon } from '@components/IconComponents/IconComponents';
import { useWindowDimensions } from '@hooks/useWindowDimension';


const ProfilePage: React.FC = () => {

    const { isMobile } = useWindowDimensions()
    const isDarkMode = useSelector(uiSelector).darkMode;
    const [editProfile, setEditProfile] = useState(false);
    const [profileTitle, setProfileTitle] = useState('My profile');

    const [showModal, setShowModal] = useState(false);

    const toggleModal = () => {
        setShowModal(pS => !pS);
    }

    return (
        <Base sidebarList={isMobile ? [] : profileDropdownList}>
            <ProfilePageChangePassword modalVisible={showModal} setModalVisible={setShowModal} />
            <div className='profile_container'>


                <div className='profile__content'>
                    <div className='__myProfile'>
                        <ArrowDownIcon strokeColor={changeColorMode(isDarkMode)} style={{ transform: 'rotate(90deg)' }} />
                        <h2 className='__title' style={{ color: changeColorMode(isDarkMode) }}>{profileTitle}</h2>
                    </div>
                    <Avatar className='profile__avatar' />
                    {editProfile ? <ProfilePageForm setEditProfile={setEditProfile} /> :
                        <>
                            <ProfilePageUserInfo />
                            {/* <Button onClick={() => setEditProfile(!editProfile)} additionalClassName='edit_profile_button'>
                                <span>Edit Profile</span>
                                <img src={PencilIcon} alt='pencil-img' />
                            </Button> */}
                        </>
                    }
                </div>

                <hr className='divider' />

                {/* <div className='password__container'>
                    <h2 className='password__title' style={{ color: changeColorMode(isDarkMode) }}>Password</h2>
                    <div className='password__content'>
                        <img src={TickIcon} alt="tick-img" />
                        <span style={{ color: changeColorMode(isDarkMode) }}>Your password has been set</span>
                        <Button buttonText='Change Password' additionalClassName='change_password_button' onClick={toggleModal}>
                            <span>Change Password</span>
                        </Button>
                    </div>
                </div> */}

            </div>

        </Base>
    )
}

export default ProfilePage;