import { useSelector } from 'react-redux';
import { uiSelector } from 'store/selectors';
import { changeColorMode } from '@utils/colors';
import { IMovie } from 'interfaces/models/model-interfaces';
import './MovieDetails.scss'
import { CloseIcon } from '@components/IconComponents/IconComponents';

interface MovieDetailsProps extends IMovie {
    tvShowTrailerUrl?: string
    tvShowStarted?: boolean
    handleCloseTrailer?: () => void
    seasonNumber?: number
    backdropImage?: string
}

const MovieDetails = ({
    movieDescription,
    movieDirector,
    movieLength,
    movieReleaseDate,
    movieTitle,
    movieMinAge,
    contentProvider,
    tvShowTrailerUrl,
    tvShowStarted,
    handleCloseTrailer,
    seasonNumber,
    backdropImage
}: MovieDetailsProps) => {

    const isDarkMode = useSelector(uiSelector).darkMode

    return (
        <div className='movie-details-container'>
            {tvShowStarted &&
                <div className='movie-trailer-container'>
                    <CloseIcon strokeColor='#FFF' style={{
                        position: 'absolute',
                        right: 20,
                        top: 20,
                        cursor: 'pointer',
                        height: 30,
                    }}
                        onClick={handleCloseTrailer}
                    />
                    <iframe
                        className='movie-trailer'
                        src={tvShowTrailerUrl}
                        title="YouTube video player"
                        frameBorder="0"
                        allowFullScreen
                    ></iframe>
                </div>
            }
            <div style={{
                display: 'flex'
            }}>


                <img className='movie-image' src={backdropImage} alt='movie' style={{
                    width: '248px',
                    height: '356px',
                    borderRadius: 16,
                    marginRight: 24
                }} />
                <div>
                    <h1 className='movie-title' style={{ color: changeColorMode(!isDarkMode) }}>{movieTitle}</h1>
                    <div className='movie-info-container'>
                        <span style={{
                            color: changeColorMode(!isDarkMode)
                        }}>
                            {!!seasonNumber ? `${seasonNumber} season${seasonNumber > 1 ? 's' : ''}`
                                : movieLength}  •</span>
                        <span style={{ color: changeColorMode(!isDarkMode) }}>{movieReleaseDate}  •</span>
                        <span style={{
                            color: changeColorMode(!isDarkMode), borderRadius: 6,
                            padding: 6,
                            border: `1px solid ${changeColorMode(!isDarkMode)}`
                        }}>{movieMinAge}</span>
                        <span style={{ color: changeColorMode(!isDarkMode) }}>  •</span>
                        {!!contentProvider && <img src={contentProvider} alt={contentProvider} />}
                    </div>
                    <p className='movie-description' style={{ color: changeColorMode(!isDarkMode, '#E5E7EB') }}>{movieDescription}</p>
                    {/* <p className='movie-director' style={{ color: changeColorMode(isDarkMode) }}>Director: {movieDirector}</p> */}
                </div>
            </div>

        </div>
    )
}

export default MovieDetails