const TidalResponse = {
    "albums": [
        {
            "resource": {
                "id": "1781800",
                "barcodeId": "074643811224",
                "title": "Thriller",
                "artists": [
                    {
                        "id": "606",
                        "name": "Michael Jackson",
                        "picture": [
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1024x256.jpg",
                                "width": 1024,
                                "height": 256
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1080x720.jpg",
                                "width": 1080,
                                "height": 720
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x107.jpg",
                                "width": 160,
                                "height": 107
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x160.jpg",
                                "width": 160,
                                "height": 160
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x214.jpg",
                                "width": 320,
                                "height": 214
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x320.jpg",
                                "width": 320,
                                "height": 320
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/480x480.jpg",
                                "width": 480,
                                "height": 480
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/640x428.jpg",
                                "width": 640,
                                "height": 428
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x500.jpg",
                                "width": 750,
                                "height": 500
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x750.jpg",
                                "width": 750,
                                "height": 750
                            }
                        ],
                        "main": true
                    }
                ],
                "duration": 2538,
                "releaseDate": "1982-11-30",
                "imageCover": [
                    {
                        "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/1080x1080.jpg",
                        "width": 1080,
                        "height": 1080
                    },
                    {
                        "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/1280x1280.jpg",
                        "width": 1280,
                        "height": 1280
                    },
                    {
                        "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/160x160.jpg",
                        "width": 160,
                        "height": 160
                    },
                    {
                        "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/320x320.jpg",
                        "width": 320,
                        "height": 320
                    },
                    {
                        "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/640x640.jpg",
                        "width": 640,
                        "height": 640
                    },
                    {
                        "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/750x750.jpg",
                        "width": 750,
                        "height": 750
                    },
                    {
                        "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/80x80.jpg",
                        "width": 80,
                        "height": 80
                    }
                ],
                "videoCover": [],
                "numberOfVolumes": 1,
                "numberOfTracks": 9,
                "numberOfVideos": 0,
                "type": "ALBUM",
                "copyright": "(P) 1982 MJJ Productions Inc.",
                "mediaMetadata": {
                    "tags": [
                        "HIRES_LOSSLESS",
                        "LOSSLESS",
                        "MQA"
                    ]
                },
                "properties": {}
            },
            "id": "1781800",
            "status": 200,
            "message": "success"
        },
        {
            "resource": {
                "id": "19994074",
                "barcodeId": "074643574525",
                "title": "Off the Wall",
                "artists": [
                    {
                        "id": "606",
                        "name": "Michael Jackson",
                        "picture": [
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1024x256.jpg",
                                "width": 1024,
                                "height": 256
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1080x720.jpg",
                                "width": 1080,
                                "height": 720
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x107.jpg",
                                "width": 160,
                                "height": 107
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x160.jpg",
                                "width": 160,
                                "height": 160
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x214.jpg",
                                "width": 320,
                                "height": 214
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x320.jpg",
                                "width": 320,
                                "height": 320
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/480x480.jpg",
                                "width": 480,
                                "height": 480
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/640x428.jpg",
                                "width": 640,
                                "height": 428
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x500.jpg",
                                "width": 750,
                                "height": 500
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x750.jpg",
                                "width": 750,
                                "height": 750
                            }
                        ],
                        "main": true
                    }
                ],
                "duration": 2542,
                "releaseDate": "1979-08-10",
                "imageCover": [
                    {
                        "url": "https://resources.tidal.com/images/79dcc832/19fa/4402/bb44/dae56b6b0e21/1080x1080.jpg",
                        "width": 1080,
                        "height": 1080
                    },
                    {
                        "url": "https://resources.tidal.com/images/79dcc832/19fa/4402/bb44/dae56b6b0e21/1280x1280.jpg",
                        "width": 1280,
                        "height": 1280
                    },
                    {
                        "url": "https://resources.tidal.com/images/79dcc832/19fa/4402/bb44/dae56b6b0e21/160x160.jpg",
                        "width": 160,
                        "height": 160
                    },
                    {
                        "url": "https://resources.tidal.com/images/79dcc832/19fa/4402/bb44/dae56b6b0e21/320x320.jpg",
                        "width": 320,
                        "height": 320
                    },
                    {
                        "url": "https://resources.tidal.com/images/79dcc832/19fa/4402/bb44/dae56b6b0e21/640x640.jpg",
                        "width": 640,
                        "height": 640
                    },
                    {
                        "url": "https://resources.tidal.com/images/79dcc832/19fa/4402/bb44/dae56b6b0e21/750x750.jpg",
                        "width": 750,
                        "height": 750
                    },
                    {
                        "url": "https://resources.tidal.com/images/79dcc832/19fa/4402/bb44/dae56b6b0e21/80x80.jpg",
                        "width": 80,
                        "height": 80
                    }
                ],
                "videoCover": [],
                "numberOfVolumes": 1,
                "numberOfTracks": 10,
                "numberOfVideos": 0,
                "type": "ALBUM",
                "copyright": "(P) 1979 MJJ Productions Inc.",
                "mediaMetadata": {
                    "tags": [
                        "HIRES_LOSSLESS",
                        "LOSSLESS",
                        "MQA"
                    ]
                },
                "properties": {}
            },
            "id": "19994074",
            "status": 200,
            "message": "success"
        },
        {
            "resource": {
                "id": "5279052",
                "barcodeId": "884977149166",
                "title": "HIStory - PAST, PRESENT AND FUTURE - BOOK I",
                "artists": [
                    {
                        "id": "606",
                        "name": "Michael Jackson",
                        "picture": [
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1024x256.jpg",
                                "width": 1024,
                                "height": 256
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1080x720.jpg",
                                "width": 1080,
                                "height": 720
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x107.jpg",
                                "width": 160,
                                "height": 107
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x160.jpg",
                                "width": 160,
                                "height": 160
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x214.jpg",
                                "width": 320,
                                "height": 214
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x320.jpg",
                                "width": 320,
                                "height": 320
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/480x480.jpg",
                                "width": 480,
                                "height": 480
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/640x428.jpg",
                                "width": 640,
                                "height": 428
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x500.jpg",
                                "width": 750,
                                "height": 500
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x750.jpg",
                                "width": 750,
                                "height": 750
                            }
                        ],
                        "main": true
                    }
                ],
                "duration": 8927,
                "releaseDate": "1995-06-20",
                "imageCover": [
                    {
                        "url": "https://resources.tidal.com/images/a2fa5815/851d/4d2d/b6a7/17a365c838f9/1080x1080.jpg",
                        "width": 1080,
                        "height": 1080
                    },
                    {
                        "url": "https://resources.tidal.com/images/a2fa5815/851d/4d2d/b6a7/17a365c838f9/1280x1280.jpg",
                        "width": 1280,
                        "height": 1280
                    },
                    {
                        "url": "https://resources.tidal.com/images/a2fa5815/851d/4d2d/b6a7/17a365c838f9/160x160.jpg",
                        "width": 160,
                        "height": 160
                    },
                    {
                        "url": "https://resources.tidal.com/images/a2fa5815/851d/4d2d/b6a7/17a365c838f9/320x320.jpg",
                        "width": 320,
                        "height": 320
                    },
                    {
                        "url": "https://resources.tidal.com/images/a2fa5815/851d/4d2d/b6a7/17a365c838f9/640x640.jpg",
                        "width": 640,
                        "height": 640
                    },
                    {
                        "url": "https://resources.tidal.com/images/a2fa5815/851d/4d2d/b6a7/17a365c838f9/750x750.jpg",
                        "width": 750,
                        "height": 750
                    },
                    {
                        "url": "https://resources.tidal.com/images/a2fa5815/851d/4d2d/b6a7/17a365c838f9/80x80.jpg",
                        "width": 80,
                        "height": 80
                    }
                ],
                "videoCover": [],
                "numberOfVolumes": 2,
                "numberOfTracks": 30,
                "numberOfVideos": 0,
                "type": "ALBUM",
                "copyright": "(P) 1979, 1982, 1987, 1988, 1991, 1995 MJJ Productions Inc.",
                "mediaMetadata": {
                    "tags": [
                        "HIRES_LOSSLESS",
                        "LOSSLESS",
                        "MQA"
                    ]
                },
                "properties": {
                    "content": [
                        "explicit"
                    ]
                }
            },
            "id": "5279052",
            "status": 200,
            "message": "success"
        },
        {
            "resource": {
                "id": "16953800",
                "barcodeId": "886443546264",
                "title": "Bad ((Remastered))",
                "artists": [
                    {
                        "id": "606",
                        "name": "Michael Jackson",
                        "picture": [
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1024x256.jpg",
                                "width": 1024,
                                "height": 256
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1080x720.jpg",
                                "width": 1080,
                                "height": 720
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x107.jpg",
                                "width": 160,
                                "height": 107
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x160.jpg",
                                "width": 160,
                                "height": 160
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x214.jpg",
                                "width": 320,
                                "height": 214
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x320.jpg",
                                "width": 320,
                                "height": 320
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/480x480.jpg",
                                "width": 480,
                                "height": 480
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/640x428.jpg",
                                "width": 640,
                                "height": 428
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x500.jpg",
                                "width": 750,
                                "height": 500
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x750.jpg",
                                "width": 750,
                                "height": 750
                            }
                        ],
                        "main": true
                    }
                ],
                "duration": 2894,
                "releaseDate": "1987-09-01",
                "imageCover": [
                    {
                        "url": "https://resources.tidal.com/images/11aa6bd0/7813/482c/b2f1/3fecb47f4ac3/1080x1080.jpg",
                        "width": 1080,
                        "height": 1080
                    },
                    {
                        "url": "https://resources.tidal.com/images/11aa6bd0/7813/482c/b2f1/3fecb47f4ac3/1280x1280.jpg",
                        "width": 1280,
                        "height": 1280
                    },
                    {
                        "url": "https://resources.tidal.com/images/11aa6bd0/7813/482c/b2f1/3fecb47f4ac3/160x160.jpg",
                        "width": 160,
                        "height": 160
                    },
                    {
                        "url": "https://resources.tidal.com/images/11aa6bd0/7813/482c/b2f1/3fecb47f4ac3/320x320.jpg",
                        "width": 320,
                        "height": 320
                    },
                    {
                        "url": "https://resources.tidal.com/images/11aa6bd0/7813/482c/b2f1/3fecb47f4ac3/640x640.jpg",
                        "width": 640,
                        "height": 640
                    },
                    {
                        "url": "https://resources.tidal.com/images/11aa6bd0/7813/482c/b2f1/3fecb47f4ac3/750x750.jpg",
                        "width": 750,
                        "height": 750
                    },
                    {
                        "url": "https://resources.tidal.com/images/11aa6bd0/7813/482c/b2f1/3fecb47f4ac3/80x80.jpg",
                        "width": 80,
                        "height": 80
                    }
                ],
                "videoCover": [],
                "numberOfVolumes": 1,
                "numberOfTracks": 11,
                "numberOfVideos": 0,
                "type": "ALBUM",
                "copyright": "(P) 2012 MJJ Productions Inc.",
                "mediaMetadata": {
                    "tags": [
                        "HIRES_LOSSLESS",
                        "LOSSLESS",
                        "MQA"
                    ]
                },
                "properties": {}
            },
            "id": "16953800",
            "status": 200,
            "message": "success"
        },
        {
            "resource": {
                "id": "19193410",
                "barcodeId": "074646940020",
                "title": "Invincible",
                "artists": [
                    {
                        "id": "606",
                        "name": "Michael Jackson",
                        "picture": [
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1024x256.jpg",
                                "width": 1024,
                                "height": 256
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1080x720.jpg",
                                "width": 1080,
                                "height": 720
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x107.jpg",
                                "width": 160,
                                "height": 107
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x160.jpg",
                                "width": 160,
                                "height": 160
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x214.jpg",
                                "width": 320,
                                "height": 214
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x320.jpg",
                                "width": 320,
                                "height": 320
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/480x480.jpg",
                                "width": 480,
                                "height": 480
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/640x428.jpg",
                                "width": 640,
                                "height": 428
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x500.jpg",
                                "width": 750,
                                "height": 500
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x750.jpg",
                                "width": 750,
                                "height": 750
                            }
                        ],
                        "main": true
                    }
                ],
                "duration": 4634,
                "releaseDate": "2001-10-31",
                "imageCover": [
                    {
                        "url": "https://resources.tidal.com/images/c002e814/0304/4301/901f/e4c0a7b96449/1080x1080.jpg",
                        "width": 1080,
                        "height": 1080
                    },
                    {
                        "url": "https://resources.tidal.com/images/c002e814/0304/4301/901f/e4c0a7b96449/1280x1280.jpg",
                        "width": 1280,
                        "height": 1280
                    },
                    {
                        "url": "https://resources.tidal.com/images/c002e814/0304/4301/901f/e4c0a7b96449/160x160.jpg",
                        "width": 160,
                        "height": 160
                    },
                    {
                        "url": "https://resources.tidal.com/images/c002e814/0304/4301/901f/e4c0a7b96449/320x320.jpg",
                        "width": 320,
                        "height": 320
                    },
                    {
                        "url": "https://resources.tidal.com/images/c002e814/0304/4301/901f/e4c0a7b96449/640x640.jpg",
                        "width": 640,
                        "height": 640
                    },
                    {
                        "url": "https://resources.tidal.com/images/c002e814/0304/4301/901f/e4c0a7b96449/750x750.jpg",
                        "width": 750,
                        "height": 750
                    },
                    {
                        "url": "https://resources.tidal.com/images/c002e814/0304/4301/901f/e4c0a7b96449/80x80.jpg",
                        "width": 80,
                        "height": 80
                    }
                ],
                "videoCover": [],
                "numberOfVolumes": 1,
                "numberOfTracks": 16,
                "numberOfVideos": 0,
                "type": "ALBUM",
                "copyright": "(P) 2001 MJJ Productions Inc.",
                "mediaMetadata": {
                    "tags": [
                        "HIRES_LOSSLESS",
                        "LOSSLESS",
                        "MQA"
                    ]
                },
                "properties": {}
            },
            "id": "19193410",
            "status": 200,
            "message": "success"
        },
        {
            "resource": {
                "id": "19193427",
                "barcodeId": "884977307832",
                "title": "Dangerous",
                "artists": [
                    {
                        "id": "606",
                        "name": "Michael Jackson",
                        "picture": [
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1024x256.jpg",
                                "width": 1024,
                                "height": 256
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1080x720.jpg",
                                "width": 1080,
                                "height": 720
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x107.jpg",
                                "width": 160,
                                "height": 107
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x160.jpg",
                                "width": 160,
                                "height": 160
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x214.jpg",
                                "width": 320,
                                "height": 214
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x320.jpg",
                                "width": 320,
                                "height": 320
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/480x480.jpg",
                                "width": 480,
                                "height": 480
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/640x428.jpg",
                                "width": 640,
                                "height": 428
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x500.jpg",
                                "width": 750,
                                "height": 500
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x750.jpg",
                                "width": 750,
                                "height": 750
                            }
                        ],
                        "main": true
                    }
                ],
                "duration": 4624,
                "releaseDate": "1991-11-19",
                "imageCover": [
                    {
                        "url": "https://resources.tidal.com/images/75311fe6/1837/4d80/b89b/c8a9e1bf8eb0/1080x1080.jpg",
                        "width": 1080,
                        "height": 1080
                    },
                    {
                        "url": "https://resources.tidal.com/images/75311fe6/1837/4d80/b89b/c8a9e1bf8eb0/1280x1280.jpg",
                        "width": 1280,
                        "height": 1280
                    },
                    {
                        "url": "https://resources.tidal.com/images/75311fe6/1837/4d80/b89b/c8a9e1bf8eb0/160x160.jpg",
                        "width": 160,
                        "height": 160
                    },
                    {
                        "url": "https://resources.tidal.com/images/75311fe6/1837/4d80/b89b/c8a9e1bf8eb0/320x320.jpg",
                        "width": 320,
                        "height": 320
                    },
                    {
                        "url": "https://resources.tidal.com/images/75311fe6/1837/4d80/b89b/c8a9e1bf8eb0/640x640.jpg",
                        "width": 640,
                        "height": 640
                    },
                    {
                        "url": "https://resources.tidal.com/images/75311fe6/1837/4d80/b89b/c8a9e1bf8eb0/750x750.jpg",
                        "width": 750,
                        "height": 750
                    },
                    {
                        "url": "https://resources.tidal.com/images/75311fe6/1837/4d80/b89b/c8a9e1bf8eb0/80x80.jpg",
                        "width": 80,
                        "height": 80
                    }
                ],
                "videoCover": [],
                "numberOfVolumes": 1,
                "numberOfTracks": 14,
                "numberOfVideos": 0,
                "type": "ALBUM",
                "copyright": "(P) 1991 MJJ Productions Inc.",
                "mediaMetadata": {
                    "tags": [
                        "HIRES_LOSSLESS",
                        "LOSSLESS",
                        "MQA"
                    ]
                },
                "properties": {}
            },
            "id": "19193427",
            "status": 200,
            "message": "success"
        },
        {
            "resource": {
                "id": "29492300",
                "barcodeId": "886444557351",
                "title": "XSCAPE",
                "artists": [
                    {
                        "id": "606",
                        "name": "Michael Jackson",
                        "picture": [
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1024x256.jpg",
                                "width": 1024,
                                "height": 256
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1080x720.jpg",
                                "width": 1080,
                                "height": 720
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x107.jpg",
                                "width": 160,
                                "height": 107
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x160.jpg",
                                "width": 160,
                                "height": 160
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x214.jpg",
                                "width": 320,
                                "height": 214
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x320.jpg",
                                "width": 320,
                                "height": 320
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/480x480.jpg",
                                "width": 480,
                                "height": 480
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/640x428.jpg",
                                "width": 640,
                                "height": 428
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x500.jpg",
                                "width": 750,
                                "height": 500
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x750.jpg",
                                "width": 750,
                                "height": 750
                            }
                        ],
                        "main": true
                    }
                ],
                "duration": 4403,
                "releaseDate": "2014-05-12",
                "imageCover": [
                    {
                        "url": "https://resources.tidal.com/images/92ab7280/3525/4e23/946c/aef8835f509f/1080x1080.jpg",
                        "width": 1080,
                        "height": 1080
                    },
                    {
                        "url": "https://resources.tidal.com/images/92ab7280/3525/4e23/946c/aef8835f509f/1280x1280.jpg",
                        "width": 1280,
                        "height": 1280
                    },
                    {
                        "url": "https://resources.tidal.com/images/92ab7280/3525/4e23/946c/aef8835f509f/160x160.jpg",
                        "width": 160,
                        "height": 160
                    },
                    {
                        "url": "https://resources.tidal.com/images/92ab7280/3525/4e23/946c/aef8835f509f/320x320.jpg",
                        "width": 320,
                        "height": 320
                    },
                    {
                        "url": "https://resources.tidal.com/images/92ab7280/3525/4e23/946c/aef8835f509f/640x640.jpg",
                        "width": 640,
                        "height": 640
                    },
                    {
                        "url": "https://resources.tidal.com/images/92ab7280/3525/4e23/946c/aef8835f509f/750x750.jpg",
                        "width": 750,
                        "height": 750
                    },
                    {
                        "url": "https://resources.tidal.com/images/92ab7280/3525/4e23/946c/aef8835f509f/80x80.jpg",
                        "width": 80,
                        "height": 80
                    }
                ],
                "videoCover": [],
                "numberOfVolumes": 1,
                "numberOfTracks": 17,
                "numberOfVideos": 0,
                "type": "ALBUM",
                "copyright": "(P) 2014 MJJ Productions, Inc.",
                "mediaMetadata": {
                    "tags": [
                        "HIRES_LOSSLESS",
                        "LOSSLESS",
                        "MQA"
                    ]
                },
                "properties": {}
            },
            "id": "29492300",
            "status": 200,
            "message": "success"
        },
        {
            "resource": {
                "id": "3149321",
                "barcodeId": "884977399295",
                "title": "Michael Jackson's This Is It",
                "artists": [
                    {
                        "id": "606",
                        "name": "Michael Jackson",
                        "picture": [
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1024x256.jpg",
                                "width": 1024,
                                "height": 256
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1080x720.jpg",
                                "width": 1080,
                                "height": 720
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x107.jpg",
                                "width": 160,
                                "height": 107
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x160.jpg",
                                "width": 160,
                                "height": 160
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x214.jpg",
                                "width": 320,
                                "height": 214
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x320.jpg",
                                "width": 320,
                                "height": 320
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/480x480.jpg",
                                "width": 480,
                                "height": 480
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/640x428.jpg",
                                "width": 640,
                                "height": 428
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x500.jpg",
                                "width": 750,
                                "height": 500
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x750.jpg",
                                "width": 750,
                                "height": 750
                            }
                        ],
                        "main": true
                    }
                ],
                "duration": 5534,
                "releaseDate": "2009-10-27",
                "imageCover": [
                    {
                        "url": "https://resources.tidal.com/images/96eb5e3f/972a/4a84/9b7b/249436a424c3/1080x1080.jpg",
                        "width": 1080,
                        "height": 1080
                    },
                    {
                        "url": "https://resources.tidal.com/images/96eb5e3f/972a/4a84/9b7b/249436a424c3/1280x1280.jpg",
                        "width": 1280,
                        "height": 1280
                    },
                    {
                        "url": "https://resources.tidal.com/images/96eb5e3f/972a/4a84/9b7b/249436a424c3/160x160.jpg",
                        "width": 160,
                        "height": 160
                    },
                    {
                        "url": "https://resources.tidal.com/images/96eb5e3f/972a/4a84/9b7b/249436a424c3/320x320.jpg",
                        "width": 320,
                        "height": 320
                    },
                    {
                        "url": "https://resources.tidal.com/images/96eb5e3f/972a/4a84/9b7b/249436a424c3/640x640.jpg",
                        "width": 640,
                        "height": 640
                    },
                    {
                        "url": "https://resources.tidal.com/images/96eb5e3f/972a/4a84/9b7b/249436a424c3/750x750.jpg",
                        "width": 750,
                        "height": 750
                    },
                    {
                        "url": "https://resources.tidal.com/images/96eb5e3f/972a/4a84/9b7b/249436a424c3/80x80.jpg",
                        "width": 80,
                        "height": 80
                    }
                ],
                "videoCover": [],
                "numberOfVolumes": 2,
                "numberOfTracks": 20,
                "numberOfVideos": 0,
                "type": "ALBUM",
                "copyright": "(P) 1979, 1982, 1987, 1991, 1995, 2009 MJJ Productions Inc./(P) 2009 MJJ Productions Inc.",
                "mediaMetadata": {
                    "tags": [
                        "LOSSLESS",
                        "MQA"
                    ]
                },
                "properties": {}
            },
            "id": "3149321",
            "status": 200,
            "message": "success"
        },
        {
            "resource": {
                "id": "11335930",
                "barcodeId": "827969428726",
                "title": "The Essential Michael Jackson",
                "artists": [
                    {
                        "id": "606",
                        "name": "Michael Jackson",
                        "picture": [
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1024x256.jpg",
                                "width": 1024,
                                "height": 256
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1080x720.jpg",
                                "width": 1080,
                                "height": 720
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x107.jpg",
                                "width": 160,
                                "height": 107
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x160.jpg",
                                "width": 160,
                                "height": 160
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x214.jpg",
                                "width": 320,
                                "height": 214
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x320.jpg",
                                "width": 320,
                                "height": 320
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/480x480.jpg",
                                "width": 480,
                                "height": 480
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/640x428.jpg",
                                "width": 640,
                                "height": 428
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x500.jpg",
                                "width": 750,
                                "height": 500
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x750.jpg",
                                "width": 750,
                                "height": 750
                            }
                        ],
                        "main": true
                    }
                ],
                "duration": 9570,
                "releaseDate": "2005-07-19",
                "imageCover": [
                    {
                        "url": "https://resources.tidal.com/images/84e558cb/2652/44b7/86de/e9d7cc70c00c/1080x1080.jpg",
                        "width": 1080,
                        "height": 1080
                    },
                    {
                        "url": "https://resources.tidal.com/images/84e558cb/2652/44b7/86de/e9d7cc70c00c/1280x1280.jpg",
                        "width": 1280,
                        "height": 1280
                    },
                    {
                        "url": "https://resources.tidal.com/images/84e558cb/2652/44b7/86de/e9d7cc70c00c/160x160.jpg",
                        "width": 160,
                        "height": 160
                    },
                    {
                        "url": "https://resources.tidal.com/images/84e558cb/2652/44b7/86de/e9d7cc70c00c/320x320.jpg",
                        "width": 320,
                        "height": 320
                    },
                    {
                        "url": "https://resources.tidal.com/images/84e558cb/2652/44b7/86de/e9d7cc70c00c/640x640.jpg",
                        "width": 640,
                        "height": 640
                    },
                    {
                        "url": "https://resources.tidal.com/images/84e558cb/2652/44b7/86de/e9d7cc70c00c/750x750.jpg",
                        "width": 750,
                        "height": 750
                    },
                    {
                        "url": "https://resources.tidal.com/images/84e558cb/2652/44b7/86de/e9d7cc70c00c/80x80.jpg",
                        "width": 80,
                        "height": 80
                    }
                ],
                "videoCover": [],
                "numberOfVolumes": 2,
                "numberOfTracks": 38,
                "numberOfVideos": 0,
                "type": "ALBUM",
                "copyright": "(P) 1972 Motown Records, a Division of UMG Recordings, Inc., 1976, 1978, 1980 Sony Music Entertainment, 1979, 1982, 1987, 1991, 1995, 2001, 2005 MJJ Productions Inc.",
                "mediaMetadata": {
                    "tags": [
                        "HIRES_LOSSLESS",
                        "LOSSLESS",
                        "MQA"
                    ]
                },
                "properties": {}
            },
            "id": "11335930",
            "status": 200,
            "message": "success"
        },
        {
            "resource": {
                "id": "16951561",
                "barcodeId": "886443546240",
                "title": "Bad 25th Anniversary",
                "artists": [
                    {
                        "id": "606",
                        "name": "Michael Jackson",
                        "picture": [
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1024x256.jpg",
                                "width": 1024,
                                "height": 256
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1080x720.jpg",
                                "width": 1080,
                                "height": 720
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x107.jpg",
                                "width": 160,
                                "height": 107
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x160.jpg",
                                "width": 160,
                                "height": 160
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x214.jpg",
                                "width": 320,
                                "height": 214
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x320.jpg",
                                "width": 320,
                                "height": 320
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/480x480.jpg",
                                "width": 480,
                                "height": 480
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/640x428.jpg",
                                "width": 640,
                                "height": 428
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x500.jpg",
                                "width": 750,
                                "height": 500
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x750.jpg",
                                "width": 750,
                                "height": 750
                            }
                        ],
                        "main": true
                    }
                ],
                "duration": 6451,
                "releaseDate": "1987-09-01",
                "imageCover": [
                    {
                        "url": "https://resources.tidal.com/images/5c962661/29f4/413b/b23f/d1fbb04eb4b3/1080x1080.jpg",
                        "width": 1080,
                        "height": 1080
                    },
                    {
                        "url": "https://resources.tidal.com/images/5c962661/29f4/413b/b23f/d1fbb04eb4b3/1280x1280.jpg",
                        "width": 1280,
                        "height": 1280
                    },
                    {
                        "url": "https://resources.tidal.com/images/5c962661/29f4/413b/b23f/d1fbb04eb4b3/160x160.jpg",
                        "width": 160,
                        "height": 160
                    },
                    {
                        "url": "https://resources.tidal.com/images/5c962661/29f4/413b/b23f/d1fbb04eb4b3/320x320.jpg",
                        "width": 320,
                        "height": 320
                    },
                    {
                        "url": "https://resources.tidal.com/images/5c962661/29f4/413b/b23f/d1fbb04eb4b3/640x640.jpg",
                        "width": 640,
                        "height": 640
                    },
                    {
                        "url": "https://resources.tidal.com/images/5c962661/29f4/413b/b23f/d1fbb04eb4b3/750x750.jpg",
                        "width": 750,
                        "height": 750
                    },
                    {
                        "url": "https://resources.tidal.com/images/5c962661/29f4/413b/b23f/d1fbb04eb4b3/80x80.jpg",
                        "width": 80,
                        "height": 80
                    }
                ],
                "videoCover": [],
                "numberOfVolumes": 2,
                "numberOfTracks": 24,
                "numberOfVideos": 0,
                "type": "ALBUM",
                "copyright": "(P) 1993 MJJ Productions Inc./(P) 2012 MJJ Productions Inc./(P) 2012 MJJ Productions Inc.",
                "mediaMetadata": {
                    "tags": [
                        "HIRES_LOSSLESS",
                        "LOSSLESS",
                        "MQA"
                    ]
                },
                "properties": {}
            },
            "id": "16951561",
            "status": 200,
            "message": "success"
        }
    ],
    "artists": [
        {
            "resource": {
                "id": "606",
                "name": "Michael Jackson",
                "picture": [
                    {
                        "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1024x256.jpg",
                        "width": 1024,
                        "height": 256
                    },
                    {
                        "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1080x720.jpg",
                        "width": 1080,
                        "height": 720
                    },
                    {
                        "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x107.jpg",
                        "width": 160,
                        "height": 107
                    },
                    {
                        "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x160.jpg",
                        "width": 160,
                        "height": 160
                    },
                    {
                        "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x214.jpg",
                        "width": 320,
                        "height": 214
                    },
                    {
                        "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x320.jpg",
                        "width": 320,
                        "height": 320
                    },
                    {
                        "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/480x480.jpg",
                        "width": 480,
                        "height": 480
                    },
                    {
                        "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/640x428.jpg",
                        "width": 640,
                        "height": 428
                    },
                    {
                        "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x500.jpg",
                        "width": 750,
                        "height": 500
                    },
                    {
                        "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x750.jpg",
                        "width": 750,
                        "height": 750
                    }
                ]
            },
            "id": "606",
            "status": 200,
            "message": "success"
        },
        {
            "resource": {
                "id": "6489083",
                "name": "Michael Jackson & Lionel Richie & Marc Reift Orchestra",
                "picture": []
            },
            "id": "6489083",
            "status": 200,
            "message": "success"
        },
        {
            "resource": {
                "id": "3530095",
                "name": "The Jackson 5",
                "picture": [
                    {
                        "url": "https://resources.tidal.com/images/00cfe5e6/2472/406c/ba66/19f9bc71bfff/1024x256.jpg",
                        "width": 1024,
                        "height": 256
                    },
                    {
                        "url": "https://resources.tidal.com/images/00cfe5e6/2472/406c/ba66/19f9bc71bfff/1080x720.jpg",
                        "width": 1080,
                        "height": 720
                    },
                    {
                        "url": "https://resources.tidal.com/images/00cfe5e6/2472/406c/ba66/19f9bc71bfff/160x107.jpg",
                        "width": 160,
                        "height": 107
                    },
                    {
                        "url": "https://resources.tidal.com/images/00cfe5e6/2472/406c/ba66/19f9bc71bfff/160x160.jpg",
                        "width": 160,
                        "height": 160
                    },
                    {
                        "url": "https://resources.tidal.com/images/00cfe5e6/2472/406c/ba66/19f9bc71bfff/320x214.jpg",
                        "width": 320,
                        "height": 214
                    },
                    {
                        "url": "https://resources.tidal.com/images/00cfe5e6/2472/406c/ba66/19f9bc71bfff/320x320.jpg",
                        "width": 320,
                        "height": 320
                    },
                    {
                        "url": "https://resources.tidal.com/images/00cfe5e6/2472/406c/ba66/19f9bc71bfff/480x480.jpg",
                        "width": 480,
                        "height": 480
                    },
                    {
                        "url": "https://resources.tidal.com/images/00cfe5e6/2472/406c/ba66/19f9bc71bfff/640x428.jpg",
                        "width": 640,
                        "height": 428
                    },
                    {
                        "url": "https://resources.tidal.com/images/00cfe5e6/2472/406c/ba66/19f9bc71bfff/750x500.jpg",
                        "width": 750,
                        "height": 500
                    },
                    {
                        "url": "https://resources.tidal.com/images/00cfe5e6/2472/406c/ba66/19f9bc71bfff/750x750.jpg",
                        "width": 750,
                        "height": 750
                    }
                ]
            },
            "id": "3530095",
            "status": 200,
            "message": "success"
        },
        {
            "resource": {
                "id": "3837004",
                "name": "Jermaine Jackson duet with Michael Jackson",
                "picture": []
            },
            "id": "3837004",
            "status": 200,
            "message": "success"
        },
        {
            "resource": {
                "id": "3632432",
                "name": "Michael Jackson Tribute",
                "picture": []
            },
            "id": "3632432",
            "status": 200,
            "message": "success"
        },
        {
            "resource": {
                "id": "4274078",
                "name": "Michael Jackson Tribute Band",
                "picture": []
            },
            "id": "4274078",
            "status": 200,
            "message": "success"
        },
        {
            "resource": {
                "id": "6469442",
                "name": "Michael Jackson & Lionel Richie & Philharmonic Wind Orchestra & Marc Reift Orchestra",
                "picture": []
            },
            "id": "6469442",
            "status": 200,
            "message": "success"
        },
        {
            "resource": {
                "id": "5296768",
                "name": "Michael Jackson & Suzanne Palmer",
                "picture": []
            },
            "id": "5296768",
            "status": 200,
            "message": "success"
        },
        {
            "resource": {
                "id": "15778840",
                "name": "Michael Jackson Jr",
                "picture": []
            },
            "id": "15778840",
            "status": 200,
            "message": "success"
        },
        {
            "resource": {
                "id": "4865760",
                "name": "Paul Anka duet with Michael Jackson",
                "picture": []
            },
            "id": "4865760",
            "status": 200,
            "message": "success"
        }
    ],
    "tracks": [
        {
            "resource": {
                "artifactType": "track",
                "id": "1781887",
                "title": "Billie Jean",
                "artists": [
                    {
                        "id": "606",
                        "name": "Michael Jackson",
                        "picture": [
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1024x256.jpg",
                                "width": 1024,
                                "height": 256
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1080x720.jpg",
                                "width": 1080,
                                "height": 720
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x107.jpg",
                                "width": 160,
                                "height": 107
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x160.jpg",
                                "width": 160,
                                "height": 160
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x214.jpg",
                                "width": 320,
                                "height": 214
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x320.jpg",
                                "width": 320,
                                "height": 320
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/480x480.jpg",
                                "width": 480,
                                "height": 480
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/640x428.jpg",
                                "width": 640,
                                "height": 428
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x500.jpg",
                                "width": 750,
                                "height": 500
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x750.jpg",
                                "width": 750,
                                "height": 750
                            }
                        ],
                        "main": true
                    }
                ],
                "album": {
                    "id": "1781800",
                    "title": "Thriller",
                    "imageCover": [
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/1080x1080.jpg",
                            "width": 1080,
                            "height": 1080
                        },
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/1280x1280.jpg",
                            "width": 1280,
                            "height": 1280
                        },
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/160x160.jpg",
                            "width": 160,
                            "height": 160
                        },
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/320x320.jpg",
                            "width": 320,
                            "height": 320
                        },
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/640x640.jpg",
                            "width": 640,
                            "height": 640
                        },
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/750x750.jpg",
                            "width": 750,
                            "height": 750
                        },
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/80x80.jpg",
                            "width": 80,
                            "height": 80
                        }
                    ],
                    "videoCover": []
                },
                "duration": 294,
                "trackNumber": 6,
                "volumeNumber": 1,
                "isrc": "USSM19902991",
                "copyright": "(P) 1982 MJJ Productions Inc.",
                "mediaMetadata": {
                    "tags": [
                        "HIRES_LOSSLESS",
                        "LOSSLESS",
                        "MQA"
                    ]
                },
                "properties": {}
            },
            "id": "1781887",
            "status": 200,
            "message": "success"
        },
        {
            "resource": {
                "artifactType": "track",
                "id": "1781861",
                "title": "Beat It",
                "artists": [
                    {
                        "id": "606",
                        "name": "Michael Jackson",
                        "picture": [
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1024x256.jpg",
                                "width": 1024,
                                "height": 256
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1080x720.jpg",
                                "width": 1080,
                                "height": 720
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x107.jpg",
                                "width": 160,
                                "height": 107
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x160.jpg",
                                "width": 160,
                                "height": 160
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x214.jpg",
                                "width": 320,
                                "height": 214
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x320.jpg",
                                "width": 320,
                                "height": 320
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/480x480.jpg",
                                "width": 480,
                                "height": 480
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/640x428.jpg",
                                "width": 640,
                                "height": 428
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x500.jpg",
                                "width": 750,
                                "height": 500
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x750.jpg",
                                "width": 750,
                                "height": 750
                            }
                        ],
                        "main": true
                    }
                ],
                "album": {
                    "id": "1781800",
                    "title": "Thriller",
                    "imageCover": [
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/1080x1080.jpg",
                            "width": 1080,
                            "height": 1080
                        },
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/1280x1280.jpg",
                            "width": 1280,
                            "height": 1280
                        },
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/160x160.jpg",
                            "width": 160,
                            "height": 160
                        },
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/320x320.jpg",
                            "width": 320,
                            "height": 320
                        },
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/640x640.jpg",
                            "width": 640,
                            "height": 640
                        },
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/750x750.jpg",
                            "width": 750,
                            "height": 750
                        },
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/80x80.jpg",
                            "width": 80,
                            "height": 80
                        }
                    ],
                    "videoCover": []
                },
                "duration": 258,
                "trackNumber": 5,
                "volumeNumber": 1,
                "isrc": "USSM19902990",
                "copyright": "(P) 1982 MJJ Productions Inc.",
                "mediaMetadata": {
                    "tags": [
                        "HIRES_LOSSLESS",
                        "LOSSLESS",
                        "MQA"
                    ]
                },
                "properties": {}
            },
            "id": "1781861",
            "status": 200,
            "message": "success"
        },
        {
            "resource": {
                "artifactType": "track",
                "id": "19994075",
                "title": "Don't Stop 'Til You Get Enough",
                "artists": [
                    {
                        "id": "606",
                        "name": "Michael Jackson",
                        "picture": [
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1024x256.jpg",
                                "width": 1024,
                                "height": 256
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1080x720.jpg",
                                "width": 1080,
                                "height": 720
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x107.jpg",
                                "width": 160,
                                "height": 107
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x160.jpg",
                                "width": 160,
                                "height": 160
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x214.jpg",
                                "width": 320,
                                "height": 214
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x320.jpg",
                                "width": 320,
                                "height": 320
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/480x480.jpg",
                                "width": 480,
                                "height": 480
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/640x428.jpg",
                                "width": 640,
                                "height": 428
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x500.jpg",
                                "width": 750,
                                "height": 500
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x750.jpg",
                                "width": 750,
                                "height": 750
                            }
                        ],
                        "main": true
                    }
                ],
                "album": {
                    "id": "19994074",
                    "title": "Off the Wall",
                    "imageCover": [
                        {
                            "url": "https://resources.tidal.com/images/79dcc832/19fa/4402/bb44/dae56b6b0e21/1080x1080.jpg",
                            "width": 1080,
                            "height": 1080
                        },
                        {
                            "url": "https://resources.tidal.com/images/79dcc832/19fa/4402/bb44/dae56b6b0e21/1280x1280.jpg",
                            "width": 1280,
                            "height": 1280
                        },
                        {
                            "url": "https://resources.tidal.com/images/79dcc832/19fa/4402/bb44/dae56b6b0e21/160x160.jpg",
                            "width": 160,
                            "height": 160
                        },
                        {
                            "url": "https://resources.tidal.com/images/79dcc832/19fa/4402/bb44/dae56b6b0e21/320x320.jpg",
                            "width": 320,
                            "height": 320
                        },
                        {
                            "url": "https://resources.tidal.com/images/79dcc832/19fa/4402/bb44/dae56b6b0e21/640x640.jpg",
                            "width": 640,
                            "height": 640
                        },
                        {
                            "url": "https://resources.tidal.com/images/79dcc832/19fa/4402/bb44/dae56b6b0e21/750x750.jpg",
                            "width": 750,
                            "height": 750
                        },
                        {
                            "url": "https://resources.tidal.com/images/79dcc832/19fa/4402/bb44/dae56b6b0e21/80x80.jpg",
                            "width": 80,
                            "height": 80
                        }
                    ],
                    "videoCover": []
                },
                "duration": 364,
                "trackNumber": 1,
                "volumeNumber": 1,
                "isrc": "USSM17900816",
                "copyright": "(P) 1979 MJJ Productions Inc.",
                "mediaMetadata": {
                    "tags": [
                        "HIRES_LOSSLESS",
                        "LOSSLESS",
                        "MQA"
                    ]
                },
                "properties": {}
            },
            "id": "19994075",
            "status": 200,
            "message": "success"
        },
        {
            "resource": {
                "artifactType": "track",
                "id": "19994076",
                "title": "Rock with You (Single Version)",
                "artists": [
                    {
                        "id": "606",
                        "name": "Michael Jackson",
                        "picture": [
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1024x256.jpg",
                                "width": 1024,
                                "height": 256
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1080x720.jpg",
                                "width": 1080,
                                "height": 720
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x107.jpg",
                                "width": 160,
                                "height": 107
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x160.jpg",
                                "width": 160,
                                "height": 160
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x214.jpg",
                                "width": 320,
                                "height": 214
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x320.jpg",
                                "width": 320,
                                "height": 320
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/480x480.jpg",
                                "width": 480,
                                "height": 480
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/640x428.jpg",
                                "width": 640,
                                "height": 428
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x500.jpg",
                                "width": 750,
                                "height": 500
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x750.jpg",
                                "width": 750,
                                "height": 750
                            }
                        ],
                        "main": true
                    }
                ],
                "album": {
                    "id": "19994074",
                    "title": "Off the Wall",
                    "imageCover": [
                        {
                            "url": "https://resources.tidal.com/images/79dcc832/19fa/4402/bb44/dae56b6b0e21/1080x1080.jpg",
                            "width": 1080,
                            "height": 1080
                        },
                        {
                            "url": "https://resources.tidal.com/images/79dcc832/19fa/4402/bb44/dae56b6b0e21/1280x1280.jpg",
                            "width": 1280,
                            "height": 1280
                        },
                        {
                            "url": "https://resources.tidal.com/images/79dcc832/19fa/4402/bb44/dae56b6b0e21/160x160.jpg",
                            "width": 160,
                            "height": 160
                        },
                        {
                            "url": "https://resources.tidal.com/images/79dcc832/19fa/4402/bb44/dae56b6b0e21/320x320.jpg",
                            "width": 320,
                            "height": 320
                        },
                        {
                            "url": "https://resources.tidal.com/images/79dcc832/19fa/4402/bb44/dae56b6b0e21/640x640.jpg",
                            "width": 640,
                            "height": 640
                        },
                        {
                            "url": "https://resources.tidal.com/images/79dcc832/19fa/4402/bb44/dae56b6b0e21/750x750.jpg",
                            "width": 750,
                            "height": 750
                        },
                        {
                            "url": "https://resources.tidal.com/images/79dcc832/19fa/4402/bb44/dae56b6b0e21/80x80.jpg",
                            "width": 80,
                            "height": 80
                        }
                    ],
                    "videoCover": []
                },
                "duration": 220,
                "trackNumber": 2,
                "volumeNumber": 1,
                "isrc": "USSM17900817",
                "copyright": "(P) 1979 MJJ Productions Inc.",
                "mediaMetadata": {
                    "tags": [
                        "HIRES_LOSSLESS",
                        "LOSSLESS",
                        "MQA"
                    ]
                },
                "properties": {}
            },
            "id": "19994076",
            "status": 200,
            "message": "success"
        },
        {
            "resource": {
                "artifactType": "track",
                "id": "1781819",
                "title": "P.Y.T. (Pretty Young Thing)",
                "artists": [
                    {
                        "id": "606",
                        "name": "Michael Jackson",
                        "picture": [
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1024x256.jpg",
                                "width": 1024,
                                "height": 256
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1080x720.jpg",
                                "width": 1080,
                                "height": 720
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x107.jpg",
                                "width": 160,
                                "height": 107
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x160.jpg",
                                "width": 160,
                                "height": 160
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x214.jpg",
                                "width": 320,
                                "height": 214
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x320.jpg",
                                "width": 320,
                                "height": 320
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/480x480.jpg",
                                "width": 480,
                                "height": 480
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/640x428.jpg",
                                "width": 640,
                                "height": 428
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x500.jpg",
                                "width": 750,
                                "height": 500
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x750.jpg",
                                "width": 750,
                                "height": 750
                            }
                        ],
                        "main": true
                    }
                ],
                "album": {
                    "id": "1781800",
                    "title": "Thriller",
                    "imageCover": [
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/1080x1080.jpg",
                            "width": 1080,
                            "height": 1080
                        },
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/1280x1280.jpg",
                            "width": 1280,
                            "height": 1280
                        },
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/160x160.jpg",
                            "width": 160,
                            "height": 160
                        },
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/320x320.jpg",
                            "width": 320,
                            "height": 320
                        },
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/640x640.jpg",
                            "width": 640,
                            "height": 640
                        },
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/750x750.jpg",
                            "width": 750,
                            "height": 750
                        },
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/80x80.jpg",
                            "width": 80,
                            "height": 80
                        }
                    ],
                    "videoCover": []
                },
                "duration": 239,
                "trackNumber": 8,
                "volumeNumber": 1,
                "isrc": "USSM19902993",
                "copyright": "(P) 1982 MJJ Productions Inc.",
                "mediaMetadata": {
                    "tags": [
                        "HIRES_LOSSLESS",
                        "LOSSLESS",
                        "MQA"
                    ]
                },
                "properties": {}
            },
            "id": "1781819",
            "status": 200,
            "message": "success"
        },
        {
            "resource": {
                "artifactType": "track",
                "id": "1781892",
                "title": "Thriller",
                "artists": [
                    {
                        "id": "606",
                        "name": "Michael Jackson",
                        "picture": [
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1024x256.jpg",
                                "width": 1024,
                                "height": 256
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1080x720.jpg",
                                "width": 1080,
                                "height": 720
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x107.jpg",
                                "width": 160,
                                "height": 107
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x160.jpg",
                                "width": 160,
                                "height": 160
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x214.jpg",
                                "width": 320,
                                "height": 214
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x320.jpg",
                                "width": 320,
                                "height": 320
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/480x480.jpg",
                                "width": 480,
                                "height": 480
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/640x428.jpg",
                                "width": 640,
                                "height": 428
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x500.jpg",
                                "width": 750,
                                "height": 500
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x750.jpg",
                                "width": 750,
                                "height": 750
                            }
                        ],
                        "main": true
                    }
                ],
                "album": {
                    "id": "1781800",
                    "title": "Thriller",
                    "imageCover": [
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/1080x1080.jpg",
                            "width": 1080,
                            "height": 1080
                        },
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/1280x1280.jpg",
                            "width": 1280,
                            "height": 1280
                        },
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/160x160.jpg",
                            "width": 160,
                            "height": 160
                        },
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/320x320.jpg",
                            "width": 320,
                            "height": 320
                        },
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/640x640.jpg",
                            "width": 640,
                            "height": 640
                        },
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/750x750.jpg",
                            "width": 750,
                            "height": 750
                        },
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/80x80.jpg",
                            "width": 80,
                            "height": 80
                        }
                    ],
                    "videoCover": []
                },
                "duration": 358,
                "trackNumber": 4,
                "volumeNumber": 1,
                "isrc": "USSM19902989",
                "copyright": "(P) 1982 MJJ Productions Inc.",
                "mediaMetadata": {
                    "tags": [
                        "HIRES_LOSSLESS",
                        "LOSSLESS",
                        "MQA"
                    ]
                },
                "properties": {}
            },
            "id": "1781892",
            "status": 200,
            "message": "success"
        },
        {
            "resource": {
                "artifactType": "track",
                "id": "16953810",
                "title": "Smooth Criminal (2012 Remaster)",
                "artists": [
                    {
                        "id": "606",
                        "name": "Michael Jackson",
                        "picture": [
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1024x256.jpg",
                                "width": 1024,
                                "height": 256
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1080x720.jpg",
                                "width": 1080,
                                "height": 720
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x107.jpg",
                                "width": 160,
                                "height": 107
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x160.jpg",
                                "width": 160,
                                "height": 160
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x214.jpg",
                                "width": 320,
                                "height": 214
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x320.jpg",
                                "width": 320,
                                "height": 320
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/480x480.jpg",
                                "width": 480,
                                "height": 480
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/640x428.jpg",
                                "width": 640,
                                "height": 428
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x500.jpg",
                                "width": 750,
                                "height": 500
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x750.jpg",
                                "width": 750,
                                "height": 750
                            }
                        ],
                        "main": true
                    }
                ],
                "album": {
                    "id": "16953800",
                    "title": "Bad ((Remastered))",
                    "imageCover": [
                        {
                            "url": "https://resources.tidal.com/images/11aa6bd0/7813/482c/b2f1/3fecb47f4ac3/1080x1080.jpg",
                            "width": 1080,
                            "height": 1080
                        },
                        {
                            "url": "https://resources.tidal.com/images/11aa6bd0/7813/482c/b2f1/3fecb47f4ac3/1280x1280.jpg",
                            "width": 1280,
                            "height": 1280
                        },
                        {
                            "url": "https://resources.tidal.com/images/11aa6bd0/7813/482c/b2f1/3fecb47f4ac3/160x160.jpg",
                            "width": 160,
                            "height": 160
                        },
                        {
                            "url": "https://resources.tidal.com/images/11aa6bd0/7813/482c/b2f1/3fecb47f4ac3/320x320.jpg",
                            "width": 320,
                            "height": 320
                        },
                        {
                            "url": "https://resources.tidal.com/images/11aa6bd0/7813/482c/b2f1/3fecb47f4ac3/640x640.jpg",
                            "width": 640,
                            "height": 640
                        },
                        {
                            "url": "https://resources.tidal.com/images/11aa6bd0/7813/482c/b2f1/3fecb47f4ac3/750x750.jpg",
                            "width": 750,
                            "height": 750
                        },
                        {
                            "url": "https://resources.tidal.com/images/11aa6bd0/7813/482c/b2f1/3fecb47f4ac3/80x80.jpg",
                            "width": 80,
                            "height": 80
                        }
                    ],
                    "videoCover": []
                },
                "duration": 258,
                "trackNumber": 10,
                "volumeNumber": 1,
                "isrc": "USSM11204989",
                "copyright": "(P) 1987 MJJ Productions Inc.",
                "mediaMetadata": {
                    "tags": [
                        "HIRES_LOSSLESS",
                        "LOSSLESS",
                        "MQA"
                    ]
                },
                "properties": {}
            },
            "id": "16953810",
            "status": 200,
            "message": "success"
        },
        {
            "resource": {
                "artifactType": "track",
                "id": "1781812",
                "title": "Human Nature",
                "artists": [
                    {
                        "id": "606",
                        "name": "Michael Jackson",
                        "picture": [
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1024x256.jpg",
                                "width": 1024,
                                "height": 256
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1080x720.jpg",
                                "width": 1080,
                                "height": 720
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x107.jpg",
                                "width": 160,
                                "height": 107
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x160.jpg",
                                "width": 160,
                                "height": 160
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x214.jpg",
                                "width": 320,
                                "height": 214
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x320.jpg",
                                "width": 320,
                                "height": 320
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/480x480.jpg",
                                "width": 480,
                                "height": 480
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/640x428.jpg",
                                "width": 640,
                                "height": 428
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x500.jpg",
                                "width": 750,
                                "height": 500
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x750.jpg",
                                "width": 750,
                                "height": 750
                            }
                        ],
                        "main": true
                    }
                ],
                "album": {
                    "id": "1781800",
                    "title": "Thriller",
                    "imageCover": [
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/1080x1080.jpg",
                            "width": 1080,
                            "height": 1080
                        },
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/1280x1280.jpg",
                            "width": 1280,
                            "height": 1280
                        },
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/160x160.jpg",
                            "width": 160,
                            "height": 160
                        },
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/320x320.jpg",
                            "width": 320,
                            "height": 320
                        },
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/640x640.jpg",
                            "width": 640,
                            "height": 640
                        },
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/750x750.jpg",
                            "width": 750,
                            "height": 750
                        },
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/80x80.jpg",
                            "width": 80,
                            "height": 80
                        }
                    ],
                    "videoCover": []
                },
                "duration": 246,
                "trackNumber": 7,
                "volumeNumber": 1,
                "isrc": "USSM19902992",
                "copyright": "(P) 1982 MJJ Productions Inc.",
                "mediaMetadata": {
                    "tags": [
                        "HIRES_LOSSLESS",
                        "LOSSLESS",
                        "MQA"
                    ]
                },
                "properties": {}
            },
            "id": "1781812",
            "status": 200,
            "message": "success"
        },
        {
            "resource": {
                "artifactType": "track",
                "id": "5279064",
                "title": "Remember the Time",
                "artists": [
                    {
                        "id": "606",
                        "name": "Michael Jackson",
                        "picture": [
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1024x256.jpg",
                                "width": 1024,
                                "height": 256
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1080x720.jpg",
                                "width": 1080,
                                "height": 720
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x107.jpg",
                                "width": 160,
                                "height": 107
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x160.jpg",
                                "width": 160,
                                "height": 160
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x214.jpg",
                                "width": 320,
                                "height": 214
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x320.jpg",
                                "width": 320,
                                "height": 320
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/480x480.jpg",
                                "width": 480,
                                "height": 480
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/640x428.jpg",
                                "width": 640,
                                "height": 428
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x500.jpg",
                                "width": 750,
                                "height": 500
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x750.jpg",
                                "width": 750,
                                "height": 750
                            }
                        ],
                        "main": true
                    }
                ],
                "album": {
                    "id": "5279052",
                    "title": "HIStory - PAST, PRESENT AND FUTURE - BOOK I",
                    "imageCover": [
                        {
                            "url": "https://resources.tidal.com/images/a2fa5815/851d/4d2d/b6a7/17a365c838f9/1080x1080.jpg",
                            "width": 1080,
                            "height": 1080
                        },
                        {
                            "url": "https://resources.tidal.com/images/a2fa5815/851d/4d2d/b6a7/17a365c838f9/1280x1280.jpg",
                            "width": 1280,
                            "height": 1280
                        },
                        {
                            "url": "https://resources.tidal.com/images/a2fa5815/851d/4d2d/b6a7/17a365c838f9/160x160.jpg",
                            "width": 160,
                            "height": 160
                        },
                        {
                            "url": "https://resources.tidal.com/images/a2fa5815/851d/4d2d/b6a7/17a365c838f9/320x320.jpg",
                            "width": 320,
                            "height": 320
                        },
                        {
                            "url": "https://resources.tidal.com/images/a2fa5815/851d/4d2d/b6a7/17a365c838f9/640x640.jpg",
                            "width": 640,
                            "height": 640
                        },
                        {
                            "url": "https://resources.tidal.com/images/a2fa5815/851d/4d2d/b6a7/17a365c838f9/750x750.jpg",
                            "width": 750,
                            "height": 750
                        },
                        {
                            "url": "https://resources.tidal.com/images/a2fa5815/851d/4d2d/b6a7/17a365c838f9/80x80.jpg",
                            "width": 80,
                            "height": 80
                        }
                    ],
                    "videoCover": []
                },
                "duration": 240,
                "trackNumber": 12,
                "volumeNumber": 1,
                "isrc": "USSM10020715",
                "copyright": "(P) 1991 MJJ Productions Inc.",
                "mediaMetadata": {
                    "tags": [
                        "HIRES_LOSSLESS",
                        "LOSSLESS",
                        "MQA"
                    ]
                },
                "properties": {}
            },
            "id": "5279064",
            "status": 200,
            "message": "success"
        },
        {
            "resource": {
                "artifactType": "track",
                "id": "1781828",
                "title": "Wanna Be Startin' Somethin'",
                "artists": [
                    {
                        "id": "606",
                        "name": "Michael Jackson",
                        "picture": [
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1024x256.jpg",
                                "width": 1024,
                                "height": 256
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1080x720.jpg",
                                "width": 1080,
                                "height": 720
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x107.jpg",
                                "width": 160,
                                "height": 107
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x160.jpg",
                                "width": 160,
                                "height": 160
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x214.jpg",
                                "width": 320,
                                "height": 214
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x320.jpg",
                                "width": 320,
                                "height": 320
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/480x480.jpg",
                                "width": 480,
                                "height": 480
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/640x428.jpg",
                                "width": 640,
                                "height": 428
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x500.jpg",
                                "width": 750,
                                "height": 500
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x750.jpg",
                                "width": 750,
                                "height": 750
                            }
                        ],
                        "main": true
                    }
                ],
                "album": {
                    "id": "1781800",
                    "title": "Thriller",
                    "imageCover": [
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/1080x1080.jpg",
                            "width": 1080,
                            "height": 1080
                        },
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/1280x1280.jpg",
                            "width": 1280,
                            "height": 1280
                        },
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/160x160.jpg",
                            "width": 160,
                            "height": 160
                        },
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/320x320.jpg",
                            "width": 320,
                            "height": 320
                        },
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/640x640.jpg",
                            "width": 640,
                            "height": 640
                        },
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/750x750.jpg",
                            "width": 750,
                            "height": 750
                        },
                        {
                            "url": "https://resources.tidal.com/images/84a147b5/48e7/4607/b0c4/8bee15101bfd/80x80.jpg",
                            "width": 80,
                            "height": 80
                        }
                    ],
                    "videoCover": []
                },
                "duration": 363,
                "trackNumber": 1,
                "volumeNumber": 1,
                "isrc": "USSM19902986",
                "copyright": "(P) 1982 MJJ Productions, Inc.",
                "mediaMetadata": {
                    "tags": [
                        "HIRES_LOSSLESS",
                        "LOSSLESS",
                        "MQA"
                    ]
                },
                "properties": {}
            },
            "id": "1781828",
            "status": 200,
            "message": "success"
        }
    ],
    "videos": [
        {
            "resource": {
                "artifactType": "video",
                "id": "23988982",
                "title": "Smooth Criminal (Official Video)",
                "image": [
                    {
                        "url": "https://resources.tidal.com/images/543b4933/3c5c/47f7/b4b2/fc005ee5d8e3/1024x256.jpg",
                        "width": 1024,
                        "height": 256
                    },
                    {
                        "url": "https://resources.tidal.com/images/543b4933/3c5c/47f7/b4b2/fc005ee5d8e3/1080x720.jpg",
                        "width": 1080,
                        "height": 720
                    },
                    {
                        "url": "https://resources.tidal.com/images/543b4933/3c5c/47f7/b4b2/fc005ee5d8e3/160x107.jpg",
                        "width": 160,
                        "height": 107
                    },
                    {
                        "url": "https://resources.tidal.com/images/543b4933/3c5c/47f7/b4b2/fc005ee5d8e3/160x160.jpg",
                        "width": 160,
                        "height": 160
                    },
                    {
                        "url": "https://resources.tidal.com/images/543b4933/3c5c/47f7/b4b2/fc005ee5d8e3/320x214.jpg",
                        "width": 320,
                        "height": 214
                    },
                    {
                        "url": "https://resources.tidal.com/images/543b4933/3c5c/47f7/b4b2/fc005ee5d8e3/320x320.jpg",
                        "width": 320,
                        "height": 320
                    },
                    {
                        "url": "https://resources.tidal.com/images/543b4933/3c5c/47f7/b4b2/fc005ee5d8e3/480x480.jpg",
                        "width": 480,
                        "height": 480
                    },
                    {
                        "url": "https://resources.tidal.com/images/543b4933/3c5c/47f7/b4b2/fc005ee5d8e3/640x428.jpg",
                        "width": 640,
                        "height": 428
                    },
                    {
                        "url": "https://resources.tidal.com/images/543b4933/3c5c/47f7/b4b2/fc005ee5d8e3/750x500.jpg",
                        "width": 750,
                        "height": 500
                    },
                    {
                        "url": "https://resources.tidal.com/images/543b4933/3c5c/47f7/b4b2/fc005ee5d8e3/750x750.jpg",
                        "width": 750,
                        "height": 750
                    }
                ],
                "releaseDate": "2010-10-22",
                "artists": [
                    {
                        "id": "606",
                        "name": "Michael Jackson",
                        "picture": [
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1024x256.jpg",
                                "width": 1024,
                                "height": 256
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1080x720.jpg",
                                "width": 1080,
                                "height": 720
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x107.jpg",
                                "width": 160,
                                "height": 107
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x160.jpg",
                                "width": 160,
                                "height": 160
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x214.jpg",
                                "width": 320,
                                "height": 214
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x320.jpg",
                                "width": 320,
                                "height": 320
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/480x480.jpg",
                                "width": 480,
                                "height": 480
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/640x428.jpg",
                                "width": 640,
                                "height": 428
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x500.jpg",
                                "width": 750,
                                "height": 500
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x750.jpg",
                                "width": 750,
                                "height": 750
                            }
                        ],
                        "main": true
                    }
                ],
                "duration": 566,
                "trackNumber": 0,
                "volumeNumber": 0,
                "isrc": "USSM21001909",
                "copyright": "(C) 2010 MJJ Productions, Inc.",
                "properties": {}
            },
            "id": "23988982",
            "status": 200,
            "message": "success"
        },
        {
            "resource": {
                "artifactType": "video",
                "id": "26153739",
                "title": "Thriller (Official 4K Video)",
                "image": [
                    {
                        "url": "https://resources.tidal.com/images/596991d9/cb74/40e8/97f6/1e246a5e5596/1024x256.jpg",
                        "width": 1024,
                        "height": 256
                    },
                    {
                        "url": "https://resources.tidal.com/images/596991d9/cb74/40e8/97f6/1e246a5e5596/1080x720.jpg",
                        "width": 1080,
                        "height": 720
                    },
                    {
                        "url": "https://resources.tidal.com/images/596991d9/cb74/40e8/97f6/1e246a5e5596/160x107.jpg",
                        "width": 160,
                        "height": 107
                    },
                    {
                        "url": "https://resources.tidal.com/images/596991d9/cb74/40e8/97f6/1e246a5e5596/160x160.jpg",
                        "width": 160,
                        "height": 160
                    },
                    {
                        "url": "https://resources.tidal.com/images/596991d9/cb74/40e8/97f6/1e246a5e5596/320x214.jpg",
                        "width": 320,
                        "height": 214
                    },
                    {
                        "url": "https://resources.tidal.com/images/596991d9/cb74/40e8/97f6/1e246a5e5596/320x320.jpg",
                        "width": 320,
                        "height": 320
                    },
                    {
                        "url": "https://resources.tidal.com/images/596991d9/cb74/40e8/97f6/1e246a5e5596/480x480.jpg",
                        "width": 480,
                        "height": 480
                    },
                    {
                        "url": "https://resources.tidal.com/images/596991d9/cb74/40e8/97f6/1e246a5e5596/640x428.jpg",
                        "width": 640,
                        "height": 428
                    },
                    {
                        "url": "https://resources.tidal.com/images/596991d9/cb74/40e8/97f6/1e246a5e5596/750x500.jpg",
                        "width": 750,
                        "height": 500
                    },
                    {
                        "url": "https://resources.tidal.com/images/596991d9/cb74/40e8/97f6/1e246a5e5596/750x750.jpg",
                        "width": 750,
                        "height": 750
                    }
                ],
                "releaseDate": "1982-01-01",
                "artists": [
                    {
                        "id": "606",
                        "name": "Michael Jackson",
                        "picture": [
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1024x256.jpg",
                                "width": 1024,
                                "height": 256
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1080x720.jpg",
                                "width": 1080,
                                "height": 720
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x107.jpg",
                                "width": 160,
                                "height": 107
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x160.jpg",
                                "width": 160,
                                "height": 160
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x214.jpg",
                                "width": 320,
                                "height": 214
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x320.jpg",
                                "width": 320,
                                "height": 320
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/480x480.jpg",
                                "width": 480,
                                "height": 480
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/640x428.jpg",
                                "width": 640,
                                "height": 428
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x500.jpg",
                                "width": 750,
                                "height": 500
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x750.jpg",
                                "width": 750,
                                "height": 750
                            }
                        ],
                        "main": true
                    }
                ],
                "duration": 822,
                "trackNumber": 0,
                "volumeNumber": 0,
                "isrc": "USSM20301507",
                "copyright": "(C) 1982 MJJ Productions Inc.",
                "properties": {}
            },
            "id": "26153739",
            "status": 200,
            "message": "success"
        },
        {
            "resource": {
                "artifactType": "video",
                "id": "24015068",
                "title": "Beat It (Official 4K Video)",
                "image": [
                    {
                        "url": "https://resources.tidal.com/images/41c31eef/e7e6/4cb6/b4c7/a618b2e4b66a/1024x256.jpg",
                        "width": 1024,
                        "height": 256
                    },
                    {
                        "url": "https://resources.tidal.com/images/41c31eef/e7e6/4cb6/b4c7/a618b2e4b66a/1080x720.jpg",
                        "width": 1080,
                        "height": 720
                    },
                    {
                        "url": "https://resources.tidal.com/images/41c31eef/e7e6/4cb6/b4c7/a618b2e4b66a/160x107.jpg",
                        "width": 160,
                        "height": 107
                    },
                    {
                        "url": "https://resources.tidal.com/images/41c31eef/e7e6/4cb6/b4c7/a618b2e4b66a/160x160.jpg",
                        "width": 160,
                        "height": 160
                    },
                    {
                        "url": "https://resources.tidal.com/images/41c31eef/e7e6/4cb6/b4c7/a618b2e4b66a/320x214.jpg",
                        "width": 320,
                        "height": 214
                    },
                    {
                        "url": "https://resources.tidal.com/images/41c31eef/e7e6/4cb6/b4c7/a618b2e4b66a/320x320.jpg",
                        "width": 320,
                        "height": 320
                    },
                    {
                        "url": "https://resources.tidal.com/images/41c31eef/e7e6/4cb6/b4c7/a618b2e4b66a/480x480.jpg",
                        "width": 480,
                        "height": 480
                    },
                    {
                        "url": "https://resources.tidal.com/images/41c31eef/e7e6/4cb6/b4c7/a618b2e4b66a/640x428.jpg",
                        "width": 640,
                        "height": 428
                    },
                    {
                        "url": "https://resources.tidal.com/images/41c31eef/e7e6/4cb6/b4c7/a618b2e4b66a/750x500.jpg",
                        "width": 750,
                        "height": 500
                    },
                    {
                        "url": "https://resources.tidal.com/images/41c31eef/e7e6/4cb6/b4c7/a618b2e4b66a/750x750.jpg",
                        "width": 750,
                        "height": 750
                    }
                ],
                "releaseDate": "1982-01-01",
                "artists": [
                    {
                        "id": "606",
                        "name": "Michael Jackson",
                        "picture": [
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1024x256.jpg",
                                "width": 1024,
                                "height": 256
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1080x720.jpg",
                                "width": 1080,
                                "height": 720
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x107.jpg",
                                "width": 160,
                                "height": 107
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x160.jpg",
                                "width": 160,
                                "height": 160
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x214.jpg",
                                "width": 320,
                                "height": 214
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x320.jpg",
                                "width": 320,
                                "height": 320
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/480x480.jpg",
                                "width": 480,
                                "height": 480
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/640x428.jpg",
                                "width": 640,
                                "height": 428
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x500.jpg",
                                "width": 750,
                                "height": 500
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x750.jpg",
                                "width": 750,
                                "height": 750
                            }
                        ],
                        "main": true
                    }
                ],
                "duration": 299,
                "trackNumber": 0,
                "volumeNumber": 0,
                "isrc": "USSM20800056",
                "copyright": "© 1982 MJJ Productions Inc.",
                "properties": {}
            },
            "id": "24015068",
            "status": 200,
            "message": "success"
        },
        {
            "resource": {
                "artifactType": "video",
                "id": "26196120",
                "title": "Liberian Girl (Official Video - Shortened Version)",
                "image": [
                    {
                        "url": "https://resources.tidal.com/images/1b8534f6/fb97/41d5/b269/0580e9c695a9/1024x256.jpg",
                        "width": 1024,
                        "height": 256
                    },
                    {
                        "url": "https://resources.tidal.com/images/1b8534f6/fb97/41d5/b269/0580e9c695a9/1080x720.jpg",
                        "width": 1080,
                        "height": 720
                    },
                    {
                        "url": "https://resources.tidal.com/images/1b8534f6/fb97/41d5/b269/0580e9c695a9/160x107.jpg",
                        "width": 160,
                        "height": 107
                    },
                    {
                        "url": "https://resources.tidal.com/images/1b8534f6/fb97/41d5/b269/0580e9c695a9/160x160.jpg",
                        "width": 160,
                        "height": 160
                    },
                    {
                        "url": "https://resources.tidal.com/images/1b8534f6/fb97/41d5/b269/0580e9c695a9/320x214.jpg",
                        "width": 320,
                        "height": 214
                    },
                    {
                        "url": "https://resources.tidal.com/images/1b8534f6/fb97/41d5/b269/0580e9c695a9/320x320.jpg",
                        "width": 320,
                        "height": 320
                    },
                    {
                        "url": "https://resources.tidal.com/images/1b8534f6/fb97/41d5/b269/0580e9c695a9/480x480.jpg",
                        "width": 480,
                        "height": 480
                    },
                    {
                        "url": "https://resources.tidal.com/images/1b8534f6/fb97/41d5/b269/0580e9c695a9/640x428.jpg",
                        "width": 640,
                        "height": 428
                    },
                    {
                        "url": "https://resources.tidal.com/images/1b8534f6/fb97/41d5/b269/0580e9c695a9/750x500.jpg",
                        "width": 750,
                        "height": 500
                    },
                    {
                        "url": "https://resources.tidal.com/images/1b8534f6/fb97/41d5/b269/0580e9c695a9/750x750.jpg",
                        "width": 750,
                        "height": 750
                    }
                ],
                "releaseDate": "1989-01-01",
                "artists": [
                    {
                        "id": "606",
                        "name": "Michael Jackson",
                        "picture": [
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1024x256.jpg",
                                "width": 1024,
                                "height": 256
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1080x720.jpg",
                                "width": 1080,
                                "height": 720
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x107.jpg",
                                "width": 160,
                                "height": 107
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x160.jpg",
                                "width": 160,
                                "height": 160
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x214.jpg",
                                "width": 320,
                                "height": 214
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x320.jpg",
                                "width": 320,
                                "height": 320
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/480x480.jpg",
                                "width": 480,
                                "height": 480
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/640x428.jpg",
                                "width": 640,
                                "height": 428
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x500.jpg",
                                "width": 750,
                                "height": 500
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x750.jpg",
                                "width": 750,
                                "height": 750
                            }
                        ],
                        "main": true
                    }
                ],
                "duration": 234,
                "trackNumber": 0,
                "volumeNumber": 0,
                "isrc": "USSM20301505",
                "copyright": "(C) 1989 MJJ Productions Inc.",
                "properties": {}
            },
            "id": "26196120",
            "status": 200,
            "message": "success"
        },
        {
            "resource": {
                "artifactType": "video",
                "id": "26143120",
                "title": "They Don't Care About Us (Brazil Version - Official Video)",
                "image": [
                    {
                        "url": "https://resources.tidal.com/images/55ae8a4d/6502/494b/b866/28ea607ae709/1024x256.jpg",
                        "width": 1024,
                        "height": 256
                    },
                    {
                        "url": "https://resources.tidal.com/images/55ae8a4d/6502/494b/b866/28ea607ae709/1080x720.jpg",
                        "width": 1080,
                        "height": 720
                    },
                    {
                        "url": "https://resources.tidal.com/images/55ae8a4d/6502/494b/b866/28ea607ae709/160x107.jpg",
                        "width": 160,
                        "height": 107
                    },
                    {
                        "url": "https://resources.tidal.com/images/55ae8a4d/6502/494b/b866/28ea607ae709/160x160.jpg",
                        "width": 160,
                        "height": 160
                    },
                    {
                        "url": "https://resources.tidal.com/images/55ae8a4d/6502/494b/b866/28ea607ae709/320x214.jpg",
                        "width": 320,
                        "height": 214
                    },
                    {
                        "url": "https://resources.tidal.com/images/55ae8a4d/6502/494b/b866/28ea607ae709/320x320.jpg",
                        "width": 320,
                        "height": 320
                    },
                    {
                        "url": "https://resources.tidal.com/images/55ae8a4d/6502/494b/b866/28ea607ae709/480x480.jpg",
                        "width": 480,
                        "height": 480
                    },
                    {
                        "url": "https://resources.tidal.com/images/55ae8a4d/6502/494b/b866/28ea607ae709/640x428.jpg",
                        "width": 640,
                        "height": 428
                    },
                    {
                        "url": "https://resources.tidal.com/images/55ae8a4d/6502/494b/b866/28ea607ae709/750x500.jpg",
                        "width": 750,
                        "height": 500
                    },
                    {
                        "url": "https://resources.tidal.com/images/55ae8a4d/6502/494b/b866/28ea607ae709/750x750.jpg",
                        "width": 750,
                        "height": 750
                    }
                ],
                "releaseDate": "1996-01-01",
                "artists": [
                    {
                        "id": "606",
                        "name": "Michael Jackson",
                        "picture": [
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1024x256.jpg",
                                "width": 1024,
                                "height": 256
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1080x720.jpg",
                                "width": 1080,
                                "height": 720
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x107.jpg",
                                "width": 160,
                                "height": 107
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x160.jpg",
                                "width": 160,
                                "height": 160
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x214.jpg",
                                "width": 320,
                                "height": 214
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x320.jpg",
                                "width": 320,
                                "height": 320
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/480x480.jpg",
                                "width": 480,
                                "height": 480
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/640x428.jpg",
                                "width": 640,
                                "height": 428
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x500.jpg",
                                "width": 750,
                                "height": 500
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x750.jpg",
                                "width": 750,
                                "height": 750
                            }
                        ],
                        "main": true
                    }
                ],
                "duration": 283,
                "trackNumber": 0,
                "volumeNumber": 0,
                "isrc": "USSM20301499",
                "copyright": "(C) 1996 MJJ Productions Inc.",
                "properties": {}
            },
            "id": "26143120",
            "status": 200,
            "message": "success"
        },
        {
            "resource": {
                "artifactType": "video",
                "id": "97320510",
                "title": "Billie Jean (Official Video)",
                "image": [
                    {
                        "url": "https://resources.tidal.com/images/0be19a55/a2e9/4802/8984/22b3c644ffff/1024x256.jpg",
                        "width": 1024,
                        "height": 256
                    },
                    {
                        "url": "https://resources.tidal.com/images/0be19a55/a2e9/4802/8984/22b3c644ffff/1080x720.jpg",
                        "width": 1080,
                        "height": 720
                    },
                    {
                        "url": "https://resources.tidal.com/images/0be19a55/a2e9/4802/8984/22b3c644ffff/160x107.jpg",
                        "width": 160,
                        "height": 107
                    },
                    {
                        "url": "https://resources.tidal.com/images/0be19a55/a2e9/4802/8984/22b3c644ffff/160x160.jpg",
                        "width": 160,
                        "height": 160
                    },
                    {
                        "url": "https://resources.tidal.com/images/0be19a55/a2e9/4802/8984/22b3c644ffff/320x214.jpg",
                        "width": 320,
                        "height": 214
                    },
                    {
                        "url": "https://resources.tidal.com/images/0be19a55/a2e9/4802/8984/22b3c644ffff/320x320.jpg",
                        "width": 320,
                        "height": 320
                    },
                    {
                        "url": "https://resources.tidal.com/images/0be19a55/a2e9/4802/8984/22b3c644ffff/480x480.jpg",
                        "width": 480,
                        "height": 480
                    },
                    {
                        "url": "https://resources.tidal.com/images/0be19a55/a2e9/4802/8984/22b3c644ffff/640x428.jpg",
                        "width": 640,
                        "height": 428
                    },
                    {
                        "url": "https://resources.tidal.com/images/0be19a55/a2e9/4802/8984/22b3c644ffff/750x500.jpg",
                        "width": 750,
                        "height": 500
                    },
                    {
                        "url": "https://resources.tidal.com/images/0be19a55/a2e9/4802/8984/22b3c644ffff/750x750.jpg",
                        "width": 750,
                        "height": 750
                    }
                ],
                "album": {
                    "id": "97320469",
                    "title": "Thriller 25 Super Deluxe Edition",
                    "imageCover": [
                        {
                            "url": "https://resources.tidal.com/images/f69fbd32/ae9d/43f6/98ae/d4077b4b9d2b/1080x1080.jpg",
                            "width": 1080,
                            "height": 1080
                        },
                        {
                            "url": "https://resources.tidal.com/images/f69fbd32/ae9d/43f6/98ae/d4077b4b9d2b/1280x1280.jpg",
                            "width": 1280,
                            "height": 1280
                        },
                        {
                            "url": "https://resources.tidal.com/images/f69fbd32/ae9d/43f6/98ae/d4077b4b9d2b/160x160.jpg",
                            "width": 160,
                            "height": 160
                        },
                        {
                            "url": "https://resources.tidal.com/images/f69fbd32/ae9d/43f6/98ae/d4077b4b9d2b/320x320.jpg",
                            "width": 320,
                            "height": 320
                        },
                        {
                            "url": "https://resources.tidal.com/images/f69fbd32/ae9d/43f6/98ae/d4077b4b9d2b/640x640.jpg",
                            "width": 640,
                            "height": 640
                        },
                        {
                            "url": "https://resources.tidal.com/images/f69fbd32/ae9d/43f6/98ae/d4077b4b9d2b/750x750.jpg",
                            "width": 750,
                            "height": 750
                        },
                        {
                            "url": "https://resources.tidal.com/images/f69fbd32/ae9d/43f6/98ae/d4077b4b9d2b/80x80.jpg",
                            "width": 80,
                            "height": 80
                        }
                    ],
                    "videoCover": []
                },
                "releaseDate": "1982-01-01",
                "artists": [
                    {
                        "id": "606",
                        "name": "Michael Jackson",
                        "picture": [
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1024x256.jpg",
                                "width": 1024,
                                "height": 256
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1080x720.jpg",
                                "width": 1080,
                                "height": 720
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x107.jpg",
                                "width": 160,
                                "height": 107
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x160.jpg",
                                "width": 160,
                                "height": 160
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x214.jpg",
                                "width": 320,
                                "height": 214
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x320.jpg",
                                "width": 320,
                                "height": 320
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/480x480.jpg",
                                "width": 480,
                                "height": 480
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/640x428.jpg",
                                "width": 640,
                                "height": 428
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x500.jpg",
                                "width": 750,
                                "height": 500
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x750.jpg",
                                "width": 750,
                                "height": 750
                            }
                        ],
                        "main": true
                    }
                ],
                "duration": 294,
                "trackNumber": 32,
                "volumeNumber": 1,
                "isrc": "USSM20301088",
                "copyright": "(C) 1982 MJJ Productions Inc.",
                "properties": {}
            },
            "id": "97320510",
            "status": 200,
            "message": "success"
        },
        {
            "resource": {
                "artifactType": "video",
                "id": "26042630",
                "title": "Don't Stop 'Til You Get Enough (Official Video)",
                "image": [
                    {
                        "url": "https://resources.tidal.com/images/60db6a78/5cb0/4e5d/a66c/49a54e0fd676/1024x256.jpg",
                        "width": 1024,
                        "height": 256
                    },
                    {
                        "url": "https://resources.tidal.com/images/60db6a78/5cb0/4e5d/a66c/49a54e0fd676/1080x720.jpg",
                        "width": 1080,
                        "height": 720
                    },
                    {
                        "url": "https://resources.tidal.com/images/60db6a78/5cb0/4e5d/a66c/49a54e0fd676/160x107.jpg",
                        "width": 160,
                        "height": 107
                    },
                    {
                        "url": "https://resources.tidal.com/images/60db6a78/5cb0/4e5d/a66c/49a54e0fd676/160x160.jpg",
                        "width": 160,
                        "height": 160
                    },
                    {
                        "url": "https://resources.tidal.com/images/60db6a78/5cb0/4e5d/a66c/49a54e0fd676/320x214.jpg",
                        "width": 320,
                        "height": 214
                    },
                    {
                        "url": "https://resources.tidal.com/images/60db6a78/5cb0/4e5d/a66c/49a54e0fd676/320x320.jpg",
                        "width": 320,
                        "height": 320
                    },
                    {
                        "url": "https://resources.tidal.com/images/60db6a78/5cb0/4e5d/a66c/49a54e0fd676/480x480.jpg",
                        "width": 480,
                        "height": 480
                    },
                    {
                        "url": "https://resources.tidal.com/images/60db6a78/5cb0/4e5d/a66c/49a54e0fd676/640x428.jpg",
                        "width": 640,
                        "height": 428
                    },
                    {
                        "url": "https://resources.tidal.com/images/60db6a78/5cb0/4e5d/a66c/49a54e0fd676/750x500.jpg",
                        "width": 750,
                        "height": 500
                    },
                    {
                        "url": "https://resources.tidal.com/images/60db6a78/5cb0/4e5d/a66c/49a54e0fd676/750x750.jpg",
                        "width": 750,
                        "height": 750
                    }
                ],
                "releaseDate": "2001-01-01",
                "artists": [
                    {
                        "id": "606",
                        "name": "Michael Jackson",
                        "picture": [
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1024x256.jpg",
                                "width": 1024,
                                "height": 256
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1080x720.jpg",
                                "width": 1080,
                                "height": 720
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x107.jpg",
                                "width": 160,
                                "height": 107
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x160.jpg",
                                "width": 160,
                                "height": 160
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x214.jpg",
                                "width": 320,
                                "height": 214
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x320.jpg",
                                "width": 320,
                                "height": 320
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/480x480.jpg",
                                "width": 480,
                                "height": 480
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/640x428.jpg",
                                "width": 640,
                                "height": 428
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x500.jpg",
                                "width": 750,
                                "height": 500
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x750.jpg",
                                "width": 750,
                                "height": 750
                            }
                        ],
                        "main": true
                    }
                ],
                "duration": 253,
                "trackNumber": 0,
                "volumeNumber": 0,
                "isrc": "USSM20402927",
                "copyright": "(C) 2001 SONY BMG MUSIC ENTERTAINMENT",
                "properties": {}
            },
            "id": "26042630",
            "status": 200,
            "message": "success"
        },
        {
            "resource": {
                "artifactType": "video",
                "id": "23988642",
                "title": "Billie Jean (Official Video)",
                "image": [
                    {
                        "url": "https://resources.tidal.com/images/aa8dbfd1/3dcc/4de7/95fb/28c53cefa4b2/1024x256.jpg",
                        "width": 1024,
                        "height": 256
                    },
                    {
                        "url": "https://resources.tidal.com/images/aa8dbfd1/3dcc/4de7/95fb/28c53cefa4b2/1080x720.jpg",
                        "width": 1080,
                        "height": 720
                    },
                    {
                        "url": "https://resources.tidal.com/images/aa8dbfd1/3dcc/4de7/95fb/28c53cefa4b2/160x107.jpg",
                        "width": 160,
                        "height": 107
                    },
                    {
                        "url": "https://resources.tidal.com/images/aa8dbfd1/3dcc/4de7/95fb/28c53cefa4b2/160x160.jpg",
                        "width": 160,
                        "height": 160
                    },
                    {
                        "url": "https://resources.tidal.com/images/aa8dbfd1/3dcc/4de7/95fb/28c53cefa4b2/320x214.jpg",
                        "width": 320,
                        "height": 214
                    },
                    {
                        "url": "https://resources.tidal.com/images/aa8dbfd1/3dcc/4de7/95fb/28c53cefa4b2/320x320.jpg",
                        "width": 320,
                        "height": 320
                    },
                    {
                        "url": "https://resources.tidal.com/images/aa8dbfd1/3dcc/4de7/95fb/28c53cefa4b2/480x480.jpg",
                        "width": 480,
                        "height": 480
                    },
                    {
                        "url": "https://resources.tidal.com/images/aa8dbfd1/3dcc/4de7/95fb/28c53cefa4b2/640x428.jpg",
                        "width": 640,
                        "height": 428
                    },
                    {
                        "url": "https://resources.tidal.com/images/aa8dbfd1/3dcc/4de7/95fb/28c53cefa4b2/750x500.jpg",
                        "width": 750,
                        "height": 500
                    },
                    {
                        "url": "https://resources.tidal.com/images/aa8dbfd1/3dcc/4de7/95fb/28c53cefa4b2/750x750.jpg",
                        "width": 750,
                        "height": 750
                    }
                ],
                "releaseDate": "1982-01-01",
                "artists": [
                    {
                        "id": "606",
                        "name": "Michael Jackson",
                        "picture": [
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1024x256.jpg",
                                "width": 1024,
                                "height": 256
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1080x720.jpg",
                                "width": 1080,
                                "height": 720
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x107.jpg",
                                "width": 160,
                                "height": 107
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x160.jpg",
                                "width": 160,
                                "height": 160
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x214.jpg",
                                "width": 320,
                                "height": 214
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x320.jpg",
                                "width": 320,
                                "height": 320
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/480x480.jpg",
                                "width": 480,
                                "height": 480
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/640x428.jpg",
                                "width": 640,
                                "height": 428
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x500.jpg",
                                "width": 750,
                                "height": 500
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x750.jpg",
                                "width": 750,
                                "height": 750
                            }
                        ],
                        "main": true
                    }
                ],
                "duration": 296,
                "trackNumber": 0,
                "volumeNumber": 0,
                "isrc": "USSM20301088",
                "copyright": "(C) 1982 MJJ Productions Inc.",
                "properties": {}
            },
            "id": "23988642",
            "status": 200,
            "message": "success"
        },
        {
            "resource": {
                "artifactType": "video",
                "id": "23794562",
                "title": "Thriller (Official Video - Shortened Version)",
                "image": [
                    {
                        "url": "https://resources.tidal.com/images/437ed41d/4d6b/49ce/983b/b5ec55e82ef6/1024x256.jpg",
                        "width": 1024,
                        "height": 256
                    },
                    {
                        "url": "https://resources.tidal.com/images/437ed41d/4d6b/49ce/983b/b5ec55e82ef6/1080x720.jpg",
                        "width": 1080,
                        "height": 720
                    },
                    {
                        "url": "https://resources.tidal.com/images/437ed41d/4d6b/49ce/983b/b5ec55e82ef6/160x107.jpg",
                        "width": 160,
                        "height": 107
                    },
                    {
                        "url": "https://resources.tidal.com/images/437ed41d/4d6b/49ce/983b/b5ec55e82ef6/160x160.jpg",
                        "width": 160,
                        "height": 160
                    },
                    {
                        "url": "https://resources.tidal.com/images/437ed41d/4d6b/49ce/983b/b5ec55e82ef6/320x214.jpg",
                        "width": 320,
                        "height": 214
                    },
                    {
                        "url": "https://resources.tidal.com/images/437ed41d/4d6b/49ce/983b/b5ec55e82ef6/320x320.jpg",
                        "width": 320,
                        "height": 320
                    },
                    {
                        "url": "https://resources.tidal.com/images/437ed41d/4d6b/49ce/983b/b5ec55e82ef6/480x480.jpg",
                        "width": 480,
                        "height": 480
                    },
                    {
                        "url": "https://resources.tidal.com/images/437ed41d/4d6b/49ce/983b/b5ec55e82ef6/640x428.jpg",
                        "width": 640,
                        "height": 428
                    },
                    {
                        "url": "https://resources.tidal.com/images/437ed41d/4d6b/49ce/983b/b5ec55e82ef6/750x500.jpg",
                        "width": 750,
                        "height": 500
                    },
                    {
                        "url": "https://resources.tidal.com/images/437ed41d/4d6b/49ce/983b/b5ec55e82ef6/750x750.jpg",
                        "width": 750,
                        "height": 750
                    }
                ],
                "releaseDate": "1982-01-01",
                "artists": [
                    {
                        "id": "606",
                        "name": "Michael Jackson",
                        "picture": [
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1024x256.jpg",
                                "width": 1024,
                                "height": 256
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1080x720.jpg",
                                "width": 1080,
                                "height": 720
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x107.jpg",
                                "width": 160,
                                "height": 107
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x160.jpg",
                                "width": 160,
                                "height": 160
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x214.jpg",
                                "width": 320,
                                "height": 214
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x320.jpg",
                                "width": 320,
                                "height": 320
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/480x480.jpg",
                                "width": 480,
                                "height": 480
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/640x428.jpg",
                                "width": 640,
                                "height": 428
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x500.jpg",
                                "width": 750,
                                "height": 500
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x750.jpg",
                                "width": 750,
                                "height": 750
                            }
                        ],
                        "main": true
                    }
                ],
                "duration": 200,
                "trackNumber": 0,
                "volumeNumber": 0,
                "isrc": "USSM20800257",
                "copyright": "(C) 1982, 2008 MJJ Productions Inc.",
                "properties": {}
            },
            "id": "23794562",
            "status": 200,
            "message": "success"
        },
        {
            "resource": {
                "artifactType": "video",
                "id": "26152443",
                "title": "Scream (Official Video)",
                "image": [
                    {
                        "url": "https://resources.tidal.com/images/96c930de/1ac0/44eb/94cf/e6d35fbbfb4c/1024x256.jpg",
                        "width": 1024,
                        "height": 256
                    },
                    {
                        "url": "https://resources.tidal.com/images/96c930de/1ac0/44eb/94cf/e6d35fbbfb4c/1080x720.jpg",
                        "width": 1080,
                        "height": 720
                    },
                    {
                        "url": "https://resources.tidal.com/images/96c930de/1ac0/44eb/94cf/e6d35fbbfb4c/160x107.jpg",
                        "width": 160,
                        "height": 107
                    },
                    {
                        "url": "https://resources.tidal.com/images/96c930de/1ac0/44eb/94cf/e6d35fbbfb4c/160x160.jpg",
                        "width": 160,
                        "height": 160
                    },
                    {
                        "url": "https://resources.tidal.com/images/96c930de/1ac0/44eb/94cf/e6d35fbbfb4c/320x214.jpg",
                        "width": 320,
                        "height": 214
                    },
                    {
                        "url": "https://resources.tidal.com/images/96c930de/1ac0/44eb/94cf/e6d35fbbfb4c/320x320.jpg",
                        "width": 320,
                        "height": 320
                    },
                    {
                        "url": "https://resources.tidal.com/images/96c930de/1ac0/44eb/94cf/e6d35fbbfb4c/480x480.jpg",
                        "width": 480,
                        "height": 480
                    },
                    {
                        "url": "https://resources.tidal.com/images/96c930de/1ac0/44eb/94cf/e6d35fbbfb4c/640x428.jpg",
                        "width": 640,
                        "height": 428
                    },
                    {
                        "url": "https://resources.tidal.com/images/96c930de/1ac0/44eb/94cf/e6d35fbbfb4c/750x500.jpg",
                        "width": 750,
                        "height": 500
                    },
                    {
                        "url": "https://resources.tidal.com/images/96c930de/1ac0/44eb/94cf/e6d35fbbfb4c/750x750.jpg",
                        "width": 750,
                        "height": 750
                    }
                ],
                "releaseDate": "1995-01-01",
                "artists": [
                    {
                        "id": "606",
                        "name": "Michael Jackson",
                        "picture": [
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1024x256.jpg",
                                "width": 1024,
                                "height": 256
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/1080x720.jpg",
                                "width": 1080,
                                "height": 720
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x107.jpg",
                                "width": 160,
                                "height": 107
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/160x160.jpg",
                                "width": 160,
                                "height": 160
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x214.jpg",
                                "width": 320,
                                "height": 214
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/320x320.jpg",
                                "width": 320,
                                "height": 320
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/480x480.jpg",
                                "width": 480,
                                "height": 480
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/640x428.jpg",
                                "width": 640,
                                "height": 428
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x500.jpg",
                                "width": 750,
                                "height": 500
                            },
                            {
                                "url": "https://resources.tidal.com/images/1493c264/d68a/4415/9013/2c913f2edf14/750x750.jpg",
                                "width": 750,
                                "height": 750
                            }
                        ],
                        "main": true
                    },
                    {
                        "id": "9033",
                        "name": "Janet Jackson",
                        "picture": [
                            {
                                "url": "https://resources.tidal.com/images/802bd615/1eb9/4e5f/a0d5/4284a046ac98/1024x256.jpg",
                                "width": 1024,
                                "height": 256
                            },
                            {
                                "url": "https://resources.tidal.com/images/802bd615/1eb9/4e5f/a0d5/4284a046ac98/1080x720.jpg",
                                "width": 1080,
                                "height": 720
                            },
                            {
                                "url": "https://resources.tidal.com/images/802bd615/1eb9/4e5f/a0d5/4284a046ac98/160x107.jpg",
                                "width": 160,
                                "height": 107
                            },
                            {
                                "url": "https://resources.tidal.com/images/802bd615/1eb9/4e5f/a0d5/4284a046ac98/160x160.jpg",
                                "width": 160,
                                "height": 160
                            },
                            {
                                "url": "https://resources.tidal.com/images/802bd615/1eb9/4e5f/a0d5/4284a046ac98/320x214.jpg",
                                "width": 320,
                                "height": 214
                            },
                            {
                                "url": "https://resources.tidal.com/images/802bd615/1eb9/4e5f/a0d5/4284a046ac98/320x320.jpg",
                                "width": 320,
                                "height": 320
                            },
                            {
                                "url": "https://resources.tidal.com/images/802bd615/1eb9/4e5f/a0d5/4284a046ac98/480x480.jpg",
                                "width": 480,
                                "height": 480
                            },
                            {
                                "url": "https://resources.tidal.com/images/802bd615/1eb9/4e5f/a0d5/4284a046ac98/640x428.jpg",
                                "width": 640,
                                "height": 428
                            },
                            {
                                "url": "https://resources.tidal.com/images/802bd615/1eb9/4e5f/a0d5/4284a046ac98/750x500.jpg",
                                "width": 750,
                                "height": 500
                            },
                            {
                                "url": "https://resources.tidal.com/images/802bd615/1eb9/4e5f/a0d5/4284a046ac98/750x750.jpg",
                                "width": 750,
                                "height": 750
                            }
                        ],
                        "main": false
                    }
                ],
                "duration": 288,
                "trackNumber": 0,
                "volumeNumber": 0,
                "isrc": "USSM20301503",
                "copyright": "(C) 1995 MJJ Productions Inc.",
                "properties": {}
            },
            "id": "26152443",
            "status": 200,
            "message": "success"
        }
    ]
}

export default TidalResponse