import { IPlaylist, ISong, MusicPlatform } from "../../interfaces/models/model-interfaces"
import AppleIcon from '../../assets/icon/streaming_applemusic.svg'
import { DefaultImage } from "../../constants"

class AppleParser {

    

    public static parsePlaylists(response: any): IPlaylist[] {
        const songsParsed: IPlaylist[] = response.map((playlist: any) => {
            const { attributes, id, relationships } = playlist
            const { name, artwork } = attributes
            const { tracks } = relationships
            const result: IPlaylist = {
                playlistId: id,
                playlistName: name,
                playlistArtworkUrl: this.getArtwork(artwork),
                tracks: AppleParser.parsePlaylistTracks(tracks.data),
                providerImg: AppleIcon,
                icon: AppleIcon

            }

            return result
        })
        return songsParsed
    }

    public static async parseArtists(response: any): Promise<ISong[]> {

        const songsParsed = response.map(({ artists }: any) => {
            if (!artists) return undefined;

            const { data } = artists;
            if (data.length > 0) {
                const { id, attributes } = data.shift();
                const { albumName, artwork, artistName, name, url, durationInMillis } = attributes;
                const result: ISong = {
                    platform: MusicPlatform.AppleMusic,
                    id,
                    songName: name,
                    img: this.getArtwork(artwork, '320', '200'),
                    albumName,
                    artistName: artistName,
                    url,
                    icon: AppleIcon,
                    durationInMillis
                };

                return result;
            }

            return undefined;
        }).filter((song: any) => song !== undefined);

        return songsParsed
    }

    public static parseAblums(response: any): ISong[] {

        const songsParsed: ISong[] = response.map((song: any) => {
            const { id, attributes } = song
            const { albumName, artwork, artistName, name, url, durationInMillis } = attributes
            const result: ISong = {
                platform: MusicPlatform.AppleMusic,
                id,
                songName: name,
                img: this.getArtwork(artwork, '320', '200'),
                albumName,
                artistName,
                url,
                icon: AppleIcon,
                durationInMillis
            }

            return result
        })
        return songsParsed
    }

    public static parseResponse(response: any): ISong[] {

        const songsParsed: ISong[] = response.map((song: any) => {
            const { id, attributes } = song
            const { albumName, artwork, artistName, name, url, durationInMillis } = attributes
            const result: ISong = {
                platform: MusicPlatform.AppleMusic,
                id,
                songName: name,
                img: this.getArtwork(artwork, '320', '200'),
                albumName,
                artistName,
                url,
                icon: AppleIcon,
                durationInMillis
            }

            return result
        })
        return songsParsed
    }

    public static getArtwork(artwork: any, width = '640', height = '400'): string {
        if (artwork === undefined) {
            return DefaultImage
        }
        return artwork.url.replace('{w}', width).replace('{h}', height)
    }
  

    public static parsePlaylistTracks(tracks: any[]): any[] {
        const songsParsed: ISong[] = tracks.map((song: any) => {
            const { id, attributes } = song
            const { albumName, artwork, artistName, name, url, durationInMillis, playParams } = attributes
            const { catalogId, purchasedId } = playParams
            const result: ISong = {
                platform: MusicPlatform.AppleMusic,
                id: catalogId || purchasedId ,
                songName: name,
                img: this.getArtwork(artwork, '320', '200'),
                albumName,
                artistName,
                url,
                icon: AppleIcon,
                durationInMillis,
                trackInfo: song
            }

            return result
        })
        return songsParsed //filter((song) => song.id !== undefined)
    }
}
export default AppleParser