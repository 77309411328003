import React from 'react';
import './StreamActionCard.scss'
import PlusIcon from '../../assets/icon/plus.svg'
import TrashIcon from '../../assets/icon/trash.svg'
import { useAuth0, User } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { sendStreamAction } from '../../actions/stream/stream-actions';
import { PlatformModel } from '../../interfaces/models/model-interfaces';
import { useSelector } from 'react-redux';
import { uiSelector } from 'store/selectors';
import { changeColorMode } from '@utils/colors';
import { useAddUserPlatformMutation } from 'services/movie.services';
import axios from 'axios'


interface StreamActionCardProps {
    streamInfo: any
    action: string
    userName?: string
    SvgIcon?: any
}

const StreamActionCard: React.FC<StreamActionCardProps> = ({ streamInfo, action, SvgIcon }) => {

    const freePlatforms = ['YouTube Music', 'Tidal Music']
    const isFreePlatform = freePlatforms.includes(streamInfo.name)

    const [addUserPlatform] = useAddUserPlatformMutation()
    const dispatch = useDispatch()
    const auth0: User = useAuth0()
    const { name, sub } = auth0.user
    const isDarkMode = useSelector(uiSelector).darkMode

    interface StreamActionInterface {
        action: string
        streamInfo: PlatformModel
    }

    const clickHandler = async (action: StreamActionInterface) => {

        dispatch(sendStreamAction(action))
    }



    return (
        <div className='stream_card'
            style={{
                backgroundColor: changeColorMode(!isDarkMode),
                border: `1px solid ${changeColorMode(!isDarkMode)}`,
                cursor: 'pointer'
            }}
        >
            {!!SvgIcon ? <SvgIcon /> : <img src={streamInfo.logo} className='stream_card__container' alt="stream-card" />}
            {action === 'add' ? <span className='stream_card__title' style={{
                color: changeColorMode(isDarkMode)
            }}>{streamInfo.name}</span> :
                <div className='stream_card__name__container'>
                    <span className='stream_card__name__container__title' style={{
                        color: changeColorMode(isDarkMode)
                    }}>{name}</span>
                    <span className='stream_card__name__container__streamTitle'>{streamInfo.name}</span>
                </div>
            }
            {action === 'add' ?
                <button className='stream_card__button add_button' onClick={() => clickHandler({ action: 'add', streamInfo })}>
                    <img src={PlusIcon} />
                </button>
                : isFreePlatform ?
                    null
                    : <button style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }} className='stream_card__button' onClick={() => clickHandler({ action: 'remove', streamInfo })}>
                        <img src={TrashIcon} />
                    </button>
            }

        </div>
    )
}

export default StreamActionCard;