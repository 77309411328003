import { uiSelector } from 'store/selectors';
import './TitleInfoComponent.scss'
import { useSelector } from 'react-redux';
import { changeColorMode } from '@utils/colors';

interface TitleInfoComponentProps {
    title: string;
    info?: string;
}

const TitleInfoComponent = ({ title, info = '' }: TitleInfoComponentProps) => {
    const isDarkMode = useSelector(uiSelector).darkMode

    return (
        <div>
            <span className="title" style={{ color: changeColorMode(isDarkMode, '#9CA3AF') }}>{title}</span>
            <h4 className="info" style={{ color: changeColorMode(isDarkMode) }}>{info}</h4>
        </div>
    );
};

export default TitleInfoComponent;
