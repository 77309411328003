import { changeColorMode } from '@utils/colors';
import { useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { uiSelector } from 'store/selectors';
import { contentProviders } from '@utils/contentProviderObject';
import { AddIcon, PlayButtonIcon } from '@components/IconComponents/IconComponents';
import { useWindowDimensions } from '@hooks/useWindowDimension';
import './MovieShelfItem.scss'
interface MovieShelfItemProps {
    movieImgUrl?: string;
    movieTitle?: string;
    onPressMovie?: () => void;
    linkTo?: string;
    movieProvider?: string[];
    type?: string;
    content_type?: string;
    movieClassification?: string;
    runTime?: string;
    tags?: any[];
    setHover?: any;
    onHover?: boolean;
}

const MovieShelfItem = ({
    movieClassification,
    content_type, type = "movie",
    movieProvider = [],
    linkTo,
    movieImgUrl,
    movieTitle,
    onPressMovie,
    runTime,
    tags,
    setHover = () => { },
    onHover
}: MovieShelfItemProps) => {

    const providerLogo = contentProviders.find((item) => item.name === movieProvider[0])?.logo
    const { isMobile } = useWindowDimensions()
    const isDarkMode = useSelector(uiSelector).darkMode
    const navigation = useNavigate()
    const [onMouseHover, setOnMouseHover] = useState(false)
    const handlePressMovie = () => {
        const pathname = type === "movie" ? `/movie/${linkTo}` : `/merge-movies/tv-show/${linkTo}`
        navigation({
            pathname
        })
    }

    return (
        <div
            onClick={handlePressMovie}
            style={{
                transition: 'all 0.5s ease-in-out',
                height: isMobile ? '147px' : '385px',
                zIndex: onMouseHover ? 1 : 0,
            }}
            className='movie-container'
            onMouseEnter={isMobile ? () => { } : () => {
                setOnMouseHover(true)
                setHover(true)
            }} onMouseLeave={() => {
                setOnMouseHover(false)
                setHover(false)
            }}>
            <div
                style={{
                    height: isMobile ? '100%' : onMouseHover ? '100%' : '70%',
                    scale: isMobile ? '1' : onMouseHover ? 0.4 : 1,
                    backgroundRepeat: 'no-repeat',

                }}
                className='movie-img'>
                {!!providerLogo &&
                    <img style={{
                        height: '30px',
                        width: '30px',
                        position: 'absolute',
                        objectFit: 'contain',
                        bottom: 10,
                        right: 10
                    }} src={providerLogo} alt='content-provider' />
                }

                <img loading='lazy' src={movieImgUrl} alt={movieTitle} style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'fill',
                    borderRadius: '10px',
                    scale: onMouseHover ? 0.4 : 1,

                }} />
                {onMouseHover && <div className='movie-hover-detail'>
                    <div className='movie-detail-top-container'>
                        <span className='movie-detail-top-title'>{movieTitle}</span>
                        <div className='movie-classification'>{movieClassification}</div>
                    </div>
                    <div className='movie-detail-info-container'>
                        <div className='movie-detail-info'>{runTime}</div>
                        {!!tags?.[0]?.display_name && <div className='movie-detail-info'>{tags?.[0]?.display_name}</div>}
                        {!!tags?.[1]?.display_name && <div className='movie-detail-info'>{tags?.[1]?.display_name}</div>}
                    </div>
                    <div style={{
                        marginTop: 10, display: 'flex', alignItems: 'center',
                        gap: 8
                    }}>
                        <PlayButtonIcon />
                        <AddIcon className='movie-detail-info' />
                    </div>
                </div>}
            </div>
            {!onMouseHover && <span className='movie-title' style={{ color: changeColorMode(isDarkMode) }}>{movieTitle}</span>}

        </div>
    )
}

export default MovieShelfItem