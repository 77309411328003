import { SearchActionTypes } from "reducers/search/search-reducer";


export const clearSearch = () => ({
    type: SearchActionTypes.CLEAR_STATE,
});

export const updateSearchInput = (input: string) => ({
    type: SearchActionTypes.UPDATE_CURRENT_SEARCH_INPUT,
    payload: input,
})

export const saveSearchResult = (result: any) => ({
    type: SearchActionTypes.UPDATE_RESULTS,
    payload: result,
});
