import IconPlay from 'assets/icon/play.png'
import { changeColorMode } from '@utils/colors'
import { DeleteIcon, EditIcon, ShareIcon } from '@components/IconComponents/IconComponents'
import { useSelector } from 'react-redux'
import { uiSelector } from 'store/selectors'
import { useWindowDimensions } from '@hooks/useWindowDimension'
import './ButtonsSettings.scss'

const ButtonsSettings = ({ clickHandler }: any) => {
    const isDarkMode = useSelector(uiSelector).darkMode
    const { isMobile } = useWindowDimensions()
    const playPlaylist = (event: any) => {

    }

    return (
        <>
            {isMobile ?
                <div className='buttons__container'>
                    <hr color='#E5E7EB' style={{ position: 'absolute', background: "#E5E7EB", width: '100%', left: 0 }} />
                    <button className='circle__button' style={{visibility: "hidden"}}>
                        <ShareIcon strokeColor={"#6B37FE"} />
                    </button>
                    <button className='play_button' style={{ background: "#6B37FE" }}>
                        <img src={IconPlay} alt='Icon Play' onClick={clickHandler} />
                    </button>
                    <button className='icon_button' style={{visibility: "hidden"}}>
                        <EditIcon strokeColor={"#6B37FE"} />
                    </button>
                </div> :
                <div className='buttons-settings-container'>
                    <button className='play_button' onClick={clickHandler}>
                        <img src={IconPlay} alt='Icon Play' onClick={playPlaylist} />
                    </button>
                    {/* <button className='icon_button'>
                        <EditIcon strokeColor={changeColorMode(isDarkMode)} />
                    </button>
                    <button className='icon_button'>
                        <ShareIcon strokeColor={changeColorMode(isDarkMode)} />
                    </button>
                    <button className='icon_button'>
                        <DeleteIcon strokeColor={changeColorMode(isDarkMode)} />
                    </button> */}
                </div>
            }
        </>
    )
}

export default ButtonsSettings